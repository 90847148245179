import React from 'react';
import { useState, useRef }from "react";
import EyeIcon from './assets/svgs/eye.svg'
import EyeClosedIcon from './assets/svgs/eye-close.svg'
import './assets/styles/input-password.scss'
import { UseControllerProps } from 'react-hook-form';

interface InputPasswordProps extends UseControllerProps {
  autoComplete?: string;
}

const InputPassword = React.forwardRef((
  props: InputPasswordProps,
  ref: React.Ref<HTMLDivElement>
  ) => {
  
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    setVisible(!visible)
  }
  
  return (
    <div className="input-password-control" ref={ref}>
      <input type={visible ? 'text': 'password'} {...props} />
      <button type="button" className="toggle" onClick={toggleVisible} >
        <img src={visible ? EyeClosedIcon : EyeIcon} alt=""/>
      </button>
    </div>
  ) 
});

export default InputPassword;