import OptionButtonsTick from 'shared/forms/option-buttons-tick';
import useSignUpType from '../hooks/sign-up.hook';
import { SignUpTypes } from '../types';
import './assets/styles/select-sign-up-as.scss';
import FormLayout from './form-layout'
import useOnboarding from '../hooks/onboarding.hook';
import ActionBar from '../action-bar/action-bar';
import { getSelectedOptions, StartupOptions, CorporateOptions } from '../utils/utils'

const MyRequests = () => {
  const { signUpType } = useSignUpType()
  const { onboardingPayload, setOnboardingPayload } = useOnboarding()

  const setOptions = async (options: string[]) => {
    const myRequests: Record<string, boolean> = {}
    for (let opt of options) {
      myRequests[opt] = true
    }
    await setOnboardingPayload({
      requests: myRequests
    })
  }

  const handleNext = async (nextStep: () => unknown) => {
    await setOnboardingPayload({
      step: 2
    })

    nextStep()
  }

  const subHeading = window.screen.width > 767 ? "Get Started" : (
    signUpType === SignUpTypes.STARTUP ?
    "Startup Onboarding" : "Corporate Onboarding"
  )

  return (
    <FormLayout
      subHeading={subHeading}
      heading="My Request"
      formClassName="sign-up-as"
    >
      <div className="row">
        <div className="col mt-md-5 mb-md-0 mb-2 mt-3 mt-md-0">
          <div className="color-text font-size-12 text-center text-md-start text-uppercase">i want to</div>
        </div>
      </div>
      <div className="row">
        <div className="col options">
          <OptionButtonsTick
            addClassName='mobile-azure'
            options={signUpType === SignUpTypes.STARTUP ? StartupOptions : CorporateOptions}
            selectedOptions={getSelectedOptions(onboardingPayload?.requests?? [], signUpType)}
            onSelect={(selected) => {
              setOptions(selected);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ActionBar onNext={handleNext} />
        </div>
      </div>
    </FormLayout>
  )
}

export default MyRequests;