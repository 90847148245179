import React, { useState } from 'react'
import './assets/styles/chat-widget-component.scss'
import Button from 'app/shared/button'
import CaretIcon from './assets/icons/caret.svg';
import ChatIcon from './assets/icons/chat.svg';
import Chat, { ChatProps } from './chat'

const ChatWidget = (props: ChatProps) => {
  const [active, setActive] = useState<boolean>(false)
  const handleOpenChat = () => {
    setActive(true)
  }

  const handleClose = () => {
    setActive(false)
  }

  return (
    <div className="chat-widget-component">
      <Button variant="icon me-3" className="chat-button" onClick={handleOpenChat}>
        <img src={ChatIcon} alt="chat" />
        <div className="label">  
          Chat
        </div>
        <img src={CaretIcon} alt="caret" />
      </Button>
      <Chat {...props} active={active} onClose={handleClose} />
    </div>
  )
}

export default ChatWidget