import './assets/styles/service-card.scss';

type Props = {
  image: string;
  title: string;
  body: string;
}

const ServiceCard = ({
  image,
  title,
  body
}: Props) => {
  return (
    <div className="service-card">
      <div className="image">
        <img src={image} alt={title} />
      </div>
      <div className="content">
        <h5>
          {title}</h5>
        <div className="body txt-light font-size-14 line-height-140 color-text">
          {body}
        </div>
      </div>
    </div>
  )
}

export default ServiceCard;