import React from 'react';
import Tile from 'app/shared/tile';
import FileGreenIcon from 'app/shared/assets/svgs/file-green.svg';
import CalendarIcon from 'app/shared/assets/svgs/calender.svg';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { 
  useGetAllDocumentsQuery
} from 'services/graphql/document-sharing';
import dayjs from 'dayjs';
import './assets/styles/documents.scss'


type DocumentItemProp = {
  name: string;
  updatedSince: string;
  url: string;
}

const DocumentItem = ({
  name,
  updatedSince,
  url,
}: DocumentItemProp) => {
  return (
    <a className="document" href={url} target="_blank" rel="noreferrer" >
      <img className="icon" src={FileGreenIcon} alt={name} />
      <h5>{name}</h5>
      <div className="mt-auto footer">
        <img src={CalendarIcon} alt="since"/><div>{updatedSince}</div>
      </div>
    </a>
  )
}

const AllDocuments = () => {
  const user = useSelector(selectCurrentUser);
  const { data, isSuccess } = useGetAllDocumentsQuery(user?.id?? '')

  return (
    <Tile 
      title="My Documents">
      <div className="all-documents">
        {isSuccess && (data?? []).map(({title, created_at, document}, i) => (
          <DocumentItem 
            key={i}
            name={title?? ''}
            url={document?.url?? ''}
            updatedSince={dayjs(created_at).from(dayjs())}
          />
        ))}
      </div>
    </Tile>
  )
}

export default AllDocuments;
