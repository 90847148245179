import './assets/styles/price.scss';

interface Props {
  price: number;
  currency: string;
}

export const Price = ({
  price,
  currency
}: Props) => {
  return (
    <div className="price-box flex-row d-flex color-nightsky justify-content-center align-items-end">
      <div className="bold-type font-size-14 letter-spacing-n-2 me-1 line-height-100">${new Intl.NumberFormat().format(price)}</div>
      <div className="bold-type font-size-10 letter-spacing-n-2 line-height-100">{currency}</div>
    </div>
  )
}