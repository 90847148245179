import './assets/styles/input-inline.scss';
import LinePencilIcon from 'app/shared/assets/svgs/line-pencil.svg';
import React, { useEffect, useState, useRef } from 'react';
import BaseInput from './base-input';
import _ from 'lodash';

interface InputInlineProps extends BaseInput {
}

const InputInline = React.forwardRef(({
  className, 
  ...rest
}: InputInlineProps, 
ref:React.Ref<HTMLDivElement>) => {
  const { onChange, value, defaultValue, placeholder, ...others } = rest;
  const inputElement = useRef<HTMLInputElement>(null)
  const displayElement = useRef<HTMLDivElement>(null)
  const [text, setText] = useState<string>(value || defaultValue || placeholder)
  const onInputChange = (evt:React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(evt);
  }
  
  const onKeyDown = (evt:React.KeyboardEvent<HTMLInputElement>) => {
    setText(evt.currentTarget.value.length > 0 ? evt.currentTarget.value : placeholder?? '')
  }
  const onKeyUp = (evt:React.KeyboardEvent<HTMLInputElement>) => {
    setText(evt.currentTarget.value.length > 0 ? evt.currentTarget.value : placeholder?? '')
  }

  return (
    <div className={`input-inline ${className ?? ''}`} ref={ref}>
      <div className="input-set">
        <input type="text"
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          defaultValue={value}
          placeholder={placeholder}
          {...others}
          ref={inputElement}
        />
        <div className="text-width-render">{text}</div>
      </div>
      <button className="edit" 
        type="button"
        onClick={() => {
          if (inputElement && inputElement.current) {
            inputElement.current.focus();
            inputElement.current.select();
          }
        }}>
        <img src={LinePencilIcon} alt="edit" />
        <label>Edit</label>
      </button>
    </div>
  )
});

export default InputInline;