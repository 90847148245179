import Button from 'app/shared/button'
import CircleTickWhiteIcon from 'app/shared/assets/svgs/circle-tick-white.svg'
import UploadWhiteIcon from './assets/icons/upload-white.svg' 
import { 
  useAgreeConverstationNDAMutation,
  useUpdateConversationMutation
} from 'services/graphql/conversation'
import { useSelector, useDispatch } from 'react-redux';
import { appReducerStateType } from 'store';
import { close } from 'features/modals/modalSlice'
import { useParams } from 'react-router-dom';
import InputFiles from 'shared/forms/input-files';
import EntityConstants from 'app/entity-type/entityConstants';
import { FileItem } from 'hooks/file-uploads';
import { useState, useEffect } from 'react';
import './assets/styles/mutual-nda.scss';
import DownloadIcon from 'app/shared/assets/svgs/download-blue.svg';
import _ from 'lodash';

const MutualNDA = () => {
  const dispatch = useDispatch()
  const [file, setFile] = useState<FileItem | null>(null)
  const { parameters } = useSelector((state: appReducerStateType) => state.modal)
  const [ agreeConversation, { isLoading, isSuccess } ] = useAgreeConverstationNDAMutation()
  const [ updateConversation ] = useUpdateConversationMutation()
  const { entityType }: any = useParams();

  const handleAgree = async () => {
    if (parameters && parameters.id)
      await agreeConversation(parameters?.id);
      dispatch(close());
  }

  const onNDAupload = async (files: FileItem[]) => {
    if (files.length === 1) {
      setFile(files[0])
    
      await updateConversation({
        id: parameters?.id,
        conversation: {
          nda_corporate_upload: files[0].id
        }
      });
    }
  }

  useEffect(() => {
    console.log(parameters)
    if (parameters?.nda_corporate_upload) {
      setFile(parameters?.nda_corporate_upload)
    }
  }, [])  

  return (
    <div className="d-flex flex-column justify-content-center">
      <h6>Information shared by Disclosing Party</h6>
      { !_.isEmpty(file) ?
      <div className="fluid-container">
        <div className="row mb-5">
          <div className="col">
            {(file !== null && file !== undefined) ? 
            <>
              <iframe src={file.url} className="corp-nda-doc" title="corporate-nda"></iframe>
              <a className="mt-3 d-flex flex-row align-items-center font-size-14 semi-bold-type color-azure" href={file.url} target="_blank" rel="noreferrer">
                <img src={DownloadIcon} className="me-2" />
                Download NDA
              </a>
            </>
            : null}
          </div>
        </div>
      </div>
      : 
      <>
        <p>
          This Agreement is intended to allow the parties to exchange, in connection with discussions and a potential business relationship (“Business Purpose”), certain information concerning the project, which includes confidential information. The party disclosing confidential information, the Corporate  (“Disclosing Party”) is willing to provide such information to the other party, the Startup (“Recipient”), subject to the execution of and in reliance on this Agreement. 
        </p>
        <h6 className="mt-3">Obligation to Maintain Confidentiality</h6>
        <p>
        In consideration of the covenants contained herein and the disclosure of the confidential information to Recipient, the parties agree to keep the most strict confidentiality. 
        </p>
        <h6 className="mt-3">Agreement</h6>
        <p>
        You solemnly agree to keep all the information revealed for you and for the project purposes only. You will not reveal by any means any information without the prior approval of the parties involved.
        </p>
      </>
      }
      <div className="d-flex mt-4 flex-row justify-content-center align-items-center">
        <Button variant="primary" 
          disabled={isLoading || isSuccess}
          className="flex-row d-flex align-items-center justify-content-center wide"
          onClick={handleAgree}>
          <img src={CircleTickWhiteIcon} alt="" className="me-2" />
          I Solemnly agree
        </Button>
      </div>
    </div>
  )
}

export default MutualNDA