import React, { useContext, useEffect } from 'react';
import Header from './header';
import Menu from './menu';
import { MenuProps } from './menu'
import './assets/styles/layout.scss';
import BackgroundImage from 'app/shared/assets/imgs/app-background.jpg';
import { useLocation, useHistory } from 'react-router-dom';
import { MenuContext } from '../providers/menu.provider'
import ButtonBack from 'app/shared/header/button-back'

interface LayoutProps {
  menu: MenuProps | null;
  children?: React.ReactNode;
}

const Layout = ({
  menu,
  children
}: LayoutProps) => {
  const { menuActive } = useContext(MenuContext)
  
  const { pathname } = useLocation();
  const { goBack } = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0, 
      behavior: 'auto'
    })
  }, [pathname]);
  
  return (
    <>
      <Header></Header>
      <Menu active={menuActive} links={menu?.links?? []} ></Menu>
      <div id="main" className={`main ${menuActive ? 'menu-active':''}`} style={{backgroundImage: `url(${BackgroundImage})}`}}>
        <div className="main-inner">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                {pathname.indexOf('/profile') > 0 ?
                <div className="top-back-button">
                  <ButtonBack onClick={() => {
                    goBack();
                  }}/>
                </div>
                : null}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;