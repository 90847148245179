import React, { useEffect, useState, useCallback } from 'react';
import SelectOne from "shared/forms/select-one";
import Button from 'app/shared/button';
import CircleOPlusBlueIcon from 'app/shared/assets/svgs/circle-o-plus-small-blue.svg';
import CircleOCrossIcon from 'app/shared/assets/svgs/circle-o-cross.svg';
import {
  useFieldArray,
  Control,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  DeepMap,
  DeepPartial,
  FieldError,
  FieldArrayWithId,
} from 'react-hook-form';
import { BusinessChallengeInputs } from '../types';
import { ProjectBudgetOptionsCorporate, Currencies } from 'app-constants';

export interface ProjectBudgetItem {
  id: string;
  currency: string;
  max_budget: number;
  option: string;
}
interface ProjectBudgetProps {
  control: Control<BusinessChallengeInputs>;
  register: UseFormRegister<BusinessChallengeInputs>;
  errors: DeepMap<DeepPartial<BusinessChallengeInputs>, FieldError>;
}

const BusinessChallengeProjectBudget = React.forwardRef(({
  control,
  register,
  errors
}: ProjectBudgetProps,
  ref: React.ForwardedRef<HTMLDivElement>) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "project_budget"
  });

  useEffect(() => {
  }, [])

  let _minBudgets: string[] = [];
  fields.forEach(x => {
    _minBudgets.push(x.max_budget.toString())
  });

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({} as any), []);

  const [availableOptions, setAvailableOptions] = useState([
    ...ProjectBudgetOptionsCorporate
  ] as string[]);
  useEffect(() => {
    setAvailableOptions(availableOptions)
  }, [availableOptions])

  useEffect(() => {
    if (fields.length === 0) {
      append({
        currency: '',
        max_budget: 0,
        option: ''
      })
    }
  }, [fields])

  const renderItems = (items: FieldArrayWithId<BusinessChallengeInputs, "project_budget", "id">[]) => items.map((item, i) => {
    return (
      <div key={i} className="col-lg-8 col-xs-12">
        {i > 0 ? <div className="row mb-3"><hr /></div> : null}
        <div className="row">
          <div className="col-lg-1 col-1 step4-coporate-project-button-remove-lg">
            <div
              className="mt-3 pt-3 pb-4 pb-m-0"
              style={{
                marginLeft:'0px',
                display: i === 0 ? 'hidden' : ''
              }}>
              <div
                style={{cursor:'pointer'}}
                onClick={() => {
                  if (i > 0)
                    remove(i);
                }}
              >
                <img src={CircleOCrossIcon} alt="delete" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-11">
            <div className="form-field">
              <label>Max Budget *</label>
              <div className="input-group">
                <input type="text"
                  style={{
                    flex: "1 1 auto",
                    width: '0px',
                    paddingRight: 0,
                    minWidth: '100px'
                  }}
                  {...register(`project_budget.${i}.max_budget`, {
                    required: true, min: 1
                  })}
                />
                <Controller
                  name={`project_budget.${i}.currency`}
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => <SelectOne
                    variant="thicker"
                    options={Currencies} style={{ flex: "0 0 100px" }}
                    {...field} />}
                />
              </div>
              <div className={`invalid-feedback ${errors?.project_budget?.[i]?.max_budget ? 'd-block' : ''}`}>Please enter a valid budget</div>
              <div className={`invalid-feedback ${errors?.project_budget?.[i]?.currency ? 'd-block' : ''}`}>Please select a currency</div>
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="form-field">
              <label>Option</label>
              <Controller
                name={`project_budget.${i}.option`}
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  options={availableOptions}
                  {...field} />}
              />
              <div className={`invalid-feedback ${errors?.project_budget?.[i]?.option ? 'd-block' : ''}`}>Please select an option</div>
            </div>
          </div>
        </div>
      </div>
    )
  });

  return (
    <div className="row">
      {renderItems(fields.length > 0 ? fields : [])}
      <div className="col-lg-4 col-xs-12">
        <div className="mt-3 pt-4">
          <Button variant="btn-link icon"
            onClick={() => {
              append({
                currency: '',
                max_budget: 0,
                option: ''
              })
            }}
          >
            <><img src={CircleOPlusBlueIcon} alt="add" />{fields ? fields.length > 0 ? "Add Another Option" : "Add Budget" : "Add Budget"}</>
          </Button>
        </div>
      </div>
    </div>
  )
})

export default BusinessChallengeProjectBudget;