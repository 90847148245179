import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { conversationApi } from 'services/graphql/conversation';
import { onBoardingSessionApi } from 'services/graphql/on-boarding-session';

export type InProgressState = {
  progress: Boolean;
}

const initialState = {
  progress: false
}

const setProgressTrue = (state: InProgressState) => {
  state.progress = true
}

const setProgressFalse = (state: InProgressState) => {
  state.progress = false
}

export const inProgressSlice = createSlice({
  name: 'inProgress',
  initialState,
  reducers: {
    reset: (state: InProgressState) => {
      state = {...initialState}
    },
    show: (state: InProgressState) => {
      state.progress = true
    },
    hide: (state: InProgressState) => {
      state.progress = false
    },
  },
  extraReducers: (builder) => {
    const apiMatchPending = [
      conversationApi.endpoints.updateConversation.matchPending,
      onBoardingSessionApi.endpoints.createOboardingSession.matchPending,
      onBoardingSessionApi.endpoints.updateOnboardingSession.matchPending,
      onBoardingSessionApi.endpoints.completeOnboardingSession.matchPending,
      onBoardingSessionApi.endpoints.useVerificationCode.matchPending,
      onBoardingSessionApi.endpoints.regenerateVerificationCode.matchPending,
      
    ]
    const apiMatchFulfilled = [
      conversationApi.endpoints.updateConversation.matchFulfilled,
      onBoardingSessionApi.endpoints.createOboardingSession.matchFulfilled,
      onBoardingSessionApi.endpoints.updateOnboardingSession.matchFulfilled,
      onBoardingSessionApi.endpoints.completeOnboardingSession.matchFulfilled,
      onBoardingSessionApi.endpoints.useVerificationCode.matchFulfilled,
      onBoardingSessionApi.endpoints.regenerateVerificationCode.matchPending,
    ]

    apiMatchPending.forEach((api) => {
      builder.addMatcher(
        api,
        setProgressTrue
      );
    })

    apiMatchFulfilled.forEach((api) => {
      builder.addMatcher(
        api,
        setProgressFalse
      );
    })
  }
});

export default inProgressSlice.reducer;

export const { reset } = inProgressSlice.actions;

export const selectInProgress = (state: RootState) => state.inProgress;