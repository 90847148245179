import { createSlice } from '@reduxjs/toolkit'
import { profileApi } from 'services/graphql/profile'
import type { RootState } from 'store'
import { PersonProfile, UploadFile, Enum_Personprofile_Profile_Type } from 'generated/graphql';

export type headerProfileState = {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  photo: UploadFile | null;
  profile_type: Enum_Personprofile_Profile_Type | null;
  organisation_id: string | null;
}

const initialState = { 
  firstName: null, 
  lastName: null, 
  photo: null, 
  profile_type: null, 
  organisation_id: null 
} as headerProfileState;

export const headerProfileSlice = createSlice({
  name: 'headerProfile',
  initialState,
  reducers: {
    resetHeader: (state) => {
      state = {...initialState}
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.myHeaderProfile.matchFulfilled,
      (state, { payload }) => {
        const { id, first_name, last_name, photo, profile_type } = payload.person as PersonProfile;
        const organisation_id = payload.organisation_id;
        state.id = id;
        state.profile_type = profile_type?? null;
        state.firstName = first_name?? '';
        state.lastName = last_name?? '';
        state.photo = photo ?? null;
        state.organisation_id = organisation_id ?? '';
      }
    )
  },
})

export default headerProfileSlice.reducer

export const { resetHeader } = headerProfileSlice.actions

export const selectHeaderProfile = (state: RootState) => state.headerProfile