import React, { FC, createContext } from 'react'
import { io } from "socket.io-client";
import {
  invalidateCacheTechnologyRequestMatches,
  invalidateCacheTechnologySolutionMatches
} from 'services/graphql/match-making-result'
import { useDispatch } from 'react-redux';

export const SocketContext = createContext(null)

type Props = {
  children?: React.ReactNode
}

const SocketProvider: FC<Props> = (props) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    const socket = io((process.env.REACT_APP_WS as string));
    // const jwt = localStorage.getItem("access_token");

    socket.on('match-making-complete', (res) => {
      console.log('match making result updated', res)
      if (res.type === "TECHNOLOGY_REQUEST") {
        console.log('dispatch tech request matches')
        dispatch(invalidateCacheTechnologyRequestMatches(res.technologyRequest.id))
      }
      if (res.type === "TECHNOLOGY_SOLUTION") {
        console.log('dispatch tech solution matches')
        dispatch(invalidateCacheTechnologySolutionMatches(res.technologySolution.id))
      }
    })
  }, [])
  
  return (
    <SocketContext.Provider value={null}>
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketProvider;