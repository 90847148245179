import { 
  useCorporateSearchTechnologySolutionsQuery, 
  SearchTechnologySolutionInput
} from 'services/graphql/tech-solutions';
import {
  useGetCurrentUserBookmarksQuery
} from 'services/graphql/bookmarks';
import TechnologySolutionCard from './technology-solution-card';
import { Enum_Bookmark_Category, Enum_Bookmark_Type, Bookmark, TechnologySolution } from 'generated/graphql';
import ExploreHelpBanner from 'app/shared/explore-help-banner/index';

interface ResultsProps {
  filters: SearchTechnologySolutionInput;
}

const Results = ({filters}: ResultsProps) => {
  const { data, isLoading, isSuccess } = useCorporateSearchTechnologySolutionsQuery(filters)
  const bookmarksQuery = useGetCurrentUserBookmarksQuery()
  
  const isBookmarked = (techSolution: TechnologySolution, bookmarks: Array<Bookmark> ) => {
    const matched = bookmarks.filter(item => 
      (item.type !== undefined && item.type  === Enum_Bookmark_Type.TechnologySolution) 
      && (item.category !== undefined && item.category === Enum_Bookmark_Category.Save))
      .find((item) => item.item_id === Number(techSolution.id))

    return matched !== undefined;
  }

  const getBookmarkId = (techSolution: TechnologySolution, bookmarks: Array<Bookmark> ) => {
    const matched = bookmarks.filter(item => 
      (item.type !== undefined && item.type  === Enum_Bookmark_Type.TechnologySolution) 
      && (item.category !== undefined && item.category === Enum_Bookmark_Category.Save))
      .find((item) => item.item_id === Number(techSolution.id))

    return matched && matched.id;
  }

  return (
    <div>
      {(isLoading || bookmarksQuery.isLoading) && 
      <div>Loading Results</div>}
      {isSuccess && bookmarksQuery.isSuccess && 
        data?.map((item, i) => <TechnologySolutionCard 
          {...{
            ...item, 
            bookmarked: isBookmarked(item, bookmarksQuery.data),
            bookmarkedId: getBookmarkId(item, bookmarksQuery.data)
            }
          } 
            key={i}/>)}
      {isSuccess && <ExploreHelpBanner/>}
    </div>
  )
}

export default Results;