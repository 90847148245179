import './assets/styles/certify.scss';
import BackgroundImage from 'app/shared/assets/imgs/certify-background.jpg';
import CertificateIcon from 'app/shared/assets/svgs/certificate-blue.svg';
import Button from 'app/shared/button';
import UnlockIcon from 'app/shared/assets/svgs/unlock.svg';
import { useHistory } from 'react-router-dom';


const Certify = () => {
  const { push } = useHistory();
  
  return (
    <div className="certify" style={{backgroundImage: `url(${BackgroundImage})`}}>
      <div className="body">
        <img className="certificate-icon" src={CertificateIcon} alt="certify" />
        <span>Do you want us to Test &amp;<br/> Certify your tech solution? </span>
      </div>
      <Button variant="primary icon" 
        onClick={() => {
          if (window.confirm('You may have some changes to be saved, exit and discard?')) {
            push('/app/startup/alkelio-services')
          }
        }}
      >
        <>
          <img src={UnlockIcon} alt="unlock" /> <span>Unlock Service</span>
        </>
      </Button>
    </div>
  )
}

export default Certify;