import Config from 'config';
import { OptionItem } from 'shared/forms/select-multiple';
import { useState, useEffect } from 'react'

export const toOptions = (data: any): Array<OptionItem> => data ? (data as Array<any>).map((item: any) => ({ label: item.name, value: String(item.id) })) : []

export const toValues = (data: any): Array<string> => data ? (data as Array<any>).map((item: any) => String(item.id)) : []

export const unNull = (d: any | null | undefined) => (d !== null && d !== undefined ? d : '')

export const valueOrNull = (d: any | null | undefined) => (d !== null && d !== undefined ? d : null)

export const numberList = (from: number, to: number) => {
  let list = [];
  for (let i = from; i < to; i++)
    list.push(i.toString())
  return list;
}

export const getLocalStorage = (key: string): string => localStorage.getItem(key) !== null ? localStorage.getItem(key) as string : ''

export const isNotNullUndefined = (obj: any) => obj !== undefined && obj !== null

export const isNotEmpty = (obj: any) => obj !== undefined && obj !== null && obj !== ''

export const setOnboardingSessionExpiryDate = () => {
  var now = new Date();
  localStorage.setItem(Config.onboardingUUIDExpiryKey, (now.setHours(now.getHours() + 24).toString()));
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    windowWidth: width,
    windowHeight: height
  };
}
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}