import './assets/styles/match-dial.scss';

interface MatchDialProps {
  percent: number;
  variant?: "default" | "light";
  size?: "default" | "lg";
}

const MatchDial = ({ 
  percent, 
  variant = "default",
  size = "default"
}: MatchDialProps) => {
  const roundDraw = percent * (2 * 34 * Math.PI) / 100
  const r = size === "lg" ? 38 : 34;
  const c = size === "lg" ? 42 : 37;
  return (
    <div className={`match-dial ${variant} ${size}`}>
      <div className="ring-wrapper">
        <svg
          className="circle">
          <circle
            className="progress-circle"
            fill="transparent"
            style={{
              strokeDasharray: `${roundDraw} 999`
            }}
            r={r}
            cx={c}
            cy={c}
          />
        </svg>
        <svg
          className="circle-dark">
          <circle
            className="progress-circle"
            fill="transparent"
            r={r}
            cx={c}
            cy={c}
          />
        </svg>
      </div>
      <div className="text">
        <div className="percent">{percent.toFixed(0)}%</div>
        <div className="label">Match</div>
      </div>
    </div>
  )
}

export default MatchDial;