import React from 'react'
import Button from 'app/shared/button'
import LongArrowRightWhite from 'app/shared/assets/svgs/long-arrow-right-white.svg';
import LongArrowLeftAzure from 'app/shared/assets/svgs/long-arrow-left-azure.svg';

type Props = {
  onBack?: () => unknown,
  onNext?: () => unknown
}

const BottomNav = ({
  onBack,
  onNext
}: Props) => {
  const handleBack = () => {
    onBack && onBack()
  }
  const handleNext = () => {
    onNext && onNext()
  }
  
  return (
    <div className="d-flex flex-row justify-content-between">
      {onBack ? <Button variant="secondary" className="wide" onClick={handleBack}>
        <img src={LongArrowLeftAzure} alt="back" className='me-2'/>
        Back
      </Button> : null}
      <Button type="submit" variant="primary" className="ms-auto wide" onClick={handleNext}>
        Next
        <img src={LongArrowRightWhite} alt="next" className='ms-2' />
      </Button>
    </div>
  )
}

export default BottomNav
