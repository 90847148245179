import './assets/styles/faq.scss';
import Accordian from 'shared/accordian';

const FAQs = () => {
  const data = [
    {
      title: 'How much does this service cost?',
      content: 'We are compensated ONLY IF we have brought you value, if you leave with what you were looking for. You can use the platform at free of cost and pay only if you want to start a conversation with your match and use ALKELIO+, our AdHoc Services. There are several packages . Feel free to choose the one that suits your specific needs.'
    },
    {
      title: 'Can a match contact me even if I have a free account?',
      content: 'Yes. – You can still be contacted by the other party freely, even if you have not subscribed to any package. However, with a package you will have access to all our services!'
    },
    {
      title: 'Why do I have to sign up?',
      content: 'Because we need to know who you are and what you are looking for to propose a suitable match. Also, after signing up you will have access to your personalized dashboard.  It will not  even take 3 min of your time!'
    },
    {
      title: 'Is a match guaranteed?',
      content: 'Yes! Our algorithm would recommend actors that correspond to your request. However, if you are not happy with the results, you can ask us to do proactive research for you.'
    },
    {
      title: 'Can I opt out at any stage?',
      content: 'Of course!. Though we would be very sad to see you go :('
    },
    {
      title: 'Is my data safe?',
      content: 'Absolutely!. The data you provide is secured and confidential. Only the summary of your request will be provided for matching approval purposes. The rest of the information will be displayed only when you have agreed to talk to your match, and the Non-Disclosure Agreement (NDA) has been signed.'
    },
    {
      title: 'What does ‘ALKELIO’ mean?',
      content: 'You were wondering right? ;) . ALK, comes from alkimie,  Medieval Latin alkimia (Alchemy): Transmutation, transformation. ELIO, From Helios, related to sun, to gold, to light. ALKELIO means then, transmuting into gold, into higher value, bringing clarity and light. Mic drop!'
    }
  ]

  return (
    <div className="pt-5 pb-5 pt-md-5 pb-md-5 row ps-md-4" id="faqs">
      <h1 className="mb-4 mb-md-4 faq">FAQs</h1>
      <div>
        {data.map((item, i) => <div key={i} className="mb-3"><Accordian {...item} /></div>)}
      </div>
      <div className="flex-row mt-4 d-flex justify-content-start">
        <span className="txt-light font-size-16 font-size-md-14 line-height-140 me-2 ms-1">Any other questions?</span>
        <div className="flex-row text-decoration-none d-flex justify-content-center align-items-center">
          <h5 className="m-0"><a href="#contact">Contact us</a></h5>
          <img className="ps-2" src="/imgs/arrow-right.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default FAQs;