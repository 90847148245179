const TOKEN_KEY = 'access_token';

const SessionManager = {
  set:(token:string): void => {
    localStorage.setItem(TOKEN_KEY, token);
  },
  get:(): any | null => localStorage.getItem(TOKEN_KEY),
  clear:(): void => {
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default SessionManager;