import React, { ForwardedRef, forwardRef } from 'react'
import './assets/styles/chat-message-item.scss'
import DialogCornerMeIcon from './assets/icons/dialog-corner-me.svg'
import DialogCornerOtherIcon from './assets/icons/dialog-corner-other.svg'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

type Props = {
  isSelf: boolean;
  message: string;
  name: string;
  profilePhoto: string;
  sentAt: Date
}

const ChatMessageItem = forwardRef<HTMLDivElement, Props>(({
  isSelf,
  message,
  name,
  profilePhoto,
  sentAt
}: Props, ref) => {
  return (
    <div ref={ref} className={`chat-message__item ${!isSelf ? 'other':''}`}>
      <div className="chat-message__item__body">
        <div className="chat-message__item__body__message">
          <div dangerouslySetInnerHTML={{__html: message.replace('\n', '<br/>')}}>
          </div>
          <img src={isSelf ? DialogCornerMeIcon : DialogCornerOtherIcon} />
        </div>
        <div className="chat-message__item__body__person">
          <img src={profilePhoto} alt={name} />
        </div>
      </div>
      <div className="chat-message__item__footer">
        <span>{dayjs(sentAt).from(dayjs())}</span>
        <span> - </span>
        <span>{isSelf ? 'You' : name}</span>
      </div>
    </div>
  )
})

export default ChatMessageItem