import React, { useEffect } from 'react';
import CircleOCloseIcon from 'assets/svgs/circle-o-close.svg';
import './assets/styles/generic-modal.scss';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';

interface GenericModalPropType {
  modalId: string;
  size?: string;
  children?: React.ReactNode;
  title?: string;
  show?: boolean;
  centered?: boolean;
  disabled?: boolean;
  thinMargins?: boolean;
}

const GenericRouteModal = ({
  modalId, size, children, title, show, centered, disabled, thinMargins
}: GenericModalPropType) => {
  const history = useHistory();
  const match = useRouteMatch({
    path: `/${modalId}`
  })

  const handleClose = () => {
    history.push('/')
  }

  useEffect(() => {
    if (match !== null)
      document.getElementById('parent-body')?.style.setProperty('overflow-y', 'hidden');
    else
      document.getElementById('parent-body')?.style.setProperty('overflow-y', 'auto');
  }, [match])

  return (
    <Route exact path={`/${modalId}`}>
      <div className={`generic-modal active ${thinMargins && 'thin-margins'}`}>
        <div className={`modal-box ${size} ${centered ? 'modal-centered' : ''}`}>
          <div className="header">
            <h3 className="title">{title}</h3>
            <button className="close" type="button" onClick={handleClose} disabled={disabled}>
              <img src={CircleOCloseIcon} alt="close" />
            </button>
          </div>
          <div className="body">
            {children}
          </div>
        </div>
      </div>
    </Route>
  )
}

export default GenericRouteModal;