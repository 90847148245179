import React, { useState, useEffect } from 'react';
import './assets/styles/corporate-matches.scss';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import Tile from 'app/shared/tile';
import Card from 'app/shared/card';
import Button from 'app/shared/button';
import TargetIcon from 'app/shared/assets/svgs/target.svg';
import BarChartsIcon from 'app/shared/assets/svgs/bar-chart.svg';
import CalenderIcon from 'app/shared/assets/svgs/calender.svg';
import IconLabel from 'app/shared/icon-label';
import StatusBadge from 'app/shared/status-badge';
import { 
  useMyTechRequestsQuery
} from 'services/graphql/tech-requests';
import { 
  useRefreshMatchesMutation
} from 'services/graphql/match-making-result'
import {
  useGetCurrentUserBookmarksQuery
} from 'services/graphql/bookmarks'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import Pill from 'app/shared/pill'
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import CircleTickWhiteLargeIcon from 'app/shared/assets/svgs/circle-tick-large-white.svg';
import Results from './results'
import MatchCount from './match-count'
import { CompletionStatuses } from 'app-constants'
import { ViewCount } from 'app/shared';
import MatchCounterTechnologyRequest from 'app/shared/match-counter/match-count.techrequest.component'
import MatchingProgress from 'app/shared/matching-progress/matching-progress.component'
import TechnologyRequestNotPublished from './technology-request.not-published'
import CardPlus from '../shared/card-plus'
import Carousel from 'react-grid-carousel'
import { io } from "socket.io-client";

dayjs.extend(relativeTime)

let socket = null

const Status = (isCompleted: boolean) => {
  const { color, info, label} = isCompleted ? CompletionStatuses.complete : CompletionStatuses.incomplete;
  return <StatusBadge color={color as ('in-progress' | 'success' | undefined)} toolTip={info}>{label}</StatusBadge>
}

const TechRequestMatches = () => {
  const { url } = useRouteMatch()
  const { push } = useHistory();
  const location = useLocation();
  
  const index = parseInt(new URLSearchParams(location.search).get('index') ?? '0')

  const [currentTechRequest, setCurrentTechRequest] = useState("")
  const [matchTriggered, setMatchTriggered] = useState(false)
  const [matchProgress, setMatchProgress] = useState<null | {
    total: number | null,
    completed: number | null
  }>(null)
  const { data, refetch, isFetching } = useMyTechRequestsQuery()
  const bookmarksQuery = useGetCurrentUserBookmarksQuery()
  const [ refreshMatches ]= useRefreshMatchesMutation()

  const handleTechRequestEditClick = (id: string) => {
    push(`${url}/tech-requests/${id}`)
  }

  const handleTechRequestMatchClick = async (id: string) => {
    setMatchTriggered(true)
    const matchPayload = await refreshMatches({
      type: "technology_request",
      technology_id: id,
      isReturnMatches: false
    }).unwrap()
    
    setMatchProgress({
      total:null,
      completed: null
    })

    // subscribe to socket messages with this jobid
    if (matchPayload?.jobId) {
      const socketSubscription = `match-making-progress-${matchPayload.jobId}`
      socket = io((process.env.REACT_APP_WS as string), { transports : ['websocket'] });
      
      socket.on(socketSubscription, (res) => {
        const { jobId, completed, total } = res
        setMatchProgress({
          total,
          completed
        })
        
        if (completed === total) {
          setMatchTriggered(false)
          setMatchProgress(null)
        }
      })
    }
    
    refetch()
  }

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      setCurrentTechRequest(data[index].id)
    }
  }, [data])

  useEffect(() => {
    return (() => {
      // dispose
    })
  }, [])

  /*
  useEffect(() => {
    if (matchTriggered) {
      setTimeout(() => {
        setMatchTriggered(false)
      }, 3000)
    }
  }, [matchTriggered])
  */

  const cards: Array<React.ReactNode> = data ? data.map((item, i) => {

    let pills = [];
    item.has_api && pills.push('API')
    item.has_ip && pills.push('IP')
    item.is_cloud && pills.push('Cloud')
    item.is_gdpr && pills.push('GDPR')
    item.is_plugandplay && pills.push('Plug & Play')
    item.can_whitelabel && pills.push('Whitelabel')
    item.is_others && pills.push(item.is_others || '')
    item.technology_types && pills.push(...item.technology_types.map((item, i) => (item !== null ? item.name || '' : '')))
    item.business_use_cases && pills.push(...item.business_use_cases.map((item, i) => (item !== null ? item.name || '': '')))
    item.industries && pills.push(...item.industries.map((item, i) => (item !== null ? item.name || '' : '')))

    const body = <div className="position-relative">
      {!item.is_published && item.published_at !== null ? <TechnologyRequestNotPublished /> : null}
      <div>
        <p>{item.business_challange_description}</p>
        <div className="flex-row flex-wrap mb-1 d-flex tags">
          {pills.map((item, i) => (<Pill key={i} icon={TickWhiteIcon} label={item} />))}
        </div>
        <div className="actions">
          <Button className="mt-2 me-md-4" variant="primary" onClick={() => {handleTechRequestMatchClick(item.id)}}>Match</Button>
          <Button className="mt-3 mt-md-2" variant="secondary" onClick={() => {handleTechRequestEditClick(item.id)}}>Edit</Button>
        </div>
      </div>
    </div> 

    const cardImageOverlay = <div className="matches-tech-request-card-image-overlay">
      <div className="completion-container">
        {Status(item.is_completed?? false)}
      </div>
      {item.is_match_triggered && <div className="match-status-container">
        <img src={CircleTickWhiteLargeIcon} alt="Matched" />
      </div>}
    </div>

    return <Card 
      key={`card-${i}`}
      className="corporate-tech-request-matches-card"
      variant="horizontal"
      cardHeight="377px"
      cardWidth="100%"
      imageWidth="360px"
      title={item.name || ''} 
      imageSrc={item?.hero_image?.url}
      imageOverlay={cardImageOverlay}
      imageOverlayPosition="none"
      body={body}
      footer={<>
        <IconLabel icon={CalenderIcon}>{dayjs(item.updated_at).from(dayjs())}</IconLabel>
        <ViewCount articleId={`tech-req-${item.id}`} />
        <IconLabel icon={TargetIcon}><MatchCounterTechnologyRequest id={item.id} /></IconLabel>
      </>}
    />
  }) : []
  
  if (cards.length === 0) {
    cards.push(
      <Carousel.Item key={`add-card`}>
        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
          <div className="bold-type font-size-26 letter-spacing-n-2 mb-3 color-nightsky">No Business Need to match</div>
          <div className="font-size-14 line-height-140 txt-regular pt-3 letter-spacing-n-2 mb-3 text-center">
            Please add a business need in order to be able to be matched with the best solution(s)
          </div>
          <Button
            variant="primary" 
            className="wide mt-3"
            onClick={() => {
              push(`/app/corporate/tech-requests/new`)
            }}>
            Add Business Need
          </Button>
        </div>
      </Carousel.Item>
    )
  }

  return (
    <>
      <div className="mb-md-5 row">
        <div className="col position-relative">
          {matchTriggered && matchProgress ? <MatchingProgress matchProgress={matchProgress} /> : null}
          <Tile 
            title={data && data.length > 0 ? 'Matches found for your Business case' : ''}
            mode="carousel"
            defaultIndex={index}
            onChange={async (index) => {
              if (data) {
                const id = data[index].id;
                setCurrentTechRequest(id);
                push({
                  pathname: location.pathname,
                  search: "?" + new URLSearchParams({'index': index.toString()}).toString()
                });
              }
            }}
            actionComponents={[
              <Button key={1} onClick={() => {
                push(`${url}/tech-requests/new`)
              }}>Add new</Button>
            ]}>
            {cards}
          </Tile>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {currentTechRequest ? <Results 
            technologyRequest={currentTechRequest} 
            bookmarks={bookmarksQuery.data} 
            matchTriggered={(data?? [] )[index ?? 0]?.is_match_triggered?? false}
          /> : null
          }
        </div>
      </div>
    </>
  )
}

export default TechRequestMatches;