import { createSlice } from '@reduxjs/toolkit'
import { authApi } from 'services/graphql/auth'
import { UsersPermissionsMe } from 'generated/graphql'
import type { RootState } from 'store'

export type AuthState = {
  user: UsersPermissionsMe | null
  token: string | null
}

const slice = createSlice({
  name: 'auth',
  initialState: { 
    user: null, 
    token: null 
  } as AuthState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
    setToken: (state, action) => {
      state.token = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.jwt ? payload.jwt : ''
          state.user = payload.user ? payload.user : null
        }
      )
      .addMatcher(
        authApi.endpoints.me.matchFulfilled,
        (state, { payload }) => {
          state.user = payload ? payload : null
        }
      )
  },
})

export default slice.reducer

export const { logout, setToken } = slice.actions

export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectCurrentToken = (state: RootState) => state.auth.token