import { createSlice } from '@reduxjs/toolkit'
import { ChatMessages } from 'generated/graphql'
import { messagingApi } from 'services/graphql/chat'
import type { RootState } from 'store'

export type ChatStateType = {
  chats: Record<string, Array<ChatMessages>>
}

const slice = createSlice({
  name: 'chats',
  initialState: {
    chats: {}
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        messagingApi.endpoints.getMessagingSession.matchFulfilled,
        (state, { payload }) => {
          console.log(payload)   
        }
      )
  }
})

export default slice.reducer

export const selectChats = (state: RootState) => state.chats.chats