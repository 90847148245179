import React from 'react'
import MatchCounter from './match.counter.component'
import { 
  useCountTechnologySolutionMatchQuery
} from 'services/graphql/match-making-result'

type MatchCountTechnologySolutionProps = {
  id: string
}

const MatchCountTechnologySolution = ({
  id
}: MatchCountTechnologySolutionProps) => {
  const { data } = useCountTechnologySolutionMatchQuery(id)
  return (
    <MatchCounter counter={data ?? 0} suffix='Matches'/>
  )
}

export default MatchCountTechnologySolution