import { useHistory } from 'react-router-dom'
import Tile from 'app/shared/tile'
import Button from 'app/shared/button'
import { useForm, Controller, SubmitHandler, UseFormWatch } from 'react-hook-form'
import InputInline from 'shared/forms/input-inline';
import InputImage from 'shared/forms/input-image';
import SelectMultiple from 'shared/forms/select-multiple';
import SelectOne from 'shared/forms/select-one';
import { CountryNames } from 'shared/countries'
import InputCheckBox from 'shared/forms/input-checkbox';
import Inputs from './inputs'
import { 
  useMyCorporateProfileQuery,
  useUpdateCorporateProfileMutation 
} from 'services/graphql/profile'
import { FileItem } from 'hooks/file-uploads';
import { toOptions, toValues, unNull } from 'shared/utilities'
import { 
  Industry,
  CorporateDepartment,
  Enum_Corporate_Esg_Rating
} from 'generated/graphql';
import { 
  useGetIndustriesQuery,
  useGetCorporateDepartmentsQuery
} from 'services/graphql/tags';
import { EmployeeCountRange, ESGRatingOptions } from 'app-constants'
import InputRadioGroup from 'shared/forms/input-radio-group';
import ChangePassword from 'app/shared/change-password';
import { useDispatch } from 'react-redux';
import { showToast } from 'features/toasts/toastsSlice';
import './assets/styles/corporate-profile-form.scss'


const ProfileForm = () => {
  const { data, isLoading, isSuccess } = useMyCorporateProfileQuery()
  const [ update ] = useUpdateCorporateProfileMutation()
  const dispatch = useDispatch()
  const history = useHistory();
  
  const { 
    register, 
    handleSubmit, 
    watch, 
    control,
    getValues,
    setValue,
    reset,
  formState: { errors } } = useForm<Inputs>({
    mode: "onChange",
  });

  const watchDecisionMaker = watch('person.decision_type');

  const IndustriesOptions = (props:any) => {
    const {data, isSuccess} = useGetIndustriesQuery()
    return (
      <>
      {isSuccess && props.render(data)}
      </>);
  }

  const CorporateDepartmentsOptions = (props:any) => {
    const {data, isSuccess} = useGetCorporateDepartmentsQuery()
    return (
      <>
      {isSuccess && props.render(data)}
      </>);
  }

  const handleSave = async ({
    corporate_profile: {
      name,
      logo,
      country,
      country_jurisdiction,
      employee_count_range,
      worked_with_startups,
      website,
      industries,
      esg_rating
    },
    person: {
      first_name,
      last_name,
      role,
      decision_type,
      decision_type_other,
      contact_number,
      corporate_department,
      photo
    }
  }: Inputs) => {
    const corporateId = data?.myCorporateProfile.id as string
    const personId = data?.myProfile?.person?.id as string
    const result = await (await update({
      corporateId: corporateId,
      corporate: {
        name,
        logo: logo ? (logo.id ?? null) : null,
        country: country.join(','),
        country_jurisdiction,
        employee_count_range,
        links: {
          website: website
        },
        worked_with_startups,
        industries,
        esg_rating: esg_rating as Enum_Corporate_Esg_Rating
      },
      personProfileId: personId,
      personProfile: {
        first_name,
        last_name,
        role,
        is_decision_influencer: decision_type === "decision-influencer",
        is_decisionmaker: decision_type === "decision-maker",
        decision_role_type: decision_type_other,
        contact_number,
        corporate_department,
        photo: photo ? (photo.id ?? null) : null,
      }
    }).unwrap());

    history.goBack();
  }
  
  return (
    <Tile>
      {isLoading && <div>Loading</div>}
      {isSuccess && 
      <form>
        <div className="corporate-profile-form">
          <div className="title">
            <div className="flex-fill me-4">
              <Controller
                name="corporate_profile.name"
                control={control}
                defaultValue={data?.myCorporateProfile.name || ''}
                rules={{
                  required: true
                }}
                render={({field}) => <InputInline 
                  {...field} /> }
              />
              <div className={`invalid-feedback ${errors.corporate_profile?.name ? 'd-block':''}`}>This field is required</div>
            </div>
            <Button className="d-none d-md-block" variant="primary" onClick={handleSubmit(handleSave)}>Save</Button>
          </div>
          <div className="photo">
            <Controller
              name="corporate_profile.logo"
              control={control}
              defaultValue={data?.myCorporateProfile.logo as FileItem || null}
              rules={{ 
                required: false
              }}
              render={({field}) => <InputImage 
                height="332px"
                width="383px"
                {...field} />}
            />
            <div className={`invalid-feedback ${errors.corporate_profile?.logo ? 'd-block':''}`}>Please upload an image</div>
          </div>
          <div className="company-details">
            <div className="form-control-group">
              <h6 className="mb-4">Company details</h6>

              <div className="pb-0 mb-0 row align-items-normal">
                <div className="col p-0 pe-md-2">
                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Industries</div>
                      <IndustriesOptions
                        render={(industries: Array<Industry> ) => (
                          <Controller 
                            name="corporate_profile.industries"
                            control={control}
                            defaultValue={toValues(data?.myCorporateProfile?.industries || [])} 
                            render={({field}) => <SelectMultiple
                              options={toOptions(industries)} 
                              {...field} />}
                          />
                        )}
                      />
                    </label>
                  </div>

                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Country jurisdiction (if applicable)</div>
                      <Controller 
                        name="corporate_profile.country_jurisdiction"
                        control={control}
                        defaultValue={data?.myCorporateProfile.country_jurisdiction !== undefined && 
                          data.myCorporateProfile.country_jurisdiction !== null && 
                          data.myCorporateProfile.country_jurisdiction !== "" ? 
                            data?.myCorporateProfile.country_jurisdiction : ''}
                        render={({field}) => <SelectOne 
                          options={CountryNames}
                          {...field} />}
                      />
                    </label>
                  </div>

                  <div className="form-control mb-4">
                    <Controller 
                      name="corporate_profile.worked_with_startups"
                      control={control}
                      defaultValue={data?.myCorporateProfile?.worked_with_startups || false}
                      render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Have worked with Startups previously" 
                        {...field} />}
                    />
                  </div>

                </div>
                <div className="col p-0 ps-md-2">
                  
                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Operating Locations</div>
                      <Controller 
                        name="corporate_profile.country"
                        control={control}
                        defaultValue={data?.myCorporateProfile.country !== undefined && 
                          data.myCorporateProfile.country !== null && 
                          data.myCorporateProfile.country !== "" ? 
                            data?.myCorporateProfile.country.split(',') : []}
                        render={({field}) => <SelectMultiple 
                          options={CountryNames}
                          {...field} />}
                      />
                    </label>
                  </div>

                  <div className="mb-4 form-control">
                    <label>
                      <div className="text">Number Employees</div>
                      <Controller 
                        name="corporate_profile.employee_count_range"
                        control={control}
                        defaultValue={data?.myCorporateProfile.employee_count_range !== undefined && 
                          data.myCorporateProfile.employee_count_range !== null && 
                          data.myCorporateProfile.employee_count_range !== "" ? 
                            data?.myCorporateProfile.employee_count_range : ''}
                        render={({field}) => <SelectOne
                          sortOptions={false} 
                          options={EmployeeCountRange}
                          {...field} />}
                      />
                    </label>
                  </div>

                  <div className="mb-0 form-control">
                    <label>
                      <div className="text">ESG Rating</div>
                      <Controller 
                        name="corporate_profile.esg_rating"
                        control={control}
                        defaultValue={ data?.myCorporateProfile.esg_rating ?? ''}
                        render={({field}) => <SelectOne
                          sortOptions={false} 
                          options={ESGRatingOptions}
                          {...field} />}
                      />
                    </label>
                  </div>

                </div>  
              </div>
              
            </div>
          </div>
          <div className="personal-info">
            <div className="form-control-group">
              <h6 className="mb-4">Personal Profile</h6>

              <div className="row p-0">
                <div className="flex-row col p-0 d-flex">
                  
                  <Controller
                    name="person.photo"
                    control={control}
                    defaultValue={data?.myProfile?.person?.photo as FileItem || null}
                    rules={{ 
                      required: false
                    }}
                    render={({field}) => <InputImage 
                      layout="elipse-left"
                      height="72px"
                      width="72px"
                      {...field} />}
                  />
                  <div className={`invalid-feedback ${errors.person?.photo ? 'd-block':''}`}>Please upload your photo</div>
                  
                </div>
              </div>

              <div className="p-0 mb-0 row align-items-normal">
                <div className="col p-0 pe-md-2 d-flex flex-column">
                  <div className="form-control mb-4">
                    <label>
                      <div className="text">First name</div>
                      <input type="text"
                        {...register("person.first_name")}
                        defaultValue={data?.myProfile?.person?.first_name || ''}
                      />
                    </label>
                  </div>
                </div>
                <div className="col p-0 ps-md-2 d-flex flex-column">
                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Last name</div>
                      <input type="text"
                        defaultValue={data?.myProfile?.person?.last_name || ''}
                        {...register("person.last_name")}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="p-0 mb-0 row align-items-normal">
                <div className="col p-0 pe-md-2 d-flex flex-column">
                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Email</div>
                      <input type="text" readOnly
                        defaultValue={data?.me.email || ''}
                      />
                    </label>
                  </div>
                </div>
                <div className="col p-0 ps-md-2 d-flex flex-column">
                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Password</div>
                      <ChangePassword />
                    </label>
                  </div>
                </div>
              </div>

              <div className="p-0 mb-0 row align-items-normal">
                <div className="col p-0 pe-md-2 d-flex flex-column">

                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Role</div>
                      <input type="text"
                        {...register("person.role")}
                        defaultValue={data?.myProfile?.person?.role || ''}
                      />
                    </label>
                  </div>

                  <div className="mb-0 form-control mb-4">
                    <Controller
                      name="person.decision_type"
                      control={control}
                      defaultValue={
                        data?.myProfile?.person?.is_decisionmaker ? 
                          'decision-maker' : 
                          data?.myProfile?.person?.is_decision_influencer ? 
                            'decision-influencer' : 'decision-other' 
                      }
                      render={({field}) => <InputRadioGroup
                        className="d-flex flex-column"
                        options={[
                          {
                            label:"I am the decision maker",
                            value:"decision-maker"
                          },
                          {
                            label:"I am the decision influencer",
                            value:"decision-influencer"
                          },
                          {
                            label:"Other",
                            value:"decision-other"
                          }
                        ]}
                        {...field}
                      />}
                    />
                    {watchDecisionMaker === 'decision-other' && 
                    <label className="mt-1">
                      <input type="text"
                        placeholder="Other - please specify"
                        {...register("person.decision_type_other")}
                        defaultValue={data?.myProfile?.person?.decision_role_type || ''}
                      />
                    </label>
                    }
                  </div>

                </div>
                <div className="col p-0 ps-md-2 d-flex flex-column">
                  
                  <div className="form-control mb-4">
                    <label>
                      <div className="text">Department</div>
                      <CorporateDepartmentsOptions
                        render={(departments: Array<CorporateDepartment>) => (
                          <Controller 
                            name="person.corporate_department"
                            control={control}
                            defaultValue={data?.myProfile?.person?.corporate_department?.id?? ''} 
                            render={({field}) => <SelectOne
                              sortOptions={true}
                              options={toOptions(departments)} 
                              {...field} />}
                          />
                        )}
                      />
                    </label>
                  </div>
                  
                  <div className="mb-0 form-control">
                    <label>
                      <div className="text">Contact number</div>
                      <div className="input-group">
                        <span className='input-group-text'>+</span>
                        <input type="number"
                          defaultValue={data?.myProfile?.person?.contact_number || ''}
                          {...register("person.contact_number")}
                        />  
                      </div>
                    </label>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div className="website">
            <div className="form-control-group">
              <h6 className="mb-4">Website</h6>
              <div className="form-control">
                <label>
                  <div className="text">Website</div>
                  <input type="text" defaultValue={data?.myCorporateProfile.links?.website || ''} 
                    {...register("corporate_profile.website")} />
                </label>
              </div>
            </div>
          </div>
          <div className="action">
            <Button className="ms-md-auto flex-grow-1 flex-md-grow-0" variant="primary" onClick={handleSubmit(handleSave)}>Save</Button>
          </div>
        </div>
      </form>
      }
    </Tile>
  )
}

export default ProfileForm;