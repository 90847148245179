import React, { useEffect, useRef } from 'react'
import './assets/styles/collab-card.scss'
import Card from 'app/shared/card'
import IconLabel from 'app/shared/icon-label'
import BoxTickAzureIcon from 'app/shared/assets/svgs/box-tick-azure.svg'
import BoxCrossedGreyIcon from 'app/shared/assets/svgs/box-crossed-grey.svg'
import StatusBadge from 'app/shared/status-badge'
import Button from 'app/shared/button'
import DocumentWhiteIcon from 'app/shared/assets/svgs/document-white.svg'
import UploadAzureIcon from 'app/shared/assets/svgs/upload-azure.svg'
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { Image } from 'shared/image'
import _ from 'lodash';
import { Icon, IconNames } from 'app/shared/icon';

interface CollabCardProps {
  id: string,
  title: string,
  imageUrl: string,
  stagesComplete?: {
    structure: boolean,
    scope: boolean
    deliverables: boolean
    feedback: boolean
  }
}

// TODO - if have uploaded NDA, change upload NDA to replace NDA

const CollabCard = React.forwardRef((
  {
    id,
    title,
    imageUrl,
    stagesComplete
  }: CollabCardProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const { entityType }: any = useParams();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  
  const imageOverlay = <div className="collab-image"><Image src={imageUrl ?? ''} alt="" /></div>
  const body = <>
      <h5>{title || ''}</h5>
      <div className="status-checks">
        <IconLabel icon={stagesComplete?.structure ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>
          Structure</IconLabel>
        <IconLabel icon={stagesComplete?.scope ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>Scope</IconLabel>
        <IconLabel icon={stagesComplete?.deliverables ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>Deliverables</IconLabel>
        <IconLabel icon={stagesComplete?.feedback ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>Feedback</IconLabel>
      </div>
    </>

  const handleCardClick = () => {
    push(`/app/${entityType}/service-collaborations/${id}`);
  }

  return (
    <Card
      onClick={handleCardClick} 
      className="collab-card"
      cardWidth="384px"
      cardHeight="auto" 
      variant="vertical" 
      imageHeight="159px"
      imageOverlayPosition="center"
      imageOverlay={imageOverlay}
      body={body}
    />
  )
})

export default CollabCard;