import { useState } from 'react';
import './assets/styles/accordian.scss';
import IconJadeCaretSVG from './assets/svgs/jade-caret-up.svg';

type AccordianProps = {
  title: string;
  content: string;
}

const Accordian = ({
  title,
  content
}: AccordianProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => {
    setExpanded(!expanded);
  }
  return (
    <div className="accordian">
      <div className="heading" onClick={handleToggle}>
        <div className="m-0">{title}</div>
        <img src={IconJadeCaretSVG} alt="" className={`${expanded ? 'invert':''}`} />
      </div>
      <div className={`content line-height-140 ${expanded ? 'expanded': ''}`} >
        <div className="inside txt-light font-size-14 line-height-140 color-text">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Accordian;