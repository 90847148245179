import React, { useEffect } from 'react'
import BottomNav from './bottom-nav'
import './assets/styles/scope.form.scss'
import Team from './scope.form.team';
import InputFiles from 'shared/forms/input-files'
import { FileItem } from 'hooks/file-uploads'
import { UsersPermissionsMe } from 'generated/graphql'

import { 
  useFieldArray,
  useForm, 
  SubmitHandler,
  Controller,
} from "react-hook-form";
import {
  useHistory,
  useRouteMatch
} from 'react-router-dom'

import { 
  useGetOneServiceCollaborationQuery,
  useUpdateMyServiceCollaborationMutation
} from 'services/graphql/service-collaboration'
import { Inputs, Person, ProjectTiming, Info } from './inputs'
import ScopeProjectTiming from './scope.form.project-timing';
import ScopeInfo from './scope.form.info';
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';

type Props = {
  id: string
}

const ScopeForm = ({
  id
}: Props) => {
  const user = useSelector(selectCurrentUser);
  const { push } = useHistory()
  const { path, url } = useRouteMatch();
  const { data, isLoading, isSuccess } = useGetOneServiceCollaborationQuery(id)
  const [update, updateResult] = useUpdateMyServiceCollaborationMutation()
  
  const isCatalyst = user?.role?.type === 'catalyst'

  const {
    register,
    reset, 
    handleSubmit, 
    watch,
    control,
    getValues,
    formState: { errors } 
  } = useForm<Inputs>();

  const client_team = useFieldArray({
    control,
    name: 'client_team'
  })

  const alkelio_team = useFieldArray({
    control,
    name: 'alkelio_team'
  })

  const info = useFieldArray({
    control,
    name: 'info'
  })

  const getData = (data: Inputs) => {
    const scope: any = {}

    scope.alkelio_team = data.alkelio_team.map(c => ({
      name: c.name,
      role: c.role,
      email: c.email,
      profile_image: c.profile_image?.id
    }))
    scope.calender_project = data.calender_project?.map(o => o.id)
    scope.client_team = data.client_team.map(c => ({
      name: c.name,
      role: c.role,
      email: c.email,
      profile_image: c.profile_image?.id
    }))
    scope.project_timing = data.project_timing
    
    return {
      id,
      scope: {
        ...scope,
        info: data.info.map(o => ({
          response: o.response,
          attachments: (o.attachments?? []).map(a => a.id),
          question_from_catalyst: o.question_from_catalyst
        }))
      }
    }
  }

  const onSubmit: SubmitHandler<Inputs> = data => {
    update(getData(data))
  }

  const handleBack = () => {
    let parts = url.split('/')
    parts.pop()
    push(parts.join('/'))
  }

  useEffect(() => {
    if (isSuccess && data) {
      reset({
        client_team: (data.scope?.client_team?? []) as Array<Person>,
        alkelio_team: (data.scope?.alkelio_team ?? []) as Array<Person>,
        project_timing: data.scope?.project_timing as unknown as ProjectTiming,
        calender_project: (data.scope?.calender_project ?? []) as unknown as Array<FileItem>,
        info: (data.scope?.info ?? []) as unknown as Array<Info>
      })
    }
  }, [isSuccess, data])

  const getNextPath = () => {
    let parts = url.split('/')
    parts.pop()
    return `${parts.join('/')}/deliverables`
  }

  useEffect(() => {
    if (updateResult.isSuccess) {
      push(getNextPath())
    }
  }, [updateResult])
  
  useEffect(() => {
    return () => {
      const data = getValues()
      update(getData(data))
    }
  }, [])

  const handleNext = () => {
    const data = getValues()
    update(getData(data))
    push(getNextPath())
  }

  return (
    <div className="main__scope-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          {isSuccess && data ?
          <>
            <div className="form-control-group">
              <h4 className="mb-4">Governance & Key Team structure</h4>
              <Team register={register}
                teamType="client"
                editable={user?.role?.type !== 'catalyst'}
                control={control}
                {...client_team}
              />
            </div>
            <div className="form-control-group mt-4">
              <h4 className="mb-4">Governance & Key Team structure ALKELIO</h4>
              <Team register={register}
                teamType="alkelio"
                editable={user?.role?.type === 'catalyst'}
                control={control}
                {...alkelio_team}
              />
            </div>
            <div className="row mt-4">
              <div className="col ps-0">
                <div className="form-control-group">
                  <ScopeProjectTiming
                    disabled={!isCatalyst}
                    register={register}
                    control={control}
                    project_timing={data.scope?.project_timing}
                  />
                </div>
              </div>
              <div className="col pe-0">
                <div className="form-control-group">
                  <h4 className="mb-4">Calendar Project</h4>
                  <div className="d-flex flex-column h-100">
                    <Controller
                      name="calender_project"
                      control={control}
                      defaultValue={(data.scope?.calender_project ?? []) as unknown as Array<FileItem>}
                      render={({field}) => <InputFiles disabled={!isCatalyst} maxFileCount={1} {...field} />}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-control-group mt-4">
              <ScopeInfo register={register}
                control={control}
                {...info}
              />
            </div>
          </>
          : null}
        </div>
        <div className="bottom">
          <BottomNav onBack={handleBack} onNext={handleNext} />
        </div>
      </form>
    </div>
  )
}

export default ScopeForm