import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "home/footer";
import './assets/styles/layout.scss';
import Header from "shared/header";
import GenericModal from 'shared/generic-modal';

import Login from 'shared/login';
import SignUpV2 from "shared/sign-up-v2/sign-up";
import ForgotPassword from 'shared/forgot-password';
import ResetPassword from 'shared/reset-password';
import AcceptCookie from "home/accept-cookie";
import useCookiePermissions from 'hooks/cookie-permission';
import { open, close } from 'features/modals/modalSlice';

import {
  useGetOnboardingSessionQuery
} from 'services/graphql/on-boarding-session';
import {
  setCurrentSession
} from "features/signup/signupSlice";
import Config from "config";

type Props = {
  children?: React.ReactNode;
  modals?: React.ReactNode;
}

const Layout = ({
  children,
  modals
}: Props) => {
  const acceptCookieModalId = 'accept-cookie';
  const dispatch = useDispatch();
  const { setPermissions, hasPermission } = useCookiePermissions();
  
  useEffect(() => {
    if (!hasPermission) {
      dispatch(open(acceptCookieModalId))
    }
  }, [hasPermission]);

  return (
    <div>
      <Header />
      <div className="page-content">
        {children}
      </div>
      <Footer />
      {modals}
      <GenericModal modalId={acceptCookieModalId} size="sm" centered>
        <AcceptCookie 
          onAcceptAll={(flags) => {
            setPermissions(flags);
            dispatch(close())
          }} 
          onDeclineAll={() => {
            setPermissions([]);
            dispatch(close())
          }} />
      </GenericModal>
      <GenericModal modalId="login" size="sm" centered>
        <Login />
      </GenericModal>
      <GenericModal modalId="sign-up" size="lg">
        <SignUpV2 />
      </GenericModal>
      <GenericModal modalId="forgot-password" size="sm" centered>
        <ForgotPassword />
      </GenericModal>
      <GenericModal modalId="reset-password" size="sm" centered>
        <ResetPassword />
      </GenericModal>
    </div>
  );
};

export default Layout;
