import './assets/styles/button-icon.scss';

interface ButtonIconProps {
  icon: string,
  altText: string,
  title: string,
  text: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonIcon = ({ icon, altText, title, text, onClick }: ButtonIconProps) => {
  return (
    <button className="button-icon" onClick={onClick || (() => { })}>
      <div className="icon">
        <img src={icon} alt={altText} />
      </div>
      <div className="text">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </button>
  )
}

export default ButtonIcon;