import React, { useEffect } from 'react'
import '../assets/styles/conversation.scss';
import NeedHelp from '../../corporate/tech-request-form/need-help';
import { Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import _ from 'lodash';
import Tab from '@material-ui/core/Tab';
import CircleCheck from '../assets/icons/circlecheck.svg';
import CircleIdle from '../assets/icons/idle.svg';
import { useUpdateConversationMutation } from "services/graphql/conversation";
import { io } from 'socket.io-client';
import { FileItemArrayToIds, convertFormConversationToInput } from './util';
import { isEqual } from "lodash"
import { diff } from 'json-diff-ts'

let conversationLastSaved:any;

type HeaderProps = {
    tech_request_name: string;
    setVValue: any;
    data: any;
    isDisabled: boolean;
    setStateKPIs: any;
    value: any;
    view: string;
    business_use_case: any;
    scope: any;
    watch:any;
    proposal: any;
    agreement: any;
    handleSubmit: any;
    kpis: any;
}

const Header = React.forwardRef((
    {
        tech_request_name,
        view,
        isDisabled,
        business_use_case,
        scope,
        proposal,
        data,
        agreement,
        setVValue,
        setStateKPIs,
        value,
        handleSubmit,
        watch,
        kpis
    }: HeaderProps,
    ref: React.Ref<HTMLDivElement>
) => {
    const [updateConversation] = useUpdateConversationMutation();
    
    const handleChange = (event: any, tabNumber: any) => {
        setVValue(tabNumber);
        setStateKPIs(kpis);
        //call handle save
        handleSubmit(handleSave);
    };

    function a11yProps(index: number) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`
        };
    }



    useEffect(() => {
        if (data) {
            conversationLastSaved = {
                business_use_case: {
                    ...data.business_use_case,
                    goals: [...data.business_use_case.goals].map((o: {id:string, Name:string}) => o.id)
                },
                scope: {
                    ...data.scope,
                },
                proposal: {
                    ...data.proposal,
                },
                agreement: {
                    ...data.agreement,
                }
            }
            delete conversationLastSaved.id;
            if (conversationLastSaved.nda_signed_startup)
                delete conversationLastSaved.nda_signed_startup;
            if (conversationLastSaved.nda_signed_corporate)
                delete conversationLastSaved.nda_signed_corporate;
        }
    }, [data])

    
    const handleSave = async (conversation: any) => {
        // check if content changed, if no changes, do not save
        if (!isEqual(conversationLastSaved, conversation)) {
            // console.log("Saving from the tab ", conversation); 
            conversationLastSaved = conversation;
            
            const id = data?.id as string;
        
            const result = await await updateConversation({
                id,
                conversation: convertFormConversationToInput(conversation),
            }).unwrap();

        }
    }
    return (
        <>
            <div className="grid-help">
                <NeedHelp />
            </div>
            <div className="title">
                <Typography variant="h6" style={{ fontSize: "18px" }} display="block" align="left" gutterBottom>{tech_request_name}</Typography>
            </div>
            <div className="tabs" style={{ maxWidth: '100%' }}>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    style={{ width: '100%' }}
                    aria-label="SCAP Tabs"
                    TabIndicatorProps={{ style: { display: "none" } }}
                >
                    <Tab disabled={isDisabled} onClick={handleSubmit(handleSave)}
                        label={<div><img style={styles.myimg} src={value === 0 ? CircleCheck : CircleIdle} alt="x" />Business Use Case</div>}
                        style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} {...a11yProps(0)} />
                    <Tab disabled={isDisabled} onClick={handleSubmit(handleSave)}
                        label={<div><img style={styles.myimg} src={value === 1 ? CircleCheck : CircleIdle} alt="x" />Scope</div>}
                        {...a11yProps(1)} />
                    <Tab disabled={isDisabled} onClick={handleSubmit(handleSave)}
                        label={<div><img style={styles.myimg} src={value === 2 ? CircleCheck : CircleIdle} alt="x" />Proposal</div>}
                        {...a11yProps(2)} />
                    <Tab disabled={isDisabled} onClick={handleSubmit(handleSave)}
                        label={<div><img style={styles.myimg} src={value === 3 ? CircleCheck : CircleIdle} alt="x" />Agreement </div>}
                        style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }} {...a11yProps(3)} />
                </Tabs>
            </div>
        </>
    )
})

export default Header;
const styles = {
    myimg: {
        marginRight: '8px'
    }
}