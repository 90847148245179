import LifeBuoyIcon from 'app/shared/assets/svgs/life-buoy.svg';
import ArrowRightBlueIcon from 'app/shared/assets/svgs/arrow-right-blue.svg';
import PaperPlanIcon from 'app/shared/assets/svgs/paper-plane.svg';
import Image from 'app/shared/assets/imgs/need-help.jpg';
import './assets/styles/need-help.scss';
import Button from 'app/shared/button';

const NeedHelp = () => {
  return (
    <div className="need-help" style={{backgroundImage: `url(${Image})`}}>
      <div className="inner">
        <div className="title"><img src={LifeBuoyIcon} alt="" />Need help? ALKELIO Catalyst can assist with:</div>
        <ul>
          <li>Pricing your tech solution <img className="ms-2" src={ArrowRightBlueIcon} alt="" /> </li>
          <li>Evaluating your tech solution <img className="ms-2" src={ArrowRightBlueIcon} alt="" /></li>
          <li>Identify business applications <img className="ms-2" src={ArrowRightBlueIcon} alt="" /></li>
        </ul>
        <Button variant="primary icon" onClick={() => {
          window.open('mailto:contact@alkelio.com?subject=Need Help')
        }}>
          <>
            <img src={PaperPlanIcon} alt="contact"/>
            contact ALKELIO catalyst
          </>
        </Button>
      </div>
    </div>
  )
}

export default NeedHelp;