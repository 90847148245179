import React from 'react'
import { useGetMyPurchasedSubscriptionQuery } from 'services/graphql/purchased-subscription'
import { useParams } from 'react-router-dom'
import EntityConstants from 'app/entity-type/entityConstants';
import { format } from 'date-fns'

import './assets/styles/subscription.component.scss'

export const Subscription = () => {
  const { data, isSuccess, isLoading } = useGetMyPurchasedSubscriptionQuery()
  const { entityType }: any = useParams();

  let credit_text = ''
  if (entityType === EntityConstants.STARTUP) {
    credit_text = 'matches'
  } else
  if (entityType === EntityConstants.CORP) {
    credit_text = 'conversations'
  } 

  return (
    <form className="my-subscription">
      {isSuccess ?
      <div className="mt-4 form-control-group">
        <h6 className="mb-4 bold-type">Subscription</h6> 
        <div className="form-control">
          <label>
            <div className="text">
              My Package
            </div>
          </label>
          <div className="value">
            <b>{data?.service_package?.name}</b> - <a className="color-azure" href="#">see what it includes</a>
          </div>
        </div>
        <div className="form-control">
          <label>
            <div className="text">
              Used
            </div>
          </label>
          <div className="value">
            <b>{data?.spent}</b> {credit_text} out of <b>{(data?.balance ?? 0) + (data?.spent ?? 0)}</b>
          </div>
        </div>
        <div className="form-control">
          <label>
            <div className="text">
              Expires
            </div>
          </label>
          <div className="value">
            {format(data?.expiry, 'MMMM YYYY')}
          </div>
        </div>
      </div> : null}
    </form>
  )
}
