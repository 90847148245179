import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { ViewCounts } from 'generated/graphql'

export const viewCountApi = createApi({
  reducerPath: "viewCountApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  tagTypes: ["views"],
  endpoints: (builder) => ({
    addViewCount: builder.mutation<ViewCounts, string>({
      query: (article_id: string) => ({
        document: gql`
          mutation addViewCount($article_id: String!) {
            addCount(article_id: $article_id) {
              article_id
              count
            }
          }
        `,
        variables: {
          article_id
        }
      }),
      invalidatesTags: (result, error, data) => {
        return [{ type: 'views', id: data }]
      },
      transformResponse:  (response:any) => {
        return response.addCount
      }
    }),
    getViewCount: builder.query<ViewCounts, string>({
      query: (article_id: string) => ({
        document: gql`
          query getViewCount($article_id: String!) {
            viewCounts(where: {
              article_id: $article_id
            }) {
              article_id
              count
            }
          }
        `,
        variables: {
          article_id
        }
      }),
      providesTags: (result, error, data) => {
        return [{ type: 'views', id: data }]
      },
      transformResponse: (response:any) => {
        return response.viewCounts[0] ?? null
      }
    })
  })
})

export const { 
  useAddViewCountMutation,
  useGetViewCountQuery
} = viewCountApi
