import React from 'react'
import './assets/styles/technology-solution.not-published.scss'

const TechnologySolutionNotPublished = () => {
  return (
    <div className="tech-solution-not-published">
      <h3>Please publish this technology solution before you can match it.</h3>
    </div>
  )
}

export default TechnologySolutionNotPublished