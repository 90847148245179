import { useEffect, useState } from 'react';
import { useUploadMutation, useRemoveMutation } from 'services/graphql/upload';
import { AppSettings } from 'app-constants';

export interface FileItem {
  id: string;
  name: string;
  url: string;
}

interface useFileUploadProps {
  name?: string;
  max: number;
  initial?: Array<FileItem>;
  onUploadsChange?: (files: Array<FileItem>) => unknown;
}

const validateFileSize = (file:File) => {
  return file.size/(1024*1024) <= AppSettings.maxFileSizeMB;
}

type FileErrors = {
  size: boolean;
  type: boolean;
}

const useFileUpload = ({
  name,
  max = 10,
  initial = [],
  onUploadsChange,
}: useFileUploadProps) => {
  const [uploads, setUploads] = useState<FileItem[]>(initial.map(({id, name, url}) => ({
    id,
    name, 
    url
  } as FileItem)))
  const [upload, uploadResult] = useUploadMutation();
  const [removeUpload, removeResult] = useRemoveMutation();
  const [fileCheckErrors, setfileCheckErrors] = useState<FileErrors>({
    size: false,
    type: false
  });

  const handleFiles = async (fileList: FileList | null) => {
  //  console.log("handling",fileList);
    setfileCheckErrors({
      size: false,
      type: false
    });

    if (fileList && fileList.length > 0) {
      const files = Array<File>();
      // console.log("handled Files: ",files);
      for (let i = 0; i < fileList.length && i < max; i++) {
        const file = fileList[i];
        if (validateFileSize(file)) {
          files.push(file);
        } else {
          setfileCheckErrors({
            ...fileCheckErrors,
            size: true
          });
        }
      }

      const result = await (await upload(files).unwrap()).map( ({id, url, name}) => ({
        id,
        url,
        name
      }));

      const list = [...uploads, ...result];
      
      while (list.length > max) {
        list.shift();
      }
      
      setUploads(list)

      onUploadsChange && onUploadsChange(list)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(e.target.files);
    // console.log("What changed",e.target.files);
  }

  const onDrop = async (e: React.DragEvent<HTMLDivElement | HTMLLabelElement>) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  }

  const remove = async (id: string) => {
    await removeUpload(id);
    const list = uploads.filter((f) => (f.id !== id));
    setUploads(list);
  }

  const errors = {
    uploading: uploadResult.error,
    fileCheckErrors: fileCheckErrors
  }

  return {
    uploads,
    setUploads,
    remove,
    onChange,
    onDrop,
    isLoading: uploadResult.isLoading,
    isSuccess: uploadResult.isSuccess,
    errors
  }
}

export default useFileUpload;