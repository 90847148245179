import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from 'store'
import { servicesInterestApi } from 'services/graphql/services-interest'

export enum Enum_CartItem_Type {
  SERVICE_PACKAGE = 'SERVICE_PACKAGE',
  SERVICE = 'SERVICE'
}

export interface CartItem {
  slug: string;
  name: string;
  currency: string;
  qty: number;
  price: number;
  itemType?: Enum_CartItem_Type;
}

export enum Enum_ConversationWithCategory {
  MATCH = 'MATCH',
  MATCH_UNLOCK_BUDGET = 'MATCH_UNLOCK_BUDGET',
  MATCH_UNLOCK_DEMO = 'MATCH_UNLOCK_DEMO',
  MATCH_UNLOCK_TESTIMONIALS = 'MATCH_UNLOCK_TESTIMONIALS',
  MATCH_UNLOCK_CASE_STUDIES = 'MATCH_UNLOCK_CASE_STUDIES',
  EXPLORE = 'EXPLORE',
  EXPLORE_UNLOCK_BUDGET = 'EXPLORE_UNLOCK_BUDGET',
  EXPLORE_UNLOCK_DEMO = 'EXPLORE_UNLOCK_DEMO',
  EXPLORE_UNLOCK_TESTIMONIAL = 'EXPLORE_UNLOCK_TESTIMONIAL',
  EXPLORE_UNLOCK_CASE_STUDIES = 'EXPLORE_UNLOCK_CASE_STUDIES'
}

export interface ConversationWith {
  action: Enum_ConversationWithCategory | '';
  targetId: string;
  targetType: string;
  name: string;
}

export interface CartStateType {
  items: CartItem[];
  conversationWith: ConversationWith;
}

const initialState: CartStateType = {
  items: [],
  conversationWith: {
    action: '',
    targetId: '',
    targetType: '',
    name: ''
  }
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setConversationWith: (state: CartStateType, action: PayloadAction<ConversationWith>) => {
      state.conversationWith = action.payload
    },
    setServicePackage: (state: CartStateType, action: PayloadAction<CartItem>) => {
      const { slug, qty, price, name, currency } = action.payload;
      const list = state.items.filter((item) => item.itemType !== Enum_CartItem_Type.SERVICE_PACKAGE);
      list.push({
        slug, 
        qty,
        price,
        name,
        currency,
        itemType: Enum_CartItem_Type.SERVICE_PACKAGE
      })
      state.items = list;
    },
    addAdhocService: (state: CartStateType, action: PayloadAction<CartItem>) => {
      const { slug, qty, price, name, currency } = action.payload;

      if (state.items.find((item) => item.slug === slug) === undefined) {
        state.items.push({
          slug, 
          qty,
          price,
          name,
          currency,
          itemType: Enum_CartItem_Type.SERVICE
        })
      }
    },
    remove: (state: CartStateType, action: PayloadAction<number>) => {
      state.items.splice(action.payload, 1);
    },
    reset: (state: CartStateType) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      servicesInterestApi.endpoints.submitInterest.matchFulfilled,
      (state: CartStateType) => {
        console.log('reset on service interest fulfilled')
        state = initialState
        return state
      }
    )
  }
});

export const { setServicePackage, addAdhocService, remove, reset, setConversationWith } = cartSlice.actions

export default cartSlice.reducer

export const currentCart = (state: RootState) => state.cart