import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'features/auth/authSlice';
import {
  Redirect
} from 'react-router-dom';
import Session from 'session';
import { authApi } from 'services/graphql/auth';
import { bookmarksApi } from 'services/graphql/bookmarks';
import { conversationApi } from 'services/graphql/conversation';
import { enquiriesApi } from 'services/graphql/enquiries';
import { matchMakingResultsApi } from 'services/graphql/match-making-result';
import { newsApi } from 'services/graphql/news';
import { profileApi } from 'services/graphql/profile';
import { tagsApi } from 'services/graphql/tags';
import { techRequestsApi} from 'services/graphql/tech-requests';
import { techSolutionsApi } from 'services/graphql/tech-solutions';
import { uploadApi } from 'services/graphql/upload';
import { viewCountApi } from 'services/graphql/view-counts';
import { catalystAllocationApi } from 'services/graphql/catalyst-allocation';
import { ratingsApi } from 'services/graphql/ratings';
import { documentSharingApi } from 'services/graphql/document-sharing';
import { notificationsApi } from 'services/graphql/notifications';
import { servicesApi } from 'services/graphql/services';
import { resetHeader } from 'features/header-profile/headerProfileSlice';
import { resetModals } from 'features/modals/modalSlice';
import { resetToasts } from 'features/toasts/toastsSlice';
import { reset as resetCart } from 'features/cart/cartSlice';

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    Session.clear();
    // clear all other caches and states
    dispatch(authApi.util.resetApiState());
    dispatch(bookmarksApi.util.resetApiState());
    dispatch(conversationApi.util.resetApiState());
    dispatch(enquiriesApi.util.resetApiState());
    dispatch(matchMakingResultsApi.util.resetApiState());
    dispatch(newsApi.util.resetApiState());
    dispatch(profileApi.util.resetApiState());
    dispatch(tagsApi.util.resetApiState());
    dispatch(techRequestsApi.util.resetApiState());
    dispatch(techSolutionsApi.util.resetApiState());
    dispatch(uploadApi.util.resetApiState());
    dispatch(viewCountApi.util.resetApiState());
    dispatch(catalystAllocationApi.util.resetApiState());
    dispatch(ratingsApi.util.resetApiState());
    dispatch(documentSharingApi.util.resetApiState());
    dispatch(notificationsApi.util.resetApiState());
    dispatch(servicesApi.util.resetApiState());
    dispatch(resetCart());
    dispatch(resetHeader());
    dispatch(resetModals());
    dispatch(resetToasts());

    dispatch(logout());
  })
  return (
    <>
      logging out
      <Redirect to="/" />
    </>
  )
}

export default Logout;