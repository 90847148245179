import React from 'react';
import Tile from 'app/shared/tile';

import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';

import Conversation from 'app/collaborate/conversation';
import Conversations from 'app/collaborate/conversations';

const CatalystConversations = () => {
  const { entityType }: any = useParams();
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Tile 
          title="Conversations" >
          <Conversations/>
          <div className="d-block d-md-none">
            <h6>Please open your conversations on a desktop for an ideal experience</h6>
          </div>
        </Tile>
      </Route>
      
      <Route path={`${path}/:id`}>
        <Conversation />
      </Route>

    </Switch>
  );
}

export default CatalystConversations;