import React, { useState, useEffect, useRef, useCallback } from 'react';
import './assets/styles/dropdown.scss';
import CaretDownBlueIcon from './assets/svgs/caret-down-blue.svg';
import {
  Link
} from 'react-router-dom';
import { debounce } from 'lodash'

export interface DropdownItem {
  label: string;
  icon?: string;
  to?: string;
}

interface DropdownProps {
  items: Array<DropdownItem>;
  children? : React.ReactNode;
  className?: string;
}

const Dropdown = (props: DropdownProps) => {
  const elemMenu = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState<boolean>(false)
  const dropdownItems: Array<React.ReactNode> = props.items.map((item, i) => (
    <Link className={`dropdown-item`} key={i} to={item.to || ''}>
      <div className="inner">
        {item.icon && <img className="dropdown-item-icon" src={item.icon} alt={item.label} />}
        <label className="dropdown-item-label">{item.label}</label>
      </div>
    </Link>
  ))

  const toggle = debounce(() => {
    setActive(!active)
  }, 200)

  const close = debounce(() => {
    if (!active)
      setActive(false)
  }, 200)

  const memoizedClose = useCallback((e) => {
    close();
  }, [active])

  const memoizedToggle = useCallback((e) => {
    toggle();
    e.stopPropagation()
  }, [active])

  const setupClickListeners = () => {
    if (elemMenu.current) {
      /*
      elemMenu.current.removeEventListener('click', (e) => {
        e.stopPropagation()
      })
      elemMenu.current.addEventListener('click', (e) => {
        e.stopPropagation()
      })
      */
      document.addEventListener('click', memoizedClose)
    }
  }

  useEffect(() => {
    setupClickListeners()
  }, [])

  return (
    <div className={`dropdown ${props.className ?? ''} ${active? 'active':''}`}>
      <div className="dropdown-toggle" onClick={memoizedToggle}>
        {props.children}
        <img className="dropdown-toggle-icon" src={CaretDownBlueIcon} alt="" />
      </div>
      <div className="dropdown-menu" ref={elemMenu}>
        {dropdownItems}
      </div>
    </div>
  )
}

export default Dropdown;