import { 
  useSearchTechnologyRequestsQuery, 
  SearchTechnologyRequestInput
} from 'services/graphql/tech-requests';
import {
  useGetCurrentUserBookmarksQuery
} from 'services/graphql/bookmarks';
import TechnologyRequestCard from './technology-request-card';
import { Enum_Bookmark_Category, Enum_Bookmark_Type, Bookmark, TechnologyRequest } from 'generated/graphql';
import ExploreHelpBanner from 'app/shared/explore-help-banner';

interface ResultsProps {
  filters: SearchTechnologyRequestInput;
}

const Results = ({filters}: ResultsProps) => {
  const { data, isLoading, isSuccess } = useSearchTechnologyRequestsQuery(filters)
  const bookmarksQuery = useGetCurrentUserBookmarksQuery()
  
  const isBookmarked = (techRequest: TechnologyRequest, bookmarks: Array<Bookmark> ) => {
    const matched = bookmarks.filter(item => 
      (item.type !== undefined && item.type  === Enum_Bookmark_Type.TechnologyRequest) 
      && (item.category !== undefined && item.category === Enum_Bookmark_Category.Save))
      .find((item) => item.item_id === Number(techRequest.id))

    return matched !== undefined;
  }

  const getBookmarkId = (techRequest: TechnologyRequest, bookmarks: Array<Bookmark> ) => {
    const matched = bookmarks.filter(item => 
      (item.type !== undefined && item.type  === Enum_Bookmark_Type.TechnologyRequest) 
      && (item.category !== undefined && item.category === Enum_Bookmark_Category.Save))
      .find((item) => item.item_id === Number(techRequest.id))

    return matched && matched.id;
  }

  const getBookmarkCategory = (techRequest: TechnologyRequest, bookmarks: Array<Bookmark> ): Enum_Bookmark_Category | null => {
    const matched = bookmarks.filter(item => 
      (item.type !== undefined && item.type  === Enum_Bookmark_Type.TechnologyRequest))
      .find((item) => item.item_id === Number(techRequest.id))

    return (matched && matched.category) ?? null
  }

  return (
    <div>
      {(isLoading || bookmarksQuery.isLoading) && 
      <div>Loading Results</div>}
      {isSuccess && bookmarksQuery.isSuccess && 
        data?.map((item, i) => <TechnologyRequestCard 
          {...{
            ...item, 
            bookmarkCategory: getBookmarkCategory(item, bookmarksQuery.data),
            bookmarked: isBookmarked(item, bookmarksQuery.data),
            bookmarkedId: getBookmarkId(item, bookmarksQuery.data)
            }
          } 
            key={i}/>)}
      {isSuccess && <ExploreHelpBanner/>}
    </div>
  )
}

export default Results;