import React, { useEffect } from 'react';
import './assets/styles/results.scss';
import Tile from 'app/shared/tile';
import { MatchItemSummary, Bookmark } from 'generated/graphql';
import { 
  useGetTechnologyRequestMatchesQuery
} from 'services/graphql/match-making-result';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import MatchedTechnologySolutionCard from 'app/shared/matched-technology-solution-card';
import { useSelector } from 'react-redux';
import {
  useHistory
} from 'react-router-dom'
import MatchHelpBanner from 'app/shared/match-help-banner';

interface ResultsProp {
  technologyRequest: string;
  bookmarks: Array<Bookmark> | undefined;
  matchTriggered?: boolean;
}

const Results = ({
  technologyRequest,
  bookmarks = [],
  matchTriggered = false
}: ResultsProp) => {
  const { push } = useHistory()
  const headerProfile = useSelector(selectHeaderProfile)

  const { data, isFetching, isSuccess } = useGetTechnologyRequestMatchesQuery(technologyRequest)
  
  const title = isFetching 
    ? 'Loading Results' 
    : isSuccess 
      ? matchTriggered 
        ? (`Found ${data && data?.matches && data?.matches.length} matches`) 
        : 'No matching started yet, click "MATCH" to start'
      : 'Error Loading Results';

  const matches = (data !== undefined 
    && data?.matches !== undefined 
    && data?.matches !== null) ? (data?.matches as Array<MatchItemSummary>) : [];

  const viewMatch = (id: string) => {
    push(`/app/corporate/matches/${id}`)
  }

  return (
    <Tile title={title} className="corporate-match-results">
      <div className="flex-row flex-wrap d-flex match-tech-solutions-inner">
        {isSuccess && matches.map(({id, technology_solution, match_rating},i) => 
          <MatchedTechnologySolutionCard 
            key={i}
            item={{
              id: id || '',
              technology_solution_id: technology_solution?.id?? '',
              hero_image_url: technology_solution?.hero_image?.url || '',
              name: technology_solution?.name || '',
              description: technology_solution?.description || '',
              country: technology_solution?.startup_profile?.country || '',
              maturity_level: technology_solution?.startup_profile?.maturity_level?.name || '',
              match_rating: match_rating || 0,
            }} 
            bookmarks={bookmarks} 
            profileId={String(headerProfile.id)} 
            onView={viewMatch} />
        )}
      </div>
      <MatchHelpBanner />
    </Tile>
  )
}

export default Results;