import { AdhocServicesCorporateList } from 'app-constants';
import ControlBar from './control-bar';
import CallToAction from './call-to-action';
import ServiceCard from 'shared/service-card';
import './assets/styles/service-card.scss';

const AdhocServiceCorporate = () => {
  return (
    <div className="service-cards-container">
      <h4 className="mb-5 ms-3">ALKELIO+ Corporate AdHOC Services</h4>
      <div className="flex-wrap d-flex flex-column flex-md-row justify-content-between">
        {AdhocServicesCorporateList.map((item, i) => (
          <ServiceCard {...item} key={i} />
        ))}
      </div>
      <CallToAction />
      <ControlBar />
    </div>
  )
}

export default AdhocServiceCorporate;