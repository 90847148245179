const Config = {
  storageBaseURL: (process.env.REACT_APP_STORAGE_BASE_URL as string).trim(),
  graphqlBaseURL: (process.env.REACT_APP_GRAPHQL_BASE_URL as string).trim(),
  onboardingUUIDStorageKey: "onboardinguuid",
  onboardingSessionStorageKey: "onboardingsession",
  onboardingUUIDExpiryKey: "onboardingexpiry",
  onboardingVerificationUUIDStorageKey: "onboardingverificationuuid",
  onboardingUserEmailKey: "onboardinguseremail",
  onboardingJwtKey: "onboardingjwt",
  onboardingResendVerificationCodeWaitTimeKey: "onboardingresendwait"
}

export default Config;