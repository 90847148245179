import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import {
  Chat,
  ChatMessages,
  GetChatMessagesPayload,
  GetOrCreateChatMessagesWithCategoryInput,
  SendMessageInput
} from 'generated/graphql'

/*
  a message session is a chat - contains users and messages
*/

export const messagingApi = createApi({
  reducerPath: "messagingApi",
  tagTypes: ["message-session", 'message'],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getMessagingSession: builder.query<GetChatMessagesPayload, GetOrCreateChatMessagesWithCategoryInput>({
      query: ({
        referenceId,
        category,
        page,
        size
      }) => ({
        document: gql`
          query getOrCreateChatMessagesWithCategory($input: GetOrCreateChatMessagesWithCategoryInput!) {
            getOrCreateChatMessagesWithCategory(input: $input) {
              size
              page
              totalPages
              totalMessages
              messages {
                id
                from_user {
                  id
                  person_profile {
                    last_name
                    first_name
                    photo {
                      url
                    }
                  }
                }
                body
                created_at
              }
            }
          },
        `,
        variables: {
          input: {
            category,
            referenceId,
            page,
            size
          }
        }
      }),
      /*
      merge: (currentCache: Array<ChatMessages>, newItems: Array<ChatMessages>) => {
        console.log('new cache', currentCache, newItems)
        currentCache.push(...newItems)
      },
      */
      /*
      extraOptions: {
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          console.log(queryArgs, endpointDefinition, endpointName)
          const { referenceId, category } = queryArgs as GetOrCreateChatMessagesWithCategoryInput
          // This can return a string, an object, a number, or a boolean.
          // If it returns an object, number or boolean, that value
          // will be serialized automatically via `defaultSerializeQueryArgs`
          return { referenceId, category } // omit `client` from the cache key
  
          // Alternately, you can use `defaultSerializeQueryArgs` yourself:
          // return defaultSerializeQueryArgs({
          //   endpointName,
          //   queryArgs: { id },
          //   endpointDefinition
          // })
          // Or  create and return a string yourself:
          // return `getPost(${id})`
        },
        merge: (currentCache: Array<ChatMessages>, newItems: Array<ChatMessages>) => {
          console.log(currentCache, newItems)
          currentCache.push(...newItems)
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg
        },
      },
      */
      transformResponse: (response: any) => {

        const messages = response.getOrCreateChatMessagesWithCategory.messages.sort((a:ChatMessages, b: ChatMessages) => {
          if (a.created_at > b.created_at) return 1
          if (a.created_at < b.created_at) return -1
          return 0
        })

        return {
          ...response.getOrCreateChatMessagesWithCategory,
          messages
        }
      },
      providesTags: (result, error, input) => [{
        type: 'message-session',
        id: `${input.referenceId}-${input.category}`
      }],
    }),
    sendMessage: builder.mutation<void, SendMessageInput>({
      query: ({
        category,
        referenceId,
        body
      }) => ({
        document: gql`
          mutation sendMessage($input: SendMessageInput) {
            sendChatMessage(input: $input) 
          }
        `,
        variables: {
          input: {
            category,
            referenceId,
            body
          }
        }
      }),
      invalidatesTags: (result, error, input) => [{
        type: 'message-session',
        id: `${input.referenceId}-${input.category}`
      }],
      /*
      onQueryStarted: async ({ referenceId, category }, { dispatch, queryFulfilled}) => {
        console.log('query started')
        const patchResult = dispatch(
          messagingApi.util.updateQueryData('getMessagingSession', {
            referenceId,
            category,
            page,
            size
          }, (draft: Array<ChatMessages>) => {
            console.log(draft)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      */
    })
  })
})

export const {
  useGetMessagingSessionQuery,
  useLazyGetMessagingSessionQuery,
  useSendMessageMutation
} = messagingApi