import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { TechnologyType } from 'generated/graphql'
import { Industry } from 'generated/graphql'
import { BusinessUseCase } from 'generated/graphql'
import { CorporateDepartment } from 'generated/graphql'
import { PricingModel } from 'generated/graphql'
import { ImpactArea } from 'generated/graphql'
import { Goals } from 'generated/graphql'
import { MaturityLevel } from 'generated/graphql'
import { TechnologyReadinessLevel } from 'generated/graphql'
import { SdgObjectives  } from 'generated/graphql'

type SdGObjectiveQueryResultItem = {
  id: string, 
  name: string
}

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getSdgObjectives: builder.query<Array<SdgObjectives>, void>({
      query: () => ({
        document: gql`
          query {
            sdgObjectives {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.sdgObjectives.sort((a: SdGObjectiveQueryResultItem, b: SdGObjectiveQueryResultItem) => Number(a.id) < Number(b.id))
    }),
    getTechnologyReadinessLevels: builder.query<Array<TechnologyReadinessLevel>, void>({
      query: () => ({
        document: gql`
          query {
            technologyReadinessLevels {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.technologyReadinessLevels
    }),
    getMaturityLevels: builder.query<Array<MaturityLevel>, void>({
      query: () => ({
        document: gql`
          query {
            maturityLevels {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.maturityLevels
    }),
    getGoals: builder.query<Array<ImpactArea>, void>({
      query: () => ({
        document: gql`
          query {
            goals {
              id
              Name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.goals.map((item: Goals) => ({
          id: item.id, 
          name: item.Name
        })
      )
    }),
    getImpactAreas: builder.query<Array<ImpactArea>, void>({
      query: () => ({
        document: gql`
          query {
            impactAreas {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.impactAreas
    }),
    getTechnologyTypes: builder.query<Array<TechnologyType>, void>({
      query: () => ({
        document: gql`
          query {
            technologyTypes {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.technologyTypes
    }),
    getIndustries: builder.query<Array<Industry>, void>({
      query: () => ({
        document: gql`
          query {
            industries {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.industries
    }),
    getBusinessUsecases: builder.query<Array<BusinessUseCase>, void>({
      query: () => ({
        document: gql`
          query {
            businessUseCases {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.businessUseCases
    }),
    getCorporateDepartments: builder.query<Array<CorporateDepartment>, void>({
      query: () => ({
        document: gql`
          query {
            corporateDepartments {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.corporateDepartments
    }),
    getPricingModels: builder.query<Array<PricingModel>, void>({
      query: () => ({
        document: gql`
          query {
            pricingModels {
              id
              name
            }
          }
        `
      }),
      transformResponse: (response: any) => response.pricingModels
    })
  })
})

export const { 
  useGetBusinessUsecasesQuery,
  useGetCorporateDepartmentsQuery,
  useGetIndustriesQuery,
  useGetPricingModelsQuery,
  useGetTechnologyTypesQuery,
  useGetImpactAreasQuery,
  useGetGoalsQuery,
  useGetMaturityLevelsQuery,
  useGetTechnologyReadinessLevelsQuery,
  useGetSdgObjectivesQuery
} = tagsApi