import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { DocumentSharing } from 'generated/graphql'

export const documentSharingApi = createApi({
  reducerPath: "documentSharingApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getRecentDocuments: builder.query<Array<DocumentSharing>, string>({
      query: (userId) => ({
        document: gql`
          query getRecentDocuments($userId: ID!) {
            documentSharings (where: {
              users_permissions_users: $userId
            }, 
            sort: "created_at:desc",
            limit: 4) {
              title
              created_at
              document {
                caption
                name
                url
              }
            }
          }
        `,
        variables: {
          userId
        }
      }),
      transformResponse: (response: any) => response.documentSharings
    }),
    getAllDocuments: builder.query<Array<DocumentSharing>, string>({
      query: (userId) => ({
        document: gql`
          query getAllDocuments($userId: ID!) {
            documentSharings (where: {
              users_permissions_users: $userId
            }, sort: "created_at:desc") {
              title
              created_at
              document {
                caption
                name
                url
              }
            }
          }
        `,
        variables: {
          userId
        }
      }),
      transformResponse: (response: any) => response.documentSharings
    })
  })
})

export const {
  useGetRecentDocumentsQuery,
  useGetAllDocumentsQuery
} = documentSharingApi