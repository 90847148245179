import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { CatalystAllocation } from 'generated/graphql'

export const catalystAllocationApi = createApi({
  reducerPath: "catalystAllocationApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getCatalystByCorporateProfile: builder.query<Array<CatalystAllocation>, string>({
      query: (corporate_profile) => ({
        document: gql`
          query catalystAllocation($corporate_profile: ID!) {
            catalystAllocations(where: {
              corporate_profiles: $corporate_profile
            }) {
              users_permissions_user {
                email
                person_profile {
                  first_name
                  last_name
                  photo {
                    formats
                  }
                }
              }
            }
          }
        `,
        variables: {
          corporate_profile
        }
      }),
      transformResponse: (response: any) => response.catalystAllocations[0],
    }),
    getCatalystByStartupProfile: builder.query<Array<CatalystAllocation>, string>({
      query: (startup_profile) => ({
        document: gql`
          query catalystAllocation($startup_profile: ID!) {
            catalystAllocations(where: {
              startup_profile: $startup_profile
            }) {
              users_permissions_user {
                email
                person_profile {
                  first_name
                  last_name
                  photo {
                    formats
                  }
                }
              }
            }
          }
        `,
        variables: {
          startup_profile
        }
      }),
      transformResponse: (response: any) => response.catalystAllocations[0],
    })
  })
})

export const {
  useGetCatalystByCorporateProfileQuery,
  useGetCatalystByStartupProfileQuery,
  useLazyGetCatalystByCorporateProfileQuery,
  useLazyGetCatalystByStartupProfileQuery,
} = catalystAllocationApi;