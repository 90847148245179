import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import InfoIcon from 'app/shared/assets/svgs/info.svg';
import './assets/styles/info-tooltip.scss'

type InfoTooltipProps = {
  toolTip?: string;
  className?: string;
}

export const InfoToolTip = ({
  toolTip,
  className
}: InfoTooltipProps) => {
  const [active, setActive] = useState<boolean>(false)
  const tooltipInfoElem = useRef<HTMLDivElement>(null)
  const tooltipElem = useRef<HTMLDivElement>(null)

  const onBodyClick = () => {
    if (tooltipElem.current) {
      console.log(tooltipElem.current.classList, active)
      if (tooltipElem.current.classList.contains('active')) {
        tooltipElem.current.classList.remove('active')
      }
    }
  }

  const setTooltipPositions = useCallback(() => {
    if (tooltipInfoElem.current && tooltipElem.current) {
      const { x } = tooltipInfoElem.current.getBoundingClientRect()
      const isRight = x > (window.screen.width / 2)
      if (isRight) {
        tooltipElem.current.style.transformOrigin = 'top right'
        tooltipElem.current.style.transform = 'translate(-100%, 20px)'
        tooltipElem.current.style.width = `${x - 70}px`
      } else {
        tooltipElem.current.style.transformOrigin = 'top left'
        tooltipElem.current.style.transform = 'translate(15px, 18px)'
        tooltipElem.current.style.width = `calc(100vw - ${x + 80}px)`
      }
    }
  }, [])

  useEffect(() => {
    // get position of this element
    if (window.screen.width <= 768) {
      setTooltipPositions()
      // get it again on window resize
  
      document.body.onclick = onBodyClick
      window.onresize = setTooltipPositions
    }
  }, [])

  return (
    <div className={`tool-tip-info ${className}`} ref={tooltipInfoElem} onClick={(e) => {
      e.stopPropagation();
    }}>
      <img className="tool-tip-icon" src={InfoIcon} alt={toolTip?? ''} onClick={(e) => {
        if (tooltipElem.current) {
          setActive(!active)
          e.stopPropagation();
        }
      }} />
      {toolTip && <div className={`tool-tip ${active ? 'active' : null}`} ref={tooltipElem}>{toolTip}</div>}
    </div>
  )
}