import IconWarning from './assets/svgs/warning.svg';
import IconCircleOTick from './assets/svgs/circle-o-tick.svg';
import { useDispatch } from 'react-redux';
import { close } from 'features/modals/modalSlice';
import Button from 'app/shared/button';
import './assets/styles/before-edit-notification.scss';

const BeforeEditNotification = () => {
  const dispatch = useDispatch();
  return (
    <div className="before-edit-notifications">
      <img src={IconWarning} alt="" className="mb-5 icon-warning" />

      <div className="d-flex justify-content-center flex-column">
        <p>We kindly request the information to be provided <span className="bold-type">anonymously</span>, without any identifiable markers.</p>

        <p>Please don’t mention your company or any specific / traded wording. <br/>
        If you don't follow this rule, we will be obliged to remove you from the platform, and we will be very sad to do so! </p>

        <p>Lets work on a long-lasting relationship based on trust : )</p>
      </div>

      <Button type="button" className="mt-4" variant="primary" 
        onClick={() => {
          dispatch(close())
        }}
      >
        <img src={IconCircleOTick} alt="" className="me-2" />
        Of Course!
      </Button>
    </div>
  )
}

export default BeforeEditNotification;