import PopCrackerIcon from './assets/svgs/pop-cracker.svg'
import Button from '../button';
import IconCircleOTick from './assets/svgs/circle-o-tick.svg';
import './assets/styles/early-access.scss'
import { useDispatch } from 'react-redux';
import { close } from 'features/modals/modalSlice'

export const EarlyAccess = () => {
  const dispatch = useDispatch();
  return (
    <div className="early-access d-flex flex-column align-items-center justify-content-center">
      <img className="icon" src={PopCrackerIcon} alt="" />
      <div className="title text-center">Welcome to the Early Access of ALKELIO. You are truly a pioneer!</div>
      <div className="font-size-14 line-height-140 txt-regular letter-spacing-n-2 mb-3 text-center">Now you have access to most of the services. We are still working to make your experience even better. We will be pushing updates on a ongoing basis. Stay tuned! </div>
      <Button
        variant="primary" 
        className="wide mt-4"
        onClick={() => {
          localStorage.setItem('early-access-checked', "true");
          dispatch(close())
        }}>
        <img src={IconCircleOTick} alt="" className="me-2" />
        Im Excited
      </Button>
    </div>
  );
}