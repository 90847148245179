import React from 'react'
import InputImage from "shared/forms/input-image";
import { Inputs, Person, ProjectTiming, Info } from './inputs'
import { FileItem } from "hooks/file-uploads";
import {
  Control,
  Controller,
  UseFormRegister
} from 'react-hook-form'
import { MemberFormProps } from './member.form'

const MemberClientForm = ({
  name,
  profile_image,
  role,
  email,
  register,
  index,
  control
}: MemberFormProps) => {
  return (
    <div className="member">
      <Controller
        name={`client_team.${index}.profile_image`}
        defaultValue={
          profile_image
        }
        control={control}
        rules={{
          required: false,
        }}
        render={({ field }) => (
          <InputImage
            layout="minimal"
            height="72px"
            width="72px"
            {...field}
          />
        )}
      />

      <label className="field">
        <div className="text">Name</div>
        <input
          type="text"
          defaultValue={name || ""}
          {...register(
            `client_team.${index}.name`
          )}
        />
      </label>
      

      <label className="field">
        <div className="text">Role</div>
        <input
          type="text"
          defaultValue={name || ""}
          {...register(
            `client_team.${index}.role`
          )}
        />
      </label>

      

      <label className="field">
        <div className="text">Email</div>
        <input
          type="text"
          defaultValue={name || ""}
          {...register(
            `client_team.${index}.email`
          )}
        />
      </label>

    </div>
  )
}

export default MemberClientForm