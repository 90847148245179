import { DocumentNode } from 'graphql'
import { GraphQLClient, ClientError } from 'graphql-request'
import { BaseQueryFn, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'

type MaybePromise<T> = T | PromiseLike<T>

type graphqlBaseQueryProps = {
  baseUrl: string,
  prepareHeaders?: (
    headers: Headers,
    api: { getState: () => unknown }
  ) => MaybePromise<Headers>,
}

type BaseQueryFnArgs = {
  document: string | DocumentNode; 
  variables?: any
}

const graphqlBaseQuery = ({
  baseUrl,
  prepareHeaders = (x) => x,
}: graphqlBaseQueryProps): BaseQueryFn<
  BaseQueryFnArgs,
  unknown,
  ClientError,
  {},
  FetchBaseQueryMeta
> => async ({ document, variables }, { getState }) => {
  let headers = new Headers({});
  const client = new GraphQLClient(baseUrl)
  const reqHeaders = await prepareHeaders(
    new Headers(headers),
    { getState }
  )

  try {
    const data = await client.request(document, variables, reqHeaders)
    return { data }
  } catch (error) {
    if (error instanceof ClientError) {
      return { error }
    }
    throw error
  }
}

export default graphqlBaseQuery;