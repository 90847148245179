
import './assets/styles/technology-request-card.scss';
import Card from 'app/shared/card';
import React from 'react'
import { Enum_Bookmark_Category, Enum_Bookmark_Type, TechnologyRequest } from 'generated/graphql';
import IconLabel from 'app/shared/icon-label';
import { ViewCount } from 'app/shared';
import CalenderIcon from 'app/shared/assets/svgs/calender.svg';
import MapPinIcon from 'app/shared/assets/svgs/map-pin.svg';
import UserIcon from 'app/shared/assets/svgs/user.svg';
import StarIcon from 'app/shared/assets/svgs/star.svg';
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import dayjs from 'dayjs';
import Button from 'app/shared/button';
import Pill from 'app/shared/pill';
import { 
  useSetBookmarkMutation,
  useUnsetBookmarkMutation 
} from 'services/graphql/bookmarks';
import relativeTime from 'dayjs/plugin/relativeTime'
import ButtonFavourite from 'app/shared/button-favourite';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Countries } from 'shared/countries';

dayjs.extend(relativeTime)

interface TechnologyRequestCardProps extends TechnologyRequest {
  bookmarked: boolean;
  bookmarkedId: string | undefined;
  bookmarkCategory: Enum_Bookmark_Category | null; 
}

const TechnologyRequestCard = React.forwardRef((
  { 
    id,
    name,
    hero_image,
    business_challange_description,
    created_at,
    corporate_profile,
    want_lease,
    want_poc,
    want_seller,
    want_investment,
    bookmarked,
    bookmarkedId,
    bookmarkCategory
  }: TechnologyRequestCardProps,
  ref: React.Ref<HTMLDivElement>
) => {
  let { url } = useRouteMatch();
  const { push } = useHistory()
  const [ setBookMark ] = useSetBookmarkMutation()
  const [ unsetBookmark ] = useUnsetBookmarkMutation()
  const headerProfile = useSelector(selectHeaderProfile)

  const body = <>
    <p className="line-height-140">{business_challange_description?.substring(0, 185)}{(business_challange_description?.length?? 0) > 185 && <span>&#8230;</span>}</p>
    <div className="looking-for">
      <span>Looking for</span>
      <div>
        {want_seller && <Pill label="Sell" icon={TickWhiteIcon} />}
        {want_poc && <Pill label="POC" icon={TickWhiteIcon} />}
        {want_lease && <Pill label="Lease" icon={TickWhiteIcon} />}
        {want_investment && <Pill label="Invest" icon={TickWhiteIcon} />}
      </div>
    </div>
  </>

  const footer = <div className="tech-request-card-footer">
    <div className="info">
      {corporate_profile?.country_jurisdiction && <IconLabel className="country" icon={MapPinIcon}>{Countries.getName(corporate_profile?.country_jurisdiction || '', "en")}</IconLabel>}
      {corporate_profile?.employee_count_range && <IconLabel className="headcount" icon={UserIcon}>{corporate_profile?.employee_count_range || ''}</IconLabel>}
      {corporate_profile?.country_jurisdiction || corporate_profile?.employee_count_range ? <div className="horizontal-border"></div> : null}
      {corporate_profile?.rating && <IconLabel className="rating" icon={StarIcon}>{corporate_profile?.rating || 0}</IconLabel>}
      <IconLabel className="last-update" icon={CalenderIcon}>{dayjs(created_at).from(dayjs())}</IconLabel>
      <ViewCount className="views" articleId={`tech-req-${id}`} />
    </div>
    <Button variant="primary" className="action" onClick={() => {
      push(`/app/startup/explore/${id}`)
    }}>View</Button>
  </div>

  return (
    <Card ref={ref} 
      onClick={() => {
        push(`${url}/${id}`)
      }}
      className="tech-request-card"
      variant="horizontal"
      cardWidth="100%"
      imageWidth="370px"
      title={name || ''}
      imageFilter="darken" 
      imageSrc={hero_image?.url?? ''}
      imageOverlay={<ButtonFavourite
        mode={bookmarkCategory}
        defaultValue={bookmarked}
        onClick={() => {
          if (bookmarked) {
            bookmarkedId && unsetBookmark(bookmarkedId)
          } else {
            setBookMark({
              type: Enum_Bookmark_Type.TechnologyRequest,
              item_id: Number(id),
              category: Enum_Bookmark_Category.Save,
              person_profile: String(headerProfile.id)
            })
          }
        }} />}
      imageOverlayPosition="top-right"
      body={body}
      footer={footer}
    />
  );
})

export default TechnologyRequestCard;