import { useEffect } from 'react';
import FormLayout from './form-layout';
import ActionBar from '../action-bar/action-bar';
import { useForm, Controller } from 'react-hook-form';
import './assets/styles/form-layout-form.scss';
import { StartupPersonProfileUserInputs } from '../types'

import useOnboarding from '../hooks/onboarding.hook'
import useSteps from '../hooks/steps.hook'
import {
    OnboardingSessionStartup_PersonProfile,
    OnboardingSessionPayload_User,
    ImageAttachment
} from 'generated/graphql';
import { FileItem } from 'hooks/file-uploads';
import InputImage from 'shared/forms/input-image';
import IconLabel from 'app/shared/icon-label';

import { setEmail } from 'features/signup/signupSlice'

interface StartupUserProfileValues {
  personProfile: OnboardingSessionStartup_PersonProfile,
  user: OnboardingSessionPayload_User,
}

const StartupPersonProfile = () => {
  const { setOnboardingPayload, onboardingPayload } = useOnboarding()
  const { currentStep } = useSteps()
  
  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors } } = useForm<StartupPersonProfileUserInputs>({
      mode: "onChange",
    });

  const transformValues = (values: StartupPersonProfileUserInputs): StartupUserProfileValues => {
    return {
      personProfile: {
        first_name: values?.first_name ?? '',
        last_name: values?.last_name ?? '',
        photo: values?.photo as ImageAttachment,
        role: values?.role ?? '',
        contact_number: values?.contact_number ?? ''
      } as OnboardingSessionStartup_PersonProfile,
      user: {
        email: values?.email ?? ''
      } as OnboardingSessionPayload_User
    } as StartupUserProfileValues;
  }

  const handlePrev = async (prevStep: () => unknown) => {
    const me = transformValues(getValues());
    await setOnboardingPayload({
      ...me,
      step: currentStep - 1
    })
    setEmail(me?.user?.email ?? '')
    prevStep()
  }

  const handleNext = async (nextStep: () => unknown) => {
    const valid = await trigger();
    if (valid) {
      const me = transformValues(getValues());
      await setOnboardingPayload({
        ...me,
        step: currentStep + 1
      })
      setEmail(me?.user?.email ?? '')
      nextStep()
    }
  }

  useEffect(() => {
    if (onboardingPayload) {
      reset({
        photo: onboardingPayload?.personProfile?.photo as FileItem,
        first_name: onboardingPayload?.personProfile?.first_name ?? '',
        last_name: onboardingPayload?.personProfile?.last_name ?? '',
        role: onboardingPayload?.personProfile?.role ?? '',
        contact_number: onboardingPayload?.personProfile?.contact_number ?? '',
        email: onboardingPayload?.user?.email ?? '',
      });
    }
  }, []);


  return (
    <FormLayout
      subHeading="Startup onboarding"
      heading="Me"
    >
      <form className="form-layout-form">
        <div className="row">
          <div className="col">
            <div className="form-field">
              <Controller
                name="photo"
                control={control}
                rules={{
                  required: false
                }}
                render={({ field }) => <InputImage
                  layout="elipse-left"
                  height="72px"
                  width="72px"
                  label="Upload Photo"
                  {...field}
                />}
              />
              <div className={`invalid-feedback ${errors?.photo ? 'd-block' : ''}`}>Please upload your photo</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>First name *</label>
              <input type="text"
                {...register("first_name", { required: true })}
              />
              <div className={`invalid-feedback ${errors?.first_name ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col">
            <div className="form-field">
              <label>Last name *</label>
              <input type="text"
                {...register("last_name", { required: true })}
              />
              <div className={`invalid-feedback ${errors?.last_name ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Email *</label>
              <input type="email"
                {...register("email", { required: true })}
              />
              <div className={`invalid-feedback ${errors?.email ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col">
            <div className="form-field">
              <label>Role *</label>
              <input type="text"
                {...register("role", { required: true })}
              />
              <div className={`invalid-feedback ${errors?.role ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-field">
              <label>Contact number</label>
              <input type="text"
                {...register("contact_number")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <ActionBar onPrev={handlePrev} onNext={handleNext} />
          </div>
        </div>
      </form>
    </FormLayout>
  )
}

export default StartupPersonProfile;