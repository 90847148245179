import React, { useState, useEffect, useMemo } from 'react'

import Tile from 'app/shared/tile'
import SelectMultiple,  { OptionItem } from 'shared/forms/select-multiple';
import { 
  useGetBusinessUsecasesQuery, 
  useGetCorporateDepartmentsQuery,
  useGetIndustriesQuery,
  useGetTechnologyTypesQuery,
  useGetSdgObjectivesQuery
} from 'services/graphql/tags';
import { 
  BusinessUseCase, 
  Industry, 
  TechnologyType 
} from 'generated/graphql';
import { 
  SearchTechnologySolutionInput
} from 'services/graphql/tech-solutions';
import Results from './results';
import { CountryNames } from 'shared/countries'

const Explore = () => {
  const [filters, setFilters] = useState({
    technology_types: [],
    countries: [],
    industries: [],
    business_use_cases: [],
    sdg_objectives: []
  } as SearchTechnologySolutionInput);
  
  const technologyTypes = useGetTechnologyTypesQuery()
  const industries = useGetIndustriesQuery()
  const business_use_cases = useGetBusinessUsecasesQuery()
  const sdgObjectives = useGetSdgObjectivesQuery()

  const toOptions = (data: any): Array<OptionItem> => data ? (data as Array<any>).map((item: any) => ({label: item.name, value: item.id})) : []
  
  return (
    <Tile 
      title="Technology Solutions"
    >
      <div className="container-fluid p-0">
        <div className="row p-0">
          <div className="col p-0">
            <form>
              <div className="row align-items-start p-0">
                <div className="col-12 col-md-3 p-0">
                  <div className="form-control inset ps-0 pe-0 pe-md-2">
                    <label>Technology</label>
                    {technologyTypes.isSuccess && <SelectMultiple
                      variant="inverse txt-md"
                      placeholderFn={(selectedOptions, defaultPlaceholder) => {
                        if (selectedOptions.length === 0)
                          return 'Show All'
                        else
                          return defaultPlaceholder
                      }}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          technology_types: values
                        })
                      }}
                      options={toOptions(technologyTypes.data)} 
                    />}
                  </div>
                </div>
                <div className="col-12 col-md-3 p-0">
                  <div className="form-control inset ps-0 pe-0 px-md-2">
                    <label>Country</label>
                    <SelectMultiple
                      variant="inverse txt-md"
                      placeholderFn={(selectedOptions, defaultPlaceholder) => {
                        if (selectedOptions.length === 0)
                          return 'Show All'
                        else
                          return defaultPlaceholder
                      }}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          countries: values
                        })
                      }}
                      options={CountryNames} 
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3 p-0">
                  <div className="form-control inset ps-0 pe-0 px-md-2">
                    <label>Industry</label>
                    {industries.isSuccess && <SelectMultiple
                      variant="inverse txt-md"
                      placeholderFn={(selectedOptions, defaultPlaceholder) => {
                        if (selectedOptions.length === 0)
                          return 'Show All'
                        else
                          return defaultPlaceholder
                      }}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          industries: values
                        })
                      }}
                      options={toOptions(industries.data)} 
                    />}
                  </div>
                </div>
                <div className="col-12 col-md-3 p-0">
                  <div className="form-control inset ps-0 pe-0 ps-md-2">
                    <label>Use Case</label>
                    {business_use_cases && <SelectMultiple
                      variant="inverse txt-md"
                      placeholderFn={(selectedOptions, defaultPlaceholder) => {
                        if (selectedOptions.length === 0)
                          return 'Show All'
                        else
                          return defaultPlaceholder
                      }}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          business_use_cases: values
                        })
                      }}
                      options={toOptions(business_use_cases.data)} 
                    />}
                  </div>
                </div>
                <div className="col-12 col-md-3 p-0">
                  <div className="form-control inset ps-0 pe-0 ps-md-2">
                    <label>SDG Objectives</label>
                    {business_use_cases && <SelectMultiple
                      sort={false}
                      variant="inverse txt-md"
                      placeholderFn={(selectedOptions, defaultPlaceholder) => {
                        if (selectedOptions.length === 0)
                          return 'Show All'
                        else
                          return defaultPlaceholder
                      }}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          sdg_objectives: values
                        })
                      }}
                      options={toOptions(sdgObjectives.data)} 
                    />}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row p-0">
          <div className="col p-0">
            <Results filters={filters} />
          </div>
        </div>
      </div>
    </Tile>
  )
}

export default Explore;