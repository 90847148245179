import './assets/styles/button.scss';

type ButtonProps = {
  children?: React.ReactNode;
  variant?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  type?: "reset" | "button" | "submit";
  value?: string;
  name?: string;
  disabled?: boolean;
}

const Button = ({
  children, 
  className, 
  variant, 
  onClick,
  onMouseEnter,
  onMouseLeave, 
  type, 
  name,
  disabled
}: ButtonProps) => {
  return (
    <button 
      disabled={disabled} 
      name={name || ""} 
      type={type || "button"} 
      className={`app-btn ${variant || ''} ${className || ''}`} 
      onClick={onClick || (() => {}) }
      onMouseEnter={onMouseEnter || (() => {}) }
      onMouseLeave={onMouseLeave || (() => {}) }
      >
      {children}
    </button>
  )
}

export default Button;