import LifeBuoyIcon from 'app/shared/assets/svgs/life-buoy.svg';
import ArrowRightBlueIcon from 'app/shared/assets/svgs/arrow-right-blue.svg';
import PaperPlanIcon from 'app/shared/assets/svgs/paper-plane.svg';
import Image from 'app/shared/assets/imgs/need-help.jpg';
import './assets/styles/need-help-corporate.scss';
import Button from 'app/shared/button';

import Grid from "@material-ui/core/Grid";
const NeedHelp = () => {
  return (
    <div className="need-help-corporate" style={{backgroundImage: `url(${Image})`}}>
      <div className="d-flex flex-column">
        <div className="title"><img src={LifeBuoyIcon} alt="" />Need help? ALKELIO Catalyst can assist with:</div>
        <ul>
          <li>Define your proposal<img className="ms-2" src={ArrowRightBlueIcon} alt="" /> </li>
          <li>Communicate with the corporate <img className="ms-2" src={ArrowRightBlueIcon} alt="" /></li>
          <li>Identify pricing for your proposal <img className="ms-2" src={ArrowRightBlueIcon} alt="" /></li>
        </ul>
      </div>
      <Button variant="primary icon me-3" onClick={() => {}}>
        <>
          <img src={PaperPlanIcon} alt="contact"/>
          <span className="d-none d-md-block">contact ALKELIO catalyst</span>
          <span className="d-block d-md-none">ALKELIO catalyst</span>
        </>
      </Button>
    </div>
  )
}

export default NeedHelp;