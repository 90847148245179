import React, { useEffect, useRef, useState } from "react";
import "../assets/styles/conversation.scss";
import Tile from "app/shared/tile";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useGetConversationQuery } from "services/graphql/conversation";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { 
  ConversationSummary, 
  EditConversationInput,
  EditComponentConversationFormFormScopeRequirementInput,
  EditComponentConversationFormFormScopeInput,
  EditComponentConversationFormFormAgreementInput,
  UploadFile
} from "generated/graphql";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Inputs from "./inputs";
import Outline from "./outline";
import BusinessUseCase from "./business-use-case";
import Scope from "./scope";
import Proposal from "./proposal";
import Agreement from "./agreement";
import Navigation from "./navigation";
import Header from "./header";
import { 
  UpdateConversationAgreementInput, 
  useUpdateConversationAgreementMutation, 
  useUpdateConversationMutation
} from 'services/graphql/conversation';
import { FileItem } from "hooks/file-uploads";
import { convertFormConversationToInput } from './util';
import _ from "lodash";
import {
  useParams
} from 'react-router-dom';

interface ConversationProps extends ConversationSummary { }

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box mt={2}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const attachmentMap: Record<string, number[]> = {
  "corporate": [0,1,2],
  "startup": [3,4,5]
};

const reqAttrNames = [
  'technical_requirements_corporate',
  'legal_requirements_corporate',
  'other_requirements_corporate',
  'technical_requirements_startup',
  'legal_requirements_startup',
  'other_requirements_startup'
];

const Conversation = () => {
    const [view, setView] = useState<string | null>(null)
    const { id:  conversationID } : any = useParams()
    const { register, handleSubmit, watch, control, getValues, setValue, formState: { errors, dirtyFields }, reset } = useForm<Inputs>({
      mode: "onChange",
    });
    const prevConversationRef = useRef<FileItem[][]>([]);
    const prevConversationAgreementUploadedRef = useRef<FileItem[]>([]);
    const [value, setVValue] = React.useState(0);
    const [name, setName] = React.useState<string | undefined | null>(null);
    const [logo, setLogo] = React.useState<string | undefined | null>(null);
    const [stateKPIs, setStateKPIs] = React.useState<String[]>([]);
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
      if (user !== null) {
        if (user?.role?.type === 'catalyst') {
          setView('startup')
        } else {
          setView(user?.role?.type ?? null)
        }
      }
    }, [user])
    
    const { data, isSuccess } = useGetConversationQuery(conversationID);
    
    const [updateConversation] = useUpdateConversationMutation();
    
    const watchClientAgree = watch('agreement.client_agree')
    const watchSupplierAgree = watch('agreement.supplier_agree')

    const watchScopeAttachments = watch([
      "scope.technical_requirements_corporate.attachment",
      "scope.legal_requirements_corporate.attachment",
      "scope.other_requirements_corporate.attachment",
      "scope.technical_requirements_startup.attachment",
      "scope.legal_requirements_startup.attachment",
      "scope.other_requirements_startup.attachment",
    ])

    const watchAgreementUploadContract = watch('agreement.uploaded_contracts')

    const jurisdiction = data?.technology_request?.corporate_profile?.country_jurisdiction;
    // console.log("check tech req value",data?.technology_request?.corporate_profile);
    const business_use_case = data?.business_use_case;
    const description = data?.business_use_case?.description_project;
    const pricing = data?.proposal?.price;
    const currency = data?.proposal?.currency;

    const tech_request_name = data?.technology_request?.name;
    const scope = data?.scope;
    const kpis = data?.scope?.kpis;
    const timingFrom = data?.scope?.timing_from;
    const timingTo = data?.scope?.timing_to;
    const proposal = data?.proposal;
    const agreement = data?.agreement;
    const uploadedContracts = data?.agreement?.uploaded_contracts;

    const startupName = data?.technology_solution?.startup_profile?.name;
    const corporateName = data?.technology_request?.corporate_profile?.name;
    // console.log("index data",data);

    useEffect(() => {
      // set the form values only if data is avaiable
      if (data && isSuccess) {
        reset({
          business_use_case: {
            description_project: data.business_use_case?.description_project ?? '',
            business_challenge: data.business_use_case?.business_challenge ?? '',
            how_to_resolve: data.business_use_case?.how_to_resolve ?? '',
            customer: data.business_use_case?.customer ?? '',
            successful_outcome: data.business_use_case?.successful_outcome ?? '',
            other_solutions: data.business_use_case?.other_solutions ?? '',
            goals: (data.business_use_case?.goals?? []).map(item => item?.id?? '') ?? [],
            risks: data.business_use_case?.risks ?? ''
          },
          scope: {
            team_startup: (data.scope?.team_startup ?? []) as Array<{
              role: string;
              name: string;
              email: string;
              profile_picture: FileItem;
            }>,
            team_corporate: (data.scope?.team_corporate?? []) as Array<{
              role: string;
              name: string;
              email: string;
              profile_picture: FileItem;
            }>,
            timing_from: data.scope?.timing_from ?? new Date(),
            timing_to: data.scope?.timing_to ?? new Date(),
            budget_from: data.scope?.budget_from ?? 0,
            budget_to: data.scope?.budget_to ?? 0,
            budget_currency: data.scope?.budget_currency?? '',
            kpis: data.scope?.kpis ?? [],
            technical_requirements_corporate: (data.scope?.technical_requirements_corporate) as {
              requirement: string;
              attachment: Array<FileItem>;
            },
            technical_requirements_startup: (data.scope?.technical_requirements_startup) as {
              requirement: string;
              attachment: Array<FileItem>;
            },
            legal_requirements_corporate: (data.scope?.legal_requirements_corporate) as {
              requirement: string;
              attachment: Array<FileItem>;
            },
            legal_requirements_startup: (data.scope?.legal_requirements_startup) as {
              requirement: string;
              attachment: Array<FileItem>;
            },
            other_requirements_corporate: (data.scope?.other_requirements_corporate) as {
              requirement: string;
              attachment: Array<FileItem>;
            },
            other_requirements_startup: (data.scope?.other_requirements_startup) as {
              requirement: string;
              attachment: Array<FileItem>;
            }
          },
          proposal: {
            proposal: (data.proposal?.proposal ?? []) as Array<FileItem>,
            deliverables: data.proposal?.deliverables?? '',
            price: data.proposal?.price?? 0,
            currency: data.proposal?.currency?? ''
          },
          agreement: {
            client_name: data.agreement?.client_name?? '',
            client_company: data.agreement?.client_company?? '',
            client_address: data.agreement?.client_address?? '',
            client_agree: data.agreement?.client_agree?? false,
            supplier_name: data.agreement?.supplier_name?? '',
            supplier_company: data.agreement?.supplier_company?? '',
            supplier_address: data.agreement?.supplier_address?? '',
            supplier_agree: data.agreement?.supplier_agree?? false,
            uploaded_contracts: (data.agreement?.uploaded_contracts ?? []) as Array<FileItem>,
          }
        })

        let name = ''
        let logo = ''

        if (view === 'startup') {
          name = data?.technology_request?.corporate_profile?.name ?? ''
          logo = data?.technology_request?.corporate_profile?.logo?.url ?? ''
        } else
        if (view === 'corporate') {
          name = data?.technology_solution?.startup_profile?.name ?? ''
          logo = data?.technology_solution?.startup_profile?.logo?.url ?? ''
        }
        
        setName(name)
        setLogo(logo)
        
      }
    }, [data]);

    const fileItemsToIdList = (files:FileItem[]): string[] => files.map(f => f.id);

    useEffect(() => {
      const update = async (convoUpdate: Partial<EditComponentConversationFormFormScopeInput>) => {
        await updateConversation({
          id: conversationID,
          conversation: {
            scope: {
              ...convoUpdate
            }
          }
        })
      };

      if (_.isEmpty(prevConversationRef?.current)) {
        return;
      }

      if (view !== null && view !== undefined) {
        const indexes = attachmentMap[view] as number[];
        
        if (!watchScopeAttachments) return;

        for(const i of indexes) {
          if (watchScopeAttachments?.[i]?.length !== prevConversationRef?.current?.[i]?.length) {
            let updateScope: Partial<EditComponentConversationFormFormScopeInput> = {};
            switch(i) {
              case 0:
                updateScope = {
                  technical_requirements_corporate: {
                    attachment: fileItemsToIdList(watchScopeAttachments[i])
                  }
                }
                break;
              case 1:
                updateScope = {
                  legal_requirements_corporate: {
                    attachment: fileItemsToIdList(watchScopeAttachments[i])
                  }
                }
                break;
              case 2:
                updateScope = {
                  other_requirements_corporate: {
                    attachment: fileItemsToIdList(watchScopeAttachments[i])
                  }
                }
                break;
              case 3:
                updateScope = {
                  technical_requirements_startup: {
                    attachment: fileItemsToIdList(watchScopeAttachments[i])
                  }
                }
                break;
              case 4:
                updateScope = {
                  legal_requirements_startup: {
                    attachment: fileItemsToIdList(watchScopeAttachments[i])
                  }
                }
                break;
              case 5:
                updateScope = {
                  other_requirements_startup: {
                    attachment: fileItemsToIdList(watchScopeAttachments[i])
                  }
                }
                break;
              default:
                updateScope = {}
            }
            
            update(updateScope)
          }
        }
      }

      prevConversationRef.current = watchScopeAttachments
    }, [watchScopeAttachments])

    const handleViewChange = (evt:React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setView(checked ? 'startup':'corporate')
    }

    return (
      <Tile>
        <div className="d-flex" id="conversation-detail">
          <Outline 
            conversationId={conversationID} 
            userRole={user?.role?.type ?? ''} 
            data={data} view={view || ""} logo={logo || ""} />

          <Grid item xs style={{paddingBottom: '10px'}}>
            {user?.role?.type === 'catalyst' ?
            <Grid component="label" container justifyContent="flex-end" alignItems="center" spacing={1}>
              <Grid item>Corporate</Grid>
              <Grid item>
                <Switch
                  checked={view === 'startup'} // relevant state for your case
                  onChange={handleViewChange} // relevant method to handle your change
                  value="checked" // some value you need
                />
              </Grid>
              <Grid item>Start Up</Grid>
            </Grid> : null}
            <Header
              tech_request_name={tech_request_name || ""}
              view={view || ""}
              setVValue={setVValue}
              setStateKPIs={setStateKPIs}
              handleSubmit={handleSubmit}
              watch={watch}
              data={data}
              business_use_case={business_use_case}
              scope={scope}
              proposal={proposal}
              agreement={agreement}
              value={value}
              kpis={kpis}
              isDisabled={!isSuccess}
            />

            <form>
              <TabPanel value={value} index={0}>
                {data && isSuccess ? (
                  <BusinessUseCase
                    view={view || ""}
                    business_use_case={business_use_case}
                    register={register}
                    control={control}
                    
                  />
                ) : (
                  ""
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {data && isSuccess ? (
                  <Scope
                    view={view || ""}
                    name={name || ""}
                    startupName={startupName || ""}
                    corporateName={corporateName || ""}
                    scope={scope}
                    conversationId={conversationID}
                    stateKPIs={stateKPIs}
                    setStateKPIs={setStateKPIs}
                    register={register}
                    setFields={setValue}              
                    watch={watch}
                    control={control}
                    errors={errors}
                  />
                ) : (
                  ""
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {data && isSuccess ? (
                  <Proposal
                    view={view || ""}
                    name={name || ""}
                    conversationId={conversationID}
                    proposal={proposal}
                    setFields={setValue}
                    register={register}
                    control={control}
                    watch={watch}
                  />
                ) : (
                  ""
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {data && isSuccess ? (
                  <Agreement
                    conversationId={conversationID}
                    view={view || ""}
                    description={description || ""}
                    proposal={proposal}
                    scope={scope}
                    countryJurisdiction={jurisdiction}
                    business_use_case={business_use_case}
                    kpis={kpis}
                    watch={watch}
                    agreement={agreement}
                    getVals={getValues}
                    register={register}
                    control={control}
                  />
                ) : (
                  ""
                )}
              </TabPanel>
            </form>
            {data && isSuccess ?
            <Navigation
              view={view || ""}
              data={data}
              business_use_case={business_use_case}
              scope={scope}
              proposal={proposal}
              agreement={agreement}
              value={value}
              watch={watch}
              setVValue={setVValue}
              handleSubmit={handleSubmit}
              uploadedContracts={uploadedContracts}
              stateKPIs={stateKPIs}
              isDisabled={!isSuccess}
            />: <></>}
          </Grid>
        </div>
      </Tile>
    );
  }

export default Conversation;
