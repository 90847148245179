import AlertIcon from 'app/shared/header/alerts/assets/svgs/bell.svg';
import './assets/styles/alerts.scss';
import { useGetCurrentUserUnreadMessagesQuery } from 'services/graphql/notifications'
import { DropdownNotifications } from './dropdown-messages';

const Alerts = () => {
  const  { data } = useGetCurrentUserUnreadMessagesQuery();
  const hasMessages = (data?.total ?? 0) > 0
  return (
    <DropdownNotifications messages={data?.result?? []}>
      <div className="alerts-container">
        <div className="alerts">
          <img src={AlertIcon} alt="alerts"/>
          {hasMessages ? <div className="counter"><span>{data?.total ?? 0}</span></div> : null}
        </div>
      </div>
    </DropdownNotifications>
  )
}

export default Alerts;