import Tile from "app/shared/tile"
import { useGetCurrentUserBookmarksContentQuery } from 'services/graphql/bookmarks'
import MatchedTechnologyRequestCard from 'app/shared/matched-technology-request-card';
import MatchedTechnologySolutionCard from 'app/shared/matched-technology-solution-card';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import { useSelector } from 'react-redux';
import { Enum_Bookmark_Category, Enum_Bookmark_Type, BookmarkContent, Bookmark } from "generated/graphql";
import {
  useHistory
} from 'react-router-dom';
import { valueOrNull } from 'shared/utilities'
import './assets/styles/favourites.scss'

const Favourites = () => {
  const { push } = useHistory()
  const headerProfile = useSelector(selectHeaderProfile)
  const { data, isLoading, isSuccess } = useGetCurrentUserBookmarksContentQuery()

  const getCard = (item: BookmarkContent | null, isReject: boolean = false) => {
    if (item?.type === Enum_Bookmark_Type.TechnologyRequest) {
      let content = item?.technologyRequest;
      
      let techRequest = {
        id: item?.id || '',
        technology_request_id: content?.id?? '',
        hero_image_url: content?.hero_image_url || '',
        name: content?.name || '',
        business_challange_description: content?.business_challange_description || '',
        country_jurisdiction: content?.corporateProfile?.country_jurisdiction || '',
        employee_count_range: content?.corporateProfile?.employee_count_range || '',
        match_rating: item?.type === Enum_Bookmark_Type.TechnologyRequest ? valueOrNull(item?.technologyRequestMatch?.match_rating) : 0
      }
  
      return <MatchedTechnologyRequestCard 
        item={techRequest}
        bookmark={item as Bookmark}
        bookmerkMode={isReject ? Enum_Bookmark_Category.Reject: Enum_Bookmark_Category.Save}
        profileId={String(headerProfile.id)} 
        onView={() => {
          push(`/app/startup/explore/${item?.item_id}`)
        }} />
    } else 
    if (item?.type === Enum_Bookmark_Type.TechnologySolution) {
      let content = item?.technologySolution
      
      let bookmark = {
        id: item?.id || '',
        technology_solution_id: content?.id?? '',
        hero_image_url: content?.hero_image_url || '',
        name: content?.name || '',
        description: content?.description || '',
        country: content?.startupProfile?.country || '',
        maturity_level: content?.startupProfile?.maturity_level || '',
        match_rating: item?.type === Enum_Bookmark_Type.TechnologySolution ? valueOrNull(item?.technologySolutionMatch?.match_rating) : 0
      }
  
      return <MatchedTechnologySolutionCard 
        item={bookmark} 
        bookmarkId={item?.id || ''}
        isBookmark={true}
        bookmerkMode={isReject ? Enum_Bookmark_Category.Reject: Enum_Bookmark_Category.Save}
        profileId={String(headerProfile.id)} 
        onView={() => {
          push(`/app/corporate/explore/${item?.item_id}`)
        }} />
    } else 
      return <></>
  }

  const favouriteCards = isSuccess && data?.bookmarks?.filter(t => t?.category === Enum_Bookmark_Category.Save)
    .map(item => getCard(item))

  const rejectCards = isSuccess && data?.bookmarks?.filter(t => t?.category === Enum_Bookmark_Category.Reject)
    .map(item => getCard(item, true))

  return (
    <Tile>
      <div className="favourites">
        <h4 className="mb-4">Favourites</h4>
        <div className="flex-row flex-wrap d-flex match-tech-requests-inner">
          {isSuccess && favouriteCards}
        </div>

        <h4 className="mt-4 mb-4">Rejected</h4>
        <div className="flex-row flex-wrap d-flex match-tech-requests-inner">
          {isSuccess && rejectCards}
        </div>
      </div>
    </Tile>
  )
}

export default Favourites