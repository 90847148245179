import { MessageWithUser } from 'generated/graphql';
import React, 
  { FC, 
    useEffect, 
    useRef, 
    useState, 
    useCallback
  } from 'react';
import './assets/styles/dropdown-messages.scss';
import SlashBellIcon from 'app/shared/assets/svgs/slash-bell.svg';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { useSetMessagesReadMutation } from 'services/graphql/notifications';
import IconAlkelioLogoOnly from '../../../assets/svgs/alkelio-logo-only.svg';
import { debounce, every } from 'lodash'

dayjs.extend(relativeTime)

interface DropdownNotificationsProps {
  messages: Array<MessageWithUser>;
  children? : React.ReactNode;
}

export const DropdownNotifications: FC<DropdownNotificationsProps> = ({
  messages,
  children
}) => {
  const elemMenu = useRef<HTMLDivElement>(null)
  const [ active, setActive ] = useState<boolean>(false)
  const [ markRead ] = useSetMessagesReadMutation()

  const handleClickClearAll = async () => {
    if (messages.length > 0) {
      await markRead(messages.map(m => String(m.id)))
    }
  }

  const toggle = debounce(() => {
    setActive(!active)
  }, 200)

  const memoizedClose = useCallback((e) => {
    setActive(false)
  }, [active])

  const memoizedToggle = useCallback((e) => {
    toggle();
    e.stopPropagation()
  }, [active])

  const setupClickListeners = () => {
    if (elemMenu.current) {
      elemMenu.current.removeEventListener('click', (e) => {
        e.stopPropagation()
      })
      elemMenu.current.addEventListener('click', (e) => {
        console.log('menu click')
        e.stopPropagation()
      })
      
    }
    document.addEventListener('click', memoizedClose)
  }

  useEffect(() => {
    if (window.screen.width < 768) {
      setupClickListeners()
    }
  }, [])

  const dropdownItems = messages.map(({ content, createdOn, from }) => (
    <div className="dropdown-item">
      <div className="inner">
        <div className="content">
          <div className="from-user">
            {from ?
            <img src={`${from?.profileImage}`} alt={`${from?.name}`} />
            :<img className="user-platform" src={IconAlkelioLogoOnly} alt="ALKELIO" />
            }
          </div>
          <div className="body">
            <div className="body-text" dangerouslySetInnerHTML={{__html: content}}></div>
            <div className="mt-1 txt-light font-size-12">{dayjs(createdOn).fromNow()}</div>
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <div className={`dropdown-messages ${active ? 'active':''}`}>
      <div className="dropdown-toggle" onClick={memoizedToggle}>
        {children}
      </div>
      <div className="dropdown-menu">
        <div className="dropdown-header">
          <div className="inner">
            <h4>Notifications</h4>
            <div className="cursor-pointer" onClick={(evt) => {
              handleClickClearAll()
              evt.stopPropagation();
            }}>
              <img src={SlashBellIcon} alt="clear all notifications" className="me-2" />
              <span className="bold-type font-size-14 letter-spacing-5 color-azure">CLEAR ALL</span>
            </div>
          </div>
        </div>
        <div className="dropdown-items-container" ref={elemMenu}>
          {dropdownItems}
          {messages.length === 0 ? 
          <div className="dropdown-item">
            <div className="inner">
              You're all caught up!
            </div>
          </div>
          : null}
        </div>
      </div>
    </div>
  );
}
