import './assets/styles/date-quarter-select.scss';
import React, { useState, useEffect } from 'react';
import SelectOne from 'shared/forms/select-one';
import { numberList } from 'shared/utilities';
import BaseInput from 'shared/forms/base-input';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

interface DateQuarterSelectProps extends BaseInput {
  value: string | Date | null;
  onChange?: (value: string |Date) => unknown;
  yearLabel?: string;
  quarterLabel?: string;
  startFromNow?: boolean
}

const quartersBase = [
  {
    label: "Q1",
    value: "0"
  },
  {
    label: "Q2",
    value: "3"
  },
  {
    label: "Q3",
    value: "6"
  },
  {
    label: "Q4",
    value: "9"
  }
];

const isValidDate = (d: Date) => !isNaN(d.getTime())

const getQuarter = (d: Date): number => {
  const month = d.getUTCMonth()
  
  if (month < 3) {
    return 0
  } else
  if (month < 6) {
    return 1
  } else
  if (month < 9) {
    return 2
  } else
    return 3
}

const getQuarterFromValue = (v:Date | null): number | null => {
  if (v!== null && isValidDate(v)) {
    const month = v.getUTCMonth()
    
    if (isNaN(month)) {
      return null;
    }
    const quarterIndex = getQuarter(v)
    return parseInt(quartersBase[quarterIndex].value);
    
  } else {
    return null
  }
}

const DateQuarterSelect = React.forwardRef(({
  value,
  onChange,
  yearLabel = '',
  quarterLabel = '',
  startFromNow = true
}: DateQuarterSelectProps,
ref: React.Ref<HTMLDivElement>) => {

  const dateValue = new Date(value ?? '')

  const [year, setYear] = useState<number | null>(isValidDate(dateValue) ? dateValue.getFullYear() : null)
  const [month, setMonth] = useState<number | null>(getQuarterFromValue(dateValue))

  const years = numberList((new Date()).getUTCFullYear(),  ((new Date()).getUTCFullYear()) + 5)

  const quarterIndex = (year === new Date().getFullYear()) ? getQuarter(new Date()) : 0
  
  const quarters = startFromNow ? quartersBase.slice(quarterIndex, 4) : quartersBase
  
  const tryParseDateChange = () => {
    if (year !== null && month !== null) {
      const date = dayjs().year(year as number).month(month as number).date(1).toDate();
      if (isValidDate(date)) {
        const d = dayjs(date).format('YYYY-MM-DD')
        onChange && onChange(d) 
      }
    }
  }

  useEffect(() => {
    tryParseDateChange();
  }, [year, month])

  useEffect(() => {
    if (year === new Date().getFullYear()) {
      setMonth(null)
    }
  }, [year])


  return (
    <div className="flex-row date-quarter-select d-flex align-items-center" ref={ref}>
      <div className="row w-100">
        <div className="col ps-0">
          <label>
            <div className="mb-2 text">{yearLabel}</div>
          </label>
          <SelectOne 
            className="me-3" 
            options={years}
            defaultValue={year?.toString()} 
            onChange={(selectedYear: string) => {
              setYear(parseInt(selectedYear))
            }} 
          />
        </div>
        <div className="col ps-0 mt-3 mt-md-0">
          <label>
            <div className="mb-2 text">{quarterLabel}</div>
          </label>
          <SelectOne 
            options={quarters} 
            defaultValue={getQuarterFromValue(dateValue)?.toString()} 
            onChange={(selectedQuarter) => {
              setMonth(parseInt(selectedQuarter))
            }}
          />
        </div>
      </div>
    </div>
  )
})

export default DateQuarterSelect;