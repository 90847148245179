import Trash from 'app/shared/assets/svgs/trash.svg';
import { IconProps, IconNames } from './types';
import React, { FC } from 'react';

export const Icon: FC<IconProps> = ({
  name,
  colour
}): JSX.Element => {
  let iconSrc = null;
  switch (name) {
    case IconNames.Trash:
      iconSrc = Trash;
      break;
  }
  return (
    <img src={iconSrc} alt=""/>
  );
}