import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  Conversations,
  ConversationAgreementInput,
  ConversationMessage,
  EditConversationInput,
  ConversationMessageInput, 
  GoalInput, 
  Goals, 
  StartupProfile,
  MyProfile,
  ComponentConversationFormFormAgreement,
  ConversationSummary,
  Corporate
} from 'generated/graphql'

type CollabSummary = {
  title: string
  id: string
  imageUrl: string
}

export enum ConversationType {
  Conversation = 'conversation',
  Collaboration = 'collaboration'
}

type GetMyConversationPayload = {
  type: ConversationType,
  collaboration?: CollabSummary,
  conversation?: ConversationSummary
}

interface UpdateConversationResponse {
  conversation: Conversations;
}
interface GenerateConversationResponse {
  makeConversationAgreementPdf: any;
}
interface CreateMessageResponse {
  conversationMessage: ConversationMessage;
}
interface CreateMessages{
  conversation_messages: ConversationMessageInput
}

interface CreateGoalResponse {
  id: string;
  Name: string;
  created_at: Date;
  updated_at: Date;
}

interface GenerateContract {
  id:Number
}
interface UpdateConversation {
  id: string;
  conversation: EditConversationInput
}

interface CreateGoal {
  goal: GoalInput;
}

export interface UpdateConversationAgreementInput {
  id: string;
  agreement: ConversationAgreementInput;
}

export const conversationApi = createApi({
  reducerPath: "conversationApi",
  tagTypes: ['conversation', 'chat', 'my-conversations'],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getConversation: builder.query<Conversations, string>({
      query: (id) => ({
        document: gql`
        query getConversation($id: ID!) {
          getConversation(id: $id) {
            id
            nda_signed_startup
            business_use_case {
              description_project
              business_challenge
              how_to_resolve
              customer
              successful_outcome
              other_solutions
              goals {
                id
                Name
              }
              risks
            }
            scope {
              team_startup {
                id
                role
                name
                email
                profile_picture {
                  url,
                  id
                }
              }
              team_corporate {
                id
                role
                name
                email
                profile_picture {
                  url,
                  id
                }
              }
              timing_from
              timing_to
              budget_from
              budget_to
              budget_currency
              kpis
              technical_requirements_startup {
                requirement
                attachment {
                  id,
                  url,
                  name
                }
              }
              technical_requirements_corporate {
                requirement
                attachment {
                  id,
                  url,
                  name
                }
              }
              legal_requirements_corporate {
                requirement
                attachment {
                  id,
                  url,
                  name
                }
              }
              legal_requirements_startup {
                requirement
                attachment {
                  id,
                  url,
                  name
                }
              }
              other_requirements_corporate {
                requirement
                attachment {
                  id,
                  url,
                  name
                }
              }
              other_requirements_startup {
                requirement
                attachment {
                  id,
                  url,
                  name
                }
              }
            }
            proposal {
              proposal {
                url,
                name,
                id
              }
              deliverables
              price
              deliverables
              price
              currency
            }
            agreement {
              client_name
              client_company
              client_address
              client_agree
              supplier_name
              supplier_company
              supplier_address
              supplier_agree
              uploaded_contracts {
                id,
                url,
                name
              }
            }
            technology_request {
              corporate_profile {
                name
                logo {
                  url
                }
                country_jurisdiction,
                person_profiles {
                  first_name
                  last_name
                  role
                  user {
                    email
                  }
                  photo {
                    url
                  }
                }
              }
            }
            technology_solution {
              startup_profile {
                name
                logo {
                  url
                }
                person_profiles {
                  first_name
                  last_name
                  role
                  user {
                    email
                  }
                  photo {
                    url
                  }
                }
              }
            }
          }
        }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => response.getConversation,
      providesTags: (result, error, id) => [{
        type: 'conversation',
        id,
      }]
    }),
    makeConversationAgreementPdf: builder.mutation<GenerateConversationResponse, GenerateContract>({
      query: ({id})=>({
        document: gql`
        mutation makeConversationAgreementPdf($id: Int!){
          makeConversationAgreementPdf(
            id:$id
            ){
              fileId,
              url,
              name,
              mime
            }
        }
        `,
        variables:{
          id
        }
      })
    }),
    updateConversationOld: builder.mutation<UpdateConversationResponse, UpdateConversation>({
      query: ({ id, conversation }) => ({
        document: gql`
        mutation updateConversation($id: ID!, $conversation: editConversationInput) {
          updateConversation({
            where: {
              id: $id,
            },
            data: $conversation
          }) {
            conversation {
              id
            }
          }
        }
        `,
        variables: {
          id,
          conversation
        }
      }),
      invalidatesTags: (result, error, input) => [{
        type: 'conversation',
        id: input.id,
      }]
    }),
    updateConversation: builder.mutation<UpdateConversationResponse, UpdateConversation>({
      query: ({ id, conversation }) => ({
        document: gql`
        mutation updateConversationByRole($id: ID!, $conversation: editConversationInput!) {
          updateConversationByRole(
            id: $id,
            input: $conversation
          ) {
            conversation {
              id
            }
          }
        }
        `,
        variables: {
          id,
          conversation
        }
      }),
      invalidatesTags: (result, error, input) => [{
        type: 'conversation',
        id: input.id,
      }, {
        type: 'my-conversations'
      }]
    }),
    getConversationMessages: builder.query<Conversations, string>({
      query: (id) => ({
        document: gql`
          query getConversation($id: ID!) {
            conversation(id: $id) {
              id
              conversation_messages(sort:"sent_datetime:desc",start: 0, limit: 100) {
                id
                body
                sent_datetime
                by_user{
                  username
                  id,
                  person_profile{
                    first_name,
                    photo{
                      url
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => response.conversation,
      providesTags: (result, error, arg) => [{ type: 'chat', id: arg}, 'chat']
    }),
    createConversationMessage: builder.mutation<CreateMessageResponse,CreateMessages>({
      query: ({ conversation_messages }) => ({
        document: gql`
          mutation createConversationMessage( $conversation_messages: ConversationMessageInput) {
            createConversationMessage(
              input: {
                data: $conversation_messages
              }
            ) {
              conversationMessage{
                id
                body
                by_user{
                  username,
                  id,
                },
                sent_datetime
              }
            }
          }
        `,
        variables: {
          conversation_messages
        },
      }),
      invalidatesTags: (result, error, input) => [{ 
        type: 'chat', 
        id: input.conversation_messages?.conversation?? ''
      }]
    }),
    getConversationProfile: builder.query<MyProfile, void>({
      query: () => ({
        document: gql`
          query getConversationProfile {
            myProfile {
              person {
                id,
                user{
                  username,
                  id
                }
              }
            }
          }
        `,
      }),
    }),
    getConversationStartupProfile: builder.query<StartupProfile, string>({
      query: (id) => ({
        document: gql`
          query getConversationStartup ($id: ID!) {
            conversation(id: $id) {
              technology_solution {
                startup_profile {
                  name
                  logo {
                    url
                  }
                  operating_locations
                  industries {
                    name
                  }
                  country
                  start_date
                  went_incubator_accelerator
                  employee_count
                  maturity_level {
                    name
                  }
                  clients
                  client_count
                  incubators_accelerators
                  investment_received
                  investment_received_currency
                  worked_with_corporates
                  person_profiles {
                    first_name
                    last_name
                    role
                    contact_number
                    user {
                      email
                    }
                    photo {
                      url
                    }
                  }
                  links {
                    website
                    linkedin
                    twitter
                    facebook
                    instagram
                  }
                }
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => ({
        ...response.conversation.technology_solution.startup_profile
        
      })
    }),
    getConversationCorporateProfile: builder.query<Corporate, string>({
      query: (id) => ({
        document: gql`
          query getConversationCorporate ($id: ID!) {
            conversation(id: $id) {
              technology_request {
                corporate_profile {
                  name
                  logo {
                    url
                  }
                  country
                  country_jurisdiction
                  employee_count_range
                  worked_with_startups
                  industries {
                    name
                  }
                  links {
                    website
                  }
                  person_profiles {
                    first_name
                    last_name
                    role
                    is_decisionmaker
                    corporate_department {
                      name
                    }
                    contact_number
                    user {
                      email
                    }
                    photo {
                      url
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => ({
        ...response.conversation.technology_request.corporate_profile
      })
    }),
    updateConversationAgreement: builder.mutation<ComponentConversationFormFormAgreement, UpdateConversationAgreementInput>({
      query: ({ id, agreement }) => ({
        document: gql`
          mutation updateAgreement(
            $id: ID!, 
            $agreement: ConversationAgreementInput!
          ) {
            updateAgreement(
              id: $id,
              agreement: $agreement
            ) {
              client_name
              client_address
              client_company
              client_agree
              supplier_name
              supplier_address
              supplier_company
              supplier_agree
              uploaded_contracts {
                id,
                url,
                name
              }
            }
          }
        `,
        variables: {
          id,
          agreement
        }
      }),
      invalidatesTags: (result, error, input) => [{
        type: 'conversation',
        id: input.id,
      }]
    }),
    getMyConversations: builder.query<Array<GetMyConversationPayload>, void>({
      query: () => ({
        document: gql`
          query {
            myConversations {
              id
              name
              image
              logo
              tech_request_name
              conversation_stages_complete {
                scope
                business_use_case
                proposal
                agreement
              }
              nda_signed
              nda_signed_by_both
              nda_corporate_upload {
                id
                name
                url
              }
            }
            getMyServiceCollaborations {
              id
              service {
                title
              }
              client {
                role {
                  type
                }
                username
                person_profile {
                  last_name
                  first_name
                  role
                  photo {
                    url
                  }
                }
              }
            }
          }
        `
      }),
      transformResponse: (response: any) => {
        const conversations: Array<GetMyConversationPayload> = []
        for (const conversation of response.myConversations) {
          conversations.push({
            type: ConversationType.Conversation,
            conversation: {
              ...conversation
            }
          })
        }
        for (const collab of response.getMyServiceCollaborations) {
          const { id, service, client } = collab
          conversations.push({
            type: ConversationType.Collaboration,
            collaboration: {
              id,
              title: service?.title,
              imageUrl: client?.person_profile?.photo?.url
            }
          })
        }
        return conversations
      },
      providesTags: ["my-conversations"]
    }),
    agreeConverstationNDA: builder.mutation<number, void>({
      query: (id) => ({
        document: gql`
          mutation agreeConversationNDA($id: ID!) {
            agreeConversationNDA(id: $id)
          }

        `,
        variables: {
          id
        }
      }),
      invalidatesTags: ['my-conversations']
    })
  })
})

export const {
  useGetConversationQuery,
  useGetConversationMessagesQuery,
  useLazyGetConversationMessagesQuery,
  useCreateConversationMessageMutation,
  useUpdateConversationMutation,
  useMakeConversationAgreementPdfMutation,
  useGetConversationProfileQuery,
  useGetConversationStartupProfileQuery,
  useGetConversationCorporateProfileQuery,
  useUpdateConversationAgreementMutation,
  useGetMyConversationsQuery,
  useAgreeConverstationNDAMutation
} = conversationApi
