import './assets/styles/options-buttons-tick.scss';
import TickWhiteIcon from './assets/svgs/tick-white.svg';
import { useEffect, useState } from 'react';

type Option = {
  label: string,
  value: string;
}

type Props = {
  options: Option[],
  onSelect: (options: string[]) => unknown;
  selectedOptions?: string[],
  addClassName?: string
}

const OptionButtonsTick = ({ 
  options,
  onSelect,
  selectedOptions = [],
  addClassName
}: Props) => {
  const [selected, setSelected] = useState<string[]>([...selectedOptions]);

  useEffect(() => {
    setSelected([])
  }, [options])
  
  return (
    <div className={`options-buttons-tick ${addClassName ?? ''}`}>
      {options.map((option, i) => 
        <label key={`${option.value}`}>
          <input type="checkbox" value={option.value}
            checked={(selectedOptions.findIndex((o) => o === option.value) >= 0)}
            onChange={(evt) => {
              let list = [...selected];
              
              if (evt.target.checked) {
                list.push(option.value);  
              } else {
                list = [...selected].filter((item) => item !== option.value);
              }
              setSelected(list);
              onSelect(list);
            }} />
          <div className="button">
            <div className="tick-icon d-flex">
              <img src={TickWhiteIcon}/>
            </div>
            <div className="text-center semi-bold-type font-size-14 font-size-md-12 color-nightsky"
              dangerouslySetInnerHTML={{__html:option.label }}></div>
          </div>
        </label>
      )}
    </div>
  );
}

export default OptionButtonsTick;