import React from 'react'
import PopCrackerIcon from './assets/svgs/pop-cracker.svg'
import './assets/styles/complete.scss'
import Tile from 'app/shared/tile'
import { useHistory, useParams } from 'react-router-dom'
import Button from 'app/shared/button'
import LongArrowRightWhite from 'app/shared/assets/svgs/long-arrow-right-white.svg';

const Complete = () => {
  const { push } = useHistory();
  const { entityType }: any = useParams();

  return (
    <Tile>
      <div className="service-collaboration-complete d-flex flex-column align-items-center justify-content-center">
        <img className="icon" src={PopCrackerIcon} alt="" />
        <div className="title">We are glad you enjoyed our collaboration</div>
        <div className="font-size-14 font-size-md-12 line-height-140 txt-regular letter-spacing-n-2 mb-3 text-center">
          Thank you for working with us !
        </div>
        <Button
          variant="primary" 
          className="wide"
          onClick={() => {
            push(`/app/${entityType}/dashboard`)
          }}>
          Go to my Dashboard
          <img src={LongArrowRightWhite} alt="next" className='ms-2' />
        </Button>
      </div>
    </Tile>
  )
}

export default Complete