import Tile from '../shared/tile'
import { Notifications } from './notifications'
import Button from 'app/shared/button'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { Subscription } from './subscription/subscription.component'
import { Inputs } from './inputs'
import { useGetSettingsQuery, useUpdateSettingsMutation } from 'services/graphql/settings'
import { useEffect } from 'react'
import { NotificationPreferences } from 'generated/graphql'
import './assets/styles/settings.scss'
import { useHistory } from 'react-router-dom'

const Settings = () => {
  const { goBack } = useHistory();
  const { data, isLoading, isSuccess } = useGetSettingsQuery()
  const [ updateSettings, updateSettingsResult ] = useUpdateSettingsMutation()

  const { 
    register, 
    handleSubmit, 
    watch, 
    control,
    getValues,
    setValue,
    reset,
  formState: { errors } } = useForm<Inputs>({
    mode: "onChange",
  });

  useEffect(() => {
    if (data) {
      const { notifications } = data
      reset({
        notifications: {
          team_notifications: notifications?.team_notifications?? null,
          platform_notifications: notifications?.platform_notifications?? null,
          potential_partner: notifications?.potential_partner?? null,
          partner_collaboration: notifications?.partner_collaboration?? null,
          chat: notifications?.chat?? null,
          activity: notifications?.activity?? null
        }
      })
    }
  }, [isSuccess])

  useEffect(() => {
    if (updateSettingsResult.isSuccess) {
      // redirect out
    }
  }, [updateSettingsResult])

  const handleSave : SubmitHandler<Inputs> = async (data: Inputs) => {
    await(await updateSettings({
      notifications: data.notifications
    }).unwrap())
    goBack();
  }

  return (
    <Tile
      className="settings-form"
      title="Settings"
    >
      <div className="row p-0">
        <div className="col-12 col-xl-6 p-0 pe-xl-2 mb-4 mb-md-0">
          <Subscription />
        </div>
        <div className="col-12 col-xl-6 p-0 ps-xl-2">
        {isSuccess && <Notifications {...{ control, register, errors, getValues, notifications: data?.notifications as NotificationPreferences } } />}
        </div>
      </div>
      <div className="row p-0 mb-0 mb-md-3">
        <div className="actions col p-0 d-flex flex-row justify-content-end">
          <Button type="button" name="save" 
            onClick={handleSubmit(handleSave)} variant="primary">Save</Button>
        </div>
      </div>
    </Tile>
  )
}

export default Settings;