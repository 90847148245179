import { useEffect, useState } from 'react';
import FormLayout from './form-layout';
import ActionBar from '../action-bar/action-bar';
import { useForm, Controller } from 'react-hook-form';
import './assets/styles/form-layout-form.scss';

import useOnboarding from '../hooks/onboarding.hook'
import useSteps from '../hooks/steps.hook'

import SelectOne from 'shared/forms/select-one';
import { toOptions, toValues } from 'shared/utilities';
import InputImage from 'shared/forms/input-image';
import InputRadioGroup from 'shared/forms/input-radio-group';
import { FileItem } from 'hooks/file-uploads';

import {
  CorporateDepartment,
  OnboardingSessionCorporate_PersonProfile,
  OnboardingSessionPayload_User,
  Enum_Personprofile_Profile_Type,
  ImageAttachment
} from 'generated/graphql';
import { CorpPersonProfileInputs } from '../types'
import {
  useGetCorporateDepartmentsQuery
} from 'services/graphql/tags';
import { EnumCorporatePersonProfile_DecisionType } from 'app-constants';

import { setEmail } from 'features/signup/signupSlice'

interface CorporateUserProfileValues {
  personProfile: OnboardingSessionCorporate_PersonProfile,
  user: OnboardingSessionPayload_User
}

const DepartmentsOptions = (props: any) => {
  const { data, isSuccess } = useGetCorporateDepartmentsQuery()
  return (
      <>
          {isSuccess && props.render(data)}
      </>);
}

const CorpPersonProfile = () => {
  const { onboardingPayload, setOnboardingPayload } = useOnboarding()
  const { currentStep } = useSteps()
  const [decisionType, setDecisionType] = useState(EnumCorporatePersonProfile_DecisionType.DecisionMaker);
  const {
      register,
      handleSubmit,
      watch,
      control,
      getValues,
      setValue,
      trigger,
      reset,
      formState: { errors } } = useForm<CorpPersonProfileInputs>({
          mode: "onChange",
      });

  const transformValues = (values: CorpPersonProfileInputs): CorporateUserProfileValues => {
    return {
      personProfile: {
        first_name: values?.first_name ?? '',
        last_name: values?.last_name ?? '',
        corporate_department: parseInt(values?.corporate_department ?? '0'),
        contact_number: values?.contact_number ?? '',
        photo: values?.photo as ImageAttachment,
        role: values?.role ?? '',
        is_decisionmaker: decisionType == EnumCorporatePersonProfile_DecisionType.DecisionMaker ?? false,
        is_decision_influencer: decisionType == EnumCorporatePersonProfile_DecisionType.Influencer ?? false,
        decision_role_type: values?.decision_role_type ?? '',
        profile_type: Enum_Personprofile_Profile_Type.Corporate
      } as OnboardingSessionCorporate_PersonProfile,
      user: {
        email: values?.email ?? ''
      } as OnboardingSessionPayload_User
    } as CorporateUserProfileValues;
  }
  
  useEffect(() => {
    const data = onboardingPayload.personProfile as OnboardingSessionCorporate_PersonProfile
    const user = onboardingPayload.user as OnboardingSessionPayload_User

    if (data && user) {
      reset({
        photo: data?.photo as FileItem,
        first_name: data?.first_name ?? '',
        last_name: data?.last_name ?? '',
        corporate_department: data?.corporate_department?.toString(),
        contact_number: data?.contact_number ?? '',
        role: data?.role ?? '',
        is_decisionmaker: data?.is_decisionmaker ?? false,
        is_decision_influencer: data?.is_decision_influencer ?? false,
        decision_role_type: data?.decision_role_type ?? '',
        email: user?.email ?? '',
      });
    }
  }, [onboardingPayload]);

  const handlePrev = async (prevStep: () => unknown) => {
    const me = transformValues(getValues());
    await setOnboardingPayload({
      ...me,
      step: currentStep - 1
    })
    setEmail(me?.user?.email ?? '')
    prevStep()
  }

  const handleNext = async (nextStep: () => unknown) => {
    const valid = await trigger();
    if (valid) {
      const me = transformValues(getValues());
      await setOnboardingPayload({
        ...me,
        step: currentStep + 1
      })
      setEmail(me?.user?.email ?? '')
      nextStep()
    }
  }
  return (
    <FormLayout
      subHeading="Corporate onboarding"
      heading="Me"
    >
      <form className="form-layout-form">
        <div className="row">
          <div className="col">
            <div className="form-field">
              <Controller
                name="photo"
                control={control}
                rules={{
                  required: false
                }}
                render={({ field }) => <InputImage
                  layout="elipse-left"
                  height="72px"
                  width="72px"
                  label="Upload Photo"
                  {...field}
                />}
              />
              <div className={`invalid-feedback ${errors.photo ? 'd-block' : ''}`}>Please upload your photo</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>First name *</label>
              <input type="text"
                {...register("first_name", { required: true })}
              />
              <div className={`invalid-feedback ${errors.first_name ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col">
            <div className="form-field">
              <label>Last name *</label>
              <input type="text"
                {...register("last_name", { required: true })}
              />
              <div className={`invalid-feedback ${errors.last_name ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label>Role *</label>
              <input type="text"{...register("role", { required: true })} />
              <div className={`invalid-feedback ${errors.role ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col">
            <div className="form-field">
              <label>Email *</label>
              <input type="email"
                {...register("email", { required: true })} />
              <div className={`invalid-feedback ${errors.email ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label>Department *</label>
              <DepartmentsOptions
                render={(departments: Array<CorporateDepartment>) => (
                  <Controller
                    name="corporate_department"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <SelectOne
                      variant="thicker"
                      options={toOptions(departments)}
                      {...field} />}
                  />
                )}
              />
              <div className={`invalid-feedback ${errors.corporate_department ? 'd-block' : ''}`}>This field is required</div>
            </div>
            
            <div className="form-field">
              <Controller
                name="decision_role_type"
                control={control}
                defaultValue={decisionType}
                render={({ field }) => <InputRadioGroup
                  className="d-flex flex-column"
                  options={[
                    {
                      label: "I am the decision maker",
                      value: EnumCorporatePersonProfile_DecisionType.DecisionMaker
                    },
                    {
                      label: "I am the decision influencer",
                      value: EnumCorporatePersonProfile_DecisionType.Influencer
                    },
                    {
                      label: "Other",
                      value: EnumCorporatePersonProfile_DecisionType.Other
                    }
                  ]}
                  {...field}
                  onChange={(value) => {
                    setDecisionType(value);
                  }}
                />}
            />

            <input type="text"
              placeholder="Other - please specify"
              {...register("decision_role_type", { required: decisionType === "decision-other" })}
              style={{
                display: decisionType === EnumCorporatePersonProfile_DecisionType.DecisionMaker ?
                  'none' :
                  decisionType === EnumCorporatePersonProfile_DecisionType.Influencer ? 'none' : ''
              }}
            />
            <div className={`invalid-feedback ${errors.decision_role_type ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label>Contact number</label>
              <input type="text"
                {...register("contact_number")}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ActionBar onPrev={handlePrev} onNext={handleNext} />
          </div>
        </div>
      </form>
    </FormLayout>
  )
}

export default CorpPersonProfile;