import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { DeductCreditPayload, DeductCreditStartConversationInput } from 'generated/graphql'

export const creditsApi = createApi({
  reducerPath: "creditsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    myCreditBalance: builder.query<number, void>({
      query: () => ({
        document: gql`
          query {
            myCreditBalance
          }
        `
      }),
      transformResponse: (response:any) => response.myCreditBalance
    }),
    deductCredit: builder.mutation<DeductCreditPayload, void>({
      query: (amount) => ({
        document: gql`
          mutation deductCredit {
            deductCredit()
          }
        `,
        variables: {
          amount
        }
      }),
      transformResponse: (response:any) => response.deductCredit
    }),
    deductCreditStartConversation: builder.mutation<DeductCreditPayload, DeductCreditStartConversationInput>({
      query: (data) => ({
        document: gql`
          mutation deductCreditStartConversation($input: DeductCreditStartConversationInput) {
            deductCreditStartConversation(input: $input) {
              balance
              success
              conversationId
            }
          }
        `,
        variables: {
          input: data
        }
      }),
      transformResponse: (response:any) => response.deductCreditStartConversation
    })
  })
})

export const {
  useDeductCreditMutation,
  useDeductCreditStartConversationMutation,
  useLazyMyCreditBalanceQuery,
  useMyCreditBalanceQuery
} = creditsApi