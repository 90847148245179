import './assets/styles/matched-technology-request-card.scss';
import Card from 'app/shared/card';
import Button from 'app/shared/button';
import ButtonFavourite from 'app/shared/button-favourite';
import { Bookmark, Enum_Bookmark_Category, Enum_Bookmark_Type } from 'generated/graphql';
import {
  useSetBookmarkMutation,
  useUnsetBookmarkMutation
} from 'services/graphql/bookmarks'
import MatchDial from 'app/shared/match-dial';
import MapPinIcon from 'app/shared/assets/svgs/map-pin.svg';
import UserIcon from 'app/shared/assets/svgs/user.svg';
import IconLabel from 'app/shared/icon-label';
import { Countries } from 'shared/countries';
import { isNotEmpty } from 'shared/utilities';
import { useEffect } from 'react';
import Pill from 'app/shared/pill'
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';

type TechnologyRequestSummary = {
  id: string;
  technology_request_id: string;
  hero_image_url: string;
  name: string;
  business_challange_description: string;
  country_jurisdiction: string;
  employee_count_range: string;
  match_rating: number | null;
}

type MatchedTechnologyRequestCardProps = {
  item: TechnologyRequestSummary;
  bookmark?: Bookmark;
  bookmerkMode?: Enum_Bookmark_Category | null;
  profileId: string;
  onView: (id:string) => unknown;
}

const MatchedTechnologyRequestCard = ({
  item,
  bookmark,
  profileId,
  bookmerkMode = Enum_Bookmark_Category.Save,
  onView
}: MatchedTechnologyRequestCardProps ) => {
  const [ setBookMark ] = useSetBookmarkMutation()
  const [ unsetBookmark ] = useUnsetBookmarkMutation()

  const imageOverlayFragment = <div className="match-tech-request-card-image-overlay">
    <div className="match-percent">
      {item.match_rating && <MatchDial percent={item.match_rating || 0} />}
    </div>
    <div className="favourite">
      <ButtonFavourite 
        mode={bookmark?.category ?? null}
        defaultValue={bookmark !== undefined} 
        onClick={() => {
          const bookmarked = bookmark !== undefined;
          const bookmarkedId = bookmark ? bookmark.id : null;
          if (bookmarked) {
            bookmarkedId && unsetBookmark(bookmarkedId)
          } else {
            setBookMark({
              type: Enum_Bookmark_Type.TechnologyRequest,
              item_id: Number(item.technology_request_id),
              category: Enum_Bookmark_Category.Save,
              person_profile: profileId
            })
          }
        }} />
    </div>
  </div>

  const cardBodyFrament = <div className="cursor-pointer" onClick={() => {
    onView(item.id)
  }}>
    <h5>{item.name}</h5>
    <p>{item.business_challange_description}</p>
  </div>

  const cardFooterFraement = <div className="tech-solution-match-result-card-footer">
    <div className="info">
      {isNotEmpty(item.country_jurisdiction) && <IconLabel icon={MapPinIcon}>{Countries.getName(item.country_jurisdiction || '', "en")}</IconLabel>}
      {isNotEmpty(item.employee_count_range) && <IconLabel icon={UserIcon}>{item.employee_count_range || ""}</IconLabel>}
    </div>
    <Button variant="primary" className="action" >View</Button>
  </div>

  return (
    <Card 
      className="match-tech-request-card"
      onClick={() => {
        onView(item.id)
      }}
      variant="vertical"
      cardHeight="360px"
      cardWidth="360px"
      imageFilter="darken"
      imageHeight="159px"
      imageSrc={item?.hero_image_url?? ''}
      imageOverlay={imageOverlayFragment}
      imageOverlayPosition="none"
      body={cardBodyFrament}
      footer={cardFooterFraement}
    />
  )
}

export default MatchedTechnologyRequestCard