import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  Corporate, 
  StartupProfile, 
  EditStartupProfileInput, 
  MyProfile,
  PersonProfile,
  UsersPermissionsMe, 
  EditCorporateInput, 
  EditPersonProfileInput 
} from 'generated/graphql'

export interface CorporateProfile {
  myProfile: MyProfile;
  me: UsersPermissionsMe;
  myCorporateProfile: Corporate;
}

interface UpdateCorporateProfile {
  corporateId: string;
  corporate: EditCorporateInput;
  personProfileId: string;
  personProfile: EditPersonProfileInput;
}

interface UpdateCorporateProfileResponse {
  corporate: Corporate;
  personProfile: PersonProfile;
}

export interface StartupProfileMerged {
  myProfile: MyProfile;
  me: UsersPermissionsMe;
  myStartupProfile: StartupProfile;
}
interface UpdateStartupProfile {
  startupId: string;
  startup: EditStartupProfileInput;
  personProfileId: string;
  personProfile: EditPersonProfileInput;
}

interface UpdateStartupProfileResponse {
  startup: StartupProfile;
  personProfile: PersonProfile;
}

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  tagTypes: ["profile", 'startupProfile', 'corporateProfile'],
  endpoints: (builder) => ({
    myCorporateProfile: builder.query<CorporateProfile, void>({
      query: () => ({
        document: gql`
          query {
            myProfile {
              person {
                id
                first_name
                last_name
                photo {
                  id
                  url
                }
                is_decisionmaker
                is_decision_influencer
                decision_role_type
                corporate_department {
                  id
                  name
                }
                contact_number
                role
              }
            }
            me {
              email
            }
            myCorporateProfile {
              id
              name
              logo {
                id
                url
              }
              country
              country_jurisdiction
              industries {
                id
                name
              }
              links {
                website
              }
              employee_count_range
              worked_with_startups
              esg_rating
            }
          }
        `
      }),
      providesTags: ["corporateProfile"]
    }),
    updateCorporateProfile: builder.mutation<UpdateCorporateProfileResponse, UpdateCorporateProfile>({
      query: ({corporateId, corporate, personProfileId, personProfile}) => ({
        document: gql`
          mutation updateProfile(
            $corporateId: ID!, 
            $corporate: editCorporateInput,
            $personProfileId: ID!,
            $personProfile: editPersonProfileInput) {
            updateCorporate(input: {
              where: { 
                id: $corporateId
              },
              data: $corporate
            }) {
              corporate {
                id
              }
            }
            updatePersonProfile(input: {
              where: {
                id: $personProfileId
              },
              data: $personProfile
            }) {
              personProfile {
                id
              }
            }
          }
        `,
        variables: {
          corporateId,
          corporate,
          personProfileId,
          personProfile
        }
      }),
      invalidatesTags: ["profile", 'corporateProfile']
    }),
    updatePassword: builder.mutation<void, string>({
      query: (password) => ({
        document: gql`
          mutation updatePassword($password: String!) {
            updatePassword(password: $password)
          }
        `,
        variables: {
          password
        }
      })
    }),
    myStartupProfile: builder.query<StartupProfileMerged, void>({
      query: () => ({
        document: gql`
          query getMyStartupProfile {
            myProfile {
              person {
                id
                first_name
                last_name
                photo {
                  id
                  url
                }
                contact_number
                role
              }
            }
            me {
              id
              email
            }
            myStartupProfile {
              id
              name
              logo {
                id
                url
              }
              operating_locations
              industries {
                id
                name
              }
              
              links {
                website
                linkedin
                twitter
                facebook
                instagram
              }
              clients
              client_count
              went_incubator_accelerator
              incubators_accelerators
              investment_received
              investment_received_currency
              employee_count
              start_date
              country
              maturity_level {
                id
                name
              }
              worked_with_corporates
              esg_rating
            }
          }
        `,
      }),
      providesTags: ["startupProfile"]
    }),
    updateStartupProfile: builder.mutation<UpdateStartupProfileResponse, UpdateStartupProfile>({
      query: ({startupId, startup, personProfile, personProfileId}) => ({
        document: gql`
          mutation updateStartupProfile(
            $startupId: ID!, 
            $startup: editStartupProfileInput,
            $personProfileId: ID!,
            $personProfile: editPersonProfileInput) {
            updateStartupProfile(input: {
              where: { 
                id: $startupId
              },
              data: $startup
            }) {
              startupProfile {
                id
                name
              }
            }
            updatePersonProfile(input: {
              where: {
                id: $personProfileId
              },
              data: $personProfile
            }) {
              personProfile {
                id
                first_name
              }
            }
          }
        `,
        variables: {
          startupId,
          startup,
          personProfile,
          personProfileId
        }
      }),
      invalidatesTags: ["profile", "startupProfile"]
    }),
    myHeaderProfile: builder.query<MyProfile, void>({
      query: () => ({
        document: gql`
          query {
            myProfile {
              person {
                id
                first_name
                last_name
                profile_type
                photo {
                  id
                  url
                  formats
                }
              }
              organisation_id
            }
          }
        `,
      }),
      transformResponse: (response: any) => response.myProfile,
      providesTags: ['profile']
    })
  }),
})

export const { 
  useMyCorporateProfileQuery,
  useUpdateCorporateProfileMutation,
  useUpdatePasswordMutation,
  useMyStartupProfileQuery,
  useUpdateStartupProfileMutation,
  useMyHeaderProfileQuery,
  useLazyMyHeaderProfileQuery 
} = profileApi