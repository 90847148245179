import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useGetMatchMakeResultQuery } from 'services/graphql/match-making-result'
import {
  Enum_Bookmark_Type,
  Enum_Bookmark_Category
} from 'generated/graphql'
import { 
  useSetBookmarkMutation, 
  useUnsetBookmarkMutation,
  useGetCurrentUserBookmarksQuery
} from 'services/graphql/bookmarks'
import './assets/styles/view-match.scss'
import Button from 'app/shared/button'
import IconLabel from 'app/shared/icon-label'
import MapPinGreenIcon from 'app/shared/assets/svgs/map-pin-green.svg';
import ThumbsUpIcon from 'app/shared/assets/svgs/thumbs-up-green.svg';
import RoundNStarIcon from 'app/shared/assets/svgs/rounded-n-star.svg';
import BoxesOddGreenIcon from 'app/shared/assets/svgs/boxes-odd-green.svg';
import PeopleGreenIcon from 'app/shared/assets/svgs/people-green.svg';
import BoxCascadeGreenIcon from 'app/shared/assets/svgs/box-cascade-green.svg';
import BoxTickGreenIcon from 'app/shared/assets/svgs/box-tick-green.svg';
import UnlockGreenIcon from 'app/shared/assets/svgs/unlock-green.svg';
import { FavouriteWidget } from 'app/shared/favourite-widget';
import Config from 'config';
import Pill from 'app/shared/pill'
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import Ratings from 'app/shared/ratings';
import { Countries } from 'shared/countries';
import SpannarGreenIcon from 'app/shared/assets/svgs/spannar-green.svg';
import CogGreenIcon from 'app/shared/assets/svgs/spannar-green.svg';
import CubeGreenIcon from 'app/shared/assets/svgs/3d-box-green.svg';
import PersonShirtIcon from 'app/shared/assets/svgs/person-shirt-green.svg';
import TrashGreenIcon from 'app/shared/assets/svgs/trash-green.svg'
import TextBubbleWhiteIcon from 'app/shared/assets/svgs/text-bubble-white.svg'
import StarGreenOutlineIcon from 'app/shared/assets/svgs/star-green-outline.svg'
import MatchDial from 'app/shared/match-dial';
import Expand from 'app/shared/expand';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import { useSelector } from 'react-redux';
import { Image } from 'shared/image';
import { ItemRating } from 'app/shared'
import { useCredit } from 'hooks/index'
import { Enum_ConversationWithCategory } from 'features/cart/cartSlice'
import { RejectWidget } from 'app/shared/reject-widget'
import { ESGRatingOptions } from 'app-constants'

dayjs.extend(quarterOfYear)

const TechnologySolutionMatchDetail = () => {
  const { id, entityType }: any = useParams()
  const { push } = useHistory()
  const headerProfile = useSelector(selectHeaderProfile)
  const { data, isLoading, isSuccess } = useGetMatchMakeResultQuery(id)
  const { deductCreditRedirect } = useCredit()

  const technology_request = data?.technology_request
  const corporate_profile = data?.technology_request?.corporate_profile
  
  const imageUrl = (technology_request?.hero_image !== undefined && technology_request?.hero_image !== null) ? 
    technology_request?.hero_image.url: ''

  const getYearQuarter = (date: Date) => {
    const d = dayjs(date)
    return `Q${d.quarter()} ${d.year()}`
  }

  const countries = technology_request?.country_implementation ? 
    technology_request?.country_implementation?.split(",") : [];
  
  const startConversationsWithAction = (action: Enum_ConversationWithCategory) => {
    if (isSuccess) {
      deductCreditRedirect({
        action,
        path: '/app/startup/collaborate',
        targetId: technology_request?.id,
        targetType: 'corporate',
        name: technology_request?.name ?? '',
      })
    }
  }

  const startConversation = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.MATCH)
  }

  const unlockBudget = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.MATCH_UNLOCK_BUDGET)
  }

  const KeyInfo = (
    <div className="key-info">
      <h6>About them</h6>
      {corporate_profile && <IconLabel icon={MapPinGreenIcon}>{Countries.getName(corporate_profile?.country_jurisdiction || '', "en")}</IconLabel>}

      {technology_request?.industries && technology_request?.industries.length > 0 && <IconLabel icon={BoxesOddGreenIcon}>{technology_request?.industries.map(item => item !== null ? item.name: '').join(" / ")}</IconLabel>}
      
      {technology_request?.corporate_departments && technology_request?.corporate_departments.length > 0 && <IconLabel icon={BoxCascadeGreenIcon}>{technology_request?.corporate_departments.map(item => item !== null ? item.name: '').join(" / ")}</IconLabel>}
      
      {technology_request?.corporate_profile?.esg_rating ? 
        <IconLabel icon={StarGreenOutlineIcon}>
          ESG Rate - {ESGRatingOptions.find(({value}) => value === technology_request?.corporate_profile?.esg_rating)?.label ?? ''}
        </IconLabel>
      : null}
      
      <h6 className="mt-4">About the project</h6>
      
      <div className="list">
        {technology_request?.projected_startdate !== null &&
        <div className="item">
          <label>Kick off date</label>
          <span>{getYearQuarter(technology_request?.projected_startdate)}</span>
        </div>
        }
        {technology_request?.projected_complete_date !== null &&
        <div className="item">
          <label>Delivery date</label>
          <span>{getYearQuarter(technology_request?.projected_complete_date)}</span>
        </div>
        }
        <div className="item">
          <label>Budget</label>
          <span>
            <Button variant="link" className="flex-row color-java d-flex align-items-center" onClick={unlockBudget}>
              <div className="mb-1 icon">
                <img src={UnlockGreenIcon} alt="unlock" />
              </div>
              <span className="ps-2 font-size-md-12 text-decoration-underline text-lowercase">unlock Budget</span>
            </Button>
          </span>
        </div>
        {countries.length > 0 && 
        <div className="item">
          <label>Implementation</label>
          <span className="flex-column d-flex">
            {countries.map((item, i) => (<span key={i} className="mb-2 me-2">{Countries.getName(item, "en")}</span>))}
          </span>
        </div>}
        
      </div>
    </div>
  )

  return (<>
    {isSuccess && <div className="view-technology-solution-match">
      <div className="left">
        <div className="image">
          <div className="top-right">
            <FavouriteWidget
              itemId={technology_request?.id?? ''}
              type={Enum_Bookmark_Type.TechnologyRequest} 
            />
          </div>
          <Image src={imageUrl} alt="" />
        </div>          
        {false && <div className="recommended">
          <div className="body">
            <img src={ThumbsUpIcon} alt="" />
            Recommended by startups
          </div>
          <img className="back" src={RoundNStarIcon} alt="" />
        </div>}
        {KeyInfo}
      </div>
      <div className="right">
        <div className="container-fluid p-0">
          
          <div className="heading">
            <div className="match-percent">
              {data?.match_rating && <MatchDial variant="light" size="lg" percent={data?.match_rating || 0} />}
            </div>
            <div className="d-flex flex-column">
              <h2 className="">{technology_request?.name || ''}</h2>
              <ItemRating itemId={`corp-${corporate_profile?.id}`} />
            </div>
          </div>
          
          
          <h5 className="pt-3">Business need description</h5>
          <p className="mt-3 mb-4">{technology_request?.business_challange_description || ''}</p>

          <div className="pt-4 row">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <h5>Looking for</h5>
              <div className="flex-column flex-md-row flex-wrap pt-2 d-flex align-items-start">
                {technology_request?.want_poc && <Pill label="POC" icon={TickWhiteIcon} />}
                {technology_request?.want_seller && <Pill label="Sell" icon={TickWhiteIcon} />}
                {technology_request?.want_lease && <Pill label="Lease" icon={TickWhiteIcon} />}
                {technology_request?.want_investment && <Pill label="Invest" icon={TickWhiteIcon} />}
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <h5>Interested in</h5>
              <div className="flex-column flex-md-row flex-wrap pt-2 d-flex align-items-start">
                {technology_request?.is_cloud && <Pill label="Cloud" icon={TickWhiteIcon} />}
                {technology_request?.sdg_driven && <Pill label="SDG Driven" icon={TickWhiteIcon} />}
                {technology_request?.is_gdpr && <Pill label="GDPR" icon={TickWhiteIcon} />}
                {technology_request?.is_plugandplay && <Pill label="Plug & Play" icon={TickWhiteIcon} />}
                {technology_request?.has_api && <Pill label="API" icon={TickWhiteIcon} />}
                {technology_request?.has_ip && <Pill label="IP" icon={TickWhiteIcon} />}
                {technology_request?.is_spin_off && <Pill label="Spin-off/Tested" icon={TickWhiteIcon} />}
                {technology_request?.can_whitelabel && <Pill label="White label available" icon={TickWhiteIcon} />}
              </div>
            </div>
          </div>

          <div className="mt-md-5 row">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <h5>Business Objectives</h5>
              <div className="flex-column flex-md-row flex-wrap pt-2 d-flex align-items-start">
                {technology_request?.goals?.map((item, i) => (item !== null && <Pill variant="show-all" icon={TickWhiteIcon} key={i} label={item?.Name || ''} />))}
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <h5>Impact for their organization</h5>
              <div className="flex-column flex-md-row flex-wrap pt-2 d-flex align-items-start">
                {technology_request?.impact_level && <Pill variant="show-all" label={technology_request?.impact_level} icon={TickWhiteIcon} />}
                {technology_request?.impact_area?.name && <Pill variant="show-all" label={technology_request?.impact_area?.name} icon={TickWhiteIcon} />}
              </div>
            </div>
          </div>

          <div className="mt-md-5 row">
            <div className="col-12 col-md-6">
              <Expand icon={SpannarGreenIcon} label="Use Cases">
                <>
                  {technology_request?.business_use_cases?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
            <div className="col-12 col-md-6">
              <Expand icon={CogGreenIcon} label="Interested Technologies">
                <>
                  {technology_request?.technology_types?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>
          <div className="mt-md-5 row">
            <div className="col-12 col-md-6">
              <Expand icon={CubeGreenIcon} label="Industry Applicable">
                <>
                  {technology_request?.industries?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
            <div className="col-12 col-md-6">
              <Expand icon={PersonShirtIcon} label="Concern Departments">
                <>
                  {technology_request?.corporate_departments?.map((item, i) => (item !== null && <Pill variant="show-all" key={i} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="mt-0 mt-md-3 row">
            <div className="col-12">
              <Expand icon={CubeGreenIcon} label="SDG Objectives">
                <>
                  {technology_request?.sdg_objectives?.map((item, i) => (item !== null && <Pill variant="show-all" key={`sdg-${i}`} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          {KeyInfo}

          <div className="flex-column flex-md-row mt-5 border start-conversation">
            <RejectWidget 
              itemId={technology_request?.id ?? ''} 
              type={Enum_Bookmark_Type.TechnologyRequest}
              onReject={() => {
                push(`/app/${entityType}/matches`)
              }}
              element={
                <Button className="me-md-3">
                  <img src={TrashGreenIcon} className="me-2" alt="reject" />
                  Reject
                </Button>
              }
            />
            
            <Button variant="primary" onClick={startConversation} 
              className="mt-4 mt-md-0">
              <img src={TextBubbleWhiteIcon} className="me-2" alt="start conversation" />
              Start Conversation
            </Button>
          </div>

        </div>
      </div>
    </div>}
    </>
  )
}

export default TechnologySolutionMatchDetail;