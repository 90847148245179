import './assets/styles/nav-item.scss';
import {
  useRouteMatch,
  Link
} from 'react-router-dom';

export interface NavItemProps {
  label: string;
  icon: string;
  route: string;
  exact: boolean;
}

const MenuNavItem = (item: NavItemProps) => {
  const match = useRouteMatch({
    path: item.route,
    exact: item.exact ?? false
  });

  return (
    <Link className={`nav-item ${match  ? `active`:``}`} to={item.route}>
      <img src={item.icon} alt={item.label} />
      <div>{item.label}</div>
      <div className="shadows">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Link>
  );
};

export default MenuNavItem;