import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  Bookmark,
  BookmarkInput, 
  FindCurrentUserBookmarksContentPayload,
  Enum_Bookmark_Type,
} from 'generated/graphql'

export interface FindBookmarkProps {
  personProfile: string | null;
  itemId: string;
  type: Enum_Bookmark_Type;
}

export const bookmarksApi = createApi({
  reducerPath: "bookmarksApi",
  tagTypes: ["Bookmarks", "Bookmark-Item"],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    setBookmark: builder.mutation<Bookmark, BookmarkInput>({
      query: (data) => ({
        document: gql`
          mutation SetBookmark($data: BookmarkInput) {
            createBookmark(input: { data: $data} ) {
              bookmark {
                id
                type
                item_id
                category
              }
            }
          }
        `,
        variables: {
          data
        }
      }),
      invalidatesTags: ["Bookmarks"],
    }),
    unsetBookmark: builder.mutation<Bookmark, string>({
      query: (id) => ({
        document: gql`
          mutation UnsetBookmark($id: ID!) {
            deleteBookmark(input: {
              where: {
                id: $id
              }
            }) {
              bookmark {
                id
                item_id
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      invalidatesTags: ["Bookmarks"],
    }),
    getCurrentUserBookmarks: builder.query<Array<Bookmark>, void>({
      query: () => ({
        document: gql`
          query {
            currentProfileBookmarks {
              id
              type
              category
              item_id
            }
          }
        `
      }),
      transformResponse: (response: any) => response.currentProfileBookmarks,
      providesTags: ["Bookmarks"]
    }),
    getCurrentUserBookmarksContent: builder.query<FindCurrentUserBookmarksContentPayload, void>({
      query: () => ({
        document: gql`
          query {
            findCurrentUserBookmarksContent {
              bookmarks {
                id
                type
                category
                item_id
                technologyRequest {
                  id
                  business_challange_description
                  name
                  hero_image_url
                  corporateProfile {
                    country_jurisdiction
                    employee_count_range
                  }
                }
                technologyRequestMatch {
                  id
                  business_challange_description
                  name
                  hero_image_url
                  corporateProfile {
                    country_jurisdiction
                    employee_count_range
                  }
                  match_rating
                }
                technologySolution {
                  id
                  name
                  description
                  hero_image_url
                  startupProfile {
                    country
                    maturity_level
                  }
                }
                technologySolutionMatch {
                  id
                  name
                  description
                  hero_image_url
                  startupProfile {
                    country
                    maturity_level
                  }
                  match_rating
                }
              }
            }
          }
        `
      }),
      transformResponse: (response: any) => response.findCurrentUserBookmarksContent,
      providesTags: ["Bookmarks"]
    }),
    findFavourite: builder.query<Bookmark, FindBookmarkProps>({
      query: ({ personProfile, itemId, type }) => ({
        document: gql`
          query findBookmark(
            $personProfile: ID!,
            $itemId: String!,
            $type: ENUM_BOOKMARK_TYPE!
          ) {
            bookmarks(where: {
              person_profile: {
                id: $personProfile
              },
              item_id: $itemId,
              type: $type,
            }) {
              id
              category
              type
              item_id
            }
          }
        `,
        variables: {
          personProfile,
          itemId,
          type
        }
      }),
      transformResponse: (response: any) => response.bookmarks.length > 0 ? response.bookmarks[0] : null,
      providesTags: (result, error, { personProfile, itemId, type }) => {
        return [
          { type: 'Bookmarks', id: 'favourite=' + [personProfile, itemId, type].join('-') }
        ]
      }
    }),
    findReject: builder.query<Bookmark, FindBookmarkProps>({
      query: ({ personProfile, itemId, type }) => ({
        document: gql`
          query findBookmark(
            $personProfile: ID!,
            $itemId: String!,
            $type: ENUM_BOOKMARK_TYPE!
          ) {
            bookmarks(where: {
              person_profile: {
                id: $personProfile
              },
              item_id: $itemId,
              type: $type,
              category: "reject"
            }) {
              id
            }
          }
        `,
        variables: {
          personProfile,
          itemId,
          type
        }
      }),
      transformResponse: (response: any) => response.bookmarks.length > 0 ? response.bookmarks[0] : null,
      providesTags: (result, error, { personProfile, itemId, type }) => {
        return [
          { type: 'Bookmarks', id: 'reject=' + [personProfile, itemId, type].join('-') }
        ]
      }
    })
  })
})

export const { 
  useSetBookmarkMutation,
  useGetCurrentUserBookmarksQuery,
  useUnsetBookmarkMutation,
  useGetCurrentUserBookmarksContentQuery,
  useFindFavouriteQuery,
  useFindRejectQuery
} = bookmarksApi