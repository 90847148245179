import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { UploadFile } from 'generated/graphql'
import { DeleteFilePayload } from 'generated/graphql'

export const uploadApi = createApi({
  reducerPath: "uploadApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    upload: builder.mutation<Array<UploadFile>, Array<File>>({
      query: (files) => ({
        document: gql`
          mutation upload($files: [Upload]!) {
            multipleUpload(files: $files) {
              id
              name
              url
            }
          },
        `,
        variables: {
          files
        }
      }),
      transformResponse: (response: any) => response.multipleUpload
    }),
    remove: builder.mutation<DeleteFilePayload, string>({
      query: (id) => ({
        document: gql`
          mutation deleteUpload($id: ID!) {
            deleteFile(input: { where: { id: $id } } ) {
              file {
                id
                name
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => response.deleteFile  
    })
  })
})

export const { useUploadMutation, useRemoveMutation } = uploadApi