import React, { useContext, useRef, useEffect, useState } from 'react'
import './assets/styles/chat-component-messages.scss'
import { ChatContext } from './chat.provider'
import ChatMessageItem from './chat-message-item'
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';

import { ChatMessages } from 'generated/graphql';

const ChatMessagesList = () => {
  const [lastScrollHeight, setLastScrollHeight ] = useState<number>(0)
  const [lastScrollDir, setLastScrollDir] = useState<'up'|'down'>('down')
  const scrollElem = useRef<HTMLDivElement>(null)
  const user = useSelector(selectCurrentUser);
  const { chat: { 
    referenceId, category, page, size, messages
  }, prevPage, nextPage } = useContext(ChatContext);
  
  const handleScroll = (evt: React.UIEvent<HTMLDivElement>) => {
    const target = evt.target as HTMLDivElement
    if (target.scrollTop === 0) {
      if (scrollElem.current) {
        setLastScrollHeight(scrollElem.current.scrollHeight)
        setLastScrollDir('up')
      }
      prevPage()
    } else
    if (target.scrollTop === target.scrollHeight) {
      if (scrollElem.current) {
        setLastScrollHeight(scrollElem.current.scrollHeight)
        setLastScrollDir('down')
      }
      nextPage()
    }
  }
  
  useEffect(() => {
    if (messages) {
      if (scrollElem.current) {
        if (lastScrollDir === 'up') {
          const diff = scrollElem.current.scrollHeight - lastScrollHeight
          scrollElem.current.scroll({ top: diff })
        } else
        if (lastScrollDir === 'down') {
          if (lastScrollHeight === 0) {
            scrollElem.current.scroll({ top: scrollElem.current.scrollHeight })
          } else {
            const diff = scrollElem.current.scrollHeight - lastScrollHeight
            scrollElem.current.scroll({ top: scrollElem.current.scrollHeight - diff })
          }
        }
      }  
    }
  }, [messages])
  

  return (
    <div className="chat-component__body__messages">
      <div ref={scrollElem} onScroll={handleScroll}>
      {messages ? 
      <>
        {(messages ?? []).map((m:ChatMessages, i:number)=> (
          <ChatMessageItem 
            key={i}
            isSelf={m.from_user?.id === user?.id}
            message={m.body}
            name={m.from_user?.person_profile?.first_name ?? ''}
            profilePhoto={m.from_user?.person_profile?.photo?.url ?? ''}
            sentAt={m.created_at}
          />
        ))}
      </>
      : null}
      </div>
    </div>
  )
}

export default ChatMessagesList