import { useState } from 'react';
import InputCheckbox from "shared/forms/input-checkbox";
import './assets/styles/accept-cookie.scss';

type AcceptCookieProps = {
  onAcceptAll: (values: string[]) => unknown;
  onDeclineAll: () => unknown;
}

const AcceptCookie = ({
  onAcceptAll,
  onDeclineAll
}: AcceptCookieProps) => {
  const [isNecessary, setIsNecessary] = useState(false);
  const [isTargeting, setIsTargeting] = useState(false);

  return (
    <div>
      <h1 className="mb-4 sm">This website use cookies</h1>
      <div className="pt-2 pb-4 line-height-160 txt-regular color-text">We use cookies to improve your user experience. <br/>
      Choose what cookies you allow us to use. <br/>
      You can read more about our Cookie Policy in our Privacy policy. </div>
      <div className="pb-2 mb-4">
        <InputCheckbox label="STRICTLY NECESSARY" defaultValue={true} disabled={true} className="mb-3 box-text accept-cookie-checkbox" />
        <InputCheckbox label="TARGETING" defaultValue={true} onChange={() => setIsTargeting(!isTargeting) } className="box-java accept-cookie-checkbox" />
      </div>
      <div className="flex-column flex-md-row pt-3 d-flex justify-content-even">
        <button className="me-3 btn btn-primary mb-3 mb-md-0" onClick={() => {
          let flags = []
          isNecessary && flags.push('necessary');
          isTargeting && flags.push('targeting');
          onAcceptAll(flags)
        }}>Accept all</button>
        <button className="btn btn-primary-inverse" onClick={onDeclineAll}>Decline all</button>
      </div>
    </div>
  )
}

export default AcceptCookie;