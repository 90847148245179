import React, { useEffect } from 'react'
import BottomNav from './bottom-nav'
import './assets/styles/deliverables.scss'

import InputFiles from 'shared/forms/input-files'
import { FileItem } from 'hooks/file-uploads'
import Button from 'app/shared/button'

import { 
  useFieldArray,
  useForm, 
  SubmitHandler,
  Controller,
} from "react-hook-form";
import {
  useHistory,
  useRouteMatch
} from 'react-router-dom'

import { 
  useGetOneServiceCollaborationQuery,
  useUpdateMyServiceCollaborationMutation
} from 'services/graphql/service-collaboration'

import DocumentIcon from 'assets/svgs/document.svg'
import CircleOCross from 'assets/svgs/circle-o-cross.svg'
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';


export type Inputs = {
  proposals: Array<FileItem> | undefined;
  comments: string;
}

type Props = {
  id: string
}

const DeliverablesForm = ({
  id
}: Props) => {
  const user = useSelector(selectCurrentUser);
  const { push } = useHistory()
  const { url } = useRouteMatch();
  const { data, isLoading, isSuccess } = useGetOneServiceCollaborationQuery(id)
  const [update, updateResult] = useUpdateMyServiceCollaborationMutation()
  
  const {
    register,
    reset, 
    handleSubmit, 
    watch,
    control,
    getValues,
    formState: { errors } 
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = data => {
    update({
      id,
      deliverables: {
        proposals: (data.proposals?? []).map(p => p.id),
        comments: data.comments?? ''
      }
    })
  }

  const handleBack = () => {
    let parts = url.split('/')
    parts.pop()
    push(parts.join('/') + '/scope')
  }
  
  useEffect(() => {
    if (isSuccess && data) {
      reset({
        proposals: (data.deliverables?.proposals ?? []) as Array<FileItem>,
        comments: data.deliverables?.comments ?? ''
      })
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (updateResult.isSuccess) {
      let parts = url.split('/')
      parts.pop()
      push(`${parts.join('/')}/feedback`)
    }
  }, [updateResult])
  
  
  useEffect(() => {
    return () => {
      const data = getValues()
      
      update({
        id,
        deliverables: {
          proposals: (data.proposals?? []).map(p => p.id),
          comments: data.comments?? ''
        }
      })
      
    }
  }, [])

  return (
    <div className="main__deliverables-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          {isSuccess && data ?
          <>
          <div className="form-control-group">
            <h6 className="bold-type mb-2">ALKELIO 's Proposal</h6>
            <div className="d-flex flex-row">
              {user?.role?.type !== 'catalyst' ?
              <div className="uploaded">
                {(data.deliverables?.proposals?? []).map((item, i) => (
                  <div className="d-flex flex-row mb-2 mt-3">
                    <a href={item?.url?? ''} target="_blank">
                      <img src={DocumentIcon} /><span className="ms-2 color-azure font-size-14 bold-type">{item?.name ?? ''}</span>
                    </a>
                  </div>
                ))}
              </div> : null }
              {user?.role?.type === 'catalyst' ?
              <div className="upload-field">
                <div className="field-label font-size-12 medium-type mb-2">Upload Documents</div>
                <Controller
                  name="proposals"
                  control={control}
                  defaultValue={(data.deliverables?.proposals ?? []) as unknown as Array<FileItem>}
                  render={({field}) => <InputFiles maxFileCount={1} {...field} />}
                />
              </div> : null }
            </div>
          </div>
          <div className="form-control-group mt-4">
            <div className="form-control">
              <label>
                <div className="field-header">
                  Comments
                </div>
                {user?.role?.type !== 'catalyst' ?
                <textarea
                  rows={6}
                  className="mb-3 mb-md-0 w-100"
                  defaultValue={data.deliverables?.comments ?? ''}
                  {...register(`comments`)} >
                </textarea>
                : <div>{data.deliverables?.comments?? ''}</div> }
              </label>
            </div>
          </div>
          </>: null}
        </div>
        <div className="bottom">
          <BottomNav onBack={handleBack}/>
        </div>
      </form>
    </div>
  )
}

export default DeliverablesForm