import './assets/styles/date-select.scss';
import React, { useState, useEffect } from 'react';
import SelectOne from 'shared/forms/select-one';
import { numberList } from 'shared/utilities';
import BaseInput from 'shared/forms/base-input';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

interface DateSelectProps extends BaseInput {
  value: string | Date | null | undefined;
  onChange?: (value: string |Date) => unknown;
  disabled?: boolean;
}

const months = [
  {
    label: "Jan",
    value: "0"
  },
  {
    label: "Feb",
    value: "1"
  },
  {
    label: "Mar",
    value: "2"
  },
  {
    label: "Apr",
    value: "3"
  },
  {
    label: "May",
    value: "4"
  },
  {
    label: "Jun",
    value: "5"
  },
  {
    label: "Jul",
    value: "6"
  },
  {
    label: "Aug",
    value: "7"
  },
  {
    label: "Sep",
    value: "8"
  },
  {
    label: "Oct",
    value: "9"
  },
  {
    label: "Nov",
    value: "10"
  },
  {
    label: "Dec",
    value: "11"
  }
];

const isValidDate = (d: Date) => !isNaN(d.getTime()) && d.getTime() >= new Date().getTime()

const DateSelect = React.forwardRef(({
  value,
  onChange,
  disabled = false
}: DateSelectProps,
ref: React.Ref<HTMLDivElement>) => {
  const dateValue = new Date(value ?? '')

  const [selectedDate, setSelectedDate] = useState<Date>(dateValue)
  const [days, setDays] = useState<Array<{
    label: string,
    value: string
  }>>([])

  const years = numberList((new Date()).getUTCFullYear(),  ((new Date()).getUTCFullYear()) + 5)
  
  const tryParseDateChange = () => {
    if (selectedDate !== null) {
      if (isValidDate(selectedDate)) {
        const d = dayjs(selectedDate).format('YYYY-MM-DD')
        onChange && onChange(d) 
      }
    }
  }

  const getDays = () => {
    
    const day_count = dayjs(selectedDate).daysInMonth()
    
    let days = []
    for (let i = 1; i <= day_count; i++) {
      days.push({
        label: i.toString(),
        value: i.toString()
      })
    }
    return days
  }

  useEffect(() => {
    tryParseDateChange();
  }, [selectedDate])

  return (
    <div className="flex-row date-select d-flex align-items-center" 
      ref={ref}>
      <div className="row w-100">
        <div className="col ps-0 year">
          <label>
            <div className="mb-2 text">Year</div>
          </label>
          <SelectOne 
            className="me-3" 
            options={years}
            sortOptions={false}
            searchable={false}
            placeholder="Year"
            disabled={disabled}
            defaultValue={selectedDate.getFullYear().toString()} 
            onChange={(selectedYear: string) => {
              const updatedDate = selectedDate.setFullYear(parseInt(selectedYear))
              selectedDate.setMonth(0)
              selectedDate.setDate(1)
              setDays(getDays())
              setSelectedDate(new Date(updatedDate))
            }} 
          />
        </div>
        <div className="col ps-0 mt-3 mt-md-0 month">
          <label>
            <div className="mb-2 text">Month</div>
          </label>
          <SelectOne 
            options={months} 
            sortOptions={false}
            searchable={false}
            placeholder="Month"
            disabled={disabled}
            defaultValue={selectedDate.getMonth().toString()} 
            onChange={(selectedMonth: string) => {
              const updatedDate = selectedDate.setMonth(parseInt(selectedMonth))
              setSelectedDate(new Date(updatedDate))
              setDays(getDays())
            }}
          />
        </div>
        <div className="col ps-0 mt-3 mt-md-0 date">
          <label>
            <div className="mb-2 text">Day</div>
          </label>
          <SelectOne 
            options={days} 
            sortOptions={false}
            searchable={false}
            placeholder="Day"
            disabled={disabled}
            defaultValue={selectedDate.getDate().toString()} 
            onChange={(selected: string) => {
              const updatedDate = selectedDate.setDate(parseInt(selected))
              setSelectedDate(new Date(updatedDate))
            }}
          />
        </div>
      </div>
    </div>
  )
})

export default DateSelect;