import React from 'react'

import { Link } from 'react-router-dom';
import linkicon from '../assets/icons/linkicon.svg';

import { Image } from 'shared/image';
import { useGetPersonProfileCompanyQuery } from 'services/graphql/company'

type Props = {
  profileId: string
}

const styleLogo = {
  display: "block",
  marginTop: "20%",
  marginBottom: "20%",
  marginLeft: "auto",
  marginRight: "auto",
  width: "60%"
}

const Company = ({
  profileId
}: Props) => {

  const { data, isLoading, isSuccess } = useGetPersonProfileCompanyQuery(profileId)

  return ( 
    <>
      {isSuccess ?
      <div>
        <Image style={styleLogo}
          src={data?.logoUrl}
          alt={'company_logo'}/>
        <hr/>
        <div className="links">
          <Link to={
              (data?.type !== "startup") ? `/app/corporate/collaborate/${
                data?.id
              }/startup-profile` : `/app/startup/collaborate/${
                data?.id
              }/corp-profile`
            }
            className="link">
            <img src={linkicon}
              alt="hyperlink"/>
            View company profile
          </Link>
        </div>
      </div>
      : null}
    </>
  )
}

export default Company