import { useEffect } from 'react'
import Tile from 'app/shared/tile'
import PopCrackerIcon from './assets/svgs/pop-cracker.svg'
import './assets/styles/complete.scss'
import Button from 'app/shared/button'
import { useHistory, useParams } from 'react-router-dom'
import { useSubmitInterestMutation } from 'services/graphql/services-interest'
import { useSelector, useDispatch } from 'react-redux'
import { currentCart, reset } from 'features/cart/cartSlice'

const CompleteWithEmail = () => {
  const dispatch = useDispatch()
  const [ submitInterest, status ] = useSubmitInterestMutation()
  const { push } = useHistory();
  const { entityType }: any = useParams();
  const cart = useSelector(currentCart)

  useEffect(() => {
    const data = {
      services: cart.items.map(({ name }) => ({
        name
      })),
      requested_conversation_with: cart.conversationWith
    };
    
    (async () => {
      await submitInterest(data);
    })();

    dispatch(reset)

  }, [])

  return (
    <Tile>
      <div className="payment-complete d-flex flex-column align-items-center justify-content-center">
        <img className="icon" src={PopCrackerIcon} alt="" />
        <div className="title">Thank you for selecting our services !</div>
        <div className="font-size-14 font-size-md-12 line-height-140 txt-regular letter-spacing-n-2 mb-3 text-center">
          We have received an email with your selection we will reach out to you soon :)
        </div>
        <Button
          variant="primary" 
          className="wide"
          onClick={() => {
            push(`/app/${entityType}/dashboard`)
          }}>
          Ok Thank You!
        </Button>
      </div>
    </Tile>
  )
}

export default CompleteWithEmail;