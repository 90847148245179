import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCorporateFindOneTechnologySolutionQuery } from 'services/graphql/tech-solutions'
import './assets/styles/view-technology-solution.scss'
import Button from 'app/shared/button'
import ThumbsUpIcon from 'app/shared/assets/svgs/thumbs-up-green.svg';
import RoundNStarIcon from 'app/shared/assets/svgs/rounded-n-star.svg';
import UnlockAzureIcon from 'app/shared/assets/svgs/unlock-azure.svg';
import { FavouriteWidget } from 'app/shared/favourite-widget'
import Pill from 'app/shared/pill'
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import SpannarGreenIcon from 'app/shared/assets/svgs/spannar-green.svg';
import CogGreenIcon from 'app/shared/assets/svgs/cog-green.svg';
import TextBubbleWhiteIcon from 'app/shared/assets/svgs/text-bubble-white.svg'
import Expand from 'app/shared/expand';
import NameCardGreenIcon from 'app/shared/assets/svgs/name-card-green.svg';
import CubeGreenIcon from 'app/shared/assets/svgs/cube-green.svg';
import { Image } from 'shared/image';
import { useAddViewCountMutation } from 'services/graphql/view-counts';
import { ItemRating} from 'app/shared';
import { useCredit } from 'hooks/index'
import { Enum_ConversationWithCategory } from 'features/cart/cartSlice'
import { Enum_Bookmark_Type } from 'generated/graphql'
import TechnologyReadiness from './tech-readiness'
import KeyInfo from './key-info'
import RocketAzureIcon from 'app/shared/assets/svgs/rocket-azure.svg';

dayjs.extend(quarterOfYear)

const ViewTechnologySolution = () => {
  const { id }: any = useParams()
  const { data, isLoading, isSuccess } = useCorporateFindOneTechnologySolutionQuery(id)
  const [ addViewCount ] = useAddViewCountMutation()
  const { deductCreditRedirect } = useCredit()

  const startConversationsWithAction = (action: Enum_ConversationWithCategory) => {
    deductCreditRedirect({
      action,
      path: '/app/corporate/collaborate',
      targetId: id,
      targetType: 'startup',
      name: data?.name ?? '',
    })
  }

  const imageUrl = (data?.hero_image !== undefined && data?.hero_image !== null) ? data?.hero_image.url: ''

  const getYearQuarter = (date: Date) => {
    const d = dayjs(date)
    return `Q${d.quarter()} ${d.year()}`
  }

  const startConversation = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.EXPLORE)
  }

  const unlockDemo = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.EXPLORE_UNLOCK_DEMO)
  }
  
  const unlockTestimonial = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.EXPLORE_UNLOCK_TESTIMONIAL)
  }

  const unlockCaseStudies = () => {
    startConversationsWithAction(Enum_ConversationWithCategory.EXPLORE_UNLOCK_CASE_STUDIES)
  }

  useEffect(() => {
    addViewCount(`tech-sol-${id}`);
  }, [])

  return (
    <div className="view-technology-solution">
      <div className="left">
        <div className="image">
          <div className="top-right">
            <FavouriteWidget 
              itemId={id} 
              type={Enum_Bookmark_Type.TechnologySolution}
            />
          </div>
          <Image src={imageUrl} alt="" />
        </div>          
        {data?.tested_by_alkelio && <div className="recommended">
          <div className="body">
            <img src={ThumbsUpIcon} alt="" />
            Technology tested by ALKELIO
          </div>
          <img className="back" src={RoundNStarIcon} alt="" />
        </div>}
        {data ? <KeyInfo 
          data={data} 
          onUnlockTestimonial={unlockTestimonial} 
          onUnlockCaseStudies={unlockCaseStudies}
        /> : null}
      </div>
      <div className="right">
        <div className="container-fluid p-0">
          <h2 className="mb-0 mb-md-3">{data?.name || ''}</h2>
          
          <div className="mb-3">
            <ItemRating itemId={`startup-${data?.startup_profile?.id}`} />
          </div>
          
          <h5 className="pt-3">The Tech Solution</h5>
          
          <p className="mt-3 mb-4">{data?.description || ''}</p>
        
          <div className="d-none d-lg-block">
            <div className="flex-row d-flex">
              <img className="me-2" src={RocketAzureIcon} alt="" />
              <div className="medium-type font-size-14 font-size-md-12 line-height-160">{data?.technology_readiness_name}</div>
            </div>
          </div>
          
          {data?.benefits_description && <div className="mt-4"><h5 className="pt-3">Why them?</h5>
          <p className="mt-3 mb-4">{data?.benefits_description || ''}</p></div>}

          <div className="pt-md-4 row">
            <div className="col-12 col-md-6">
              <h5 className="mb-0 mb-md-2">Looking for</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {data?.purpose_poc && <Pill label="POC" icon={TickWhiteIcon} />}
                {data?.purpose_sell && <Pill label="Sell" icon={TickWhiteIcon} />}
                {data?.purpose_lease && <Pill label="Lease" icon={TickWhiteIcon} />}
                {data?.purpose_investment && <Pill label="Investment" icon={TickWhiteIcon} />}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0">
              <h5 className="mb-0 mb-md-2">Can Propose</h5>
              <div className="flex-row flex-wrap pt-2 d-flex">
                {data?.is_cloud && <Pill label="Cloud" icon={TickWhiteIcon} />}
                {data?.sdg_driven && <Pill label="SDG Driven" icon={TickWhiteIcon} />}
                {data?.is_gdpr && <Pill label="GDPR" icon={TickWhiteIcon} />}
                {data?.is_plugandplay && <Pill label="Plug & Play" icon={TickWhiteIcon} />}
                {data?.has_api && <Pill label="API" icon={TickWhiteIcon} />}
                {data?.has_ip && <Pill label="IP" icon={TickWhiteIcon} />}
              </div>
            </div>
          </div>

          <div className="mt-4 pt-md-3 mt-md-4 row">
            <div className="col-12 col-md-6">
              <Expand icon={SpannarGreenIcon} label="Use case & application">
                <>
                  {data?.business_use_cases?.map((item, i) => (item !== null && <Pill variant="show-all" key={`use-case-${i}`} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
            <div className="col-12 col-md-6">
              <Expand icon={CogGreenIcon} label="Type of Technology">
                <>
                  {data?.technology_types?.map((item, i) => (item !== null && <Pill variant="show-all" key={`type-tech-${i}`} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="mt-0 mt-md-3 row">
            <div className="col-12 col-md-6">
              <Expand icon={CubeGreenIcon} label="Applicable Industries">
                <>
                  {data?.industries?.map((item, i) => (item !== null && <Pill variant="show-all" key={`industry-${i}`} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
            <div className="col-12 col-md-6">
              <Expand icon={NameCardGreenIcon} label="Applicable departments">
                <>
                  {data?.corporate_departments?.map((item, i) => (item !== null && <Pill variant="show-all" key={`potential-dept-${i}`} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="mt-0 mt-md-3 row">
            <div className="col-12">
              <Expand icon={CubeGreenIcon} label="SDG Objectives">
                <>
                  {data?.sdg_objectives?.map((item, i) => (item !== null && <Pill variant="show-all" key={`sdg-${i}`} label={item.name || ''} icon={TickWhiteIcon}  />))}
                </>
              </Expand>
            </div>
          </div>

          <div className="pt-md-4 mt-3 row">
            <div className="col">
              <h5>Demo area</h5>
              <p className="flex-row d-md-flex">A demo link is available, start the conversation to 
                <Button variant="link" className="link-unlock flex-row color-azure align-items-center ms-2" onClick={unlockDemo}>
                  <div className="mb-1 icon">
                    <img src={UnlockAzureIcon} alt="unlock" className="me-1" />
                  </div>
                  <span className="mb-1 text-decoration-underline text-lowercase">unlock the demo</span>
                </Button>
              </p>
            </div>
          </div>

          {data ? <KeyInfo 
            data={data} 
            onUnlockTestimonial={unlockTestimonial} 
            onUnlockCaseStudies={unlockCaseStudies}
          /> : null}

          <div className="flex-column flex-md-row mt-4 mt-md-5 border start-conversation">
            <h6>Want to know more or connect?</h6>
            <Button variant="primary" onClick={startConversation}>
              <img src={TextBubbleWhiteIcon} className="me-2" alt="start conversation" />
              Start Conversation
            </Button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ViewTechnologySolution;