import React from 'react';
import './assets/styles/results.scss';
import Tile from 'app/shared/tile';
import { 
  MatchItemSummary,
  Bookmark,
  Enum_Bookmark_Category,
  Enum_Bookmark_Type
} from 'generated/graphql';
import { useGetTechnologySolutionMatchesQuery } from 'services/graphql/match-making-result';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import { useSelector } from 'react-redux';
import {
  useHistory
} from 'react-router-dom';
import MatchedTechnologyRequestCard from 'app/shared/matched-technology-request-card';
import ExploreHelpBanner from 'app/shared/explore-help-banner';

interface ResultsProp {
  technologySolution: string;
  bookmarks: Array<Bookmark> | undefined;
  matchTriggered?: boolean;
}

const Results = ({
  technologySolution,
  bookmarks = [],
  matchTriggered = false
}: ResultsProp) => {
  const { push } = useHistory()
  const headerProfile = useSelector(selectHeaderProfile)

  const { data, isFetching, isSuccess } = useGetTechnologySolutionMatchesQuery(technologySolution)
  
  const title = isFetching 
    ? 'Loading Results' 
    : isSuccess 
      ? matchTriggered 
        ? (`Found ${data && data?.matches && data?.matches.length} matches`) 
        : 'No matching started yet, click "MATCH" to start'
      : 'Error Loading Results';

  const matches = (data !== undefined 
    && data?.matches !== undefined 
    && data?.matches !== null) ? (data?.matches as Array<MatchItemSummary>) : [];

  const viewMatch = (id: string) => {
    push(`/app/startup/matches/${id}`)
  }
  
  const getBookmark = (technology_request_id: string) => {
    return (bookmarks ?? [])
    .find(b => b.type === Enum_Bookmark_Type.TechnologyRequest 
      && b.item_id === Number(technology_request_id || ''))
  }

  return (
    <Tile title={title} className="startup-match-results">
      <div className="flex-row flex-wrap d-flex match-tech-requests-inner">
        {isSuccess && matches.map(({id, technology_request, match_rating}, i) => 
          <MatchedTechnologyRequestCard 
            key={i}
            item={{
              id: id || '',
              technology_request_id: technology_request?.id?? '',
              hero_image_url: technology_request?.hero_image?.url || '',
              name: technology_request?.name || '',
              business_challange_description: technology_request?.business_challange_description || '',
              country_jurisdiction: technology_request?.corporate_profile?.country_jurisdiction || '',
              employee_count_range: technology_request?.corporate_profile?.employee_count_range || '',
              match_rating: match_rating || 0,
            }} 
            bookmark={getBookmark(technology_request?.id?? '')} 
            profileId={String(headerProfile.id)} 
            onView={viewMatch} />
        )}
      </div>
      <ExploreHelpBanner />
    </Tile>
  )
}

export default Results;