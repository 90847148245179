import React from 'react';
import '../assets/styles/conversation.scss';
import Grid from '@material-ui/core/Grid';
import { Box, Typography,Modal,Tabs,Tab,} from "@material-ui/core";
// import { chromeTabsStylesHook } from '@mui-treasury/styles/tabs';
import ScheduleDateIcon from '../../shared/menu/help/assets/svgs/schedule-date.svg';
import { useRouteMatch,Link } from 'react-router-dom';
import InputImage from "shared/forms/gray-image";
import Caret from '../assets/icons/caret.svg';
import Chat from '../assets/icons/chat.svg';

import WhiteXIcon from 'app/shared/assets/svgs/WhiteX.svg';
import Config from 'config';
import linkicon from '../assets/icons/linkicon.svg';
import MockPhoto from '../assets/img/alkelio-catalyst-dummy.jpg';
import { Image } from 'shared/image';
import Button from 'app/shared/button';
import ChatModule from './chatModule';
import {useGetConversationProfileQuery} from 'services/graphql/conversation';
import { useGetCatalystForConversationsQuery } from 'services/graphql/catalyst-conversations';

type OutlineProps = {
    conversationId: number;
    userRole: string;
    data: any;
    logo: string;
    view: string;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }
const Outline = React.forwardRef((
    {
        conversationId,
        userRole,
        data,
        view,
        logo
    }: OutlineProps,
    ref: React.Ref<HTMLDivElement>
) => {
    const {
        data: catalystProfile, 
        isLoading: isLoadingCatalystProfile, 
        isSuccess: isSuccessCatalystProfile 
    } = useGetCatalystForConversationsQuery(conversationId)

    const { data:profileData, isLoading, isSuccess } = useGetConversationProfileQuery();
    const [isChatVisible, setIsChatVisible] = React.useState(false);
    const [value,setValue] =React.useState(0);
    // const tabsStyles = chromeTabsStylesHook.useTabs();
    // const tabItemStyles = chromeTabsStylesHook.useTabItem();
    const corpData = data?.technology_request?.corporate_profile;
    const startData = data?.technology_solution?.startup_profile;
    const repImageUrl = view === 'startup' ? corpData?.person_profiles[0]?.photo?.url : startData?.person_profiles[0]?.photo?.url;
    const companyLogo = view === 'startup' ? corpData?.logo?.url : startData?.logo?.url
    
    const repName = corpData?.person_profiles[0]?.first_name + " " + corpData?.person_profiles[0]?.last_name;
    const repRole = corpData?.person_profiles[0]?.role;
    const repMail = corpData?.person_profiles[0]?.user?.email;
    const startImageUrl= startData?.person_profiles[0]?.photo?.url;
    const startRole= startData?.person_profiles[0]?.role;
    const startName= startData?.person_profiles[0]?.first_name + " " + startData?.person_profiles[0]?.last_name;
    const startMail= startData?.person_profiles[0]?.user?.email;
    let { path } = useRouteMatch();
    const pid=profileData?.person?.id || "";
    const styleLogo = {
        display: "block",
        marginTop: "20%",
        marginBottom: "20%",
        marginLeft: "auto",
        marginRight: "auto",
        width: "60%"
    }

    const styleRep = {
        marginTop: "8px",
        marginBottom: "8px",
        width: "80px",
        height: "80px",
        borderRadius: "50%",
    };
    const grayStyle={
        borderRadius:"50%",
        backgroundColor: "rgba(0,0,0, .2)",
        flex: '0 0 auto',
        marginTop: "5%",
        marginBottom: "5%",
        width: "80px",
        height: "80px",

    //     flex: 0 0 auto;
    //   border-radius: 50%;
    //   background-color: rgba(0,0,0, .2);
    //   margin-right:16px;

    //   img[src=""] {
    //     display:none;
    //   }
    };
    const closeChat = () => {
        // console.log("Closing chat");
        
        setIsChatVisible(false);

    };
    const handleTabs = (event :any, newValue :number) => {
        setValue(newValue);
      };
    const openChat = () => {
        console.log("the profile ",startData);
        console.log("corping",corpData);
        setIsChatVisible(true);
    };

    return (
        
        <Grid container item xs={3} spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid item xs={10}>
                <Box
                    border={1}
                    borderColor="grey.300"
                    borderRadius={10} >
                    <Image style={styleLogo} src={companyLogo} alt={'company_logo'} />
                    <hr />
                    <div className="links">
                        <Link to={(view === "startup") ? 
                                `/app/startup/collaborate/${data?.id}/corp-profile`
                                :`/app/corporate/collaborate/${data?.id}/startup-profile`
                            }
                            className="link" >
                            <img src={linkicon} alt="hyperlink" />
                            View company profile
                        </Link>
                    </div>
                    <hr />
                    <div className="representative">
                        <Typography variant="subtitle2" display="block" align="center" gutterBottom>You are talking to</Typography>
                        {repImageUrl ? 
                        <>
                            {(view === "startup") ?
                            <>
                                <Image style={styleRep} src={repImageUrl} alt={'profile_picture'} />
                            </> :
                            <>
                                <Image style={styleRep} src={startImageUrl} alt={'profile_picture'} />
                            </>}
                        </> :
                        <>
                            <InputImage
                                layout="elipse-left"
                                height="80px"
                                width="80px"
                                {...repImageUrl}
                            />
                        </>}
                        {/* <Image style={repImageUrl? styleRep:grayStyle} src={repImageUrl} alt={'profile_picture'} /> */}
                        
                        <Typography variant="body1" display="block" align="center" gutterBottom>
                            {(view !=="startup") ? startName:repName} <span className="subtitle">{(view !== "startup")? startRole:repRole} </span>
                        </Typography>
                        <span>{(view !=="startup")? startMail:repMail}</span>
                        <hr />
                        <div className="links">
                            <button className="link" color='primary'> {/*@todo add nav link onclick */}
                                <img src={ScheduleDateIcon} alt="Link" />
                                Book a meeting
                            </button>
                        </div>
                    </div>
                </Box>
                <Modal open={isChatVisible} onClose={closeChat}>
                    <div className="chatModal">
                        <header >
                            
                            <img src={Chat} alt="chat" style={{margin: "24px 8px 25px 24px"}} />

                            {/* <b style={{paddingRight:"25px",marginLeft:"20px"}}> Chat</b> */}
                            <b style={{ fontWeight: 700, color: "#fff"}} > Chat</b>

                        
                            <button type="button" onClick={closeChat}>
                                <img src={WhiteXIcon} alt={"X"} />
                            </button>
                        </header>
                        <Tabs className="paperTabs" value={value}  onChange={handleTabs} >
                            <Tab className="paperTab" label="Project Chat" />
                        </Tabs>
                        <div className='chatWrapper'>

                        <TabPanel  value={value} index={0}>
                        {isSuccess? <>
                            <ChatModule sid={profileData}  />
                        </>:<></>}
                        </TabPanel>
                        </div>

                        
                    </div>
                </Modal>
                <Button variant="icon me-3" className="ChatButton" onClick={openChat}>

                    <div>
                        <img src={Chat} alt="chat" />

                        {/* <b style={{paddingRight:"25px",marginLeft:"20px"}}> Chat</b> */}
                        <b style={{ fontWeight: 700, paddingLeft: "8px" }} > Chat</b>
                        
                    </div>
                        <div><img src={Caret} alt="caret" /></div>

                </Button>
                {userRole !== 'catalyst' && isSuccessCatalystProfile && catalystProfile ? 
                <Box
                    border={1}
                    borderColor="grey.300"
                    borderRadius={10}
                >
                    <div className="catalyst">
                        {(catalystProfile && catalystProfile?.photo) ? <img style={styleRep} src={catalystProfile?.photo?.url} alt={'profile_picture'} /> : null}
                        <h6>{catalystProfile?.first_name + ' ' + catalystProfile?.last_name}</h6>
                        <p>Your ALKELLIO Catalyst</p>
                        <hr />
                        <div className="links">
                            <button className="link" color='primary' onClick={() => {
                                // window.open(``)
                            }}> 
                                <img src={ScheduleDateIcon} alt="Link" />
                                Book a meeting
                            </button>
                        </div>
                    </div>
                </Box> : null }
            </Grid>
        </Grid>

    )
})

export default Outline;