import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { ApplicationConfig } from 'generated/graphql'

export const singleTypesApi = createApi({
  reducerPath: "singleTypesApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getApplicationConfig: builder.query<ApplicationConfig, void>({
      query: () => ({
        document: gql`
          query {
            applicationConfig {
              payment_stripe_key
              enable_payments
            }
          }
        `
      }),
      transformResponse: (response: any) => response.applicationConfig
    })
  })
})

export const {
  useGetApplicationConfigQuery
} = singleTypesApi