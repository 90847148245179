import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { Services, Enum_Services_Role, GetServicesPayload } from 'generated/graphql'

type ServicesWithCurrency = {
  services: Array<GetServicesPayload>,
  currency: string
}

export const servicesApi = createApi({
  reducerPath: "servicesApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getServices: builder.query<Array<Services>, Enum_Services_Role>({
      query: (role) => ({
        document: gql`
          query getServices($role: String!) {
            services(where: {
                role: $role
              }) {
              title
              description
              price
              image {
                url
              }
              slug
            }
          }
        `,
        variables: {
          role
        }
      }),
      transformResponse: (resposne:any) => resposne.services
    }),
    getMyServices: builder.query<ServicesWithCurrency, void>({
      query: () => ({
        document: gql`
          query {
            getServices {
                title
                slug
                image {
                    url
                }
                description
                price
            } 
            myProfile {
              region_currency
            }
        }
        `
      }),
      transformResponse: (response:any) => ({
        services: response.getServices,
        currency: response.myProfile.region_currency
      })
    })
  })
})

export const {
  useGetServicesQuery,
  useGetMyServicesQuery
} = servicesApi