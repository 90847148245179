import React from 'react';
import SelectOne from "shared/forms/select-one";
import CurrencyCode from 'currency-codes';
import Button from 'app/shared/button';
import CircleOPlusBlueIcon from 'app/shared/assets/svgs/circle-o-plus-small-blue.svg';
import CircleOClose from 'assets/svgs/circle-o-close.svg';
import { useFieldArray, 
  Control, 
  UseFormRegister, 
  UseFormGetValues,
  Controller,
  DeepMap,
  DeepPartial,
  FieldError, 
} from 'react-hook-form';
import Inputs from './inputs';
import { CurrenciesPreferred, ProjectBudgetOptions } from 'app-constants';
import InputMoney from 'shared/forms/input-money';
import './assets/styles/project-budget.scss';

const Currencies = CurrencyCode.codes();
const Options = ProjectBudgetOptions

export interface ProjectBudgetItem {
  id: string;
  currency: string;
  budget: number;
  option: string;
}
interface ProjectBudgetProps {
  control?: Control<Inputs>;
  register: UseFormRegister<Inputs>;
  errors?: DeepMap<DeepPartial<Inputs>, FieldError>;
  getValues?: UseFormGetValues<Inputs>;
}

const ProjectBudget = React.forwardRef(({
  control,
  register,
  errors,
  getValues,
  }: ProjectBudgetProps,
  ref: React.ForwardedRef<HTMLDivElement>) => {
    
  const { fields, append, remove } = useFieldArray({
    control,
    name: "project_budget"
  });

  return (
    <div className="form-control-group project-budget" ref={ref}>
      <label className="mb-4">
        <div className="bold">Pricing</div>
      </label>
      {(fields.length > 0 ? fields : [{budget:0, currency:'', option:''}]).map((item, i) => (
      <div key={i} className="flex-column d-flex justify-content-between mb-3 mb-md-0">
        <div className="mb-0 row align-items-start" style={{width:'100%'}}>
          <div className="col-12 order-2 order-md-1 mb-3 mb-md-0 col-md-12 col-lg-5 ps-0">
            <div className="form-control">
              <label className="">
                <div className="text">Min. Price</div>
              </label>
              <div className="input-group seamless">
                <Controller
                  name={`project_budget.${i}.budget`}
                  control={control}
                  defaultValue={item.budget}
                  rules={{
                    required: false
                  }}
                  render={({field}) => <InputMoney 
                    style={{flex: "1 1 auto", 
                      width: '0px',
                      paddingRight: "0px",
                      minWidth: '100px'
                    }} 
                    {...field}
                  />}
                />
                <Controller 
                  name={`project_budget.${i}.currency`}
                  control={control}
                  rules={{
                    required: false
                  }}
                  render={({field}) => <SelectOne 
                    orderFirst={CurrenciesPreferred}
                    options={Currencies} style={{flex: "0 0 80px"}} 
                    {...field} />}
                />
              </div>
              <div className={`invalid-feedback ${errors?.project_budget?.[i]?.budget ? 'd-block':''}`}>Please enter a valid budget</div>
              <div className={`invalid-feedback ${errors?.project_budget?.[i]?.currency ? 'd-block':''}`}>Please select a currency</div>
            </div>
          </div>
          <div className="col-12 order-3 order-md-2 mb-3 mb-md-0 col-md-11 col-lg-5 ps-0">
            <div className="form-control">
              <label>
                <div className="text">Option</div>
                <Controller 
                  name={`project_budget.${i}.option`}
                  control={control}
                  rules={{
                    required: false,
                    validate: {
                      unique: (value) => {
                        return (getValues ? getValues(`project_budget`): []).find((pb, k) => (k !== i && value === pb.option)) === undefined
                      }
                    }
                  }}
                  render={({field}) =>
                    <SelectOne 
                      options={Options}
                      {...field} 
                    />
                  }
                />
              </label>
              <div className={`invalid-feedback ${errors?.project_budget?.[i]?.option ? 'd-block':''}`}>
                {errors?.project_budget?.[i]?.option?.type === 'required' && <span>Please select an option</span>}
                {errors?.project_budget?.[i]?.option?.type === 'unique' && <span>Please select a different option, only 1 option per pricing</span>}
              </div>
            </div>
          </div>
          <div className="col-12 order-1 order-md-3 mb-3 mb-md-0 col-md-1 col-lg-1 ps-0 pe-0 d-flex d-md-block justify-content-center">
            {i > 0 && <button 
              className="pt-md-2 mt-md-4 remove"
              type="button"
              onClick={() => {
                remove(i);
              }} 
            >
              <img src={CircleOClose} alt="remove"/>
            </button>}
          </div>
        </div>
        {i < fields?.length ? <hr className="d-block d-md-none"/> : null}
      </div>
      ) )}
      
      <div>
        <Button 
          variant="link icon" 
          className="add-option"
          onClick={() => {
            append({
              id: '',
              currency: '',
              budget: 0,
              option: ''
            })
          }}
        >
          <div className="d-flex align-items-center">
            <img src={CircleOPlusBlueIcon} alt="add" />
            <span className="color-azure font-size-14 ms-2 ms-md-0 font-size-md-12 bold-type">Add Another Option</span>
          </div>
        </Button>
      </div>
    </div>
  )
})

export default ProjectBudget;