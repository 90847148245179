import './assets/styles/icon-label.scss'

type IconLabelProps = {
  icon: string;
  className?: string;
  children?: React.ReactNode | string
}

const IconLabel = ({
  icon, 
  className, 
  children
}: IconLabelProps) => {
  return (
    <div className={`icon-label ${className}`}>
      <span className="icon">
        <img src={icon} alt="" />
      </span>
      <span>
        {children}
      </span>
    </div>
  )
}

export default IconLabel;