import './assets/styles/bookmarks.scss';
import HeartIcon from './assets/svgs/heart.svg';
import { Link, useParams } from 'react-router-dom';

const Bookmarks = () => {
  const { entityType }: any = useParams();
  return (
    <Link className="bookmarks" to={`/app/${entityType}/favourites`}>
      <img src={HeartIcon} alt="bookmarks" />
    </Link>
  )
}

export default Bookmarks;