import React, { FC, createContext, useState, useEffect } from 'react'
import { ChatMessages, Enum_Chat_Category } from 'generated/graphql'
import { io } from "socket.io-client";

import { 
  useGetMessagingSessionQuery,
  useLazyGetMessagingSessionQuery
} from 'services/graphql/chat'

type ChatStateType =  {
  referenceId: string,
  category: Enum_Chat_Category,
  page: number,
  size: number,
  totalPages: number,
  messages: Array<ChatMessages>,
  messageIdMap: Record<string, ChatMessages>
}

type ChatContextType = {
  chat: ChatStateType,
  prevPage: () => void,
  nextPage: () => void
}

const ChatMessageMaxItemsPerPage = 10

export const ChatContext = createContext<ChatContextType>({
  chat: { 
    referenceId: '',
    category: Enum_Chat_Category.Conversation,
    page: 0,
    size: ChatMessageMaxItemsPerPage,
    totalPages: 0,
    messages: [],
    messageIdMap: {}
  },
  prevPage: () => {},
  nextPage: () => {}
})

type Props = {
  referenceId: string,
  category: Enum_Chat_Category,
  children?: React.ReactNode
}

const ChatProvider: FC<Props> = ({
  referenceId,
  category,
  children
}) => {
  const [chat, setChat] = useState<ChatStateType>({
    referenceId,
    category,
    page: 0,
    size: ChatMessageMaxItemsPerPage,
    totalPages: 0,
    messages: [],
    messageIdMap: {}
  })
  const [getChat, { data, isLoading, isSuccess, isFetching }] = useLazyGetMessagingSessionQuery()
  /*
  const { data, isLoading, isSuccess, isFetching } = useGetMessagingSessionQuery({
    referenceId,
    category,
    page: chat.page,
    size: chat.size
  })
  */

  const prevPage = () => {
    if (chat.page < (chat.totalPages - 1)) {
      setChat({
        ...chat,
        page: chat.page + 1,
        size: ChatMessageMaxItemsPerPage
      })
    }
  }

  const nextPage = () => {
    if (chat.page > 0) {
      setChat({
        ...chat,
        page: chat.page - 1,
        size: ChatMessageMaxItemsPerPage
      })
    }
  }

  useEffect(() => {
    if (isSuccess && data) {
      const updatedChat = {
        ...chat
      }
      let messages = [...updatedChat.messages]
      
      for (let m of data.messages as Array<ChatMessages>) {
        if (!(m.id in updatedChat.messageIdMap)) {
          updatedChat.messageIdMap[m.id] = m
          messages.push(m)
        }
      }

      messages.sort((a:ChatMessages, b: ChatMessages) => {
        if (a.created_at > b.created_at) return 1
        if (a.created_at < b.created_at) return -1
        return 0
      })

      updatedChat.messages = messages
      updatedChat.totalPages = data.totalPages
      console.log(updatedChat)
      setChat({
        ...updatedChat
      })
    }
  }, [data, isSuccess])

  useEffect(() => {
    const socket = io((process.env.REACT_APP_WS as string), {
      withCredentials: true,
      transports : ['websocket']
    });
    const jwt = localStorage.getItem("access_token");
    
    socket.emit("join-chat", { 
      referenceId,
      category,
      jwt: jwt 
    });

    socket.on("message", (res) => {
      if (res === "success") return;
      // trigger a get
      console.log(res)
      getChat({
        referenceId: chat.referenceId,
        category: chat.category,
        page: chat.page,
        size: chat.size
      })
    });

    getChat({
      referenceId: chat.referenceId,
      category: chat.category,
      page: chat.page,
      size: chat.size
    })

    return () => {
      socket.disconnect();
    };
  }, [])

  return (
    <ChatContext.Provider value={{
      chat,
      prevPage,
      nextPage
    }}>
      {children}
    </ChatContext.Provider>
  )  
}

export default ChatProvider