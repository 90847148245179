import Button from "app/shared/button";
import LongArrowWhiteRight from 'app/shared/assets/svgs/long-arrow-right-white.svg';
import { useDispatch } from "react-redux";
import { setServicePackage } from 'features/cart/cartSlice';
import CircleOTickTangerine from './assets/svgs/circle-o-tick-tangerine.svg'
import CircleOTickAzure from './assets/svgs/circle-o-tick-azure.svg'
import CircleOTickPurple from './assets/svgs/circle-o-tick-purple.svg'
import { useHistory } from "react-router-dom";
import './assets/styles/service-card.scss';

interface Props {
  slug: string,
  icon: string;
  color: string,
  matchCount: number,
  pricePerMonth: number,
  pricePerYear: number,
  name: string,
  features: string[];
  selected: boolean;
  currency: string
}

const allFeatures = [
  {
    key: 'business-need-validate',
    text: 'Business need validation'
  },
  {
    key: 'business-case-define',
    text: 'Business case definition'
  },
  {
    key: 'catalyst-support-pre-convo',
    text: 'Catalyst support pre-conversation'
  },
  {
    key: 'unlimimted-matches',
    text: 'Unlimited Matches'
  },
  {
    key: 'x-matches',
    text: (args: Record<string, string | number>): React.ReactNode => <span>Up to <span className={`bold-type color-${args['color']}`}>{`${args['count']} conversations`}</span> from matched</span>
  },
  {
    key: 'access-collab-dashboard',
    text: 'Access to in collaboration dashboard'
  },
  {
    key: 'support-close-deal',
    text: 'Support to close the deal'
  },
  {
    key: 'dedicated-alkelio-catalyst',
    text: 'Dedicated ALKELIO Catalyst'
  },
  {
    key: 'impl-planning-roadmap',
    text: 'Planning & roadmap for implementation'
  },
  {
    key: '1-pilot',
    text: (args: Record<string, string | number>): React.ReactNode => <><span className={`bold-type color-${args['color']}`}>1 pilot</span> included</>
  },
  {
    key: 'solution-impl-coord',
    text: 'Solution implementation coordination'
  },
  {
    key: 'personalised-report',
    text: 'Personalised reporting of collaboration'
  }
]

interface FeatureProps {
  enabled: boolean;
  text: React.ReactNode | string;
  color: string;
}

const Feature = ({
  enabled,
  text,
  color,
}: FeatureProps) => {
  let icon = '';

  switch(color) {
    case 'tangerine': 
      icon = CircleOTickTangerine;
      break;
    case 'azure':
      icon = CircleOTickAzure;
      break;
    case 'purple':
      icon = CircleOTickPurple;
      break;
  }

  return (
    <div className={`mb-2 mt-2 d-flex flex-row txt-regular ${enabled ? '': 'filter-grayscale-100-opacity-20'}`}>
      <img src={icon} alt="" className="me-2 me-md-1" />
      <div>{text}</div>
    </div>
  )
}

export const ServiceCard = ({
  slug = '',
  icon,
  color,
  currency,
  matchCount = 0,
  pricePerMonth = 0,
  pricePerYear = 0,
  name = '',
  features,
  selected
}: Props) => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  return (
    <div className={`p-4 p-md-5 m-2 service-card-corporate box-white-rounded-shadow d-flex flex-column align-items-center justify-content-start ${selected ? `border-${color}`:`hover-border-${color}`}`}>
      <div className="d-flex flex-row align-items-center mb-5">
        <img src={icon} alt={name} className="me-2" />
        <h4 className="text-uppercase">{name}</h4>
      </div>
      <div className="d-flex flex-column mt-3">
        {allFeatures.map(({key, text}, i) => 
          <Feature enabled={features.includes(key)} text={typeof text === 'function' ? text({'count': matchCount, 'color': color}) : text} color={color} />
        )}
      </div>
      <div className="price-action d-flex flex-column flex-md-row align-items-center justify-content-between mt-4">
        <div className={`flex-row d-flex color-nightsky justify-content-start align-items-end me-3 mb-3 mb-md-0`}>
          <div className={`bold-type font-size-18 letter-spacing-n-2 me-1 line-height-100 color-${color}`}>${new Intl.NumberFormat().format(pricePerMonth)}</div>
          <div className={`bold-type font-size-12 letter-spacing-n-2 line-height-100 color-${color}`}>{currency}/MONTH</div>
        </div>
        <Button className={`color-bg-${color} color-white border-0`} onClick={() => {
          dispatch(setServicePackage({
            slug: slug,
            price: pricePerYear,
            qty: 1,
            name: name,
            currency
          }))
          push('/app/corporate/alkelio-services')
        }}>
          Select
          <img src={LongArrowWhiteRight} className="ms-2" alt="select" />
        </Button> 
      </div>
      
      <div className="text-left w-100 color-text font-size-12 mt-1 letter-spacing-n-2 line-height-140">
        (${new Intl.NumberFormat().format(pricePerYear)} per annum)
      </div>
    </div>
  )
}