import StatusBadge from 'app/shared/status-badge';
import { CompletionStatuses } from 'app-constants'

type Props = {
  isCompleted: boolean;
}

export const CompletionBadge = ({
  isCompleted
}: Props) => {
  const { color, info, label} = isCompleted ? CompletionStatuses.complete : CompletionStatuses.incomplete;
  return <StatusBadge color={color as ('in-progress' | 'success' | undefined)} toolTip={info}>{label}</StatusBadge>
}
