import LongArrowAzureRight from 'app/shared/assets/svgs/long-arrow-right-azure.svg';
import ThinkingPersonOutline from 'app/shared/assets/svgs/thinking-person-outline.svg';
import { useHistory } from 'react-router-dom'

export const NotReadyCommit = () => {
  const { goBack } = useHistory();
  
  return (
    <div className="row">
      <div className="pt-md-5 col">
        <div className="flex-row d-flex justify-content-center align-items-end">
          <img src={ThinkingPersonOutline} alt="" className="pe-4 align-self-start" />
          <div className="d-flex flex-column align-items-start">
            <div className="mb-2 color-nightsky font-size-14 letter-spacing-n-2 bold-type">Not ready to commit just yet?</div>
            <div className="light-type color-text font-size-14 line-height-140 letter-spacing-n-2">
            It’s ok you can continue on your free plan - it just <br/>means you can’t start conversations yourself. 
            </div>
            <div className="flex-row mt-2 d-flex d-none d-md-block">
              <div className="cursor-pointer me-2 color-azure font-size-14 letter-spacing-n-2 txt-semibold" onClick={() => {
                goBack();
              }} >
                Back to browsing Matches
              </div>
              <img src={LongArrowAzureRight} alt="" />
            </div>
          </div>
        </div>
      
        <div className="flex-row mt-2 d-flex d-block d-md-none">
          <div className="cursor-pointer me-2 color-azure font-size-14 letter-spacing-n-2 txt-semibold" onClick={() => {
            goBack();
          }} >
            Back to browsing Matches
          </div>
          <img src={LongArrowAzureRight} alt="" />
        </div>
      </div>
    </div>
  );
}