import './assets/styles/assigned-catalyst.scss';
import Tile from '../tile';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import { useSelector } from 'react-redux';
import useGetCatalyst from './get-catalyst.hook';
import { useEffect } from 'react';
import PhotoDefault from './assets/img/alkelio-catalyst-default.jpg';

const AssignedCatalyst = () => {
  const { profile_type, organisation_id } = useSelector(selectHeaderProfile);
  const { getCatalyst, result } = useGetCatalyst(profile_type);

  useEffect(() => {
    if (organisation_id !== null) {
      getCatalyst(organisation_id); 
    }
  }, [organisation_id])

  const photo = result?.data?.[0] ? result?.data?.[0]?.users_permissions_user?.person_profile?.photo?.formats.thumbnail.url : PhotoDefault;
  const name = result?.data?.[0] ? result?.data?.[0]?.users_permissions_user?.person_profile?.first_name : "Emilie";
  const email = result?.data?.[0] ? result?.data?.[0]?.users_permissions_user?.email : "contact@alkelio.com";
  
  return (
    <Tile>
      <div className="assigned-catalyst">
        <div className="image">
          <img src={result?.isSuccess ? photo : null} alt="" />
        </div>
        {result?.isSuccess &&
        <div className="body">
          <h3>{name} is your ALKELIO Catalyst</h3>
          <div className="content">ALKELIO Catalysts are here to support you  to ensure the best result along the whole process.</div>
          <a className="link" href={`mailto:${email}`}>Get in touch</a>
        </div>
        }
      </div>
    </Tile>
  )
}

export default AssignedCatalyst;
