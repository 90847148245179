
import StatusBadge from 'app/shared/status-badge';
import { CompletionStatuses } from 'app-constants';

interface CompletionStatusProps {
  watchAll: any
}

export const completionRate = (obj: any) => {
  let completedCount = 0;
  let count = 0;
  for(let k in obj) {
    let isCount = false;

    if ([
      'name',
      'hero_image',
      'description_project',
      'business_challange_description',
      'impact_level',
      'impact_area',
      'goals',
      'technology_types',
      'industries',
      'business_use_cases',
      'description_how_to_resolve_problem',
      'description_customer',
      'description_successful_outcome',
      'description_similiar_solutions',
      'description_risk_challenges',
      'project_budget',
      'projected_startdate',
      'projected_complete_date',
      'country_implementation'
    ].includes(k)) {
      isCount = true;
    }

    if (isCount) {
      count++;
      if (obj[k] !== undefined 
        && obj[k] !== null 
        && (String(obj[k]) !== '' || (Array.isArray(obj[k]) && (obj[k] as Array<any>).length > 0 ))) {
        completedCount++;
      }
    }

  }
  return completedCount / count;
}

const CompletionStatus = ({ watchAll } : CompletionStatusProps ) => {
  const rate = completionRate(watchAll)

  const { color, info, label } = rate === 1 ? CompletionStatuses.complete : CompletionStatuses.incomplete;

  return (
    <StatusBadge size="large" color={color} toolTip={info}>{label}</StatusBadge>
  )
}

export default CompletionStatus;