import { OptionItem } from 'shared/forms/select-multiple';
import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const dictionaryToOptions = (obj: any) => {
  let list: Array<OptionItem> = []
  for(let k in obj) {
    list.push({
      label: obj[k],
      value: k
    })
  }
  return list;
}

export const CountryNames = dictionaryToOptions(countries.getNames("en"))

export const Countries = countries;