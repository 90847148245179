import BaseInput from './base-input';
import React, { useState } from 'react';
import './assets/styles/input-textarea-counter.scss';

interface Props extends BaseInput {
  maxLength?: number;
}

const InputTextAreaCounter = React.forwardRef(({
  maxLength = 1000, 
  defaultValue,
  ...rest
}: Props, 
ref:React.Ref<HTMLDivElement>) => {
  const [count, setCount] = useState((defaultValue?? '').length)
  return (
    <div className="input-textarea-counter" ref={ref}>
      <textarea maxLength={maxLength}
        defaultValue={defaultValue} 
        onKeyDown={(evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
          if (evt.currentTarget.value.length <= maxLength) {
            setCount(evt.currentTarget.value.length);
          } else {
            evt.preventDefault();
            return false;
          }
        }}
        {...rest} 
      />
      <div className="counter">{maxLength - count} characters left</div>
    </div>
  )
});

export default InputTextAreaCounter;