import LogoHorizontal from './assets/svgs/logo-horizontal.svg';
import LogoHorizontalWhite from './assets/svgs/logo-horizontal-white.svg';
import NavBarsIcon from './assets/svgs/nav-bars.svg';
import CrossWhiteIcon from './assets/svgs/cross-white.svg';
import './assets/styles/header.scss';
import { open } from 'features/modals/modalSlice';
import { setIsFromHomePage } from 'features/signup/signupSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom'
import useOnboarding from 'shared/sign-up-v2/hooks/onboarding.hook';

interface HeaderProps {
}

const Header = (props: HeaderProps) => {
  const { resetOnboardingPayload } = useOnboarding()
  const { push } = useHistory()
  const dispatch = useDispatch()
  
  const closeMenu = () => {
    setMenuVisible(false);
  }

  const handleLoginClick = () => {
    closeMenu();
    push('/login');
  }
  const handleSignUpClick = () => {
    closeMenu();
    resetOnboardingPayload();
    dispatch(open('sign-up'));
    dispatch(setIsFromHomePage(false));
  }

  const [menuVisible, setMenuVisible] = useState(false);

  const navIconClick = () => {
    setMenuVisible(!menuVisible);
  }

  return (
    <>
      <div className={`page-header-wrapper ${menuVisible ? 'as-menu':''}`}>
        <div className={`page-header`}>
          <Link className="home-logo" to="/"><img src={menuVisible ? LogoHorizontalWhite : LogoHorizontal} alt="Alkelio" /></Link>
          <div className="nav" id="topNav">
            <a className="mobile-menu-button" onClick={navIconClick}>
              <img src={menuVisible ? CrossWhiteIcon : NavBarsIcon} alt="Menu" />
            </a>
            <div className="mobile-menu">
              <Link to="/#about" onClick={closeMenu}>About</Link>
              <Link to="/#services" onClick={closeMenu}>Services</Link>
              <Link to="/#why-choose-us" onClick={closeMenu}>Why Choose Us</Link>
              <Link to="/#contact" onClick={closeMenu}>Contact</Link>
            </div>
          </div>
          <div className={'login-controls'}>
            <div className="float-start">
              <button className="btn btn-secondary" type="button" onClick={handleLoginClick}>Login</button>
            </div>
            <div className="float-end">
              <button className="btn btn-primary" type="button" onClick={handleSignUpClick}>Sign Up</button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-header-wrapper-bg"></div>
    </>
  )
}

export default Header;