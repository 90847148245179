import HeartOutlineIcon from 'app/shared/assets/svgs/o-heart.png';
import TangerineHeartIcon from 'app/shared/assets/pngs/tangerine-heart.png';
import HeartOutlineCancelIcon from 'app/shared/assets/svgs/o-heart-cancel.svg';
import './assets/styles/button-favourite.scss';
import { Enum_Bookmark_Category } from 'generated/graphql';

interface ButtonFavouriteProps {
  onClick?: () => unknown;
  defaultValue?: boolean;
  mode?: Enum_Bookmark_Category | null
}

const ButtonFavourite = ({
  defaultValue = false, 
  mode,
  onClick
}: ButtonFavouriteProps) => {
  return (
    <button className={`button-favourite ${`mode-${mode}`} ${defaultValue ? 'selected': ''}`} 
      onClick={(evt) => {
        evt.stopPropagation();
        onClick && onClick()
      }}>
      <div className="icon">
        <img className="icon default" src={HeartOutlineIcon} alt="Not Favourite" />
        <img className="icon selected" src={TangerineHeartIcon} alt="Favourite" />
        <img className="icon rejected" src={HeartOutlineCancelIcon} alt="Rejected" />
      </div>
    </button>
  )
}

export default ButtonFavourite;