import StarYellowIcon from 'app/shared/assets/svgs/star-yellow.svg';
import './assets/styles/ratings.scss';

interface RatingsProps {
  rating: number;
  ratingCounts?: number;
}

const Ratings = ({
  rating,
  ratingCounts
}: RatingsProps) => {
  
  const max = 5;
  let list = [];
  for(let i = 0; i < max; i++) 
    list.push(i)

  return (
    <div className="ratings">
      <div className="stars">
        <div className="fore" style={{width: `${100 * rating / max}%` }}>
          {list.map((v, i) => <img key={i} src={StarYellowIcon} alt="" /> )}
        </div>
        <div className="back">
          {list.map((v, i) => <img key={i} src={StarYellowIcon} alt="" /> )}
        </div>
      </div>
      <div className="rate">{rating}</div>
      {ratingCounts ? <div className="count">({ratingCounts} ratings)</div> : null}
    </div>
  )
}

export default Ratings;