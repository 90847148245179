import { FileItem } from "hooks/file-uploads";
import Inputs from './inputs'
import { 
  EditConversationInput,
  EditComponentConversationFormFormScopeRequirementInput,
  Goals
} from 'generated/graphql'

export const FileItemArrayToIds = (fileItems?: FileItem[]) => {
  if (fileItems === undefined) return [];

  return fileItems.map(item => item.id);
}

const getTeamProfile = (teamProfile: {
  role: string;
  name: string;
  email: string;
  profile_picture: FileItem
}): {
  role: string;
  name: string;
  email: string;
  profile_picture: string;
} => ({
  role: teamProfile.role,
  name: teamProfile.name,
  email: teamProfile.email,
  profile_picture: teamProfile.profile_picture?.id
})

const getRequirement = (requirement: {
  requirement?: string;
  attachment?: Array<FileItem>
} ): EditComponentConversationFormFormScopeRequirementInput => ({
  requirement: requirement?.requirement?? null,
  attachment: FileItemArrayToIds(requirement?.attachment ?? [])
})

export const convertFormConversationToInput = (conversation: Inputs): EditConversationInput => {
  const {
    business_use_case,
    scope,
    proposal,
    agreement
  } = conversation
  const editConvoInput : EditConversationInput = {
    business_use_case: {
      business_challenge: business_use_case?.business_challenge,
      description_project: business_use_case?.description_project,
      goals: business_use_case?.goals?? [],
      how_to_resolve: business_use_case?.how_to_resolve, 
      customer: business_use_case?.customer,
      successful_outcome: business_use_case?.successful_outcome,
      other_solutions: business_use_case?.other_solutions,
      risks: business_use_case?.risks
    },
    scope: {
      timing_from: scope?.timing_from,
      timing_to: scope?.timing_to,
      team_startup: (scope?.team_startup?? []).map(getTeamProfile),
      team_corporate: (scope?.team_corporate?? []).map(getTeamProfile),
      budget_from: scope?.budget_from,
      budget_to: scope?.budget_to,
      budget_currency: scope?.budget_currency,
      technical_requirements_corporate: getRequirement(scope?.technical_requirements_corporate),
      technical_requirements_startup: getRequirement(scope?.technical_requirements_startup),
      legal_requirements_corporate: getRequirement(scope?.legal_requirements_corporate),
      legal_requirements_startup: getRequirement(scope?.legal_requirements_startup),
      other_requirements_corporate: getRequirement(scope?.other_requirements_corporate),
      other_requirements_startup: getRequirement(scope?.other_requirements_startup),
      kpis: scope?.kpis
    },
    proposal: {
      proposal: FileItemArrayToIds(proposal?.proposal),
      price: proposal?.price,
      currency: proposal?.currency,
      deliverables: proposal?.deliverables
    },
    agreement: {
      client_name: agreement?.client_name,
      client_company: agreement?.client_company,
      client_address: agreement?.client_address,
      client_agree: agreement?.client_agree,
      supplier_name: agreement?.supplier_name,
      supplier_company: agreement?.supplier_company,
      supplier_address: agreement?.supplier_address,
      supplier_agree: agreement?.supplier_agree,
      uploaded_contracts: FileItemArrayToIds(agreement?.uploaded_contracts)
    }
  }

  return editConvoInput
}