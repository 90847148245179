import React, { useEffect } from "react";
import "../assets/styles/conversation.scss";
import Grid from "@material-ui/core/Grid";
import { Box,Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Control, UseFormRegister, Controller, UseFormSetValue, UseFormWatch } from "react-hook-form";
import InputFiles from "shared/forms/input-files";
import Inputs from "./inputs";
import SelectOne from "shared/forms/select-one";
import CurrencyCode from "currency-codes";
import WhiteXIcon from 'app/shared/assets/svgs/WhiteX.svg';
import DocumentBlueIcon from 'app/shared/assets/svgs/document-blue.svg';
import DownloadIcon from 'app/shared/assets/svgs/download-blue.svg';
import PreviewIcon from 'app/shared/assets/svgs/preview-blue.svg';

import CircleOCrossIcon from 'app/shared/assets/svgs/circle-o-cross.svg';
import InputMoney from 'shared/forms/input-money';

import { useUpdateConversationMutation } from "services/graphql/conversation";

type ProposalProps = {
  view: string;
  name: string;
  conversationId: any;
  proposal: any;
  register: UseFormRegister<Inputs>;
  setFields: UseFormSetValue<Inputs>;
  control: Control<Inputs>;
  watch: UseFormWatch<Inputs>;
};

const Proposal = React.forwardRef(
  (
    { view, name, proposal, register, setFields, control, conversationId, watch }: ProposalProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const watchProposals = watch('proposal')

    const conversationID = conversationId;
    const Currencies = CurrencyCode.codes();
    const proposalDocs = watchProposals?.proposal ?? [];
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [fileUrl, setFileUrl] = React.useState('');
    const [fileCounter, setFileCounter] = React.useState(0);
    
    const [docs, setDocs] = React.useState(watchProposals?.proposal ?? []);
    const [updateConversation] = useUpdateConversationMutation();

    const checkForPdf = (text:string) =>{
      if(text ===undefined){
        return false;
      }
      let result = text.substring(text.length - 3);
      if(result ==='pdf'){
        // console.log("Is Pdf? ",result);
        return true;
      }
      return false;
    }

    const numberWithCommas = (x :number) => {
      if(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
    }

    /*
    const handleUpload = async (d: any) => {
      console.log("proposal docs changed", d);
      let newDocs = [...docs];
      // console.log("old data", newDocs);
      // cannot have the same id inserted
      newDocs.push(d[fileCounter]);
      
      

    

      let oldIds = [];
      for (var i = 0; i < newDocs.length; i++) {
        var cur = newDocs[i];
        if (cur !== undefined && cur.id !== undefined) {
          oldIds.push(cur.id);
        }
      }
      if (d.length > 0) {
        // let concat = [...proposalDocs,d[0].id];
        setDocs(newDocs);
        setFields("proposal.proposal", newDocs);
        // console.log("after upload", concat);
        const updatedConversation = {
          proposal: {
            proposal: oldIds,
          },
        };
        const id = conversationID as string;
        const result = await await updateConversation({
          id,
          conversation: updatedConversation,
        }).unwrap();
        let nc= fileCounter +1
        setFileCounter(nc);
        // console.log("The result", result);
      }
    }
    */
    
    const removeFile = async (docId: string) => {
      // console.log("removing upload with id", docId)

      let newDocs = [...docs];
      let removed = newDocs.filter(el => el.id !== docId);

      // console.log("new array", removed)
      //remove item
      setDocs(removed);
      setFields("proposal.proposal", removed);
      let newIds = [];
      for (var i = 0; i < removed.length; i++) {
        var cur = removed[i];
        if (cur !== undefined && cur.id !== undefined) {
          newIds.push(cur.id);
        }
      }
      const updatedConversation = {
        proposal: {
          proposal: newIds,
        },
      };
      const id = conversationID as string;
      const result = await await updateConversation({
        id,
        conversation: updatedConversation,
      }).unwrap();
      // console.log("The result", result);
    }
    const displayModal = (url:string) =>{
      // console.log("Displayingg modal",url);
      setFileUrl(url);
      handleOpen();
    }
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    const handleOpen = () => {
      setIsModalVisible(true);
    };
    React.useEffect(() => {
      console.log("proposal attachments: ", proposal);
      // console.log("Docs:", proposalDocs);
      if (proposal !== null && proposalDocs.length > 0) {
        setDocs(proposalDocs);
        setFields("proposal.proposal", proposalDocs);
      }
    }, [proposal]);

    console.log(watchProposals?.proposal)
    return (
      <>
      <Modal 
      open={isModalVisible} onClose={handleCancel}>
        <div className="pdfModal">
          <header style={{background:"#252525"}}>
        <button type="button" onClick={handleCancel}> <img src={WhiteXIcon} alt={"X"} /></button>                               
          </header>
        <iframe title="odt" src={fileUrl}></iframe>
        </div></Modal>
        {view === "startup" ? (
          <div>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="sectionProposal">

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Grid item md={6}>
                    <label className="subHeaderLabel">
                      <b>{name}'s Proposal</b>
                    </label>
                    
                    {(watchProposals?.proposal ?? []).map((doc: any) => (
                        <div className="links">
                          <div className="uploadedDocs">
                            { doc.id && doc.name.length >0?
                            <div key={doc.id} className="file">
                              
                              {checkForPdf(doc?.name) && false ? <> <img src={DocumentBlueIcon} alt={doc.name} />
                                <button type="button" className="link-extra"
                                  style={{ paddingLeft: '0px', marginLeft: '0px' }} onClick={() => {
                                    displayModal(doc.url)
                                  }} >
                                  <div className="upname">
                                    {doc.name}
                                  </div>
                                </button></> :
                                <> <img src={DocumentBlueIcon} alt={doc.name} />
                                  <a href={doc?.url} target={"_blank"} rel="noreferrer" >

                                    <div className="upname">
                                      {doc.name}
                                    </div>
                                  </a></>}

                              <button type="button" className="button-reset" onClick={() => {
                                removeFile(doc.id)
                              }}>
                                <img src={CircleOCrossIcon} alt="remove" />
                              </button>
                            </div>
                            :<> </>}

                          </div>
                        </div>

                    ))}
                  </Grid>

                  <Grid item md={6}>

                    <label id="upDoc">
                      UPLOAD DOCUMENTS
                    </label>

                    <div className="dropZone">
                      <Controller
                        name="proposal.proposal"
                        control={control}
                        render={({ field }) => (
                          <InputFiles maxFileCount={10} {...field} />
                        )}
                      />
                    </div>

                  </Grid>

                </Grid>
              </div>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
            >
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius={10}
                  className="sideBySideBoard"
                >
                  <div className="deliverables">
                    <label className="subHeaderLabel">
                      <b>Deliverables</b>
                    </label>

                    <Box
                      border={1}
                      borderColor="grey.300"
                      borderRadius={10}
                      className="innerBoard"
                    >
                      <div className="startDeliverables"  >
                        <TextField
                          multiline
                          defaultValue={
                            (proposal && proposal?.deliverables) || ""
                          }
                          fullWidth
                          InputProps={{ disableUnderline: true }}
                          {...register("proposal.deliverables")}
                        ></TextField>
                      </div>
                    </Box>
                  </div>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius={10}
                  className="mainBoard"
                >
                  <div className="deliverables">
                    <label className="subHeaderLabel">
                      <b>Price</b>
                    </label>

                    <Box
                      border={1}
                      borderColor="grey.300"
                      borderRadius={10}
                      className="innerBoard"
                    >
                      <div className="input-group seamless">
                        <Controller
                          name={"proposal.price"}
                          control={control}
                          defaultValue={proposal?.price}
                          rules={{
                            required: false
                          }}
                          render={({ field }) => <InputMoney
                            style={{
                              flex: "1 1 auto",
                              width: "0px",
                              paddingRight: '0px',
                              minWidth: "100px",
                            }}
                            className="zero-border"
                            {...field}
                          />}
                        />

                        <Controller
                          name={"proposal.currency"}
                          control={control}
                          defaultValue={proposal?.currency || ""}
                          render={({ field }) => (
                            <SelectOne
                              options={Currencies}
                              variant="zero-border"
                              style={{ flex: "0 0 80px" }}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </Box>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="sectionProposal">
                <label className="subHeaderLabel">
                  <b>{name}'s Proposal</b>
                </label>

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Grid item md={11}>

                    {proposalDocs &&
                      proposalDocs.map((doc: any) => (
                        <div key={doc.id} className="mapproposal">
                          {checkForPdf(doc?.name) && false? <> 
                              <button type="button" className="link-extra"
                              style={{paddingLeft:'0px',marginLeft:'0px'}} onClick={() =>{
                                displayModal(doc.url)
                              }} >
                                <div className="upname" style={{color:"#6c6c6c"}}>
                                  {doc.name}
                                </div>
                              </button>
                              <div className="sameLine">
                                  <bdo onClick={()=>{displayModal(doc.url)}}
                                    style={{ marginLeft: '8px', color: '#007fff', fontSize: '12px' }} >
                                    <img src={PreviewIcon} alt={doc.name}
                                      style={{ marginRight: '8px', height: '14px', width: '14px' }} />
                                    PREVIEW
                                  </bdo>
                                  <a href={doc?.url} target={"_blank"} rel="noopener noreferrer"
                                    style={{ marginLeft: '8px' }} download >
                                    <img style={{ height: '16px',width:'16px' }} src={DownloadIcon} alt={doc.name} />
                                  </a>
                                </div>
                              </>:
                              <>
                                <a className="link-extra" href={doc?.url} target={"_blank"} rel="noreferrer" >
                                  <div className="upname" style={{color:"#6c6c6c"}}>
                                    {doc.name}
                                  </div>
                                </a>
                                <div className="sameLine">
                                  <a href={doc?.url} target={"_blank"} rel="noreferrer"
                                    style={{ marginLeft: '8px', color: '#007fff', fontSize: '12px' }} >
                                    <img src={PreviewIcon} alt={doc.name}
                                      style={{ marginRight: '8px', height: '14px', width: '14px' }} />
                                    PREVIEW
                                  </a>
                                  <a href={doc?.url} target={"_blank"} rel="noopener noreferrer"
                                    style={{ marginLeft: '8px' }} download >
                                    <img style={{ height: '16px', width: '16px' }} src={DownloadIcon} alt={doc.name} />
                                  </a>
                                </div>
                              </>}
                              

                        </div>
                      ))}
                  </Grid>
                  <Grid item md={1}>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
            >
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius={10}
                  className="sideBySideBoard"
                >
                  <div className="deliverables">
                    <label className="subHeaderLabel">
                      <b>Deliverables</b>
                    </label>
                    
                      <div >
                        {proposal && proposal?.deliverables ? (
                           <TextField
                           multiline
                           rows={2}
                           disabled
                           defaultValue={
                             (proposal?.deliverables) || "None"
                           }
                           fullWidth
                           InputProps={{ disableUnderline: true }}
                         ></TextField>
                        ) : (
                          <></>
                        )}
                      </div>
                    
                  </div>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius={10}
                  className="mainBoard"
                >
                  <div className="deliverables">
                    <label className="subHeaderLabel">
                      <b>Price</b>
                    </label>
                      <div className="Brange" >
                        {proposal && proposal?.price ? (
                          <span>
                            {numberWithCommas(proposal?.price)} 
                          </span>
                        ) : (
                          <p >
                            No Price yet
                          </p>
                        )}
                        {proposal && proposal?.currency ? (
                          <span style={{color:'#6c6c6c',fontSize:'12px'}}>
                            &nbsp; {proposal?.currency}
                          </span>
                        ) : (
                          <p >
                            No Currency yet
                          </p>
                        )}
                      </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }
);

export default Proposal;
