import React, { useState } from 'react'
import './assets/styles/chat-component.scss'
import { Enum_Chat_Category } from 'generated/graphql'
import ChatIcon from './assets/icons/chat.svg';
import CloseIcon from './assets/icons/close.svg';
import ChatMessagesList from './chat.messages';
import ChatSendInput from './chat.send-input';
import ChatProvider from './chat.provider';

export type ChatProps = {
  referenceId: string;
  category: Enum_Chat_Category
}

const Chat = ({
  referenceId,
  category,
  active,
  onClose
}: ChatProps & {
  active: boolean,
  onClose: () => void
}) => {
  
  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <div className="chat-component">
      {active ?
      <div className="chat-component__wrapper">
        <div className="chat-component__header">
          <img src={ChatIcon} alt="" />
          <span>Chat</span>
          <img src={CloseIcon} alt="" onClick={handleClose} />
        </div>
        <div className="chat-component__body">
          <div className="chat-component__body__conversation">
            <ChatProvider referenceId={referenceId} category={category}>
              <ChatMessagesList />
              <ChatSendInput />
            </ChatProvider>
          </div>
        </div>
      </div>: null}
    </div>
  )
}

export default Chat