import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Home from './home';
import Jobs from './jobs';
import TermsConditions from './terms-conditions';
import { Privacy } from './privacy';
import Application from './app/application';
import { selectCurrentToken, selectCurrentUser, setToken } from 'features/auth/authSlice';
import Session from './session';
import Logout from './app/logout';
import ToastsNotifications from 'shared/toast';
import { isNotNullUndefined } from 'shared/utilities';

const useAuth = () => {
  const token = useSelector(selectCurrentToken)
  return useMemo(() => ({token}), [token])
}

const PrivateRoute = ({children, ...rest}: any) => {
  const { token } = useAuth();
  const session = Session.get()
  const isLoggedIn = token || session
  
  return (
    <Route {...rest}
      render={({location}) => {
        return (
          isLoggedIn ? (
              children
          ) : (
            <Redirect 
              to={{
                pathname: "/login",
                state: { from: location }
              }}
              />
          )
        )
      }}
    />
  )
}

const App = () => {
  // const dispatch = useDispatch()
  // const token = useSelector(selectCurrentToken)
  const token = Session.get()
  /*
  if  (token === null && isNotNullUndefined(Session.get())) {
    dispatch(setToken(Session.get()))
  }
  */

  return (
    <>
      <Router>
        {false && token ? <Redirect to="/app"/> : null}
        <Switch>
          <Route exact path={[
              '/', 
              '/login', 
              '/forgot-password', 
              '/reset-password',
              '/adhoc-service-corporate', 
              '/adhoc-service-startup',
              '/contact'
              ]}>
            <Home />
          </Route>
          <Route exact path="/jobs">
            <Jobs />
          </Route>
          <Route exact path="/terms-conditions">
            <TermsConditions />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          
          <Route exact path="/reset-password/:code">
            <Home />
          </Route>
          <PrivateRoute path="/app/:entityType?">
            <Application />
          </PrivateRoute>
          <Route path="/logout">
            <Logout/>
          </Route>
        </Switch>
        <ToastsNotifications />
      </Router>
    </>
  );
}

export default App;
