import './assets/styles/matched-technology-solution-card.scss';
import Card from 'app/shared/card';
import Button from 'app/shared/button';
import ButtonFavourite from 'app/shared/button-favourite';
import Config from 'config';
import { Bookmark, Enum_Bookmark_Category, Enum_Bookmark_Type } from 'generated/graphql';
import {
  useSetBookmarkMutation,
  useUnsetBookmarkMutation
} from 'services/graphql/bookmarks'
import MatchDial from 'app/shared/match-dial';
import MapPinIcon from 'app/shared/assets/svgs/map-pin.svg';
import UserIcon from 'app/shared/assets/svgs/user.svg';
import IconLabel from 'app/shared/icon-label';
import { Countries } from 'shared/countries';
import { isNotEmpty } from 'shared/utilities';

type TechnologySolutionSummary = {
  id: string;
  technology_solution_id: string;
  hero_image_url: string;
  name: string;
  description: string;
  country: string;
  maturity_level: string;
  match_rating: number | null;
}

type MatchedTechnologySolutionCardProps = {
  item: TechnologySolutionSummary;
  bookmarks?: Array<Bookmark> | undefined;
  isBookmark?: boolean;
  bookmarkId?: string;
  bookmerkMode?: Enum_Bookmark_Category | null;
  profileId: string;
  onView: (id:string) => unknown;
}

const MatchedTechnologySolutionCard = ({
  item,
  bookmarks,
  isBookmark,
  bookmarkId,
  bookmerkMode = Enum_Bookmark_Category.Save,
  profileId,
  onView
}: MatchedTechnologySolutionCardProps ) => {
  
  const [ setBookMark ] = useSetBookmarkMutation()
  const [ unsetBookmark ] = useUnsetBookmarkMutation()

  const isBookmarked = (techRequestId: string, bookmarks: Array<Bookmark> ) => {
    const matched = bookmarks.filter(item => 
      (item.type !== undefined && item.type  === Enum_Bookmark_Type.TechnologySolution) 
      && (item.category !== undefined && item.category === Enum_Bookmark_Category.Save))
      .find((item) => item.item_id === Number(techRequestId))

    return matched !== undefined;
  }

  const getBookmarkId = (techRequestId: string, bookmarks: Array<Bookmark> ) => {
    const matched = bookmarks.filter(item => 
      (item.type !== undefined && item.type  === Enum_Bookmark_Type.TechnologySolution) 
      && (item.category !== undefined && item.category === Enum_Bookmark_Category.Save))
      .find((item) => item.item_id === Number(techRequestId))

    return matched && matched.id;
  }

  const imageOverlayFragment = <div className="match-tech-solution-card-image-overlay">
    <div className="match-percent">
      {item.match_rating && <MatchDial percent={item.match_rating || 0} />}
    </div>
    <div className="favourite">
      <ButtonFavourite 
        mode={bookmerkMode}
        defaultValue={isBookmark ? isBookmark : (bookmarks !== undefined ? isBookmarked(item?.technology_solution_id || '', bookmarks) : false)} 
        onClick={() => {
          const bookmarked = isBookmark ? isBookmark : bookmarks && isBookmarked(item?.id || '', bookmarks);
          const bookmarkedId = bookmarkId ? bookmarkId : bookmarks && getBookmarkId(item?.id || '', bookmarks);
          if (bookmarked) {
            bookmarkedId && unsetBookmark(bookmarkedId)
          } else {
            setBookMark({
              type: Enum_Bookmark_Type.TechnologySolution,
              item_id: Number(item.technology_solution_id),
              category: Enum_Bookmark_Category.Save,
              person_profile: profileId
            })
          }
        }} />
    </div>
  </div>

  const cardBodyFrament = <div className="cursor-pointer" onClick={() => {
    onView(item.id)
  }}>
    <h5>{item.name}</h5>
    <p>{item.description}</p>
  </div>

  const cardFooterFraement = <>
    {isNotEmpty(item.country) || isNotEmpty(item.maturity_level) ?
    <div className="tech-solution-match-result-card-footer">
      <div className="info">
        {isNotEmpty(item.country) && <IconLabel className="country" icon={MapPinIcon}>{Countries.getName(item.country || '', "en")}</IconLabel>}
        {isNotEmpty(item.maturity_level) && <IconLabel icon={UserIcon}>{item.maturity_level || ""}</IconLabel>}
      </div>
      <Button variant="primary" className="action" onClick={() => {
          onView(item.id)
        }}>View</Button>
    </div>
    : null}
    </>

  return (
    <Card 
      onClick={() => {
        onView(item.id)
      }}
      className="match-tech-solution-card"
      variant="vertical"
      imageSrc={item?.hero_image_url ?? ''}
      imageOverlay={imageOverlayFragment}
      imageOverlayPosition="none"
      body={cardBodyFrament}
      footer={cardFooterFraement}
    />
  )

}

export default MatchedTechnologySolutionCard;