import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  ServiceCollaboration,
  UpdateMyServiceCollaborationInput 
} from 'generated/graphql'

export const serviceCollaborationApi = createApi({
  reducerPath: "serviceCollaborationApi",
  tagTypes: ['service-collaborations', 'service-collaboration'],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getServiceCollaborations: builder.query<Array<ServiceCollaboration>, void>({
      query: () => ({
        document: gql`
          query {
            getMyServiceCollaborations {
              id
              service {
                title
              }
              client {
                role {
                  type
                }
                username
                person_profile {
                  last_name
                  first_name
                  role
                  photo {
                    url
                  }
                }
              }
            }
          }
        `
      }),
      transformResponse: (response: any) => response.getMyServiceCollaborations,
      providesTags: (result, error, id) => [{
        type: 'service-collaborations'
      }]
    }),
    getOneServiceCollaboration: builder.query<ServiceCollaboration, string>({
      query: (id) => ({
        document: gql`
          query getOneServiceCollaboration($id: ID!) {
            serviceCollaboration(id: $id) {
              id
              service {
                title
              }
              catalyst {
                role {
                  type
                }
                email
                person_profile {
                  id
                  last_name
                  first_name
                  photo {
                    url
                  }
                }
              }
              client {
                role {
                  type
                }
                email
                person_profile {
                  id
                  last_name
                  first_name
                  role
                  photo {
                    url
                  }
                }
              }
              startup_profile {
                logo {
                  url
                }
              }
              corporate_profile {
                logo {
                  url
                }
              }
              structure {
                content
              }
              scope {
                alkelio_team {
                  profile_image {
                    id
                    url
                  }
                  name
                  email
                  role
                }
                client_team {
                  profile_image {
                    id
                    url
                  }
                  name
                  email
                  role
                }
                project_timing {
                  start
                  end
                }
                calender_project {
                  id
                  url
                  name
                }
                info {
                  id
                  question_from_catalyst
                  attachments {
                    id,
                    url,
                    name
                  }
                  response
                }
              }
              deliverables {
                comments
                proposals {
                  id
                  url
                  name
                }
              }
              feedback {
                feedback
                rating
                need_rework
              }
              
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => response.serviceCollaboration,
      providesTags: (result, error, id) => [{
        type: 'service-collaboration',
        id: id
      }]
    }),
    updateMyServiceCollaboration: builder.mutation<void, UpdateMyServiceCollaborationInput>({
      query: (input) => ({
        document: gql`
          mutation updateServiceCollaboration($input: UpdateMyServiceCollaborationInput!) {
            updateMyServiceCollaboration(input: $input)
          }
        `,
        variables: {
          input
        }
      }),
      invalidatesTags: (result, error, input) => [{
        type: 'service-collaboration',
        id: input.id
      }]
    })
  })
})

export const {
  useGetServiceCollaborationsQuery,
  useGetOneServiceCollaborationQuery,
  useUpdateMyServiceCollaborationMutation
} = serviceCollaborationApi