import useSteps from "../hooks/steps.hook";
import useSignUpType from "../hooks/sign-up.hook";
import { SignUpTypes } from '../types';
import { Link } from 'react-router-dom';
import ArrowGreenSVG from './assets/svgs/arrow-green-right.svg';
import './assets/styles/action-bar.scss'

type ActionBarProps = {
  onPrev?: (fn: () => unknown) => unknown;
  onNext?: (fn: () => unknown) => unknown;
}

const ActionBar = ({
  onPrev,
  onNext
}: ActionBarProps) => {
  const { signUpType } = useSignUpType();
  const { currentStep, nextStep, prevStep } = useSteps();

  return (
    <div className="container-fluid p-0 action-bar pb-5 pt-4 pt-md-5">
      <div className={`row ${currentStep > 1 ? 'p-md-3 ms-md-1 me-md-1 border-round':''}`}>
        <div className={`order-md-1 ${currentStep > 1 ? 'order-1 col-6' : 'order-2 col-md-6 col-12'} d-flex flex-row justify-content-start align-items-center`}>
          {signUpType === SignUpTypes.CORPORATE && currentStep === 1 &&
            <Link to="/adhoc-service-corporate">
              See ALKELIO+ Services for Corporates
              <img src={ArrowGreenSVG} className="ms-2" alt="" />
            </Link>
          }
          {signUpType === SignUpTypes.STARTUP && currentStep === 1 &&
            <Link to="/adhoc-service-startup">
              See ALKELIO+ Services for Startups
              <img src={ArrowGreenSVG} className="ms-2" alt="" />
            </Link>
          }
          {currentStep > 1 && 
          <button type="button" className="btn btn-primary" onClick={() => {
            if (onPrev) {
              onPrev(prevStep);
            } else {
              prevStep()
            }
          }}><span className="d-none d-md-span">&lt; </span>Back</button>
          }
        </div>
        <div className={`order-md-2 ${currentStep > 1 ? 'order-2 col-6': 'order-1 col-md-6 col-12 mb-5 mb-md-0'} d-flex flex-row justify-content-end`}>
          <button type="button" className="btn btn-primary" onClick={() => {
            if (onNext) {
              onNext(nextStep);
            } else {
              nextStep()
            }
          }}>Next<span className="d-none d-md-span"> &gt;</span></button>
        </div>
      </div>
    </div>
  )
}

export default ActionBar;