import React, { FC, createContext, useState } from 'react'

export const MenuContext = createContext({
  menuActive: false,
  toggleMenu: () => {},
  showMenu: () => {},
  hideMenu: () => {}
})

type Props = {
  children?: React.ReactNode
}

const MenuProvider: FC<Props> = (props) => {
  const [menuActive, setMenuActive] = useState<boolean>(false)

  return (
    <MenuContext.Provider 
      value={{
        menuActive, 
        toggleMenu: () => {
          setMenuActive(!menuActive)
        },
        showMenu: () => {
          setMenuActive(true)
        },
        hideMenu: () => {
          setMenuActive(false)
        }
      }}
    >
      {props.children}
    </MenuContext.Provider>
  )
}

export default MenuProvider;