import React from 'react'
import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import LargeCircleOPlusicon from 'app/shared/assets/svgs/2xl-circle-o-plus.svg'
import './assets/styles/card-plus.scss'

const CardPlus = () => {
  let { url } = useRouteMatch();
  const { entityType }: any = useParams();
  const { push } = useHistory()
  return (
    <div className="card-plus" onClick={() => {
      push(`/app/${entityType}/tech-requests/new`)
    }}>
      <img src={LargeCircleOPlusicon} alt="Add" />
    </div>
  )
}

export default CardPlus