import BarChartsIcon from 'app/shared/assets/svgs/bar-chart.svg';
import IconLabel from 'app/shared/icon-label';
import { useGetViewCountQuery } from 'services/graphql/view-counts';

type Props = {
  articleId: string;
  className?: string;
}

export const ViewCount = ({
  articleId,
  className
}: Props) => {
  const { data, isSuccess } = useGetViewCountQuery(articleId);

  return <IconLabel className={className} icon={BarChartsIcon}>{isSuccess ? data?.count?? 0 : 0} Views</IconLabel>
}
