import './assets/styles/app-service-card.scss';
import { Price } from './price';
import { useState } from 'react';
import { CircleCheckBox } from './circle-chekbox';
import { useDispatch } from "react-redux";
import { addAdhocService } from 'features/cart/cartSlice';

type Props = {
  image: string;
  title: string;
  slug: string;
  description: string;
  price: number;
  currency?: string;
  selected?: boolean;
  onSelect?: (arg: string) => unknown;
}

const ServiceCard = ({
  image,
  title,
  description,
  slug,
  onSelect,
  price = 0,
  currency = 'CHF',
  selected = false
}: Props) => {
  const [isChecked, setChecked] = useState<boolean>(selected)
  const dispatch = useDispatch();


  return (
    <div className="app-service-card" onClick={() => {
      setChecked(!isChecked);
      dispatch(addAdhocService({
        slug: slug,
        price: price,
        qty: 1,
        name: title,
        currency
      }));
    }}>
      <div className="image">
        {isChecked && <CircleCheckBox />}
        {price > 0 && <Price price={price} currency={currency} />}
        <img src={image} alt={title} />
      </div>
      <div className="content">
        <h5>{title}</h5>
        <div className="body txt-light font-size-14 line-height-140 color-text">
          {description}
        </div>
      </div>
    </div>
  )
}

export default ServiceCard;