import './assets/styles/how-it-works.scss';

type ProcessStepProps = {
  step: string;
  title: string;
  content: string;
}

const ProcessStep = ({
  step,
  title,
  content
}: ProcessStepProps) => {
  return (
    <div className="process-step col-12 col-xl d-flex flex-column align-items-center align-items-xl-start mb-4 mb-xl-0">
      <h6 className="mb-2 mb-xl-4 tangerine">{step.toString().padStart(2, '0')}</h6> 
      <div className="flex-xl-row flex-column d-flex align-items-center align-items-xl-start justify-content-between">
        <div>
          <h1 className="mb-2 mb-xl-4 sm">{title}</h1>
          <div className="color-text pe-xl-4 line-height-140 txt-light font-size-14 text-center text-xl-start">{content}</div>
        </div>
        <div className="d-flex justify-content-center ps-xl-3 pe-xl-1">
          {parseInt(step) < 4 && <img className="mt-3 caret-right" src="imgs/grey-caret-right.png" alt="next" />}
        </div>
      </div>
    </div>
  )
}

const HowitWorks = () => {
  const data = [
    {
      title: 'Sign Up',
      content: "Tell us who you are and what you are looking for so we can qualify your request and create your personal space. "
    },
    {
      title: "Get matched or explore ",
      content: "Once you are in your personal dashboard, you can explore the ecosystem based on your preferred criteria or you can ask to get matched automatically to find the best partners for you. "
    },
    {
      title: "Start the conversation",
      content: "Once you have selected the partner or lead  that you would like to start a conversation with, just make the request to notify the other party.   "
    },
    {
      title: "Seal the deal &  start collaborating",
      content: "Once connected, you will be able to use conjointly the centralized environement  to agree on the specifics of the project, sign the contract  and  start the collaboration. "
    }
  ]

  return (
    <div className="pt-5 pb-5 pb-md-5 pt-md-5 container-fluid color-bg-white" id="how-it-works">
      <div className="container pb-4 pb-md-5">
        <div className="row justify-content-center">
          <div className="pb-5 pt-5 col-md-4 d-flex justify-content-center w-100">
            <div className="screen-computer pb-5">
              <img className="app-screen" src="imgs/app-screen.png" alt="Akleio" />
              <img className='pc-screen' src="imgs/screen.png" alt="ALKELIO" />
            </div>
          </div>
        </div>
        <h1 className="pb-4 pb-md-5 text-center">How it works</h1>
        <div className="row">
          {data.map((item, i) => <ProcessStep key={i} step={(i + 1).toString()} {...item} />)}
        </div>
      </div>
    </div>
  )
}

export default HowitWorks;