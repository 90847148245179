import { ServiceCard } from "./service-card"
import './assets/styles/packages.scss';
import CircleOTickAzure from 'app/shared/assets/svgs/circle-o-tick-azure.svg';
import Button from "app/shared/button";
import LongArrowWhiteRight from 'app/shared/assets/svgs/long-arrow-right-white.svg';
import {
  useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setServicePackage, currentCart } from 'features/cart/cartSlice';
import { NotReadyCommit } from "app/shared/not-ready-commit/not-ready-commit";

import { useGetServicePackagesQuery } from 'services/graphql/service-packages'

type MetaInfo = {
  color: string,
  discount: number
}

// to replace with get service packages graphql
const metaInfo: Record<string, MetaInfo> = {
  'level1': {
    color: 'tangerine',
    discount: 0,
  },
  'level2': {
    color: 'azure',
    discount: 0,
  },
  'level3': {
    color: 'purple',
    discount: 0,
  },
  'level4': {
    color: 'java',
    discount: 0,
  }
}

type ServicePackageWithMeta = {
  slug: string,
  matchCount: number,
  price: number,
  currency: string,
  name: string
} & MetaInfo

type ServicePackageSubscriptionMeta = {
  slug: string,
  name: string,
  currency: string,
  prices: {
    monthly: number,
    annual: number
  }
}

export const Packages = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector(currentCart);
  const { data, isSuccess } = useGetServicePackagesQuery()

  let transformData: Array<ServicePackageWithMeta> = []
  let subscriptionPlanData: ServicePackageSubscriptionMeta | null = null
  
  if (isSuccess && data) {
    transformData = data.servicePackages.filter(d => !d.unlimited_credits)
      .map(d => ({
        slug: (d.slug ?? '') as string,
        name: d.name as string,
        currency: data.currency,
        matchCount: d.service_package_price_mode ? d.service_package_price_mode[0]?.credits as number : 0,
        price: d.service_package_price_mode ? d.service_package_price_mode[0]?.price as number : 0,
        ...metaInfo[d.slug as string]
      }))

    const subscription = data.servicePackages.find(d => d.unlimited_credits)
    if (subscription) {
      subscriptionPlanData = {
        prices: {
          annual: (subscription.service_package_price_mode ? 
            subscription.service_package_price_mode.find(s => s?.payment_interval === 'ANNUAL')?.price : 0) as number,
          monthly: (subscription.service_package_price_mode ? 
            subscription.service_package_price_mode.find(s => s?.payment_interval === 'MONTHLY')?.price : 0) as number,
        },
        slug: (subscription.slug ?? '') as string,
        name: subscription.name as string,
        currency: data.currency,
      }
    }
  }

  
  return (
    <div className="row w-100 startup-packages">
      <div className="p-0 col">
        <div className="row">
          <div className="flex-column flex-md-row p-0 col d-flex justify-content-between w-100">
            {transformData.map((item: ServicePackageWithMeta) => <ServiceCard {...item} 
              selected={cart.items.find((cartItem) => item?.slug === cartItem.slug) !== undefined} />)}
          </div>
          <div className="h-line">
            <div className="color-nightsky text-uppercase bold-type font-size-18 letter-spacing-n-2">or</div>
          </div>
          <div className="flex-row d-flex justify-content-center">
            {subscriptionPlanData ?
            <div className={`item-subscribe pt-5 pb-5 m-2 box-white-rounded-shadow d-flex flex-column align-items-center justify-content-start ${cart.items.find((cartItem) => cartItem.slug === 'subscription') !== undefined ? 'border-azure': 'hover-border-azure'}`}>
              <div className="mb-2 txt-semibold font-size-14 letter-spacing-n-2 text-uppercase color-azure">Subscribe for</div>
              <div className="d-flex flex-row pb-2 bold-type text-uppercase color-nightsky letter=spacing-n-2 align-items-end">
                <div className="font-size-32 line-height-100 ">{subscriptionPlanData?.currency}{subscriptionPlanData?.prices?.monthly}</div>
                <div className="mb-1 font-size-14 line-height-100">/ month</div>
              </div>
              <div className="mt-0 font-size-14 color-text text-regular">
                (${subscriptionPlanData?.prices?.annual} per annum)
              </div>
              <div className="pt-4 mt-4">
                <div className="flex-row pb-3 mb-2 d-flex">
                  <img src={CircleOTickAzure} alt="" className="me-2" />
                  <div>Tech solution certfied</div>
                </div>
                <div className="flex-row pb-3 mb-2 d-flex">
                  <img src={CircleOTickAzure} alt="" className="me-2" />
                  Unlimited conversations
                </div>
                <div className="flex-row pb-3 mb-2 d-flex">
                  <img src={CircleOTickAzure} alt="" className="me-2" />
                  Adhoc recommendations
                </div>
                <div className="flex-row pb-3 mb-2 d-flex">
                  <img src={CircleOTickAzure} alt="" className="me-2" />
                  ALKELIO Catalyst support
                </div>
                <div className="flex-row pb-3 mb-2 d-flex">
                  <img src={CircleOTickAzure} alt="" className="me-2" />
                  50% back if no deal in the year
                </div>
              </div>
              <Button className="mt-4 border-0 color-bg-azure color-white w-100" onClick={() => {
                dispatch(setServicePackage({
                  slug: 'startup-subscription',
                  qty: 1,
                  price: subscriptionPlanData?.prices?.monthly as number,
                  currency: 'USD',
                  name: 'Subscription'
                }))
                push('/app/startup/alkelio-services')
              }}>
                Select
                <img src={LongArrowWhiteRight} className="ms-2" alt="select" />
              </Button>
            </div> : null}
          </div>
        </div>
        <NotReadyCommit />
        <div className="row">
          <div className="pt-2 text-center col">
            <div className="color-text font-size-12 letter-spacing-n-2 text-regular">*  If the Match don’t respond within 3 weeks we will credit you the full amount.</div>
          </div>
        </div>
      </div>
    </div>
  )
}