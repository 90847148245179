import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  MatchMakingResult,
  MatchPayload, 
  MatchResults,
  RefreshMatchesInput, 
  FindMatchMakingResultWithRolePayload,  
  Bookmark,
  Enum_Bookmark_Type,
  TechnologyReadinessLevel
} from 'generated/graphql'

export type FindOneMatchResultWithRoleWithTechReadiness = FindMatchMakingResultWithRolePayload & {
  technology_readiness_name: string;
}

export const matchMakingResultsApi = createApi({
  reducerPath: "matchMakingResultsApi",
  tagTypes: ["match-making-results"],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    refreshMatches: builder.mutation<MatchPayload, RefreshMatchesInput>({
      query: (data) => ({
        document: gql`
          mutation RefreshMatches($data: RefreshMatchesInput) {
            refreshMatches(input: $data) {
              jobId
              messages
              results{
                matches {
                  id
                  is_saved
                  is_rejectd
                  match_is_cloud
                  match_can_whitelabel
                  match_has_api
                  match_has_ip
                  match_is_cloud
                  match_sdg_driven
                  match_is_gdpr
                  match_is_plugandplay
                  match_purpose_lease
                  match_purpose_poc
                  match_purpose_sell
                  match_rating
                  technology_request {
                    id
                    name
                    business_challange_description
                    hero_image {
                        url
                    }
                  }
                  technology_solution {
                    id
                    name
                    description
                    hero_image {
                        url
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          data: {
            type: data.type,
            technology_id: data.technology_id,
            isReturnMatches: data.isReturnMatches
          }
        }
      }),
      transformResponse: (response: any) => response.refreshMatches,
      invalidatesTags: (results, error, data) => [
        { type: "match-making-results", id: `${data.type}-${data.technology_id as string}`},
      ]
    }),
    getTechnologySolutionMatches: builder.query<MatchResults, string>({
      query: (id) => ({
        document: gql`
          query getTechSolutionsMatches($id: ID!){
            getTechSolutionsMatches(id: $id) {
              matches {
                id
                is_saved
                is_rejectd
                match_is_cloud
                match_can_whitelabel
                match_has_api
                match_has_ip
                match_is_cloud
                match_sdg_driven
                match_is_gdpr
                match_is_plugandplay
                match_purpose_lease
                match_purpose_poc
                match_purpose_sell
                match_rating
                technology_request {
                  id
                  name
                  business_challange_description
                  hero_image {
                      url
                  }
                  corporate_profile {
                      country_jurisdiction
                      employee_count_range
                      esg_rating
                  }
                }
                technology_solution {
                  id
                  name
                  description
                  hero_image {
                      url
                  }
                }
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => response.getTechSolutionsMatches,
      providesTags: (result, error, id) => [
        { type: "match-making-results", id: `technology_solution-${id}` }
      ]
    }),
    countTechnologySolutionMatch: builder.query<number, string>({
      query: (id) => ({
        document: gql`
          query matchMakingResultsConnection($id: ID!) {
            matchMakingResultsConnection(
              where: {
                technology_solution: {
                  id: $id
                }
                technology_request: {
                  is_published: true
                  published_at_null:false
                }
              }
            ) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response:any) => response.matchMakingResultsConnection.aggregate.count ?? 0,
      providesTags: (result, error, id) => [
        { type: "match-making-results", id: `technology_solution-count-${id}` }
      ]
    }),
    countTechnologyRequestMatch: builder.query<number, string>({
      query: (id) => ({
        document: gql`
          query matchMakingResultsConnection($id: ID!) {
            matchMakingResultsConnection(
              where: {
                technology_request: {
                  id: $id
                }
                technology_solution: {
                  is_published: true
                  published_at_null:false
                }
              }
            ) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response:any) => response.matchMakingResultsConnection.aggregate.count ?? 0,
      providesTags: (result, error, id) => [
        { type: "match-making-results", id: `technology_request-count-${id}` }
      ]
    }),
    getMatchMakeResult: builder.query<FindOneMatchResultWithRoleWithTechReadiness, number>({
      query: (id) => ({
        document: gql`
          query getMatchMakeResult($id: ID!) {
            findOneMatchResultWithRole(id: $id) {
              id
              is_saved
              is_rejected
              match_is_cloud
              match_can_whitelabel
              match_has_api
              match_has_ip
              match_is_cloud
              match_sdg_driven
              match_is_gdpr
              match_is_plugandplay
              match_purpose_lease
              match_purpose_poc
              match_purpose_sell
              match_rating
              technology_request {
                id
                name
                business_challange_description
                hero_image {
                    url
                }
                corporate_profile {
                    country_jurisdiction
                    employee_count_range
                    esg_rating
                }
                
                want_explore
                want_lease
                want_poc
                want_seller
                want_investment

                is_cloud
                sdg_driven
                is_gdpr
                is_plugandplay
                is_spin_off
                can_whitelabel
                has_api
                has_ip

                technology_solution_model_b2b
                technology_solution_model_b2b2c
                technology_solution_model_b2c
                technology_solution_model_others
                other_tech_option

                goals {
                    id
                    Name
                }

                impact_level
                impact_area {
                    id
                    name
                }
                technology_types {
                    id
                    name
                }
                industries {
                    id
                    name
                }
                business_use_cases {
                    id
                    name
                }
                corporate_departments {
                    id
                    name
                }
                sdg_objectives {
                    id
                    name
                }
              }
              technology_solution {
                id
                name
                description
                benefits_description
                hero_image {
                    id
                    url
                    name
                }
                purpose_poc
                purpose_sell
                purpose_lease
                purpose_certify
                purpose_investment
                tested_by_alkelio
                has_ip
                has_api
                is_whitelabel
                is_plugandplay
                is_gdpr
                is_cloud
                sdg_driven
                is_spin_off
                spin_off_source
                is_others
                key_features
        
                technology_types {
                  id
                  name
                }
                technology_readiness
        
                industries  {
                  id
                  name
                }
        
                business_use_cases  {
                  id
                  name
                }
        
                corporate_departments {
                  id
                  name
                }
                sdg_objectives {
                    id
                    name
                }
        
                testimonial {
                  have_testimonials
                  quantity   
                }

                case_studies {
                  have_case_studies
                  quantity
                }

                startup_profile {
                  id 
                  country
                  maturity_level {
                    name
                  }
                  esg_rating
                  investment_received
                  investment_stage
                  worked_with_corporates
                  employee_count
                  client_count
                  operating_locations
                  client_count
                }
              }
            }
            technologyReadinessLevels {
              id
              name
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => {
        response.findOneMatchResultWithRole.technology_readiness_name = (response.technologyReadinessLevels as TechnologyReadinessLevel[]).find(item => item.id === response.findOneMatchResultWithRole.technology_readiness as string ?? '')?.name
        return response.findOneMatchResultWithRole as FindOneMatchResultWithRoleWithTechReadiness
      }
    }),
    getTechnologyRequestMatches: builder.query<MatchResults, string>({
      query: (id) => ({
        document: gql`
          query getTechnologyRequestMatches($id: ID!){
            getTechRequestsMatches(id: $id) {
              matches {
                id
                is_saved
                is_rejectd
                match_is_cloud
                match_can_whitelabel
                match_has_api
                match_has_ip
                match_is_cloud
                match_sdg_driven
                match_is_gdpr
                match_is_plugandplay
                match_purpose_lease
                match_purpose_poc
                match_purpose_sell
                match_rating
                technology_request {
                  id
                  name
                  business_challange_description
                  hero_image {
                    url
                  }
                  corporate_profile {
                    country_jurisdiction
                    employee_count_range
                    esg_rating
                  }
                  want_poc
                  want_seller
                  want_lease
                  want_investment
                }
                technology_solution {
                  id
                  name
                  description
                  hero_image {
                    url
                  }
                  startup_profile {
                    country
                    maturity_level {
                      name
                    }
                  }
                  tested_by_alkelio
                }
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => response.getTechRequestsMatches,
      providesTags: (result, error, id) => [
        { type: "match-making-results", id: `technology_request-${id}` }
      ]
    })
  })
})

export const { 
  useRefreshMatchesMutation,
  useGetTechnologySolutionMatchesQuery,
  useLazyGetTechnologyRequestMatchesQuery,
  useLazyCountTechnologySolutionMatchQuery,
  useCountTechnologySolutionMatchQuery,
  useCountTechnologyRequestMatchQuery,
  useGetMatchMakeResultQuery,
  useGetTechnologyRequestMatchesQuery
} = matchMakingResultsApi

export const matchMatchingResultApi = matchMakingResultsApi.util;

export const invalidateCacheTechnologyRequestMatches = (id: string) => {
  console.log('service:graphgql:match-making-result:invalidateCacheTechnologyRequestMatches', id)
  return matchMakingResultsApi.util.invalidateTags([
    { type: 'match-making-results', id: `technology_request-${id}` },
    { type: 'match-making-results', id: `technology_request-count-${id}` },
  ])
}

export const invalidateCacheTechnologySolutionMatches = (id: string) => {
  console.log('service:graphgql:match-making-result:invalidateCacheTechnologySolutionMatches', id)
  return matchMakingResultsApi.util.invalidateTags([
    { type: 'match-making-results', id: `technology_solution-${id}` },
    { type: 'match-making-results', id: `technology_solution-count-${id}` },
  ])
}