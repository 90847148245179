import { SignUpType, SignUpTypes } from '../types';
import { SignUpPurposesStartup, SignUpPurposeCorporate} from 'app-constants';

export const StartupOptions = SignUpPurposesStartup.map(({label, key}) => ({ label, value: key }))
export const CorporateOptions = SignUpPurposeCorporate.map(({label, key}) => ({ label, value: key }))

export const getSelectedOptions = (requests: any, signUpType: SignUpType): string[] => {
  const selectedOpts: string[] = []

  if (requests) {
    let opts: string[] = []
    if (signUpType === SignUpTypes.STARTUP) {
      opts = StartupOptions.map(o => o.value);
    } else
    if (signUpType === SignUpTypes.CORPORATE) {
      opts = CorporateOptions.map(o => o.value);
    }

    const keys = Object.keys(requests)

    for (let k of opts) {
      
      if (keys.includes(k)) {
        selectedOpts.push(k)
      }
    }
  }

  return selectedOpts;
}