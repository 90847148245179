import './assets/styles/status-badge.scss';
import React from 'react';
import InfoIcon from './assets/svgs/info.svg';

type BadgeProps = {
  color?: "in-progress" | "success";
  size?: string;
  options?: string
  children?: React.ReactNode;
  toolTip?: string;
}

const StatusBadge = (props: BadgeProps) => {
  return (
    <div className={`app-status-badge ${props.size && "size-" + props.size} ${props.options && "options-" + props.options}`}>
      <div className={`status ${props.color}`}></div>
      <div className="content">{props.children}</div>
      <img className="tool-tip-icon" src={InfoIcon} alt={props.toolTip?? ''} />
      {props.toolTip !== '' && props.toolTip && <div className="tool-tip">{props.toolTip}</div>}
    </div>
  )
}

export default StatusBadge;