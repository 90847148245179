import React from 'react'
import Tile from '../shared/tile'
import {
  useParams
} from 'react-router-dom';
import { useGetOneNewsArticleQuery } from 'services/graphql/news'
import { format } from 'date-fns'
import './assets/styles/news.detail.component.scss'
import showdown from 'showdown'

const converter = new showdown.Converter()

const NewsDetail = () => {
  const { id } : any = useParams()
  const { data = null, isLoading, isError, isSuccess } = useGetOneNewsArticleQuery(id)

  if (isError) {
    return <div>Something went wrong.</div>
  }

  const getImage = (formats: any) => {
    if (formats) {
      const sizes = ['large','medium','small']

      for (const size of sizes) {
        if (formats[size]) return formats[size]?.url ?? null
      }
    }

    return null
  }

  let imgLarge = getImage(data?.main_image?.formats)
  
  return (
    <Tile>
      {data !== null && 
      <div className="news-detail d-flex flex-column">
        {imgLarge &&
        <div className="image-top mb-5">
          <img src={imgLarge} alt={data?.headline ?? ''} />
        </div>
        }
        <h1 className="font-size-40 line-height-120 mb-5">{data?.headline}</h1>
        <p>{format(new Date(data?.published_at), 'MMMM YYYY')}</p>
        <div className="body" dangerouslySetInnerHTML={{__html: converter.makeHtml(data?.body as string)}} >
        </div>
      </div>
      }
    </Tile>
  )
}

export default NewsDetail