import { createSlice } from "@reduxjs/toolkit";

export interface modalStateType {
  active: boolean;
  modalId: string | null;
  parameters: any | null;
}

const initialState = {
  active: false,
  modalId: null,
  parameters: null,
} as modalStateType;

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open: (state, action) => {
      state.active = true;
      state.modalId = typeof(action.payload) === 'string' ? action.payload : action.payload.id;
      state.parameters = typeof(action.payload) === 'object' ? action.payload.parameters : null;
    },
    close: (state) => {
      state.active = false;
      state.modalId = null;
      state.parameters = null;
    },
    resetModals: (state) => {
      state = {...initialState}
    }
  }
})

export const { open, close, resetModals } = modalSlice.actions

export default modalSlice.reducer