import { useParams } from 'react-router-dom'
import './assets/styles/conversation.scss'

import Tile from "app/shared/tile";

import Left from './left'
import Top from './top'
import Main from './main'

import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useGetOneServiceCollaborationQuery } from 'services/graphql/service-collaboration'
import { ServiceCollaboration } from 'generated/graphql';
import CorProfile from 'app/collaborate/conversation/corProfile';
import StartupProfile from 'app/collaborate/conversation/startuProfile';
import Complete from '../complete'

import { Switch, Route, useRouteMatch } from 'react-router-dom'

type Params = {
  id: string
}

const ServiceCollaborationDetails = () => {
  let { path } = useRouteMatch();
  const user = useSelector(selectCurrentUser);
  const { id } = useParams<Params>()
  const { data, isLoading, isSuccess } = useGetOneServiceCollaborationQuery(id)

  const getPerson = (currentRole:string, d: ServiceCollaboration) => {
    const { client, catalyst } = d

    if (currentRole === 'catalyst') {
      return {
        profileId: client?.person_profile?.id ?? '',
        userType: client?.role?.type ?? '',
        role: client?.person_profile?.role ?? '',
        name: [client?.person_profile?.last_name?? '', client?.person_profile?.first_name?? ''].join(' '),
        photoUrl: client?.person_profile?.photo?.url ?? '',
        email: client?.email ?? ''
      }
    } else {
      return {
        profileId: client?.person_profile?.id ?? '',
        userType: catalyst?.role?.type ?? '',
        role: catalyst?.person_profile?.role ?? '',
        name: [catalyst?.person_profile?.last_name?? '', catalyst?.person_profile?.first_name?? ''].join(' '),
        photoUrl: catalyst?.person_profile?.photo?.url ?? '',
        email: catalyst?.email ?? ''
      }
    }
  }

  return (
    <Tile>
      {(isSuccess && data)?
      <div className="service__collaboration-details">
        <div className="left">
          <Left 
            id={id}
            currentUserType={user?.role?.type ?? null} 
            talkingToPerson={getPerson(user?.role?.type?? '', data)} 
          />
        </div>
        <div className="right">
          <div className="top">
            <Top/>
          </div>
          <div className="main-area">
            <Main
              id={data.id}
              title={data.service?.title ?? ''}
            />
          </div>
        </div>
      </div> : null}
    </Tile>
  )
}

export default ServiceCollaborationDetails