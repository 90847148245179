import { useEffect, useState } from 'react';

const getCookie = (key: string) => {
  const cookies = document.cookie.split(';')
  return cookies.find(item => item.trim().startsWith(key + '='))
}

const useCookiePermissions = () => {
  const cookieKey = 'accept'
  const [hasPermission, setHasPermission] = useState(getCookie(cookieKey) !== undefined)

  const checkPermissions = () => {
    const cookie = getCookie(cookieKey)
    cookie !== undefined && cookie !== null && setHasPermission(true);
  }

  const setPermissions = (flags: string[]) => {
    const permissionCookie = `${cookieKey}=${flags.join(',')};`
    document.cookie = permissionCookie;
    setHasPermission(true);
  }

  useEffect(() => {
    checkPermissions()
  }, [])

  return {
    setPermissions,
    hasPermission
  }
}

export default useCookiePermissions;