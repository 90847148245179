import './assets/styles/packages.scss'
import { NotReadyCommit } from 'app/shared/not-ready-commit/not-ready-commit'
import IgnitorIcon from './assets/svgs/ignitor.svg'
import ChallengerIcon from './assets/svgs/challenger.svg'
import ChampionIcon from './assets/svgs/champion.svg'
import { ServiceCard } from './service-card'
import { useSelector } from 'react-redux'
import { currentCart } from 'features/cart/cartSlice'

import { ServicePackage, 
  Enum_Componentservicepackageservicepackagepaymentmode_Payment_Interval 
} from 'generated/graphql'
import { useGetServicePackagesQuery } from 'services/graphql/service-packages'

type MetaInfo = {
  icon: string
  color: string
  features: string[]
}

type ServicePackageWithMeta = {
  slug: string,
  matchCount: number,
  pricePerMonth: number,
  pricePerYear: number,
  name: string,
  currency: string
} & MetaInfo

const servicePackagesAddData: Record<string, MetaInfo> = {
  'ignitor': {
    icon: IgnitorIcon,
    color: 'tangerine',
    features: [
      'business-need-validate',
      'business-case-define',
      'catalyst-support-pre-convo',
      'unlimimted-matches',
      'x-matches',
      'access-collab-dashboard',
      'support-close-deal'
    ]
  },
  'challenger': {
    icon: ChallengerIcon,
    color: 'azure',
    features: [
      'business-need-validate',
      'business-case-define',
      'catalyst-support-pre-convo',
      'unlimimted-matches',
      'x-matches',
      'access-collab-dashboard',
      'support-close-deal',
      'dedicated-alkelio-catalyst',
      'impl-planning-roadmap'
    ]
  },
  'champion': {
    icon: ChampionIcon,
    color: 'purple',
    features: [
      'business-need-validate',
      'business-case-define',
      'catalyst-support-pre-convo',
      'unlimimted-matches',
      'x-matches',
      'access-collab-dashboard',
      'support-close-deal',
      'dedicated-alkelio-catalyst',
      'impl-planning-roadmap',
      '1-pilot',
      'solution-impl-coord',
      'personalised-report'
    ]
  }
}

export const Packages = () => {
  const cart = useSelector(currentCart);

  const { data, isSuccess } = useGetServicePackagesQuery()
  let transformedData: Array<ServicePackageWithMeta>  = []

  if (isSuccess && data) {
    transformedData = data.servicePackages?.map(d => ({
      slug: (d.slug ?? '') as string,
      name: d.name as string,
      matchCount: (d.service_package_price_mode?.find(p => p?.payment_interval === Enum_Componentservicepackageservicepackagepaymentmode_Payment_Interval.Annual)?.credits ?? 0) as number,
      pricePerMonth: (d.service_package_price_mode?.find(p => p?.payment_interval === Enum_Componentservicepackageservicepackagepaymentmode_Payment_Interval.Monthly)?.price ??0) as number,
      pricePerYear: (d.service_package_price_mode?.find(p => p?.payment_interval === Enum_Componentservicepackageservicepackagepaymentmode_Payment_Interval.Annual)?.price ??0) as number,
      currency: data.currency,
      ...servicePackagesAddData[d.slug ?? '']
    }))
  }

  return (
    <div className="row w-100 corporate-packages mb-0">
      <div className="p-0 col">
        <div className="row">
          <div className="flex-column flex-md-row p-0 col d-flex justify-content-between w-100">
            {isSuccess ? transformedData.map((item, i) => 
              <ServiceCard key={i} 
                selected={cart.items.find((cartItem) => cartItem.slug === item.slug) !== undefined } 
                {...item}
              />): null }
          </div>
        </div>
        <NotReadyCommit />
        <div className="row mb-0">
          <div className="pt-2 text-left col">
            <div className="color-text font-size-12 letter-spacing-n-2 text-regular">*  If not used, Expires after 1 year </div>
          </div>
        </div>
      </div>
    </div>
  )
}