import './assets/styles/card.scss'
import React from 'react'
import { Image } from 'shared/image'

type CardProps = {
  variant?: string;
  cardHeight?: string;
  cardWidth?: string;
  imageSrc?: string;
  imageFilter?: string;
  imageWidth?: string;
  imageHeight?: string;
  imageOverlay?: React.ReactNode;
  imageOverlayPosition?: string;
  actionOverlay?: React.ReactNode;
  actionOverlayShow?: boolean;
  title?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  onClick?: () => unknown;
}

const Card = React.forwardRef(({
  variant, 
  cardHeight, 
  cardWidth, 
  imageSrc, 
  imageFilter,
  imageWidth, 
  imageHeight,
  imageOverlay, 
  imageOverlayPosition, 
  actionOverlay,
  actionOverlayShow = false,
  title, 
  body, 
  footer,
  className,
  onClick
}: CardProps,
ref: React.Ref<HTMLDivElement>) => {
  const styleCard = {
    height: cardHeight || 'auto',
    width: cardWidth || 'auto',
  }

  const styleCardImage = {
    width: imageWidth || 'auto',
    height: imageHeight || 'auto',
    flex: `0 0 ${imageWidth || imageHeight}`
  }
  
  return (
    <div className={`card-wrapper ${className}`} onClick={() => {
      onClick && onClick()
    }}>
      <div className={`card ${variant || ''} ${actionOverlayShow ? 'action-overlay-active':''}`} >
        <div className={`card-image ${imageFilter ? `filter-${imageFilter}`: ``}`}>
          {imageOverlay && <div className={`overlay ${imageOverlayPosition ? imageOverlayPosition : 'top-left'}`}>{imageOverlay}</div>}
          {imageSrc && <Image src={imageSrc} className="card-img-top" alt="..." />}
        </div>
        <div className={`card-body`}>
          {actionOverlayShow && <div className="action-overlay">{actionOverlay}</div>}
          <div className={`card-body-content`}>
            {title && <h5 className="card-title">{title}</h5>}
            {body}
          </div>
          {footer && <div className={`card-footer`}>
            {footer}
          </div>}
        </div>
      </div>
    </div>
  )
});

export default Card;