import React, { FC, createContext, useState } from 'react'

export const CheckoutContext = createContext({
  paying: false,
  pay: () => {},
  payComplete: () => {}
})

type Props = {
  children?: React.ReactNode
}

const CheckoutProvider: FC<Props> = (props) => {
  const [paying, setPaying] = useState<boolean>(false)

  return (
    <CheckoutContext.Provider value={{
      paying: paying,
      pay: () => {
        setPaying(true)
      },
      payComplete: () => {
        setPaying(false)
      }
    }}>
      {props.children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutProvider

