import React from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import Tile from 'app/shared/tile';
import Card from 'app/shared/card';
import Button from 'app/shared/button';
import TargetIcon from 'app/shared/assets/svgs/target.svg';
import BarChartsIcon from 'app/shared/assets/svgs/bar-chart.svg';
import CalenderIcon from 'app/shared/assets/svgs/calender.svg';
import IconLabel from 'app/shared/icon-label';
import { useMyTechSolutionsQuery } from 'services/graphql/tech-solutions';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import { CompletionBadge } from 'app/shared'
import { ViewCount } from 'app/shared'
import MatchCounterTechnologySolution from 'app/shared/match-counter/match-count.techsolution.component'
import './assets/styles/tech-solutions.scss';

dayjs.extend(relativeTime)

const TechSolutions = () => {
  const { url } = useRouteMatch()
  const { entityType }: any = useParams()
  const { push } = useHistory();
  const { data, refetch, isFetching, isLoading } = useMyTechSolutionsQuery()

  const handleTechSolutionEditClick = (id: string) => {
    push(`/app/${entityType}/tech-solutions/${id}`)
  }

  const cards: Array<React.ReactNode> = data ? data.map((item, i) => (
    <Card 
      key={i}
      variant="horizontal"
      cardWidth="100%"
      className="my-tech-solution-item"
      title={item.name || ''} 
      imageSrc={item.hero_image?.url?? ''}
      imageOverlay={<CompletionBadge isCompleted={item.is_completed?? false} />}
      body={<>
        <p>{item.description?.substring(0,200)}{(item.description?.length?? 0) > 200 && <span>&#8230;</span>}</p>
        <Button className="mt-2" variant="primary" onClick={() => {handleTechSolutionEditClick(item.id)}}>edit</Button>
      </>}
      footer={<div className="footer-info w-100 ps-md-5">
        <IconLabel icon={CalenderIcon}>{dayjs(item.updated_at).from(dayjs())}</IconLabel>
        <ViewCount articleId={`tech-sol-${item.id}`}/>
        <IconLabel icon={TargetIcon}><MatchCounterTechnologySolution id={item.id} /></IconLabel>
      </div>}
    />
  )) : []

  return (
    <Tile 
      title="My Tech Solutions"
      mode="carousel"
      actionComponents={[
        <Button key={1} onClick={() => {
          push(`/app/${entityType}/tech-solutions/new`)
        }}>Add new</Button>
      ]}>
      {cards}
    </Tile>
  )
}

export default TechSolutions;