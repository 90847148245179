import React from 'react'
import './assets/styles/main.scss'
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';

import { 
  ComponentServiceCollaborationStructure, 
  ComponentServiceCollaborationScope,
  ComponentServiceCollaborationDeliverables,
  ComponentServiceCollaborationFeedback
} from 'generated/graphql'

import Nav from './nav'
import StructureForm from './structure.form'
import ScopeForm from './scope.form'
import DeliverablesForm from './deliverables.form';
import FeedbackForm from './feedback.form';

import NeedHelp from 'app/corporate/tech-request-form/need-help';

type Props = {
  id: string;
  title: string
}

const Main = ({
  id,
  title
}: Props) => {
  let { path } = useRouteMatch();

  return (
    <div className="right__main">
      <NeedHelp/>
      <h4 className="mt-4 mb-4">{title}</h4>
      <div className="right__main__nav mb-4 w-100">
        <Nav />
      </div>
      <Switch>
        <Route exact path={path}>
          <StructureForm id={id} />
        </Route>
        <Route path={`${path}/scope`}>
          <ScopeForm id={id} />
        </Route>
        <Route path={`${path}/deliverables`}>
          <DeliverablesForm id={id} />
        </Route>
        <Route path={`${path}/feedback`}>
          <FeedbackForm  id={id} />
        </Route>
      </Switch>
    </div>
  )
}

export default Main;