import { SignUpType, SignUpTypes } from '../types'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSession, selectCurrentSession } from 'features/signup/signupSlice';

const useSignUpType = () => {
  const currentSession = useSelector(selectCurrentSession);
  const dispatch = useDispatch()

  const setSignUpType = (signUpType: SignUpType) => {
    dispatch(setCurrentSession({
      type: signUpType,
    }));
  }

  return {
    signUpType: currentSession?.payload?.type?? SignUpTypes.STARTUP, 
    setSignUpType 
  };
}

export default useSignUpType;