import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
import ConversationCard from 'app/collaborate/conversation-card'
import CollabCard from 'app/service-collaborations/collab-card';
import { useGetMyConversationsQuery, ConversationType } from 'services/graphql/conversation'

const Conversations = () => {
  const user = useSelector(selectCurrentUser);
  const { data, isLoading, isSuccess } = useGetMyConversationsQuery()

  const conversations = isSuccess && data !== undefined ? 
    data.map((item, i) => {
      if (item.type === ConversationType.Conversation && item.conversation) {
        return <ConversationCard {...item?.conversation} key={i} type={user?.role?.type?? ''} />
      } else
      if (item.type === ConversationType.Collaboration && item.collaboration) {
        const { id, title, imageUrl } = item.collaboration 
        return <CollabCard {...{
          id,
          title,
          imageUrl
        }} key={i} />
      }

    }): null

  return (
    <div className="conversation-cards">
    {conversations}
    {isSuccess && data !== undefined && data.length === 0 && 
      <>
      <h6>You don't have any conversations at the moment</h6>
      <p>
      This is your own private space where you will find all the 
      conversations with your pre-qualified partner and your ALKELIO catalyst.
      </p>
      </>
    } 
    </div>
  )
}

export default Conversations
