import React from 'react'
import InputImage from "shared/forms/input-image";
import { Inputs, Person, ProjectTiming, Info } from './inputs'
import { FileItem } from "hooks/file-uploads";
import {
  Control,
  Controller,
  UseFormRegister
} from 'react-hook-form'
import MemberClientForm from './member-client.form';
import MemberAlkelioForm from './member-alkelio.form';
import { MemberExpression } from 'typescript';

export type MemberFormProps = {
  name: string;
  profile_image: FileItem | null;
  role: string;
  email: string;
  control: Control<Inputs>;
  register: UseFormRegister<Inputs>;
  index: number;
}

type Props = MemberFormProps & {
  teamType: 'client' | 'alkelio'
}

const MemberForm = ({teamType, ...rest}: Props) => {
  return (
    <>
    {teamType === 'client' ? <MemberClientForm {...rest} /> : null}
    {teamType === 'alkelio' ? <MemberAlkelioForm {...rest} /> : null}
    </>
  )
}

export default MemberForm