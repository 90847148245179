import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory
} from 'react-router-dom';
import TechSolutions from 'app/startup/dashboard/tech-solutions';
import TechRequests from 'app/corporate/dashboard/tech-requests';
import News from 'app/dashboard/news';
import AssignedCatalyst from 'app/shared/assigned-catalyst';
import LatestDocuments from './latest-documents';
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
import EntityConstants from 'app/entity-type/entityConstants';
import TechSolutionForm from '../startup/tech-solution-form';
import TechRequestForm from '../corporate/tech-request-form';

const Dashboard = () => {
  let { path } = useRouteMatch();
  const { entityType }: any = useParams();
  const user = useSelector(selectCurrentUser)
  

  return (
    <Switch>
      <Route exact path={path}>
        <div className="row">
          <div className="col">
            {user?.role?.type === "startup" && <TechSolutions />}
            {user?.role?.type === "corporate" && <TechRequests />}
          </div>
        </div>
        <div className="row d-block d-md-none">
          <div className="col">
            <AssignedCatalyst />
          </div>
        </div>
        <div className="row">
          <div className="mt-md-5 col-12 col-xxl-6">
            <News />
          </div>
          <div className="mt-md-5 col-12 col-xxl-6">
            {(user?.role?.type?? '') !== EntityConstants.CATALYST && 
            <div className="mb-4 row d-none d-md-block">
              <div className="col">
                <AssignedCatalyst />
              </div>
            </div>
            }
            <div className="row">
              <div className="col">
                <LatestDocuments />
              </div>
            </div>
          </div>
        </div>
      </Route>
      {entityType === EntityConstants.STARTUP &&
      <Route exact path={`${path}/tech-solutions/new`}>
        <TechSolutionForm/>
      </Route>}
      {entityType === EntityConstants.STARTUP &&
      <Route path={`${path}/tech-solutions/:id`}>
        <TechSolutionForm/>
      </Route>}
      {entityType === EntityConstants.CORP &&
      <Route exact path={`${path}/tech-requests/new`}>
        <TechRequestForm/>
      </Route>}
      {entityType === EntityConstants.CORP &&
      <Route path={`${path}/tech-requests/:id`}>
        <TechRequestForm/>
      </Route>}
    </Switch>
  );
};

export default Dashboard;
