import React from 'react'
import './assets/styles/person.scss'

import {Image} from 'shared/image';
import InputImage from "shared/forms/gray-image";
import ScheduleDateIcon from '../../shared/menu/help/assets/svgs/schedule-date.svg';

type Props = {
  userType: string;
  role: string;
  name: string;
  photoUrl: string;
  email: string
}

const styleRep = {
  marginTop: "8px",
  marginBottom: "8px",
  width: "80px",
  height: "80px",
  borderRadius: "50%",
};

const Person = ({userType, role, name, photoUrl, email} : Props) => {
  return (
    <div className="left__person">
      <div className="font-size-12 color-text line-height-100 text-center mb-2">You are talking to</div>
      {photoUrl ?
      <Image style={styleRep}
        src={photoUrl}
        alt={'profile_picture'}
      />: <InputImage layout="elipse-left" height="80px" width="80px"/>
      }
      <div className="mt-2 d-flex flex-row justify-center text-center align-items-end">
        <span className="color-azure semi-bold-type font-size-14 line-height-100 me-2">{name}</span>
        <span className="font-size-12 color-text line-height-100">{role}</span>
      </div>
      <a className="text-regular color-azure font-size-14 mt-1 pb-3" href={`mailto:${email}`}>{email}</a>
      <hr/>
      <div className="links">
        <button className="link">
          {/*@todo add nav link onclick */}
          <img src={ScheduleDateIcon}
            alt="Link"/>
          Book a meeting
        </button>
      </div>
    </div>
  )
}

export default Person
