import React from "react";
import "../assets/styles/conversation.scss";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import dayjs from "dayjs";
import _ from 'lodash';
import {
  Control,
  UseFormRegister,
  UseFormGetValues,
  useFormState,
  Controller,
} from "react-hook-form";
import Inputs from "./inputs";
import InputCheckBox from "shared/forms/input-checkbox";
import { CountryNames } from "shared/countries";
import { 
  UpdateConversationAgreementInput, 
  useUpdateConversationAgreementMutation
} from 'services/graphql/conversation';

type AgreementProps = {
  conversationId: string;
  view: string;
  description: string;
  proposal: any;
  scope: any;
  countryJurisdiction: any;
  business_use_case: any;
  getVals: UseFormGetValues<Inputs>;
  kpis: any;
  agreement: any;
  watch: any;
  register: UseFormRegister<Inputs>;
  control: Control<Inputs>;
};

const enum AgreementBelongsTo {
  Client,
  Supplier
}

const Agreement = React.forwardRef(
  (
    {
      conversationId,
      view,
      description,
      proposal,
      scope,
      countryJurisdiction,
      business_use_case,
      kpis,
      agreement,
      watch,
      register,
      getVals,
      control,
    }: AgreementProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [ agreeClient, setAgreeClient ] = React.useState()
    const [ agreeSupplier, setAgreeSupplier ] = React.useState()

    const watchDesc = watch("business_use_case.description_project");
    const watchTfrom = watch("scope.timing_from");
    const watchTto = watch("scope.timing_to");
    const watchPrice = watch("proposal.price");
    const watchCur = watch("proposal.currency");
    const watchDel = watch("proposal.deliverables");
    const watchKpi = watch("scope.kpis");

    const watchClientName = watch('agreement.client_name');
    const watchClientAddr = watch('agreement.client_address')
    const watchClientCompany = watch('agreement.client_company')    
    const watchClientAgree = watch('agreement.client_agree')

    const watchSupplierName = watch('agreement.supplier_name');
    const watchSupplierAddr = watch('agreement.supplier_address')
    const watchSupplierCompany = watch('agreement.supplier_company')
    const watchSupplierAgree = watch('agreement.supplier_agree')

    const [countryJurName, setCountryJurName] = React.useState("");

    const [updateConversationAgreement] = useUpdateConversationAgreementMutation()

    React.useEffect(() => {
      if (countryJurisdiction && countryJurisdiction.length >0){
        const country = _.find(CountryNames, { value: countryJurisdiction });
        if (country) setCountryJurName(country.label);
      }else{
        setCountryJurName("Not Available")
      }
      
    }, [countryJurisdiction]);

    const formatTiming = (d: Date) => {
      var dayTo = dayjs(d);
      let toForm = dayTo.format("DD MMMM YYYY");
      return toForm.toString();
    };
    const numberWithCommas = (x: number) => {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    };
    
    
    const updateAgreementClient = async () => {
      const agreementUpdateInput:UpdateConversationAgreementInput = {
        id: conversationId,
        agreement: {
          client_name: watchClientName,
          client_address: watchClientAddr,
          client_company: watchClientCompany,
          client_agree: watchClientAgree
        }
      }
      await updateConversationAgreement(agreementUpdateInput);
    };

    const updateAgreementSupplier = async () => {
      const agreementUpdateInput:UpdateConversationAgreementInput = {
        id: conversationId,
        agreement: {
          supplier_name: watchSupplierName,
          supplier_address: watchSupplierAddr,
          supplier_company: watchSupplierCompany,
          supplier_agree: watchSupplierAgree
        }
      }
      await updateConversationAgreement(agreementUpdateInput);
    };
    React.useEffect(() => {
      setAgreeClient(watchClientAgree)
      setAgreeSupplier(watchSupplierAgree)
    }, [])
    
    React.useEffect(() => {
      const update = async (belongsTo: AgreementBelongsTo) => {
        if (belongsTo === AgreementBelongsTo.Client)
          await updateAgreementClient();
        if (belongsTo === AgreementBelongsTo.Supplier)
          await updateAgreementSupplier();
      };


      if (agreeClient === false && agreeClient !== watchClientAgree) {
        update(AgreementBelongsTo.Client)
      }

      if (agreeSupplier === false && agreeSupplier !== watchSupplierAgree) {
        update(AgreementBelongsTo.Supplier)
      }

      setAgreeClient(watchClientAgree)
      setAgreeSupplier(watchSupplierAgree)
      
    }, [watchClientAgree, watchSupplierAgree]);

    return (
      <>
        <Box
          border={1}
          borderColor="grey.300"
          borderRadius={10}
          className="mainBoard"
          paddingBottom={2}
        >
          <div className="section">
            <h6>Summary</h6>

            <label>
              <b className="darkHeader">Project Description</b>
            </label>

            <div className="body">
              {business_use_case?.description_project === null ? (
                "Description is not available"
              ) : (
                <>
                  {view !== "startup" ? (
                    <>{watchDesc}</>
                  ) : (
                    <>{business_use_case?.description_project}</>
                  )}{" "}
                </>
              )}
            </div>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
            >
              <Grid item md={6}>
                <label>
                  <b className="darkHeader">Duration of the Project</b>
                </label>

                <div className="body">
                  {/* format date correctly */}
                  {view !== "startup" ? (
                    <>
                      <>{formatTiming(watchTfrom)}</> -{" "}
                      <>{formatTiming(watchTto)}</>
                    </>
                  ) : (
                    <>
                      {formatTiming(scope?.timing_from)} -{" "}
                      <>{formatTiming(scope?.timing_to)}</>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item md={6}>
                <label>
                  <b className="darkHeader">Duration of the Contract</b>
                </label>

                <div className="body">Not available</div>

                {/*@todo Add Contract duration */}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
            >
              <Grid item md={6}>
                <label>
                  <b className="darkHeader">Pricing</b>
                </label>

                <div className="body">
                  {proposal?.price !== null ? (
                    <>
                      {view === "startup" ? (
                        <>{numberWithCommas(Number(watchPrice))}</>
                      ) : (
                        <>{proposal?.price}</>
                      )}
                    </>
                  ) : (
                    "Pricing is not available"
                  )}
                  {proposal?.price !== null && proposal?.currency !== null ? (
                    <>
                      {" "}
                      -&nbsp;
                      {view === "startup" ? (
                        <>{watchCur}</>
                      ) : (
                        <>{proposal?.currency}</>
                      )}
                    </>
                  ) : (
                    " "
                  )}
                </div>
              </Grid>
              <Grid item md={6}>
                <label>
                  <b className="darkHeader">Country (Jurisdiction)</b>
                </label>
                {/*@todo Add Contract duration */}
                <div className="body">
                  {countryJurName}
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
            >
              <Grid item md={6}>
                <label>
                  <b className="darkHeader">Deliverables</b>
                </label>

                {proposal && proposal?.deliverables ? (
                  <div
                    className="body"
                    style={{ maxWidth: "393px", marginRight: "32px" }}
                  >
                    {view === "startup" ? (
                      <>{watchDel}</>
                    ) : (
                      <>{proposal?.deliverables}</>
                    )}
                  </div>
                ) : (
                  <div className="body">None</div>
                )}
              </Grid>
              <Grid item md={6}>
                <label>
                  <b className="darkHeader">KPIs / success metrics</b>
                </label>

                <Grid container spacing={0}>
                  {(watchKpi?? []).length === 0 ? (
                    <div className="body">KPIs are not available</div>
                  ) : (
                    <>
                      <ul>
                        {(watchKpi?? []).map((item: any) => {
                          return (
                            <li className="tagItem">{item}</li>
                            // <Tooltip title={item}>
                            //   <div className="flex-row flex-wrap mb-1 d-flex tags">
                            //     <Pill icon={TickWhiteIcon} label={item} />
                            //   </div>
                            // </Tooltip>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Box
          border={1}
          borderColor="grey.300"
          borderRadius={10}
          className="mainBoard"
        >
          <div className="section" style={{ marginBottom: "20px" }}>
            <h6>Agreement</h6>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
              style={{ marginTop: 0 }}
            >
              <Grid item md={12}>
                <div className="agreementForm">
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item md={4}>
                      <label style={{ paddingTop: "1em", width: "auto" }}>
                        <div className="text">CLIENT NAME</div>
                        <input
                          type="text"
                          placeholder={agreement?.client_name || ""}
                          className="canBeDisabled"
                          disabled={view === "startup"}
                          {...register("agreement.client_name")}
                        />
                      </label>
                    </Grid>
                    <Grid item md={4}>
                      <label style={{ paddingTop: "1em", width: "auto" }}>
                        <div className="text">COMPANY NAME</div>
                        <input
                          type="text"
                          placeholder={agreement?.client_company || ""}
                          className="canBeDisabled"
                          disabled={view === "startup"}
                          {...register("agreement.client_company")}
                        />
                      </label>
                    </Grid>
                    <Grid item md={4}>
                      <label style={{ paddingTop: "1em", width: "auto" }}>
                        <div className="text">ADDRESS</div>
                        <input
                          type="text"
                          placeholder={agreement?.client_address || ""}
                          disabled={view === "startup"}
                          className="canBeDisabled"
                          {...register("agreement.client_address")}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="checkBox">
            <Controller
              name="agreement.client_agree"
              control={control}
              defaultValue={agreement?.client_agree || false}
              render={({ field }) => (
                <InputCheckBox
                  className="mb-2 mr-2"
                  label="I agree to the terms"
                  disabled={view === "startup"}
                  {...field}
                />
              )}
            />
            <hr />
          </div>
          <div
            className="section"
            style={{ marginBottom: "20px", marginTop: "0px" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className=""
            >
              <Grid item md={12}>
                <div className="agreementForm">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item md={4}>
                      <label>
                        <div className="text">SUPPLIER NAME</div>
                        <input
                          type="text"
                          placeholder={agreement?.supplier_name || ""}
                          disabled={view === "corporate"}
                          className="canBeDisabled"
                          {...register("agreement.supplier_name")}
                        />
                      </label>
                    </Grid>
                    <Grid item md={4}>
                      <label>
                        <div className="text">COMPANY NAME</div>
                        <input
                          type="text"
                          placeholder={agreement?.supplier_company || ""}
                          disabled={view === "corporate"}
                          className="canBeDisabled"
                          {...register("agreement.supplier_company")}
                        />
                      </label>
                    </Grid>
                    <Grid item md={4}>
                      <label>
                        <div className="text">ADDRESS</div>
                        <input
                          type="text"
                          placeholder={agreement?.supplier_address || ""}
                          disabled={view === "corporate"}
                          className="canBeDisabled"
                          {...register("agreement.supplier_address")}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="checkBox">
            <Controller
              name="agreement.supplier_agree"
              control={control}
              defaultValue={agreement?.supplier_agree || false}
              render={({ field }) => (
                <InputCheckBox
                  className="mb-2 mr-2"
                  label="I agree to the terms"
                  disabled={view === "corporate"}
                  {...field}
                />
              )}
            />
          </div>
        </Box>
      </>
    );
  }
);

export default Agreement;
