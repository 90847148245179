import React, { useEffect } from 'react';
import CircleOCloseIcon from 'assets/svgs/circle-o-close.svg';
import { useSelector, useDispatch } from 'react-redux';
import { close } from '../../features/modals/modalSlice';
import { appReducerStateType } from 'store';
import './assets/styles/generic-modal.scss';

interface GenericModalPropType {
  modalId: string;
  size?: string;
  children?: React.ReactNode;
  title?: string;
  show?: boolean;
  centered?: boolean;
  disabled?: boolean;
  thinMargins?: boolean;
  onClose?: () => unknown;
  disableClose?: boolean;
}

const GenericModal = (props: GenericModalPropType) => {
  const { active, modalId } = useSelector((state: appReducerStateType) => state.modal)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(close())
    props.onClose && props.onClose();
  }

  useEffect(() => {
    
  }, [])

  if (active) {
    document.getElementById('parent-body')?.style.setProperty('overflow-y', 'hidden');
  }
  else {
    document.getElementById('parent-body')?.style.setProperty('overflow-y', 'auto');
  }

  if (active && props.modalId === modalId && props.children)
    return (
      <div className={`generic-modal ${active && props.modalId === modalId ? 'active' : ''} ${props.thinMargins && 'thin-margins'}`}>
        <div className={`modal-box ${props.size} ${props.centered ? 'modal-centered' : ''}`}>
          <div className="header">
            <h3 className="title">{props.title}</h3>
            {!props.disableClose && <button className="close" type="button" onClick={handleClose} disabled={props.disabled}>
              <img src={CircleOCloseIcon} alt="close" />
            </button>}
          </div>
          <div className="body">
            {active && props.children}
          </div>
        </div>
      </div>
    )
  else 
    return null;
}

export default GenericModal;