import React, { useState, useCallback, useRef, useEffect } from 'react';
import { UseVerificationPayload } from 'generated/graphql';
import './assets/styles/sign-up-verification.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectVerificationUuid,
    selectResendVerificationCodeWaitTime,
    setJwt,
    setResendVerificationCodeWaitTime
} from 'features/signup/signupSlice';
import { open, close } from '../../features/modals/modalSlice';
import {
    useUseVerificationCodeMutation,
    useRegenerateVerificationCodeMutation
} from 'services/graphql/on-boarding-session';
import Config from 'config';
import Session from 'session'
import { useHistory } from 'react-router-dom'

const SignUpVerification = () => {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);

    const [useVerificationCode] = useUseVerificationCodeMutation();
    const [regenerateVerificationCode] = useRegenerateVerificationCodeMutation();

    const [isResendVerificationOnProcess, setIsResendVerificationOnProcess] = useState(false);
    const resendVerificationCodeWaitTime = useSelector(selectResendVerificationCodeWaitTime);
    const [resendTime, setResendTime] = useState(resendVerificationCodeWaitTime);

    const verificationUuid = useSelector(selectVerificationUuid);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({} as any), []);

    const inputRef1 = useRef<HTMLInputElement>(null);
    const inputRef2 = useRef<HTMLInputElement>(null);
    const inputRef3 = useRef<HTMLInputElement>(null);
    const inputRef4 = useRef<HTMLInputElement>(null);
    const inputRef5 = useRef<HTMLInputElement>(null);
    const inputRef6 = useRef<HTMLInputElement>(null);
    const submitRef = useRef<HTMLButtonElement>(null);

    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);

    const [enableAutoFocus, setEnabledAutoFocus] = useState(false);
    const [inputFocusStates, setInputFocusStates] = useState([true, false, false, false, false, false]);
    const [isPastingValues, setIsPastingValues] = useState(false);

    const OnInput = (event: React.FormEvent<HTMLInputElement>, idx: number) => {
        if (!isPastingValues) {

            const newInputVal = (event.nativeEvent as InputEvent).data;
            let newInputFocusStates: boolean[] = [false, false, false, false, false, false];
            let newOtpValues = JSON.parse(JSON.stringify(otpValues));

            if (newInputVal) {
                let moveToNext = false;
                for (let i = 0; i < 6; i++) {
                    if (moveToNext) {
                        newInputFocusStates[i] = (true);
                        moveToNext = false;
                    }
                    else
                        newInputFocusStates[i] = (false);

                    moveToNext = (i === idx);

                    if (i === idx)
                        newOtpValues[idx] = newInputVal;
                }
            }
            else {
                let moveToPrev = false;
                for (let i = 6; i > 0; i--) {
                    if (idx === 0) {
                        newOtpValues[idx] = '';
                        newInputFocusStates[0] = (true);
                    }
                    else {
                        if (moveToPrev) {
                            newInputFocusStates[i - 1] = (true);
                            moveToPrev = false;
                        }
                        else
                            newInputFocusStates[i - 1] = (false);
                    }

                    moveToPrev = ((i - 1) === idx);

                    if (i === idx)
                        newOtpValues[idx] = '';
                }
            }
            setOtpValues(newOtpValues);
            setInputFocusStates(newInputFocusStates);
            setEnabledAutoFocus(
                !newInputFocusStates[0] && !newInputFocusStates[1] && !newInputFocusStates[2] &&
                !newInputFocusStates[3] && !newInputFocusStates[4] && !newInputFocusStates[5]
            );
            forceUpdate();
        }
    }

    const OnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        let pastedValue = event.clipboardData.getData('text');
        if (Number(pastedValue)) {
            let newOtpValues: string[] = [];
            if (pastedValue.length == 6) {
                for (let i = 0; i < 6; i++) {
                    newOtpValues.push(pastedValue[i]);
                }
            }
            setIsPastingValues(true);
            setOtpValues(newOtpValues);
            setInputFocusStates([false, false, false, false, false, false]);
            setEnabledAutoFocus(true);
            setTimeout(() => {
                setIsPastingValues(false);
            }, 1000);
        }
    }

    const OnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
        let newInputFocusStates: boolean[] = [false, false, false, false, false, false];
        if (event.key === "ArrowLeft" && idx > 0) {
            newInputFocusStates[(idx - 1)] = true;
            setInputFocusStates(newInputFocusStates);
            forceUpdate();
        }
        else if (event.key === "ArrowRight" && idx < 5) {
            newInputFocusStates[(idx + 1)] = true;
            setInputFocusStates(newInputFocusStates);
            forceUpdate();
        }
    }

    useEffect(() => {
        if (inputFocusStates[0]) {
            inputRef1?.current?.focus();
        }
    }, [inputFocusStates[0]]);
    useEffect(() => {
        if (inputFocusStates[1]) {
            inputRef2?.current?.focus();
        }
    }, [inputFocusStates[1]]);
    useEffect(() => {
        if (inputFocusStates[2]) {
            inputRef3?.current?.focus();
        }
    }, [inputFocusStates[2]]);
    useEffect(() => {
        if (inputFocusStates[3]) {
            inputRef4?.current?.focus();
        }
    }, [inputFocusStates[3]]);
    useEffect(() => {
        if (inputFocusStates[4]) {
            inputRef5?.current?.focus();
        }
    }, [inputFocusStates[4]]);
    useEffect(() => {
        if (inputFocusStates[5]) {
            inputRef6?.current?.focus();
        }
    }, [inputFocusStates[5]]);
    useEffect(() => {
        if (enableAutoFocus) {
            submitRef?.current?.focus();
        }
    }, [enableAutoFocus]);

    useEffect(() => {
        // localStorage.removeItem(Config.onboardingSessionStorageKey);
    }, [])

    const ResendCode = async () => {
        await regenerateVerificationCode(verificationUuid).then(() => {
            setIsResendVerificationOnProcess(false);
            let waitTime = 20;
            setResendTime(waitTime);
            setResendVerificationCodeWaitTime(waitTime);
            localStorage.setItem(Config.onboardingResendVerificationCodeWaitTimeKey, String(waitTime));

            const timer = setInterval(function () {
                waitTime--;
                setResendTime(waitTime);
                setResendVerificationCodeWaitTime(waitTime);
                localStorage.setItem(Config.onboardingResendVerificationCodeWaitTimeKey, String(waitTime));

                if (waitTime < 0) {
                    clearInterval(timer);
                }
            }, 1000);
        });
    }

    const Submit = async (code: string) => {
        const result: UseVerificationPayload = await useVerificationCode({
            code,
            uuid: verificationUuid
        }).unwrap();

        if (result) {
            result.jwt && dispatch(setJwt(result.jwt));
            // localStorage.setItem(Config.onboardingJwtKey, String(result.jwt));
            // better to save jwt in state, not local storage, local storage will have issues
            // Set jwt for auth, user has access already
            result.jwt && Session.set(result.jwt);
        }
        dispatch(close());
        dispatch(open('sign-in'));
        
    }
    return (
        verificationUuid ? (
            <div className="sign-up-verification" >
                <h1 className="mb-4 text-center">Verify Account</h1>
                {error && <div className="alert alert-danger" role="alert">
                    Technical error. Please try again in a few minutes
                </div>}
                <div className="description-wrapper">
                    <p className="text-muted description">
                        We have sent an activation link to your email. Click on the link to continue your registration.
                    </p>
                    <p className="description"><b>or</b></p>
                    <p className="text-muted description">Enter the code in the box below:</p>
                </div>
                <div className="mt-4 input-otp-wrapper">
                    <input type="number" className="ml-2" defaultValue={otpValues[0]} value={otpValues[0]} onInput={(x) => { OnInput(x, 0); }} ref={inputRef1} autoFocus={true} onKeyDown={(x) => { OnKeyDown(x, 0) }} onPaste={(x) => { OnPaste(x) }} />
                    <input type="number" className="ml-2" defaultValue={otpValues[1]} value={otpValues[1]} onInput={(x) => { OnInput(x, 1); }} ref={inputRef2} onKeyDown={(x) => { OnKeyDown(x, 1) }} onPaste={(x) => { OnPaste(x) }} />
                    <input type="number" className="ml-2" defaultValue={otpValues[2]} value={otpValues[2]} onInput={(x) => { OnInput(x, 2); }} ref={inputRef3} onKeyDown={(x) => { OnKeyDown(x, 2) }} onPaste={(x) => { OnPaste(x) }} />
                    <span className="ml-2">-</span>
                    <input type="number" className="ml-2" defaultValue={otpValues[3]} value={otpValues[3]} onInput={(x) => { OnInput(x, 3); }} ref={inputRef4} onKeyDown={(x) => { OnKeyDown(x, 3) }} onPaste={(x) => { OnPaste(x) }} />
                    <input type="number" className="ml-2" defaultValue={otpValues[4]} value={otpValues[4]} onInput={(x) => { OnInput(x, 4); }} ref={inputRef5} onKeyDown={(x) => { OnKeyDown(x, 4) }} onPaste={(x) => { OnPaste(x) }} />
                    <input type="number" className="ml-2" defaultValue={otpValues[5]} value={otpValues[5]} onInput={(x) => { OnInput(x, 5); }} ref={inputRef6} onKeyDown={(x) => { OnKeyDown(x, 5) }} onPaste={(x) => { OnPaste(x) }} />
                </div>
                <div className="mt-5 text-center input-otp-button-wrapper pb-4 pb-md-0">
                    <button type="submit" className="mb-5 btn btn-primary" ref={submitRef}
                        onKeyDown={(x) => {
                            let newInputFocusStates: boolean[] = [false, false, false, false, false, false];
                            if (x.key === "ArrowLeft" || x.key === "ArrowUp") {
                                newInputFocusStates[5] = true;
                                setInputFocusStates(newInputFocusStates);
                                forceUpdate();
                            }
                        }}
                        onClick={() => {
                            setError(false);
                            try {
                                if (otpValues.length === 6) {
                                    let code = '';
                                    otpValues.forEach(x => {
                                        code += x;
                                    });
                                    Submit(code);
                                }
                            }
                            catch (err) {
                                console.log(err);
                                setError(true);
                            }
                        }}
                    >
                        Next &gt;
                    </button>
                    {isResendVerificationOnProcess ? (
                        <span className="text-muted">Please wait...</span>
                    ) : (
                        resendTime > 0 ? (
                            <span className="text-muted">Resend activation link in {resendTime} </span>
                        ) : (
                            <a href="#" onClick={() => {
                                setError(false);
                                setIsResendVerificationOnProcess(true);
                                try {
                                    ResendCode();
                                }
                                catch (err) {
                                    console.log(err);
                                    setError(true);
                                    setIsResendVerificationOnProcess(false);
                                }
                            }}>
                                Resend activation link
                            </a>
                        )
                    )}

                </div>
            </div >
        ) : (
            <div className="alert alert-danger" role="alert">
                Sorry. You don't have permission to see this page.
            </div>
        )
    )
}

export default SignUpVerification;