import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { RatingConnection } from 'generated/graphql'

export const ratingsApi = createApi({
  reducerPath: "ratingsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getRating: builder.query<RatingConnection, string>({
      query: (item_id) => ({
        document: gql`
          query getAverageRatings($item_id: String!) {
            ratingsConnection(where: {
              item_id: $item_id
            }) {
              aggregate {
                count
                avg {
                  value
                }
              }
            }
          }
        `,
        variables: {
          item_id
        }
      }),
      transformResponse: (response:any) => response.ratingsConnection
    })
  })
})

export const {
  useGetRatingQuery
} = ratingsApi
