import React, { useCallback, useState, useEffect, useRef } from 'react';
import './assets/styles/select-multiple.scss';
import XWhiteIcon from 'app/shared/assets/svgs/x-white.svg';
import XNightSky from 'app/shared/assets/svgs/x-nightsky.svg';
import CaretDownBlueIcon from 'app/shared/assets/svgs/caret-down-blue.svg';
import BaseInput from 'shared/forms/base-input';

interface SelectedItemProps {
  text: string;
  onRemove: (text: string) => unknown;
  variant?: string;
}

const SelectedItem = (props: SelectedItemProps) => {
  return (
    <div className={`selected-item ${props.variant}`}>
      <span>{props.text}</span>
      <button type="button" onClick={() => {
        props.onRemove && props.onRemove(props.text)
      }}>
        <img src={props.variant !== undefined && props.variant?.indexOf('inverse') >= 0 ? XNightSky : XWhiteIcon} alt="delete" />
      </button>
    </div>
  )
}

export interface OptionItem {
  label: string;
  value?: string;
}

interface SelectMultipleProps extends BaseInput {
  options: Array<OptionItem>;
  defaultValue?: Array<string>;
  value?: Array<string>;
  variant?: string;
  placeholder?: string;
  placeholderFn?: (options: any, defaultPlaceholder: string) => string;
  onChange?: (value: Array<String>) => unknown;
  enableOthers?: boolean;
  sort?: false | ((a: OptionItem, b: OptionItem) => number);
}

const OptionOthers = {
  label: 'Others',
  value: 'others'
}

const sortOptionsDefault = (a: OptionItem, b: OptionItem) => {
  if (a.label > b.label) return 1;
  else
    if (a.label < b.label) return -1;
    else
      return 0;
}

const SelectMultiple = React.forwardRef((
  {
    name,
    options,
    defaultValue,
    variant = "",
    className,
    placeholderFn,
    value,
    onChange,
    sort = sortOptionsDefault,
    enableOthers = false,
    placeholder = "Select..."
  }: SelectMultipleProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const optionsMap: Record<string, OptionItem> = {}
  const optionsFinal = [...options]

  if (enableOthers) {
    optionsFinal.push(OptionOthers)
  }

  optionsFinal.forEach((item) => {
    optionsMap[String(item.value || item.label)] = item;
  })

  const getSelectedFromValues = (values: Array<string> | undefined | null): Array<OptionItem> => {
    if (values === undefined || values === null) {
      return []
    } else {
      let result: Array<OptionItem> = []
      for (let i = 0; i < values.length; i++) {
        if (optionsMap[values[i]]) {
          result.push(optionsMap[values[i]])
        }
      }
      return result
    }
  }

  const selectedOptionsFromProps: Array<OptionItem> = getSelectedFromValues(defaultValue || value)

  const elementRef = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [matchedOptions, setMatchOptions] = useState<Array<OptionItem>>(sort ? [...options.sort(sort), OptionOthers] : optionsFinal)
  const [selectedOptions, setSelected] = useState<Array<OptionItem>>(selectedOptionsFromProps)
  const [showOptions, setShowOptions] = useState(false)
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const matchedOptions = optionsFinal.filter((item) => (
      item.label.toUpperCase().indexOf(e.target.value.toUpperCase()) === 0
    ))
    setMatchOptions(matchedOptions)
    if (matchedOptions.length > 0) {
      setShowOptions(true)
    }
  }

  const removeSelectedOption = (text: string) => {
    const filtered = selectedOptions.filter((item) => (item.label !== text));
    setSelected(filtered);
    onChange && onChange(filtered.map((item) => (item.value || item.label)));
  }

  const toggleOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!showOptions) {
      setMatchOptions(optionsFinal);
      setShowOptions(true)
      inputRef && inputRef.current && inputRef.current.focus();
    } else {
      setShowOptions(false)
    }
  }

  const selectOption = (option: OptionItem) => {
    if (option !== null) {
      inputRef && inputRef.current !== null && (inputRef.current.value = "");
      const selected = [...selectedOptions, option]
      setSelected(selected)
      setShowOptions(false)
      onChange && onChange(selected.map((item) => (item.value || item.label)));
      setFocusedIndex(null)
    }
  }

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setShowOptions(false)
    }
  }, [])

  const clickListener = useCallback(
    (e: MouseEvent) => {
      const element = (elementRef.current! as any)
      if (element !== null) {
        if (!element.contains(e.target)) {
          setShowOptions(false)
        }
      }
    },
    [elementRef.current]
  )

  useEffect(() => {
    setSelected(getSelectedFromValues(defaultValue || value));

    document.addEventListener('click', clickListener)
    document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [value])

  const filterByUnSelected = (item: OptionItem, i: number) => (
    selectedOptions.findIndex((o) => o.label === item.label) < 0
  )

  const handleUpDown = (evt:React.KeyboardEvent<HTMLDivElement>) => {
    const maxLength = matchedOptions.filter(filterByUnSelected).length;
    if (evt.key === 'ArrowDown') {
      if (!showOptions) {
        setShowOptions(true)
        setFocusedIndex(0)
      } else {
        setFocusedIndex(focusedIndex === null ? 0 : (focusedIndex + 1) % maxLength)
      }
    }
    if (evt.key === 'ArrowUp') {
      if (showOptions && focusedIndex !== null) {
        setFocusedIndex(focusedIndex > 0 ? focusedIndex - 1 : maxLength - 1);
      }
    }
    if (evt.key === 'Enter') {
      focusedIndex !== null && selectOption(matchedOptions.filter(filterByUnSelected)[focusedIndex]);
    }
  }

  useEffect(() => {
    if (focusedIndex !== null) {
      if (optionsRef && optionsRef.current && focusedIndex !== null) {
        const optionItemElement = optionsRef.current.querySelectorAll('div.option-item')[focusedIndex] as HTMLElement
        
        if (((optionItemElement.offsetTop + optionItemElement.offsetHeight) - optionsRef.current.scrollTop) >= optionsRef.current.offsetHeight) {
          if (focusedIndex === matchedOptions.filter(filterByUnSelected).length - 1) {
            optionsRef.current.scrollTop = optionItemElement.offsetTop;
          } else {
            optionsRef.current.scrollTop += optionItemElement.offsetHeight;
          }
        } else
        if (((optionItemElement.offsetTop) - optionsRef.current.scrollTop) <= 0) {
          optionsRef.current.scrollTop = optionItemElement.offsetTop;
        }
      }
    }
  }, [focusedIndex])

  return (
    <div 
      className={`select-multiple ${variant} ${className ?? ''}`} 
      ref={elementRef} 
      onKeyDown={handleUpDown}
    >
      <div className="input">

        <input type="text" autoComplete="false"
          placeholder={(placeholderFn && placeholderFn(selectedOptions, placeholder)) || placeholder} 
          onChange={handleChange} 
          ref={inputRef} 
        />

        <div className={`more ${showOptions ? 'expanded' : ''}`} onClick={toggleOptions}>
          <img src={CaretDownBlueIcon} alt="more" />
        </div>
      </div>

      {selectedOptions.length > 0 && <div className="selected-options">
        {selectedOptions.map((item, i) => (
          <SelectedItem key={i} text={item.label}
            onRemove={removeSelectedOption}
            variant={variant}
          />
        ))}
      </div>}

      <div 
        className={`options ${showOptions ? 'visible' : 'hidden'}`}
        ref={optionsRef}
      >
        {matchedOptions.filter(filterByUnSelected).map((item, i) => (
          <div 
            className={`option-item ${focusedIndex === i ? 'focused':''}`} 
            key={i} 
            onClick={() => {
              selectOption(item);
            }}
            onMouseEnter={() => setFocusedIndex(i)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
});

export default SelectMultiple;