import React, { useEffect, useRef } from "react";
import "../assets/styles/conversation.scss";
import Grid from "@material-ui/core/Grid";
import { Box, Typography,Modal } from "@material-ui/core";
import WhiteXIcon from 'app/shared/assets/svgs/WhiteX.svg';
import _ from 'lodash';
// import Button from '@material-ui/core/Button';
import Button from "app/shared/button";
import BlueArrow from '../assets/icons/bluFrwrd.svg';
import CircleOCrossIcon from 'app/shared/assets/svgs/circle-o-cross.svg';
import ForwardArrow from '../assets/icons/frwrdArw.svg';
import BackArrow from '../assets/icons/bckArw.svg';
import UploadWhiteIcon from '../assets/icons/upload-white.svg';
import UploadAzureIcon from 'app/shared/assets/svgs/upload-azure.svg'

import DocumentBlueIcon from 'app/shared/assets/svgs/document-blue.svg';
// import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { 
  useUpdateConversationMutation,
  useMakeConversationAgreementPdfMutation,
  useUpdateConversationAgreementMutation,
  UpdateConversationAgreementInput
} from "services/graphql/conversation";
import { useDispatch } from "react-redux";
import { showToast } from "features/toasts/toastsSlice";
import useFileUpload, { FileItem } from "hooks/file-uploads";
import { convertFormConversationToInput } from './util';
import { UploadFile } from "generated/graphql";
import { CollectionsOutlined } from "@material-ui/icons";

type NavigationProps = {
  view: string;
  isDisabled: boolean;
  data: any;
  business_use_case: any;
  scope: any;
  proposal: any;
  agreement: any;
  value: any;
  setVValue: any;
  watch:any;
  handleSubmit: any;
  uploadedContracts: any;
  stateKPIs: any;
};

const Navigation = React.forwardRef(
  (
    {
      view,
      data,
      isDisabled,
      agreement,
      watch,
      value,
      setVValue,
      handleSubmit,
      uploadedContracts = []
    }: NavigationProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const uploadsRef = useRef<FileItem[]>([])
    // const [uploaded, setUploaded] = React.useState([uploadedContracts]);
    const [updateConversation] = useUpdateConversationMutation();
    const [makeConversationAgreementPdf]= useMakeConversationAgreementPdfMutation();
    const [updateConversationAgreement] = useUpdateConversationAgreementMutation()
    // watch variables for uploads changes in Form 
    /*
    const watchLegalCorp = watch('scope.legal_requirements_corporate.attachment');
    const watchLegalStart = watch('scope.legal_requirements_startup.attachment');
    const watchTechCorp = watch('scope.technical_requirements_corporate.attachment');
    const watchTechStart = watch('scope.technical_requirements_startup.attachment');
    const watchOtherCorp = watch('scope.other_requirements_corporate.attachment');
    const watchOtherStart = watch('scope.other_requirements_startup.attachment');
    */
    const watchClientName = watch('agreement.client_name');
    const watchClientAddr = watch('agreement.client_address')
    const watchClientCompany = watch('agreement.client_company')    
    const watchClientAgree = watch('agreement.client_agree')

    const watchSupplierName = watch('agreement.supplier_name');
    const watchSupplierAddr = watch('agreement.supplier_address')
    const watchSupplierCompany = watch('agreement.supplier_company')
    const watchSupplierAgree = watch('agreement.supplier_agree')

    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [fileUrl, setFileUrl] = React.useState('');
    const [isShown, setIsShown] = React.useState(false);
    const { uploads, remove, onChange, setUploads } = useFileUpload({
      max: 1,
    });

    const handleBack = () => {
      if (value > 0) {
        setVValue(value - 1);
      }

    };
    const checkForPdf = (text:string) =>{
      if(text ===undefined){
        return false;
      }
      let result = text.substring(text.length - 3);
      if(result ==='pdf'){
        // console.log("result file",result);
        return true;
      }
      return false;
    }
    const displayModal = (url:string) =>{
      // console.log("Displayingg modal",url);
      setFileUrl(url);
      handleOpen();
    }
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    const handleOpen = () => {
      setIsModalVisible(true);
    };
    const removeFile = async (fid: string) => {
      await updateConversation({
        id: data?.id as string,
        conversation: {
          agreement: {
            uploaded_contracts: uploads.filter(item => item.id !== fid).map(item => item.id)
          }
        },
      });
      await remove(fid);
    }
    const removeAllFiles = async () => {
      await updateConversation({
        id: data?.id as string,
        conversation: {
          agreement: {
            uploaded_contracts: []
          }
        },
      });
    }
    const handleUploadSave = async (uploads: any) => {
      await updateConversation({
        id: data?.id as string,
        conversation: {
          agreement: {
            uploaded_contracts: uploads.map((item: FileItem) => item.id)
          }
        },
      });
    }
    
    useEffect(() => {
      /*
      if (_.isEmpty(uploadsRef?.current)) {
        return;
      }
      */
      
      if ((uploads.length > 0 && uploads.length !== uploadsRef?.current?.length) ||
        (uploads?.[0]?.id !== uploadsRef?.current?.[0]?.id)) {
        handleUploadSave(uploads);
      }
      
      uploadsRef.current = uploads;
    }, [uploads])

    useEffect(() => {
      const initialUploads = (uploadedContracts as UploadFile[]).map(({ id, url, name }) => ({
        id,
        name,
        url
      })) as FileItem[]
      setUploads(initialUploads)

      uploadsRef.current = initialUploads;
    }, [])
    
    const createContract = async ()=>{
      const id = Number(data?.id);

      const agreementUpdateInput:UpdateConversationAgreementInput = {
        id: data?.id,
        agreement: {
          client_name: watchClientName,
          client_address: watchClientAddr,
          client_company: watchClientCompany,
          client_agree: watchClientAgree,
          supplier_name: watchSupplierName,
          supplier_address: watchSupplierAddr,
          supplier_company: watchSupplierCompany,
          supplier_agree: watchSupplierAgree
        }
      }
      await updateConversationAgreement(agreementUpdateInput);
      
      const agreementPdf = await await makeConversationAgreementPdf({
        id
      }).unwrap();
      
      let downloadLink= agreementPdf?.makeConversationAgreementPdf?.url;
      window.open(downloadLink, '_blank');
      console.log("What is generated",downloadLink);
    }
    
    const handleSave = async (conversation: any) => {
      //problem is that conversation is not passed
      console.log("func Conv",conversation);
      window.scrollTo({
        top: 0,
        left: 0, 
        behavior: 'auto'
      })
      let tab = "";
      switch (value) {
        case 0:
          tab = "Business Use Case";
          break;
        case 1:
          tab = "Scope";
          break;
        case 2:
          tab = "Proposal";
          break;
        case 3:
          tab = "Agreement";
          break;
        default:
          break;
      }
      interface LooseObject {
        [key: string]: any;
      }
      
      const id = data?.id as string;
      const result = await await updateConversation({
        id,
        conversation: convertFormConversationToInput(conversation),
      }).unwrap();
      
      if (value < 3) setVValue(value + 1);
      dispatch(
        showToast({
          title: tab + " Updated Successfully",
          body: "",
          time: null,
        })
      );
    };

    return (
      <>
        <Box
          border={1}
          borderColor="grey.300"
          borderRadius={10}
          className="buttonsBox"
        >
          <div className="buttons">
            <Grid
              container
              direction="row"
              justifyContent="space-between"

              alignItems="center"
            >
              {value !== 3 ? (
                <>
                  <Grid item style={value===0? {opacity:'0.5'}:{}}>
                    
                    <Button variant='' onClick={handleBack}>
                      <>
                        
                        <img style={{ paddingRight: 10 }} src={BackArrow} alt="arrow" />
                        BACK
                      </>
                    </Button>
                  </Grid>
                  <Grid item>
                    <div className="next">
                      <Button variant="primary" disabled={isDisabled} onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)} onClick={handleSubmit(handleSave)}>
                        NEXT
                        <img style={{ paddingLeft: 10 }} src={isShown ? BlueArrow : ForwardArrow} alt="arrow" />
                        {/* <ArrowFowardIcon /> */}
                      </Button>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid className="col-md-6 col-sm-12 col-lg-3 d-flex">
                    <Button onClick={handleBack} className="flex-fill me-5">
                      <img style={{ paddingRight: 10 }} src={BackArrow} alt="arrow" />
                      BACK
                    </Button>
                  </Grid>
                  
                  <Grid className="upload-generate-contract-col col-sm-12 col-md-6 col-lg-9">
                    {view!=='startup' &&
                    <>
                      <div className="upload">
                        <div className="w-100 d-flex justify-content-center">
                          <input
                            id="upload-contract"
                            type="file" 
                            disabled={view==='startup'}
                            multiple={false}
                            hidden
                            onChange={onChange}                          
                          />
                          <Button variant="primary icon" className="flex-fill" 
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}
                            disabled={view==='startup'}
                          >
                            <label htmlFor="upload-contract" className="d-flex flex-row align-items-center">
                              <img style={{ paddingRight: 10 }} src={isShown ? UploadAzureIcon : UploadWhiteIcon} alt="upload" />
                              <span>UPLOAD CONTRACT</span>
                            </label>
                          </Button>
                        </div>
                      </div>
                      <b className="">or</b>
                    </>
                    }
                    <div className="upload">
                      <Button
                        variant="primary"
                        onClick={createContract}
                        disabled={ 
                            (!(agreement.supplier_agree && agreement.client_agree) )}
                      >
                        GENERATE CONTRACT
                        <img style={{ paddingLeft: 10 }} src={ForwardArrow} alt="arrow" />
                      </Button>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Box>

        {value === 3 ? (
          <Box
            border={1}
            borderColor="grey.300"
            borderRadius={10}
            className="uploadedContracts"
          >
            <div className="sectionProposal">
              <h6>
                Uploaded Contracts
              </h6>
              <Modal
                open={isModalVisible} onClose={handleCancel}>
                <div className="pdfModal">
                  <header style={{ background: "#252525" }}>
                    <button type="button" onClick={handleCancel}> <img src={WhiteXIcon} alt={"X"} /></button>
                  </header>
                  <iframe title="odt" src={fileUrl}></iframe>
                </div></Modal>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Grid item md={12} >
                  {uploads &&
                    uploads.map((doc: FileItem) => (
                      <div className="documents">
                          {checkForPdf(doc?.name) && false ? <> <img src={DocumentBlueIcon} alt={doc.name} />
                              <button type="button" className="link-extra"
                              style={{paddingLeft:'0px',marginBottom:'0px',marginLeft:'0px',paddingBottom:'13px',paddingTop:'13px'}} onClick={() =>{
                                displayModal(doc.url)
                              }} >
                                <div className="name">
                                  {doc.name}
                                </div>
                              </button></>:
                              <> <img src={DocumentBlueIcon} alt={doc.name} />
                              <a className="link-extra" href={doc?.url} target={"_blank"} rel="noreferrer" >
                              
                                <div className="name">
                                  {doc.name}
                                </div>
                              </a></>}
                              { (view!=='startup')? <button type="button" className="button-reset" onClick={() => {
                          removeFile(doc?.id || '')
                        }}>
                          <img src={CircleOCrossIcon} alt="remove" />
                        </button>:<></>}
                        
                      </div>
                    ))}
                </Grid>
              </Grid>
            </div>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  }
);

export default Navigation;
