import './assets/styles/services.scss';
import { open } from '../../features/modals/modalSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

type ServiceCardProps = {
  icon: string;
  title: string;
  content: string;
}

const ServiceCard = ({
  icon,
  title,
  content
}: ServiceCardProps) => {
  return (
    <div className="pt-2 pb-0 col-12 col-md-4 ps-md-3 pe-md-3 mb-4 service-item-card">
      <div className="d-flex flex-column justify-content-between align-items-center box-white-rounded">
        <div className="mb-4 color-bg-java-opacity-10 round-6 d-flex justify-content-center align-items-center service-icon">
          <img src={icon} alt={title} />
        </div>
        <h4 className="title mb-2 text-center">{title}</h4>
        <h5 className="copy text-center txt-light color-text">
          {content}
        </h5>
      </div>
    </div>
  );
}

const Services = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const data = [
    {
      icon: '/imgs/icon-explore.png',
      title: 'Ecosystem exploration',
      content: 'Discover next generation tech solutions and business challenges from around the globe.'
    },
    {
      icon: '/imgs/icon-matching.png',
      title: 'Qualified matchmaking',
      content: 'Find the right lead and partner with our automated matching capabilities in seconds.'
    },
    {
      icon: '/imgs/icon-workflow.png',
      title: 'Centralised workflow',
      content: 'Collaborate in a more productive way having all communications and documents in one place.'
    },
    {
      icon: '/imgs/icon-identification.png',
      title: 'Opportunities identification',
      content: 'Spot unforeseen areas for growth, impact and disruption for your industry and organization.'
    },
    {
      icon: '/imgs/icon-valuation.png',
      title: 'Technology valuation',
      content: 'Understand the real value of your technology and get a certificate of its performance.'
    },
    {
      icon: '/imgs/icon-implementation.png',
      title: 'Support & implementation',
      content: 'Get all the necessary support from us to ensure the best result along the whole process.'
    }
  ]

  return (
    <>
      <a id="services"></a>
      <div className="pt-md-5 pb-md-5 pt-4 pb-5 container-fluid services">
      <div className="container pb-md-5">
        <div className="pt-5 pb-md-5 pt-md-5 d-flex flex-column align-items-center">
          <h6 className="mb-0 mb-md-4 tangerine">SERVICES</h6>
          <h1 className="mb-3 mb-md-4">What we offer</h1>
          <h5 className="text-center txt-light color-text line-height-160">
          Our mission is to redefine the corporate–startup collaboration. Connecting opportunities seamlessly, fast and efficiently.<br/>  
          We cover the whole value chain of open innovation,  blending the human, the digital  and the machine in a completely new way.
          </h5>
          <div className="flex-md-row flex-column flex-md-wrap mt-md-5 mt-3 d-flex w-100 justify-content-between">
            {data.map((item, i) => <ServiceCard key={i} {...item} />)}
          </div>
          <div className="flex-md-row flex-column mt-4 d-flex justify-content-between action-row w-100">
            <div className="action-col d-flex justify-content-center justify-content-md-end mb-md-0 mb-3 order-2 order-md-1">
              <div
                className="flex-row cursor-pointer text-decoration-none d-flex justify-content-center align-items-center" 
                onClick={() => {
                  push('/adhoc-service-startup')
                }}
              >
                <h5 className="m-0">See ALKELIO+ Services for Startups</h5>
                <img className="ps-2" src="/imgs/arrow-right.png" alt="" />
              </div>
            </div>
            <div className="action-col action-col-signup d-flex justify-content-center mb-md-0 mb-3 order-1 order-md-2">
              <button className="btn btn-primary btn-sign-up" type="button" onClick={() => {
                dispatch(open('sign-up'));
              }}>Sign Up Today</button>
            </div>
            <div className="action-col d-flex justify-content-center justify-content-md-start mb-md-0 mb-3 order-3 order-md-3">
              <div
                className="flex-row cursor-pointer text-decoration-none d-flex justify-content-center align-items-center"
                onClick={() => {
                  push('/adhoc-service-corporate')
                }}
              >
                <h5 className="m-0">See ALKELIO+ Services for Corporates </h5>
                <img className="ps-2" src="/imgs/arrow-right.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Services;