import React, { useContext, useState, useRef } from 'react'
import './assets/styles/chat-component-send-input.scss'
import { ChatContext } from './chat.provider'
import Button from 'app/shared/button'

import { 
  useSendMessageMutation
} from 'services/graphql/chat'

const ChatSendInput = () => {
  const inputElem = useRef<HTMLTextAreaElement>(null)
  const [message, setMessage] = useState<string | null>(null)
  const { chat: { 
    referenceId, category
  } } = useContext(ChatContext);
  const [send, { isLoading, isSuccess}] = useSendMessageMutation()

  const onSend = () => {
    if (message !== '' && message !== null) {
      send({
        referenceId,
        category,
        body: message ?? ''
      })
      setMessage('')
      if (inputElem.current)
        inputElem.current.value = ''
    }
  }

  return (
    <div className="chat-component__body__send-form">
      <div className="input-box">
        <textarea
          ref={inputElem}
          placeholder="Type your message here"
          onChange={(e) => {
            setMessage(e.target.value)
          }} 
        />
        <Button variant="primary" onClick={onSend}>Send</Button>
      </div>
    </div>
  )
}

export default ChatSendInput