import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  CreatePaymentIntent,
  CreatePaymentIntentPayload
} from 'generated/graphql'

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  tagTypes: [""],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    createPaymentIntent: builder.mutation<CreatePaymentIntentPayload, CreatePaymentIntent>({
      query: (input) => ({
        document: gql`
          mutation createPaymentIntent($input: CreatePaymentIntent!) {
            createPaymentIntent(input: $input) {
              method
              client_secret
            }
          }
        `,
        variables: {
          input
        }
      }),
      transformResponse: (response: any) => response.createPaymentIntent
    })
  })
})

export const { 
  useCreatePaymentIntentMutation
} = paymentsApi