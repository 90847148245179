import './assets/styles/about.scss';

const About = () => {
  return (
    <>
      <a id="about"></a>
      <div className="pt-5 pt-md-5 container-fluid color-bg-white pb-md-5 pb-5 about">
        <div className="container pt-2 pt-md-5 ps-3 pe-3 pb-md-5">
          <div className="row">
            <div className="col-12 col-md-6 ps-md-4 pe-md-5 order-2 order-md-1 pt-4 pt-md-0">
              <img src="imgs/about-image.png" className="d-block w-100" alt="about" />
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2 d-flex justify-content-center align-items-center">
              <div className="w-75">
                <h6 className="mb-md-4 tangerine">ABOUT ALKELIO</h6>
                <h1>Next level<br/>matchmaking</h1>
                <div className="mt-4 d-flex align-items-center justify-content-center">
                  <div className="">
                    <div className="pb-4 font-size-16 font-size-md-14 color-text light-type">Our core product lies on a proprietary matchmaking algorithm. 
                      Matching business needs with tested start-ups tech solutions.</div>

                    <div className="pb-4 font-size-16 font-size-md-14 color-text light-type">Why next level? The inputs & data we use, are real-world information collected directly from the user. Reviewed & certified by our teams and not picked up from external databases. </div>

                    <div className="pb-4 font-size-16 font-size-md-14 color-text light-type">What is different is that our matches are contextual, meaning they are sorted out based on your specific needs. Going beyond industries & technologies proposing unforeseen applications & opportunities. To achieve this, we mix AI with rule-based classification with human input. </div>

                    <div className="font-size-16 font-size-md-14 color-text light-type">Finding the right lead and partner usually takes months. Now with our ALKELIO matchmaking capabilities it is done in seconds.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About;