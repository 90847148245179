import React, { useState } from 'react'
import './assets/styles/change-password.scss'
import { 
  useUpdatePasswordMutation
} from 'services/graphql/profile'
import { useDispatch } from 'react-redux';
import { showToast } from 'features/toasts/toastsSlice';

const ChangePassword = React.forwardRef(() => {
  const dispatch = useDispatch()
  const [ password, setPassword ] = useState('')
  const [ updatePassword ] = useUpdatePasswordMutation()

  const handleChangePassword = () => {
    updatePassword(password)
    
    dispatch(showToast({
      title: "Password updated",
      body: "",
      time: null
    }))
  }

  return (
    <div className="change-password">
      <input onChange={(e) => {
        setPassword(e.target.value)
      }} type="password" className="password" />
      <button type="button" onClick={handleChangePassword}>Change</button>
    </div>
  )
})

export default ChangePassword;