import './assets/styles/team.scss';
import { Link } from 'react-router-dom';
import LinkedInIcon from './assets/svgs/linkedin-icon.svg';

type MemberProps = {
  photo?: string;
  name?: string;
  role?: string;
  isYou?: boolean;
  url? :string;
}

const Member = ({
  photo,
  name,
  role,
  isYou,
  url
}:MemberProps) => {
  const inner = <>
    <div className={`profile-photo ${url ? 'has-link':''}`} onClick={() => {
      if (url)
        window.open(url)
    }}>
      {photo !== "" && <img src={photo} alt={name} className={`${!isYou? 'photo elipse-8' : 'is-you'}`} />}
      {photo === "" && <div className="photo-placeholder"></div>}
      {!isYou && <div className="linkedin-icon">
        <img src={LinkedInIcon} alt="LinkedIn" />
      </div>}
    </div>
    <div className={`mt-3 text-center ${isYou ? 'offset-top-less' : ''}`}>
      <h4 className="mb-md-1 mb-0 person-name">{name}</h4>
      <p className="light-type color-text person-role">{role}</p>
    </div>
  </>
  
  return (
    <div className="col-6 col-md-3">
      <div className="mb-4 person d-flex flex-column align-items-center">
        {isYou ? <Link to="/jobs">{inner}</Link> : inner}
      </div>
    </div>
  )
}

const Team = () => {
  const data = [
    {
      photo: "imgs/team/adela.png",
      name: "Adela",
      role: "Founder & CEO/CVO",
      url: "https://www.linkedin.com/in/adelavillanueva/"
    },
    {
      photo: "imgs/team/ethan.png",
      name: "Ethan",
      role: "Co-Founder & CTO",
      url: "https://www.linkedin.com/in/ethanchan/"
    },
    {
      photo: "imgs/team/mariela.png",
      name: "Mariela",
      role: "Co-Founder & CFO",
      url: "https://www.linkedin.com/in/marielahunter/"
    },
    {
      photo: "imgs/team/brice.png",
      name: "Brice",
      role: "Catalyst Tech Expert",
      url: "https://www.linkedin.com/in/bricelenain/"
    },
    {
      photo: "imgs/team/emilie.png",
      name: "Emilie",
      role: "Catalyst Open Innovation",
      url: "https://www.linkedin.com/in/emilie-vez-a789a541"
    },
    {
      photo: "imgs/team/julien.png",
      name: "Julien",
      role: "Catalyst Open Innovation",
      url: "https://www.linkedin.com/in/julien-piedbois/"
    },
    {
      photo: "imgs/team/thulasi.png",
      name: "Thulasi",
      role: "Digital Marketing & Content",
      url: "https://www.linkedin.com/in/thulasi-mahadevan-4055a439/"
    },
    {
      photo: "imgs/team/mathieu.png",
      name: "Mathieu",
      role: "Innovation Catalyst",
      url: "https://www.linkedin.com/in/mathieuledu"
    },
    {
      photo: "imgs/team/deniz.jpg",
      name: "Deniz",
      role: "Business Operations Lead",
      url: "https://www.linkedin.com/in/deniz-tekin-6597a5b2/"
    },
    {
      photo: "imgs/team/you.png",
      name: "You",
      role: "",
      isYou: true,
    },
  ]

  return (
    <div className="container-fluid home-team pt-md-5">
      <div className="container pb-5 pt-5 pb-md-5 pt-md-5">
        <div className="row">
          <div className="col-12 col-md-4 pe-md-5">
            <h6 className="mb-0 mb-md-4 tangerine">ABOUT US</h6>
            <h1 className="mb-2 mb-md-4">Dream team</h1>
            <div className="w-100 w-md-80 d-flex flex-column align-items-start pe-md-5">
              <h5 className="pb-3 txt-light color-text line-height-160">We are a team of Globe trotters with passion for Open Innovation &amp; New technologies. </h5>

              <h5 className="pb-3 txt-light color-text line-height-160">We believe that there is a huge potential to increase the collaboration between startups &amp; corporations. As well as to challenge the way it is done (which is not optimal today). </h5>
    
              <h5 className="pb-3 txt-light color-text line-height-160">We are here to change the game. Be part of the ALKELIO revolution!</h5>

              <Link to="/jobs" className="flex-row text-decoration-none d-flex justify-content-center align-items-center">
                <h5 className="m-0 join-team-cta">Join the team</h5>
                <img className="ps-2" src="/imgs/arrow-right.png" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-8 mt-md-0 mt-5">
            <div className="flex-row flex-wrap row d-flex">
              {data.map((item, i) => <Member key={i} {...item} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team;