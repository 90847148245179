import './assets/styles/steps-status.scss';
import { SignUpType, SignUpTypes } from '../types'
import useSteps from '../hooks/steps.hook';
import WhiteTickSVG from './assets/svgs/tick-white.svg';

const startupSteps = [
  "My Request",
  "My Startup",
  "Me",
  "My Tech Solution"
]

const corporateSteps = [
  "My Request",
  "My Company",
  "About Me",
  "Business Challenge"
]

type StepProps = {
  step: number;
  label: string;
  active: boolean;
  past: boolean
}

const Step = ({past, step, label, active}: StepProps) => {
  return (
    <div className={`step ${past ? 'past':''} ${active ? 'active':''}`}>
      <div className="circle">
        {!past && <div className="semi-bold-type font-size-12 letter-spacing-5">{step}</div>}
        {past && <img src={WhiteTickSVG} alt="completed" />}
      </div>
      <label className="semi-bold-type font-size-12 letter-spacing-5 d-none d-md-block">{label}</label>
    </div>
  )
}

type StepStatusProps = {
  signUpType: SignUpType;
}

const StepsStatus = ({
  signUpType
}: StepStatusProps) => {
  const { currentStep } = useSteps();
  
  const steps = signUpType === SignUpTypes.CORPORATE ? corporateSteps : startupSteps
  return (
    <div className="row">
      <div className="steps-status">
        <div className="step-nodes">
        {steps.map((v, i) => (
          <Step key={i} past={currentStep > (i + 1)} active={currentStep === (i + 1)} step={i + 1} label={v} />
        ))}
        </div>
        <div className="step-lines">
          {steps.slice(1).map((v, i) => (
            <div key={i} className={`line ${currentStep > (i + 1) ? 'active':''}`}></div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StepsStatus;