import { useState, useEffect } from 'react';
import InputPassword from 'shared/forms/input-password';
import { useDispatch } from 'react-redux';
import {
    selectCurrentSession,
    selectEmail,
    selectJwt,
    setCurrentSession,
    setEmail,
    setVerificationUuid,
    setResendVerificationCodeWaitTime,
    setJwt
} from 'features/signup/signupSlice'
import { useForm, SubmitHandler } from 'react-hook-form'
import IconLabel from 'app/shared/icon-label'
import WarningRoundIcon from 'app/shared/assets/svgs/warning-round-orange.svg'
import { useSelector } from 'react-redux'
import {
    useSetPasswordMutation
} from 'services/graphql/on-boarding-session';
import Config from 'config';
import './assets/styles/sign-in.scss';
import { useHistory } from 'react-router-dom'
import { reset } from 'features/signup/signupSlice'

import useOnboarding from '../sign-up-v2/hooks/onboarding.hook'

interface Inputs {
    password: string;
    confirmPassword: string;
}

const SignIn = () => {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [technicalError, setTechnicalError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [setPassword] = useSetPasswordMutation();

    const currentSession = useSelector(selectCurrentSession);
    // const email = useSelector(selectEmail);
    const jwt = useSelector(selectJwt);
    
    const { onboardingPayload } = useOnboarding()

    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({
        mode: "onChange"
    });

    const onSubmit: SubmitHandler<Inputs> = async (loginData: Inputs) => {
        try {
            setLoading(true);
            if (loginData.password === loginData.confirmPassword) {
                await setPassword(loginData.password).then(() => {
                    //Clear sessions and local storages
                    
                    localStorage.removeItem(Config.onboardingUUIDStorageKey);
                    localStorage.removeItem(Config.onboardingSessionStorageKey);
                    localStorage.removeItem(Config.onboardingUUIDExpiryKey);
                    localStorage.removeItem(Config.onboardingUserEmailKey);
                    localStorage.removeItem(Config.onboardingVerificationUUIDStorageKey);
                    localStorage.removeItem(Config.onboardingJwtKey);
                    localStorage.removeItem(Config.onboardingResendVerificationCodeWaitTimeKey);
                    
                    // dispatch(setCurrentSession(null));
                    // dispatch(setEmail(''));
                    // dispatch(setVerificationUuid(''));
                    // dispatch(setJwt(''));
                    // dispatch(setResendVerificationCodeWaitTime(0))

                    dispatch(reset())
                    setLoading(false);
                    window.location.pathname = `/app/${currentSession?.payload?.type.toLowerCase()}`;
                    // push(`/app/${currentSession?.payload?.type.toLowerCase()}`);

                });
            }
            else {
                setError(true);
                setLoading(false);
            }
        } catch (err) {
            console.log(err)
            setTechnicalError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
    }, []);

    const email = onboardingPayload.user?.email;

    return (
        jwt && email ? (
            <div className="container-fluid sign-in pb-4 pb-m-0">
                <h1 className="mb-4 text-left">Congrats!</h1>
                <div className="row">
                    <div className="col">
                        <div className="description">
                            <span className="text-muted desc-text-lg">
                                Your profile has been created! Sign in to get to your dashboard and start matchmaking!
                            </span>
                            <span className="text-muted desc-text-sm">
                                Your profile has been created! Sign in to get to your dashboard and start matchmaking!
                            </span>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form-layout-form mt-5">
                    {isLoading && <div>Loading</div>}
                    {technicalError && <div className="alert alert-danger" role="alert">
                        Technical error. Please try again in a few minutes
                    </div>}
                    <div className="row">
                        <div className="col">
                            <div className="form-field">
                                <label>Email Address</label>
                                <input type="text" value={email ?? ''} autoComplete="off" readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-field">
                                <label>Password</label>        
                                <InputPassword {...register("password", { required: true })} autoComplete="off" />
                                <div className={`invalid-feedback ${errors.password ? 'd-block' : ''}`}>This field is required</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-field">
                                <label>Confirm Password</label>        
                                <InputPassword {...register("confirmPassword", { required: true })} autoComplete="off" />
                                <div className={`invalid-feedback ${errors.confirmPassword ? 'd-block' : ''}`}>This field is required</div>
                                <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>Doesn't match with Password</div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary mt-md-5 finish-button-lg" >Let's go</button>
                    <button type="submit" className="btn btn-primary mt-md-5 finish-button-sm" >You are set up!</button>
                </form>
            </div>
        ) : (
            <div className="alert alert-danger" role="alert">
                {isLoading ? <div>Loading</div> : <div>Sorry. You don't have permission to see this page.</div>}
            </div>
        )
    )
}

export default SignIn;