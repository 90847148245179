import './assets/styles/footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <div className="container-fluid footer color-bg-white pt-5 pb-5">
        <div className="container pt-5 pb-5 pt-md-5 pb-md-5">
          <div className="row pt-3">
            <div className="col-12 col-md-4 pe-md-5 pb-4 pb-md-0 mb-4 mb-mb-0 border-sep">
              <div className="pe-md-1 pb-2 pb-md-0">
                <img src="imgs/logo-inverse.png" className="mb-3" alt="ALKELIO" />
                <p className="mt-3 txt-light font-size-14 line-height-160 color-white footer-copy">
                  Though the gap for collaboration is closing, there are still some challenges to make the startup- corporate collaboration a success. We are here to change the game!</p>
                <div className="flex-row mt-4 d-flex justify-content-start">
                  <a href="https://www.linkedin.com/company/alkelio" className="me-3" target="_blank" rel="noreferrer">
                    <img src="imgs/logo-linkedin.png" alt="LinkedIn official page" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCyFfT3KjCnylAFszhDnupRQ" target="_blank" rel="noreferrer">
                    <img src="imgs/logo-yt.png" alt="Youtube official page" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 ps-md-5 pb-4 pb-md-0 mb-4 mb-mb-0 border-sep">
              <div className="ps-md-1 pt-2 pt-md-0 pb-3 pb-md-0">
                <h6 className="mb-4 mt-2 pb-2 pb-md-0 java txt-medium">Contact</h6>
                <div className="flex-row d-flex">
                  <img src="imgs/icon-sm-address.png" className="align-self-start" alt="address"/>
                  <div className="color-white txt-md ms-2 line-height-140">
                    Switzerland (Headquarters)<br/>
                    Rte de Frontenex 62,<br/>
                    1207 Genève<br/>
                  </div>
                </div>
                <div className="flex-row mt-4 d-flex">
                  <img src="imgs/icon-sm-email.png" className="align-self-start" alt="address"/>
                  <div className="color-white txt-md ms-2 line-height-140">
                    <a className="text-decoration-none color-white txt-regular" href="mailto:contact@alkelio.com">contact@alkelio.com</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 d-flex ps-md-5 flex-column">
              <div className="ps-md-1">
                <h6 className="mb-4 pt-2 pt-md-0 pb-2 pb-md-0 java txt-medium">Legal</h6>
                <div className="d-flex flex-column">
                <Link className="mb-4 text-decoration-none color-white txt-light" to="/terms-conditions">Terms and Conditions</Link>
                <Link className="text-decoration-none color-white txt-light" to="/privacy">Privacy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-3 pb-3 txt-light font-size-12 font-size-md-10">&copy; {year} Copyright ALKELIO</div>
    </>
  )
}

export default Footer;