import { MenuProps } from 'app/shared/menu';
import DashboardIcon from 'assets/svgs/dashboard.svg';
import ExploreIcon from 'assets/svgs/explore.svg';
import FindaMatchIcon from 'assets/svgs/find-a-match.svg';
import CollaborateIcon from 'assets/svgs/collaborate.svg';
import AlkelioPlusServicesIcon from 'assets/svgs/alkelio-plus-services.svg';

const MenuCatalystConstants: MenuProps = {
  links: [
    {
      label: 'Dashboard',
      icon: DashboardIcon,
      route: '/app/catalyst/dashboard',
      exact: true,
    },
    {
      label: 'Conversations',
      icon: CollaborateIcon,
      route: '/app/catalyst/conversations',
      exact: false,
    }
  ]
}

export default MenuCatalystConstants;