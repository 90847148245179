import React, { useEffect, useState, useCallback } from 'react';
import SelectOne from "shared/forms/select-one";
import Button from 'app/shared/button';
import CircleOPlusBlueIcon from 'app/shared/assets/svgs/circle-o-plus-small-blue.svg';
import CircleOCrossIcon from 'app/shared/assets/svgs/circle-o-cross.svg';
import {
    useFieldArray,
    Control,
    UseFormRegister,
    Controller,
    DeepMap,
    DeepPartial,
    FieldError,
    useWatch,
    FieldArrayWithId
} from 'react-hook-form';
import { useGetPricingModelsQuery } from 'services/graphql/tags';
import {
    PricingModel
} from 'generated/graphql';
import { StartupTechSolutionInputs } from '../types';
import { toOptions, toValues, unNull } from 'shared/utilities';
import { ProjectBudgetOptionsStartup, Currencies } from 'app-constants';

const PricingModelsOptions = (props: any) => {
  const { data, isSuccess } = useGetPricingModelsQuery();
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

export interface ProjectBudgetItem {
  id: string;
  currency: string;
  budget: number;
  option: string;
}

interface ProjectBudgetProps {
  control: Control<StartupTechSolutionInputs>;
  register: UseFormRegister<StartupTechSolutionInputs>;
  errors: DeepMap<DeepPartial<StartupTechSolutionInputs>, FieldError>;
}

const TechnologySolutionProjectBudget = React.forwardRef(({
  control,
  register,
  errors,
}: ProjectBudgetProps,
  ref: React.ForwardedRef<HTMLDivElement>) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "technology_solution_pricing_model"
  });

  useEffect(() => {
  }, [])

  let _minBudgets: string[] = [];
  fields.forEach(x => {
    _minBudgets.push(x.min_budget.toString())
  });

  const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({} as any), []);

  const [availableOptions, setAvailableOptions] = useState([
    ...ProjectBudgetOptionsStartup
  ] as string[]);

  const [minBudgets, setMinBudgets] = useState(_minBudgets);

  /*
  useEffect(() => {
    if (availableOptions.length > 0)
      setAvailableOptions(availableOptions)
    else
      setAvailableOptions([{
        technology_solution_pricing_model: null,
        
      }])
  }, [availableOptions])
  */

  useEffect(() => {
    if (fields.length === 0) {
      append({
        currency: '',
        min_budget: 0,
        option: '',
        pricing_model: ''
      });
    }
  }, [fields])

  const renderItems = (items: FieldArrayWithId<StartupTechSolutionInputs, "technology_solution_pricing_model", "id">[]) => items.map((item, i) => {
    return (
      <div key={i} className="col-xs-12">
        <div className="row">
          <div className="col-lg-4 col-xs-4">
            <div className="form-field">
              <label>Pricing Model</label>
              <PricingModelsOptions
                render={(pricingModel: Array<PricingModel>) => (
                  <Controller
                    name={`technology_solution_pricing_model.${i}.pricing_model`}
                    control={control}
                    render={({ field }) => <SelectOne
                      variant="thicker"
                      options={toOptions(pricingModel)}
                      {...field} />}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-xs-4">
            <div className="form-field">
              <label>Min. Price *</label>
              <div className="input-group">
                <input type="number"
                  style={{
                    flex: "1 1 auto",
                    width: '0px',
                    paddingRight: 0,
                  }}
                  {...register(`technology_solution_pricing_model.${i}.min_budget`, {
                    required: true
                  })}
                  onInput={(event) => {
                    const newVal = (event.target as HTMLInputElement).value;
                    let newValues: string[] = JSON.parse(JSON.stringify(minBudgets));
                    if (Number(newVal) || newVal == '') {
                      if ((i + 1) > newValues.length)
                        newValues.push(newVal);
                      else
                        newValues[i] = newVal;
                    }

                    setMinBudgets(newValues);
                    // forceUpdate();
                  }}
                />
                <Controller
                  name={`technology_solution_pricing_model.${i}.currency`}
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field: { value, onChange } }) => <SelectOne
                    variant="thicker"
                    options={Currencies}
                    value={value}
                    style={{ flex: "0 0 100px" }}
                    onChange={(x) => {
                      onChange(x);
                    }}
                  />}
                />
              </div>
              <div className={`invalid-feedback ${errors?.technology_solution_pricing_model?.[i]?.min_budget ? 'd-block' : ''}`}>Please enter a valid budget</div>
              <div className={`invalid-feedback ${errors?.technology_solution_pricing_model?.[i]?.currency ? 'd-block' : ''}`}>Please select a currency</div>
            </div>
          </div>
          <div className="col-lg-3 col-xs-3">
            <div className="form-field">
              <label>Option</label>
              <Controller
                name={`technology_solution_pricing_model.${i}.option`}
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  options={availableOptions}
                  {...field} />}
              />
              <div className={`invalid-feedback ${errors?.technology_solution_pricing_model?.[i]?.option ? 'd-block' : ''}`}>Please select an option</div>
            </div>
          </div>
          <div className="col-lg-1 col-xs-1">
            <div className="form-field pt-md-4">
              <div className="delete-row"
                style={{
                  display: i === 0 ? 'none' : ''
                }}>
                <button
                  type="button"
                  className="btn-link icon"
                  onClick={() => {
                    remove(i);

                    let newValues: string[] = JSON.parse(JSON.stringify(minBudgets));
                    newValues.splice(i, 1);
                    setMinBudgets(newValues);
                    // forceUpdate();
                  }}
                >
                  <><img src={CircleOCrossIcon} alt="delete" style={{ marginTop: "15%" }} /></>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })
  return (
    <div className="row">
      {renderItems(fields.length > 0 ? fields : [])}
      <div className="col add-option-row">
        <button type="button" 
          className="btn-link icon mt-3"
          onClick={() => {
            append({
              currency: '',
              min_budget: 0,
              option: '',
              pricing_model: ''
            });
            let newValues: string[] = JSON.parse(JSON.stringify(minBudgets));
            newValues.push('');
            setMinBudgets(newValues);
            // forceUpdate();
          }}
        >
          <>
            <img src={CircleOPlusBlueIcon} alt="add" className="me-2" />
            <span className="bold-type font-size-14 text-uppercase">{(fields && fields.length > 0) ? "Add Another Option" : "Add Budget" }</span>
          </>
        </button>
      </div>
    </div>
  )
})

export default TechnologySolutionProjectBudget;