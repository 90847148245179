import React from 'react'
import CircleOTick from 'assets/svgs/circle-o-tick.svg'
import CircleOTickFilledAzure from 'assets/svgs/circle-o-tick-filled-azure.svg'
import './assets/styles/nav.scss'

import { useHistory, useRouteMatch } from 'react-router-dom'

const NavItems = [
  {
    label: 'structure'
  },
  {
    label: 'scope',
    to: 'scope'
  },
  {
    label: 'deliverables',
    to: 'deliverables'
  },
  {
    label: 'feedback',
    to: 'feedback'
  } 
]

type NavItemProps = {
  active: boolean,
  label: string,
  to?: string
}

const NavItem = ({
  active,
  label,
  to
}:NavItemProps) => {
  const { push } = useHistory()
  const { url } = useRouteMatch();
  const match = useRouteMatch({
    path: to ? `${url}/${to}` : `${url}`,
    exact: true
  });
  return (
    <div className={`tab-nav-item ${match ? 'active' : ''}`} onClick={() => {
      if (to)
        push(`${url}/${to}`)
      else
        push(`${url}`)
    }}>
      <div className="icon">
        <img src={CircleOTick} />
        <img src={CircleOTickFilledAzure} />
      </div>
      <span>{label}</span>
    </div>
  )
}

const Nav = () => {
  return (
    <div className="main__nav">
      {NavItems.map((item, i) => (
        <NavItem key={i} active={false} {...item} />        
      ))}
    </div>
  )
}

export default Nav