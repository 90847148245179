import { useEffect } from 'react';
import FormLayout from './form-layout';
import ActionBar from '../action-bar/action-bar';
import { useForm, Controller } from 'react-hook-form';
import { StartupProfileFormInputs } from '../types';
import './assets/styles/form-layout-form.scss';

import { CountryNames } from 'shared/countries';
import SelectOne from 'shared/forms/select-one';
import SelectMultiple from 'shared/forms/select-multiple';
import InputImage from 'shared/forms/input-image';
import { toOptions, toValues } from 'shared/utilities';
import {
  ImageAttachment,
  Industry,
  MaturityLevel,
  OnboardingSessionStartup_StartupProfile
} from 'generated/graphql';
import {
  useGetIndustriesQuery,
  useGetMaturityLevelsQuery
} from 'services/graphql/tags';
import { FileItem } from 'hooks/file-uploads';
import { Currencies, ESGRatingOptions } from 'app-constants';
import useOnboarding from '../hooks/onboarding.hook'
import useSteps from '../hooks/steps.hook'

const IndustriesOptions = (props: any) => {
  const { data, isSuccess } = useGetIndustriesQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

const MaturityLevelOptions = (props: any) => {
  const { data, isSuccess } = useGetMaturityLevelsQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

const MyStartup = () => {
  const { setOnboardingPayload, onboardingPayload } = useOnboarding()
  const { currentStep } = useSteps()
  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors } } = useForm<StartupProfileFormInputs>({
      mode: "onChange",
    });
  
  const transformValues = (values: StartupProfileFormInputs): OnboardingSessionStartup_StartupProfile => {
    return {
      name: values?.name ?? '',
      country: values?.country ?? '',
      industries: values?.industries?.map(item => parseInt(item)),
      start_date: values?.start_date ? new Date(parseInt(values?.start_date), 0, 1) : null,
      client_count: values?.client_count ?? 0,
      employee_count: values?.employee_count ?? 0,
      operating_locations: (values?.operating_locations ?? []).join(','),
      logo: values?.logo ? values?.logo as ImageAttachment : null,
      maturity_level: parseInt(values?.maturity_level ?? '0'),
      went_incubator_accelerator: values?.went_incubator_accelerator === 'Yes',
      investment_received: parseInt(values?.investment_received ?? '0'),
      investment_received_currency: values?.investment_received_currency ?? '',
      esg_rating: values?.esg_rating ?? ''
    } as OnboardingSessionStartup_StartupProfile;
  }

  const handlePrev = async (prevStep: () => unknown) => {
    const startupProfile = transformValues(getValues());
    await setOnboardingPayload({
      startupProfile: startupProfile,
      step: currentStep - 1
    })
    prevStep()
  }

  const handleNext = async (nextStep: () => unknown) => {
    const valid = await trigger();
    if (valid) {
      const startupProfile = transformValues(getValues());
      await setOnboardingPayload({
        startupProfile: startupProfile,
        step: currentStep + 1
      })

      nextStep()
    }
  }
  
  useEffect(() => {
    const data = onboardingPayload.startupProfile as OnboardingSessionStartup_StartupProfile;

    if (data) {
      const selectedIndustries: string[] = data?.industries?.map(item => item?.toString() || '') as string[]

      reset({
        name: data?.name ?? '',
        country: data?.country ?? '',
        logo: data?.logo as FileItem,
        industries: selectedIndustries,
        operating_locations: data?.operating_locations?.split(',') ?? [],
        start_date: data?.start_date ? new Date(data?.start_date).getFullYear().toString() : '0',
        maturity_level: data?.maturity_level?.toString(),
        client_count: (data?.client_count ?? 0).toString(),
        went_incubator_accelerator: data?.went_incubator_accelerator ? 'Yes' : 'No',
        employee_count: data.employee_count ?? 0,
        investment_received: data?.investment_received ? data?.investment_received.toString() : '0',
        investment_received_currency: data?.investment_received_currency ?? '',
        esg_rating: data?.esg_rating?? ''
      });
      // onBeforeUnload();
    }

  }, []);

  return (
    <FormLayout
      subHeading="Startup onboarding"
      heading="My Startup"
    >
      <form className="form-layout-form">
        <div className="row">
          <div className="col order-md-1 order-2">
            <div className="form-field">
              <label htmlFor="name">
                Company Name *
              </label>
              <input type="text" {...register("name", { required: true })} />
              <div className={`invalid-feedback ${errors.name ? 'd-block' : ''}`}>This field is required</div>
            </div>
            
            <div className="form-field">
              <label htmlFor="country">
                Country Registered in *
              </label>
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  options={CountryNames}
                  {...field} />}
              />
              <div className={`invalid-feedback ${errors.country ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col order-1 order-md-2">
            <div className="form-field">
              <label htmlFor="logo">
                Company Logo
              </label>
              <Controller
                name="logo"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <InputImage
                  height="100%"
                  width="100%"
                  {...field}
                />}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label htmlFor="industries">
                Industries *
              </label>
              <IndustriesOptions
                render={(industries: Array<Industry>) => (
                  <Controller
                    name="industries"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(industries)}
                      {...field} />}
                  />
                )}
              />
              <div className={`invalid-feedback ${errors.industries ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label htmlFor="operating_locations">
                Operating Location *
              </label>
              <Controller
                name="operating_locations"
                control={control}
                render={({ field }) => <SelectMultiple
                  variant="thicker"
                  options={CountryNames}
                  {...field} />}
              />
              <div className={`invalid-feedback ${errors.operating_locations ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label htmlFor="start_date">
                Incorporated Since *
              </label>
              <input type="number"
                min={1}
                {...register("start_date", { required: true })} />
              <div className={`invalid-feedback ${errors.start_date ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label htmlFor="maturity_level">
                Maturity Level *
              </label>
              <MaturityLevelOptions
                render={(maturityLevels: Array<MaturityLevel>) =>
                  <Controller
                    name="maturity_level"
                    control={control}
                    render={({ field }) => <SelectOne
                      variant="thicker"
                      options={toOptions(maturityLevels)}
                      {...field} />}
                  />}
              />
              <div className={`invalid-feedback ${errors.maturity_level ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label htmlFor="client_count">
                Number Clients *
              </label>
              <input type="number"
                min={1}
                {...register("client_count", { required: true, min: 1 })} />
              <div className={`invalid-feedback ${errors.client_count ? 'd-block' : ''}`}>Minimum is 1</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label htmlFor="went_incubator_accelerator">
                went to Incubator/accelerator *
              </label>
              <Controller
                name="went_incubator_accelerator"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  options={['Yes', 'No']}
                  {...field} />}
              />
              <div className={`invalid-feedback ${errors?.went_incubator_accelerator ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-field">
              <label htmlFor="employee_count">
                Number Employees *
              </label>
              <input type="number"
                min={1}
                {...register("employee_count", { required: true, min: 1 })}
              />
              <div className={`invalid-feedback ${errors?.employee_count ? 'd-block' : ''}`}>Minimum is 1</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label htmlFor="">
                Investment Received *
              </label>
              <div className="input-group">
                <input type="text"
                  min={0}
                  style={{
                    flex: "1 1 auto",
                    width: '0px',
                    paddingRight: 0,
                    minWidth: '100px'
                  }}
                  {...register("investment_received", { required: true, min: 0 })}
                />
                <Controller
                  name="investment_received_currency"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => <SelectOne
                    variant="thicker"
                    options={Currencies} style={{ flex: "0 0 100px" }}
                    sortOptions={false}
                    {...field} />}
                />
              </div>
              <div className="row">
                <div className={`invalid-feedback col-lg-6 ${errors?.investment_received ? 'd-block' : ''}`}>This field is required</div>
                <div className={`invalid-feedback col-lg-6 ${errors?.investment_received_currency ? 'd-block' : ''}`}>Please enter currency</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-field">
              <label>HOW WOULD YOU RATE YOUR ESG?*</label>
              <Controller
                name="esg_rating"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  options={ESGRatingOptions}
                  sortOptions={false}
                  {...field} />}
              />
              <div className={`invalid-feedback ${errors.country ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ActionBar onPrev={handlePrev} onNext={handleNext} />
          </div>
        </div>
      </form>
    </FormLayout>
  )
}

export default MyStartup;