
import './assets/styles/technology-solution-card.scss';
import Card from 'app/shared/card';
import React from 'react'
import { Enum_Bookmark_Category, Enum_Bookmark_Type, TechnologySolution } from 'generated/graphql';
import IconLabel from 'app/shared/icon-label';
import BarChartsIcon from 'app/shared/assets/svgs/bar-chart.svg';
import CalenderIcon from 'app/shared/assets/svgs/calender.svg';
import MapPinIcon from 'app/shared/assets/svgs/map-pin.svg';
import UserIcon from 'app/shared/assets/svgs/user.svg';
import StarIcon from 'app/shared/assets/svgs/star.svg';
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import dayjs from 'dayjs';
import Button from 'app/shared/button';
import Pill from 'app/shared/pill';
import { 
  useSetBookmarkMutation,
  useUnsetBookmarkMutation 
} from 'services/graphql/bookmarks';
import relativeTime from 'dayjs/plugin/relativeTime'
import ButtonFavourite from 'app/shared/button-favourite';
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Countries } from 'shared/countries';
import { ViewCount } from 'app/shared';

dayjs.extend(relativeTime)

interface TechnologyRequestCardProps extends TechnologySolution {
  bookmarked: boolean;
  bookmarkedId: string | undefined;
}

const TechnologySolutionCard = React.forwardRef((
  { 
    id,
    name,
    hero_image,
    description,
    created_at,
    startup_profile,
    purpose_lease,
    purpose_poc,
    purpose_sell,
    purpose_investment,
    bookmarked,
    bookmarkedId
  }: TechnologyRequestCardProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const [ setBookMark ] = useSetBookmarkMutation()
  const [ unsetBookmark ] = useUnsetBookmarkMutation()
  const headerProfile = useSelector(selectHeaderProfile)

  const body = <>
    <p className="line-height-140">{description}</p>
    <div className="looking-for">
      <span>Looking for</span>
      <div>
        {purpose_sell && <Pill label="Sell" icon={TickWhiteIcon} />}
        {purpose_poc && <Pill label="POC" icon={TickWhiteIcon} />}
        {purpose_lease && <Pill label="Lease" icon={TickWhiteIcon} />}
        {purpose_investment && <Pill label="Investment" icon={TickWhiteIcon} />}
      </div>
    </div>
  </>

  const footer = <div className="tech-solution-card-footer">
    <div className="info">
      {startup_profile?.country && <IconLabel className="country" icon={MapPinIcon}>{Countries.getName(startup_profile?.country || '', "en")}</IconLabel>}
      {startup_profile?.maturity_level && <IconLabel className="maturity" icon={UserIcon}>{startup_profile?.maturity_level?.name || ''}</IconLabel>}
      {startup_profile?.country || startup_profile?.maturity_level ? <div className="horizontal-border"></div> : null}
      {startup_profile?.rating && <IconLabel className="rating" icon={StarIcon}>{startup_profile?.rating || ''}</IconLabel>}
      <IconLabel className="last-update" icon={CalenderIcon}>{dayjs(created_at).from(dayjs())}</IconLabel>
      <ViewCount className="views" articleId={`tech-sol-${id}`}/>
    </div>
    <Button variant="primary" className="action">View</Button>
  </div>

  return (
    <Card ref={ref} 
      onClick={() => {
        push(`${url}/${id}`)
      }}
      className="tech-solution-card"
      variant="horizontal"
      imageFilter="darken"
      title={name || ''} 
      imageSrc={hero_image?.url}
      imageOverlay={<ButtonFavourite 
        defaultValue={bookmarked}
        onClick={() => {
          if (bookmarked) {
            bookmarkedId && unsetBookmark(bookmarkedId)
          } else {
            setBookMark({
              type: Enum_Bookmark_Type.TechnologySolution,
              item_id: Number(id),
              category: Enum_Bookmark_Category.Save,
              person_profile: String(headerProfile.id)
            })
          }
        }} />}
      imageOverlayPosition="top-right"
      body={body}
      footer={footer}
    />
  );
})

export default TechnologySolutionCard;