import { useEffect } from 'react';
import FormLayout from './form-layout';
import ActionBar from '../action-bar/action-bar';
import { useForm, Controller } from 'react-hook-form';

import useOnboarding from '../hooks/onboarding.hook'
import useSteps from '../hooks/steps.hook'

import { MyCompanyInputs } from '../types'
import {
  Industry,
  UploadFile,
  OnboardingSessionCorporate_CorporateProfile,
  ImageAttachment
} from 'generated/graphql';
import {
  useGetIndustriesQuery
} from 'services/graphql/tags';

import SelectOne from 'shared/forms/select-one';
import SelectMultiple from 'shared/forms/select-multiple';
import InputImage from 'shared/forms/input-image';
import { FileItem } from 'hooks/file-uploads';
import { toOptions, toValues } from 'shared/utilities';
import { CountryNames } from 'shared/countries';
import { 
  EmployeeCountRange,
  ESGRatingOptions 
} from 'app-constants';

const IndustriesOptions = (props: any) => {
  const { data, isSuccess } = useGetIndustriesQuery()
  return (
    <>
      {isSuccess && props.render(data)}
    </>);
}

const MyCompany = () => {
  const { setOnboardingPayload, onboardingPayload } = useOnboarding()
  const { currentStep } = useSteps()
  
  let employeeCountRange: string[] = [];
  for (var i = 0; i < EmployeeCountRange.length; i++) {
      employeeCountRange.push(EmployeeCountRange[i]);
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors } } = useForm<MyCompanyInputs>({
        mode: "onChange",
    });

    const transformValues = (values: MyCompanyInputs): OnboardingSessionCorporate_CorporateProfile => {
      return {
        name: values?.name ?? '',
        country_jurisdiction: values?.country_jurisdiction ?? '',
        industries: values?.industries?.map(item => parseInt(item)),
        employee_count_range: values?.employee_count_range ?? '',
        logo: values?.logo as ImageAttachment,
        country: (values?.country ?? []).join(','),
        esg_rating: values?.esg_rating ?? ''
      } as OnboardingSessionCorporate_CorporateProfile;
  }

  const handlePrev = async (prevStep: () => unknown) => {
    const corporateProfile = transformValues(getValues());
    await setOnboardingPayload({
      corporateProfile: corporateProfile,
      step: currentStep - 1
    })
    prevStep()
  }

  const handleNext = async (nextStep: () => unknown) => {
    const valid = await trigger();
    if (valid) {
      const corporateProfile = transformValues(getValues());
      await setOnboardingPayload({
        corporateProfile: corporateProfile,
        step: currentStep + 1
      })
      nextStep()
    }
  }
  
  useEffect(() => {
    const data = onboardingPayload.corporateProfile as OnboardingSessionCorporate_CorporateProfile;
    if (data) {
      reset({
        name: data?.name ?? '',
        country_jurisdiction: data?.country_jurisdiction ?? '',
        industries: data?.industries?.map(item => item?.toString()),
        employee_count_range: data?.employee_count_range ?? '',
        logo: data?.logo as FileItem,
        country: data?.country?.split(',') ?? [],
        esg_rating: data?.esg_rating ?? ''
      });
    }
  }, [onboardingPayload])

  return (
    <FormLayout
      subHeading="corporate onboarding"
      heading="My Company"
    >
      <form className="form-layout-form">
        <div className="row">
          <div className="col-12 col-md-6 order-1">
            <div className="form-field">
              <label>Company Name *</label>
              <input type="text"
                {...register("name", { required: true })} />
              <div className={`invalid-feedback ${errors.name ? 'd-block' : ''}`}>This field is required</div>
            </div>
            
            <div className="form-field">
              <label>Country jurisdiction (if applicable)</label>
              <Controller
                name="country_jurisdiction"
                control={control}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  options={CountryNames}
                  {...field} />}
              />
            </div>

          </div>
          <div className="col-12 col-md-6 order-2">
            <div className="form-field">
              <label>Company Logo</label>
              <Controller
                  name="logo"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => <InputImage
                    height="167px"
                    width="100%"
                    {...field}
                  />}
              />
              <div className={`invalid-feedback ${errors.logo ? 'd-block' : ''}`}>Please upload an image</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label>Industries *</label>
              <IndustriesOptions
                render={(industries: Array<Industry>) => (
                  <Controller
                    name="industries"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <SelectMultiple
                      variant="thicker"
                      options={toOptions(industries)}
                      {...field} />}
                  />
                )}
              />
              <div className={`invalid-feedback ${errors.industries ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label>Operating Location *</label>
              <Controller
                  name="country"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <SelectMultiple
                    variant="thicker"
                    options={CountryNames}
                    {...field} />}
              />
              <div className={`invalid-feedback ${errors.country ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-field">
              <label>Number Employees *</label>
              <Controller
                name="employee_count_range"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <SelectOne
                  variant="thicker"
                  options={employeeCountRange}
                  sortOptions={false}
                  {...field} />}
              />
              <div className={`invalid-feedback ${errors.employee_count_range ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-field">
              <label>HOW WOULD YOU RATE YOUR ESG?*</label>
              <Controller
                  name="esg_rating"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <SelectOne
                    variant="thicker"
                    options={ESGRatingOptions}
                    sortOptions={false}
                    {...field} />}
              />
              <div className={`invalid-feedback ${errors.country ? 'd-block' : ''}`}>This field is required</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ActionBar onPrev={handlePrev} onNext={handleNext} />
          </div>
        </div>
      </form>
    </FormLayout>
  )
}

export default MyCompany;