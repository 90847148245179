import { createSlice } from "@reduxjs/toolkit";

export interface toastStateType {
  active: boolean;
  title: string;
  body: string;
  time: Date | null;
  image: string | null
}

const initialState = {
  active: false,
  title: "",
  body: "",
  image: "",
  time: null
} as toastStateType;

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.active = true;
      state.title = action.payload.title;
      state.body = action.payload.body;
      state.time = action.payload.time || null;
      state.image = action.payload.image || null
    },
    dismissToast: (state) => {
      state.active = false;
      state.title = "";
      state.body = "";
      state.time = null;
    },
    resetToasts: (state) => {
      state = {...initialState}
    }
  }
})

export const { showToast, dismissToast, resetToasts } = toastsSlice.actions

export default toastsSlice.reducer