import { useCreateEnquiryMutation } from 'services/graphql/enquiries';
import { useForm, SubmitHandler  } from 'react-hook-form';
import { useState } from 'react';

import './assets/styles/contact-us-form.scss';
type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

type FormResult = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const ContactUsForm = () => {
  const [ createEnquiry, createEnquiryResult ] = useCreateEnquiryMutation();
  const [{ isLoading, isSuccess, isError }, setFormState] = useState<FormResult>({
    isLoading: false,
    isSuccess: false,
    isError: false
  })
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormInputs>();
  const watchFirstName = watch("firstName")
  const watchLastName = watch("lastName")
  const watchEmail = watch("email")
  const watchMessage = watch("message")

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setFormState({
      isLoading: true,
      isSuccess,
      isError
    })
    
    // send to api
    const result = await( await createEnquiry({
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      message: data.message
    }).unwrap());

    setFormState({
      isLoading: false,
      isSuccess: result as boolean,
      isError
    })
  }
  
  return (
    <div className="pt-5 pb-md-5 pb-5 box-white-rounded-shadow ps-md-5 pe-md-5 pe-md-4 ps-md-4" id="contact-form">
      <div className="p-4 p-md-5">
        <h1 className="sm text-center text-md-start pt-2">Contact form</h1>
        <h5 className="mb-5 txt-light line-height-140 color-text text-center text-md-start">
          We want to hear from you
        </h5>
        {isSuccess && <div className="mb-5 alert alert-success" role="alert">
          Thank you for your message, we will reach out to you soon.
        </div>}
        {isError && <div className="mb-5 alert alert-danger" role="alert">
          There was a problem sending your message, please drop us an email at <a href="mailto:contact@alkelio.com">contact@alkelio.com</a>
        </div>}
        <form onSubmit={handleSubmit(onSubmit)} className="form contact-us-form">
          <div className="mt-3 row">
            <div className="col-12 col-md-6 mb-4 mb-md-0">

              <label className="form-control-label-inside">
                
                <input type="text" {...register("firstName", { required: true })} autoComplete="off"/>

                <div className={`text ${watchFirstName !== '' ? 'focused':''}`}>
                  First name <span className="color-tangerine">*</span>
                </div>
                
                <div className={`invalid-feedback ${errors.firstName ? 'd-block':''}`}>{errors.firstName?.message}</div>
              </label>

            </div>
            <div className="col-12 col-md-6">
              
              <label className="form-control-label-inside">
                <input type="text" {...register("lastName")} autoComplete="off" />

                <div className={`text ${watchLastName !== '' ? 'focused':''}`}>
                  Last name
                </div>

                <div className={`invalid-feedback ${errors.lastName ? 'd-block':''}`}>{errors.lastName?.message}</div>
              </label>

            </div>
          </div>
          <div className="mt-4 row">
            <div className="col">
              
              <label className="form-control-label-inside">
                
                <input type="text" {...register("email", { required: true, pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/ })} autoComplete="off" />

                <div className={`text ${watchEmail !== '' ? 'focused':''}`}>
                  Email <span className="color-tangerine">*</span>
                </div>

                <div className={`invalid-feedback ${errors.email ? 'd-block':''}`}>{errors.email?.message}</div>
              </label>

            </div>
          </div>
          <div className="mt-4 row">
            <div className="col">
              
              <label className="form-control-label-inside">
                
                <textarea {...register("message", { required: true })} autoComplete="off" className="textarea-3" />
                
                <div className={`text textarea ${watchMessage !== '' ? 'focused':''}`}>
                  Message <span className="color-tangerine">*</span>
                </div>

                <div className={`invalid-feedback ${errors.message ? 'd-block':''}`}>{errors.message?.message}</div>

              </label>

            </div>
          </div>
          <div className="mt-4 row">
            <div className="flex-md-row flex-column col d-flex justify-content-between align-items-center">
              <button className="btn-submit btn btn-primary mb-4 mb-md-0" type="submit" disabled={isLoading ? true : false} >Submit</button>
              <div className="txt-sm">
                <span className="color-tangerine">*</span>
                <span className="color-text ms-2 txt-light font-size-12 font-size-md-10">Required field</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactUsForm;