import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  PagedMessages
} from 'generated/graphql'

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  tagTypes: ['notifications'],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getCurrentUserMessages: builder.query<PagedMessages, void>({
      query: () => ({
        document: gql`
          query getCurrentUserMessages {
            getCurrentUserMessages {
              page
              pageSize
              total
              result {
                id
                subject
                content
                readStatus
                createdOn
                from {
                  name
                  profileImage
                }
              }
            }
          }
        `
      }),
      transformResponse: (response: any) => response.getCurrentUserMessages,
      providesTags: ['notifications']
    }),
    getCurrentUserUnreadMessages: builder.query<PagedMessages, void>({
      query: () => ({
        document: gql`
          query getCurrentUserUnreadMessages {
            getCurrentUserUnreadMessages {
              page
              pageSize
              total
              result {
                id
                subject
                content
                readStatus
                createdOn
                from {
                  name
                  profileImage
                }
              }
            }
          }
        `
      }),
      transformResponse: (response: any) => response.getCurrentUserUnreadMessages,
      providesTags: ['notifications']
    }),
    setMessagesRead: builder.mutation<void, string[]>({
      query: (ids) => ({
        document: gql`
          mutation setMessagesRead($input: SetMessagesReadInput) {
            setMessagesRead(input: $input)
          }
        `,
        variables: {
          input: {
            ids,
          }
        }
      }),
      invalidatesTags: ['notifications']
    })
  })
})

export const {
  useGetCurrentUserUnreadMessagesQuery,
  useGetCurrentUserMessagesQuery,
  useSetMessagesReadMutation
} = notificationsApi