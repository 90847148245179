import { ReactElement, cloneElement } from 'react';
import ButtonFavourite from "../button-favourite";
import { useFindRejectQuery, useSetBookmarkMutation, useUnsetBookmarkMutation } from 'services/graphql/bookmarks'
import { Enum_Bookmark_Type, Enum_Bookmark_Category } from 'generated/graphql'
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice'
import { useSelector } from 'react-redux';

export interface FavouriteWidgetProps {
  itemId: string;
  type: Enum_Bookmark_Type;
  element: ReactElement;
  onReject?: () => unknown;
}

export const RejectWidget = ({
  itemId, 
  type,
  element,
  onReject
}: FavouriteWidgetProps) => {
  const [ setBookMark ] = useSetBookmarkMutation()
  const [ unsetBookmark ] = useUnsetBookmarkMutation()

  const headerProfile = useSelector(selectHeaderProfile)
  const { data, isLoading, isSuccess } = useFindRejectQuery({
    personProfile: headerProfile?.id,
    itemId,
    type,
  })

  const bookmarked = data !== null;

  const toggleBookmark = async () => {
    if (bookmarked) {
      data && unsetBookmark(data?.id)
    } else {
      await setBookMark({
        type,
        item_id: Number(itemId),
        category: Enum_Bookmark_Category.Reject,
        person_profile: headerProfile.id?? ''
      })
      onReject && onReject()
    }
  }

  return (
    <>
      {isSuccess && 
        cloneElement(
          element, 
          {
            onClick: toggleBookmark
          }
        )
      }
    </>
  );
}