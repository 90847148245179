import { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { DeductCreditPayload } from 'generated/graphql'
import { useLazyMyCreditBalanceQuery, useDeductCreditMutation, useDeductCreditStartConversationMutation } from 'services/graphql/credits'
import { useDispatch } from 'react-redux'
import { setConversationWith, ConversationWith, Enum_ConversationWithCategory } from 'features/cart/cartSlice'
import { useLazyMyTechRequestsQuery, useMyTechRequestsQuery } from 'services/graphql/tech-requests'
import { useLazyMyTechSolutionsQuery, useMyTechSolutionsQuery } from 'services/graphql/tech-solutions'

import EntityConstants from 'app/entity-type/entityConstants'

interface UseCreditRedirectProps {
  path: string;
  action?: Enum_ConversationWithCategory | '';
  targetId?: string;
  targetType?: string;
  name?: string;
}

export const useCredit = () => {
  const dispatch = useDispatch()
  const [ targetUrl, setTargetUrl ] = useState<string>('');
  const [ userConversationWith, setUserConversationWith ] = useState<ConversationWith>({
    action: '',
    targetId: '',
    targetType: '',
    name: ''
  })
  const getTechRequestsResult = useMyTechRequestsQuery()
  const getTechSolutionResults = useMyTechSolutionsQuery()
  const { push } = useHistory()
  const { entityType }: any = useParams();
  const [ getBalance, { data, isLoading, isSuccess} ] = useLazyMyCreditBalanceQuery();
  
  const [ deduct, deductResult ] = useDeductCreditStartConversationMutation();
  //const [ deduct, deductResult ] = useDeductCreditMutation();

  const buyCreditsPath = `/app/${entityType}/packages`;
  
  const deductCreditRedirect = async ({ 
    path,
    action = '',
    targetId = '',
    targetType = '',
    name = '',
  }: UseCreditRedirectProps) => {
    
    setTargetUrl(path)
    setUserConversationWith({
      action,
      targetId,
      targetType,
      name
    })
    await getBalance();
  }

  useEffect(() => {
    if (isSuccess && data !== undefined) {
      if (data > 0) {
        (async () => { 
          let fromTechnologyRequestId = ''
          let fromTechnologySolutionId = ''
          // todo - user may need to select a tech req/solution or change it in the in conversation
          // now choosing the first one
          if (entityType === EntityConstants.STARTUP) {
            if (getTechSolutionResults.data) {
              fromTechnologySolutionId = getTechSolutionResults.data[0].id
            }
            
          } else
          if (entityType === EntityConstants.CORP) {
            if (getTechRequestsResult.data) {
              fromTechnologyRequestId = getTechRequestsResult.data[0].id
            }
          }

          const result = await deduct({
            fromType: entityType,
            techRequestId: entityType === EntityConstants.STARTUP ? userConversationWith.targetId : fromTechnologyRequestId,
            techSolutionId: entityType === EntityConstants.CORP ? userConversationWith.targetId : fromTechnologySolutionId
          }).unwrap();
          
          push(targetUrl); // go the collaborate page because user need to clear mutual  
        })();
      } else {
        dispatch(setConversationWith(userConversationWith));
        push(buyCreditsPath);
      }
    }
  }, [data, isSuccess])

  useEffect(() => {
    if (deductResult.isSuccess) {
      // push(targetUrl);
    }
  }, [deductResult])
  
  return {
    deductCreditRedirect
  }
}