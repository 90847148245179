import Tile from 'app/shared/tile'
import { Packages as StartupPackages } from 'app/startup/packages'
import { Packages as CorporatePackages } from 'app/corporate/packages'
import EntityConstants from 'app/entity-type/entityConstants';
import { useParams } from 'react-router-dom'


const StartConversation = () => {
  const { entityType }: any = useParams();

  const subTitle = entityType === EntityConstants.STARTUP 
    ? 'Choose your prefered package to start theconversation and see the specifics for the project.' 
    : 'Choose the package* that best suits your needs'; 

  return (
    <Tile  
      title="Start a conversation"
      subHeading={subTitle}
    >
        {entityType === EntityConstants.STARTUP && <StartupPackages/>}
        {entityType === EntityConstants.CORP && <CorporatePackages/>}
    </Tile>
  )
}

export default StartConversation;