import './assets/styles/profile-form.scss'
import Tile from 'app/shared/tile'
import Button from 'app/shared/button'
import { useHistory } from 'react-router-dom';
import { useForm, Controller, SubmitHandler, UseFormWatch } from 'react-hook-form'
import InputInline from 'shared/forms/input-inline';
import InputImage from 'shared/forms/input-image';
import SelectMultiple from 'shared/forms/select-multiple';
import SelectOne from 'shared/forms/select-one';
import { CountryNames } from 'shared/countries'
import InputCheckBox from 'shared/forms/input-checkbox';
import Inputs from './inputs'
import { 
  useMyStartupProfileQuery,
  useUpdateStartupProfileMutation
} from 'services/graphql/profile'
import { FileItem } from 'hooks/file-uploads';
import { toOptions, toValues, unNull } from 'shared/utilities'
import { 
  Enum_Startupprofile_Esg_Rating,
  Industry,
  MaturityLevel
} from 'generated/graphql';
import { 
  useGetIndustriesQuery,
  useGetMaturityLevelsQuery
} from 'services/graphql/tags';
import ChangePassword from 'app/shared/change-password';
import { useDispatch } from 'react-redux';
import InputMoney from 'shared/forms/input-money';
import CurrencyCode from 'currency-codes';
import { CurrenciesPreferred, ESGRatingOptions } from 'app-constants';
import './assets/styles/profile-form.scss';

const Currencies = CurrencyCode.codes();

const IndustriesOptions = (props:any) => {
  const {data, isSuccess} = useGetIndustriesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const MaturityLevelOptions = (props:any) => {
  const {data, isSuccess} = useGetMaturityLevelsQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}


const ProfileForm = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess } = useMyStartupProfileQuery()
  
  const [ updateProfile ] = useUpdateStartupProfileMutation()

  const yearOptions = Array.from(new Array(10),(val,index)=>((new Date().getFullYear()) - (index+1)).toString())
  const range = (start:number, stop:number, step:number = 1) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  const numberList = (start:number, count:number) => range(start, start + count, 1).map(v => v.toString())
  
  const { 
    register, 
    handleSubmit, 
    watch, 
    control,
    getValues,
    setValue,
    reset,
  formState: { errors } } = useForm<Inputs>({
    mode: "onChange",
  });

  const watchWentToIncubator = watch('startup_profile.went_incubator_accelerator');
  
  const handleSave = async ({
    startup_profile: {
      name,
      logo,
      industries,
      operating_locations,
      country,
      employee_count,
      start_date_year,
      worked_with_corporates,
      website,
      linkedin,
      twitter,
      facebook,
      instagram,
      maturity_level,
      clients,
      client_count,
      went_incubator_accelerator,
      incubators_accelerators,
      investment_received,
      investment_received_currency,
      esg_rating
    },
    person: {
      first_name,
      last_name,
      photo,
      role,
      contact_number
    }
  }: Inputs) => {
    const startupId = data?.myStartupProfile.id as string
    const personProfileId = data?.myProfile?.person?.id as string
    const result = await (await updateProfile({
      startupId,
      startup: {
        name,
        logo: logo ? logo.id : null,
        industries,
        operating_locations: operating_locations.join(','),
        country,
        employee_count: parseInt(employee_count),
        start_date: start_date_year !== "" ? `${start_date_year}-01-01` : null,
        worked_with_corporates,
        links: {
          website,
          linkedin,
          twitter,
          facebook,
          instagram,
        },
        maturity_level,
        clients,
        client_count: parseInt(client_count),
        went_incubator_accelerator: went_incubator_accelerator === 'Yes',
        incubators_accelerators,
        investment_received: parseInt(investment_received),
        investment_received_currency: investment_received_currency,
        esg_rating: esg_rating as Enum_Startupprofile_Esg_Rating
      },
      personProfileId,
      personProfile: {
        first_name,
        last_name,
        photo: photo ? photo.id : null,
        role,
        contact_number
      }
    }))

    history.goBack();
  }

  return (
    <Tile>
      {isLoading && <div>Loading</div>}
      {isSuccess && 
      <form>
        <div className="startup-profile-form">
          <div className="title">
            <div className="flex-fill me-4">
              <Controller
                name="startup_profile.name"
                control={control}
                defaultValue={data?.myStartupProfile.name || ''}
                rules={{
                  required: true
                }}
                render={({field}) => <InputInline 
                  {...field} /> }
              />
              <div className={`invalid-feedback ${errors.startup_profile?.name ? 'd-block':''}`}>This field is required</div>
            </div>
            <Button className="d-none d-md-block" variant="primary" onClick={handleSubmit(handleSave)}>Save</Button>
          </div>
          <div className="photo">
            <Controller
              name="startup_profile.logo"
              control={control}
              defaultValue={data?.myStartupProfile.logo as FileItem || null}
              rules={{ 
                required: true
              }}
              render={({field}) => <InputImage 
                height="332px"
                width="383px"
                {...field} />}
            />
            <div className={`invalid-feedback ${errors.startup_profile?.logo ? 'd-block':''}`}>Please upload an image</div>
          </div>
          <div className="company-details">
            <div className="form-control-group">
              <h6 className="mb-4">Startup details</h6>

              <div className="pb-0 mb-0 row align-items-normal">
                <div className="col-12 col-md-6 p-0 pe-md-2">
                  <div className="form-control">
                    <label>
                      <div className="text">Industries</div>
                      <IndustriesOptions
                        render={(industries: Array<Industry> ) => (
                          <Controller 
                            name="startup_profile.industries"
                            control={control}
                            defaultValue={toValues(data?.myStartupProfile?.industries || [])} 
                            render={({field}) => <SelectMultiple
                              options={toOptions(industries)} 
                              {...field} />}
                          />
                        )}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 p-0 ps-md-2 mt-4 mt-md-0">
                  <div className="form-control">
                    <label>
                      <div className="text">Operating Locations</div>
                      <Controller 
                        name="startup_profile.operating_locations"
                        control={control}
                        defaultValue={data?.myStartupProfile.operating_locations !== undefined && 
                          data.myStartupProfile.operating_locations !== null && 
                          data.myStartupProfile.operating_locations !== "" ? 
                            data?.myStartupProfile.operating_locations.split(',') : []}
                        render={({field}) => <SelectMultiple 
                          options={CountryNames}
                          {...field} />}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="pb-0 mb-0 row align-items-normal">
                <div className="col-12 col-md-6 p-0 pe-md-2 mt-4 mt-md-0 mb-3 mb-md-0">
                  <div className="form-control">
                    <label>
                      <div className="text">Country registered in</div>
                      <Controller 
                        name="startup_profile.country"
                        control={control}
                        defaultValue={data?.myStartupProfile.country !== undefined && 
                          data.myStartupProfile.country !== null && 
                          data.myStartupProfile.country !== "" ? 
                            data?.myStartupProfile.country : ''}
                        render={({field}) => <SelectOne 
                          options={CountryNames}
                          {...field} />}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 p-0 ps-md-2 d-flex flex-column justify-content-center">
                  <div className="mb-3 form-control">
                    <Controller 
                      name="startup_profile.worked_with_corporates"
                      control={control}
                      defaultValue={data?.myStartupProfile.worked_with_corporates ?? false}
                      render={({field}) => <InputCheckBox {...field} label="Have worked with Corporates previously" /> }
                    />
                  </div>
                </div>
              </div>

              <div className="pb-0 mb-0 row align-items-normal">
                <div className="col ps-0 pe-2 pe-md-2">

                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="text">Incorprated Since</div>
                      <Controller 
                        name="startup_profile.start_date_year"
                        control={control}
                        defaultValue={data?.myStartupProfile.start_date ? new Date(data?.myStartupProfile.start_date).getFullYear().toString(): ''}
                        render={({field}) => <SelectOne 
                          options={yearOptions}
                          {...field} />}
                      />
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="text">Number Clients</div>
                      <input type="text" 
                        {...register("startup_profile.client_count")}
                        defaultValue={data?.myStartupProfile.client_count ? data?.myStartupProfile.client_count.toString() : ''}
                      />
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="text">went to Incubator/accelerator</div>
                      <Controller 
                        name="startup_profile.went_incubator_accelerator"
                        control={control}
                        defaultValue={data?.myStartupProfile.went_incubator_accelerator ? (data?.myStartupProfile.went_incubator_accelerator ? 'Yes' : 'No') : ''}
                        render={({field}) => <SelectOne 
                          options={['Yes','No']}
                          {...field} />}
                      />
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="text">Number Employees</div>
                      <input type="number" 
                        {...register("startup_profile.employee_count")} 
                        defaultValue={data?.myStartupProfile?.employee_count ? data?.myStartupProfile?.employee_count.toString() : ''} />
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="text">ESG Rating</div>
                      <Controller
                        name="startup_profile.esg_rating"
                        control={control}
                        defaultValue={data?.myStartupProfile.esg_rating ?? ''}
                        rules={{ required: true }}
                        render={({ field }) => <SelectOne
                          options={ESGRatingOptions}
                          sortOptions={false}
                          {...field} />}
                      />
                      <div className={`invalid-feedback ${errors.startup_profile?.esg_rating ? 'd-block':''}`}>Please select an ESG</div>
                    </label>
                  </div>

                </div>

                <div className="col p-0 ps-md-2 ">
                  
                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="text">Maturity level</div>
                      <MaturityLevelOptions
                        render={(maturityLevels: Array<MaturityLevel>) => <Controller 
                          name="startup_profile.maturity_level"
                          control={control}
                          rules={{ required: true }}
                          defaultValue={data?.myStartupProfile?.maturity_level ? data?.myStartupProfile.maturity_level.id : ''}
                          render={({field}) => <SelectOne 
                            sortOptions={false}
                            options={toOptions(maturityLevels)}
                            {...field} />}
                        />}
                      />
                      <div className={`invalid-feedback ${errors.startup_profile?.maturity_level ? 'd-block':''}`}>Please select a maturity level</div>
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="text">name of clients</div>
                      <input type="text" 
                        {...register("startup_profile.clients")}
                        defaultValue={data?.myStartupProfile.clients ? data?.myStartupProfile.clients : ''}
                      />
                    </label>
                  </div>

                  {watchWentToIncubator === 'Yes' && <div className="form-control">
                    <label>
                      <div className="text">name of Incubator/accelerator</div>
                      <input type="text" 
                        {...register("startup_profile.incubators_accelerators")}
                        defaultValue={data?.myStartupProfile.incubators_accelerators ? data?.myStartupProfile.incubators_accelerators : ''}
                      />
                    </label>
                  </div>}

                  <div className="form-control mt-4 mt-mb-0">
                    <label>
                      <div className="mb-2 text">Investment Received</div>
                    </label>
                    <div className="input-group seamless">
                      <Controller
                          name={`startup_profile.investment_received`}
                          control={control}
                          defaultValue={data?.myStartupProfile.investment_received ? data?.myStartupProfile.investment_received : 0}
                          rules={{
                            required: false
                          }}
                          render={({field}) => <InputMoney 
                            style={{flex: "1 1 auto", 
                              width: '0px',
                              paddingRight: "0px",
                              minWidth: '100px'
                            }} 
                            {...field}
                          />}
                        />
                        <Controller 
                          name={`startup_profile.investment_received_currency`}
                          defaultValue={data?.myStartupProfile.investment_received_currency ? data?.myStartupProfile.investment_received_currency : ''}
                          control={control}
                          rules={{
                            required: false
                          }}
                          render={({field}) => <SelectOne 
                            orderFirst={CurrenciesPreferred}
                            options={Currencies} style={{flex: "0 0 80px"}} 
                            {...field} />}
                        />
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
          <div className="personal-info">
            <div className="form-control-group">
              <h6 className="mb-4">Personal Profile</h6>
              <div className="row p-0 mb-4">
                <div className="col p-0 d-flex flex-column">
                  <div className="flex-row d-flex">
                  <Controller
                    name="person.photo"
                    control={control}
                    defaultValue={data?.myProfile?.person?.photo as FileItem || null}
                    rules={{ 
                      required: false
                    }}
                    render={({field}) => <InputImage 
                      layout="elipse-left"
                      {...field} />}
                  />
                  </div>
                  <div className={`invalid-feedback ${errors.person?.photo ? 'd-block':''}`}>Please upload your photo</div>
                  
                </div>
              </div>

              <div className="pb-0 mb-0 row p-0 align-items-normal">
                <div className="col p-0 pe-md-2 d-flex flex-column">

                  <div className="form-control">
                    <label>
                      <div className="text">First name</div>
                      <input type="text"
                        {...register("person.first_name")}
                        defaultValue={data?.myProfile?.person?.first_name || ''}
                      />
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-md-0">
                    <label>
                      <div className="text">Email</div>
                      <input type="text" readOnly
                        defaultValue={data?.me.email || ''}
                      />
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-md-0">
                    <label>
                      <div className="text">Role</div>
                      <input type="text"
                        {...register("person.role")}
                        defaultValue={data?.myProfile?.person?.role || ''}
                      />
                    </label>
                  </div>

                </div>
                <div className="col p-0 ps-md-2 d-flex flex-column">
                  
                  <div className="form-control mt-4 mt-md-0">
                    <label>
                      <div className="text">Last name</div>
                      <input type="text"
                        defaultValue={data?.myProfile?.person?.last_name || ''}
                        {...register("person.last_name")}
                      />
                    </label>
                  </div>

                  <div className="form-control mt-4 mt-md-0">
                    <label>
                      <div className="text">Password</div>
                      <ChangePassword />
                    </label>
                  </div>
                        
                  <div className="mb-0 form-control mt-4 mt-md-0">
                    <label>
                      <div className="text">Contact number</div>
                      <div className="input-group">
                        <span className='input-group-text'>+</span>
                        <input type="number"
                          defaultValue={data?.myProfile?.person?.contact_number || ''}
                          {...register("person.contact_number")}
                        />  
                      </div>
                    </label>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="website">
            <div className="form-control-group">
              <h6 className="mb-4">Website & Social Profiles</h6>
              <div className="form-control mb-4">
                <label>
                  <div className="text">Website</div>
                  <input type="text" defaultValue={data?.myStartupProfile.links?.website || ''} 
                    {...register("startup_profile.website")} />
                </label>
              </div>
              
              <div className="form-control mb-4">
                <label>
                  <div className="text">Linkedin</div>
                  <input type="text" defaultValue={data?.myStartupProfile.links?.linkedin || ''} 
                    {...register("startup_profile.linkedin")} />
                </label>
              </div>
              
              <div className="form-control mb-4">
                <label>
                  <div className="text">Twitter</div>
                  <input type="text" defaultValue={data?.myStartupProfile.links?.twitter || ''} 
                    {...register("startup_profile.twitter")} />
                </label>
              </div>
              
              <div className="form-control mb-4">
                <label>
                  <div className="text">Facebook</div>
                  <input type="text" defaultValue={data?.myStartupProfile.links?.facebook || ''} 
                    {...register("startup_profile.facebook")} />
                </label>
              </div>
              
              <div className="form-control">
                <label>
                  <div className="text">Instagram</div>
                  <input type="text" defaultValue={data?.myStartupProfile.links?.instagram || ''} 
                    {...register("startup_profile.instagram")} />
                </label>
              </div>

            </div>
          </div>
          <div className="action">
            <Button className="ms-md-auto flex-grow-1 flex-md-grow-0" variant="primary" onClick={handleSubmit(handleSave)}>Save</Button>
          </div>
        </div>        
      </form>}
    </Tile>
  )
}

export default ProfileForm