import './assets/styles/input-radio.scss';
import React, { useState, useEffect } from 'react';

interface InputRadioProps {
  label: string;
  value?: string;
  name: string;
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange: (data:any) => unknown;
}

const InputRadio = React.forwardRef((
  {
    name,
    label,
    onChange,
    className,
    checked,
    value,
    defaultChecked
  }: InputRadioProps, 
  ref: React.Ref<HTMLDivElement>
) => {
  const [isChecked, setIsChecked] = useState(defaultChecked || checked || false)

  useEffect(() => {
    setIsChecked(defaultChecked || checked || false)
  }, [defaultChecked, checked])

  return (
    <div className={`input-radio ${className || ''}`} ref={ref} >
      <label>
        <input name={name} 
          onChange={(e) => {
            const checked = e.target.checked
            setIsChecked(checked);
            onChange && onChange(e);
          }}
          type="radio" 
          value={value}
          defaultChecked={isChecked} />
        <div className="radio"></div>
        {label}
      </label>
    </div>
  )
})

export default InputRadio;