import LogoHorizontal from './assets/svgs/logo-horizontal.svg';
import './assets/styles/logo.scss';

const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoHorizontal.toString()} alt="ALKELIO" />
      <span className="beta">
        <span className="text">BETA</span>
      </span>
    </div>
  );
};

export default Logo;