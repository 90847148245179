import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { ServicePackage } from 'generated/graphql'

type ServicePackagesWithCurrency = {
  servicePackages: Array<ServicePackage>,
  currency: string
}

export const servicePackagesApi = createApi({
  reducerPath: 'servicePackagesApi',
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getServicePackages: builder.query<ServicePackagesWithCurrency, void>({
      query: () => ({
        document: gql`
          query {
            getServicePackages {
              id
              slug
              name
              region_code
              role_type
              unlimited_credits
              service_package_price_mode {
                payment_interval
                price
                credits
                credit_refresh_interval
              }
            } 
            myProfile {
              region_currency
            }
        }
        `
      }),
      transformResponse: (response: any) => ({
        servicePackages: response.getServicePackages,
        currency: response.myProfile.region_currency
      })
    })
  })
})

export const {
  useGetServicePackagesQuery
} = servicePackagesApi