import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { singleTypesApi } from 'services/graphql/single-types'

export type appConfigStateType = {
  payment_stripe_key: string | null;
  enable_payments: boolean;
}

const initialState: appConfigStateType = {
  payment_stripe_key: null,
  enable_payments: false,
}

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      singleTypesApi.endpoints.getApplicationConfig.matchFulfilled,
      (state, { payload }) => {
        const { 
          payment_stripe_key,
          enable_payments
        } = payload
        state.payment_stripe_key = payment_stripe_key ?? ''
        state.enable_payments = enable_payments ?? false
      }
    )
  }
});

export default appConfigSlice.reducer

export const payment_stripe_key = (state: RootState) => state.appConfig.payment_stripe_key
export const enable_payments = (state: RootState) => state.appConfig.enable_payments