import { 
  useLazyGetCatalystByCorporateProfileQuery,
  useLazyGetCatalystByStartupProfileQuery
} from 'services/graphql/catalyst-allocation';
import { CatalystAllocation, Enum_Personprofile_Profile_Type } from 'generated/graphql';

const useGetCatalyst = (
  profile_type: Enum_Personprofile_Profile_Type | null, 
) => {
  const [ getByCorporateProfile, getByCorporateProfileResult ] = useLazyGetCatalystByCorporateProfileQuery();
  const [ getByStartupProfile, getByStartupProfileResult ] = useLazyGetCatalystByStartupProfileQuery();
  
  return {
    getCatalyst: Enum_Personprofile_Profile_Type !== null 
      ? (profile_type === Enum_Personprofile_Profile_Type.Corporate ? getByCorporateProfile : getByStartupProfile) 
      : (arg: string) => {},
    result: Enum_Personprofile_Profile_Type !== null 
      ? (profile_type === Enum_Personprofile_Profile_Type.Corporate ? getByCorporateProfileResult : getByStartupProfileResult) 
      : null
  }
}

export default useGetCatalyst;