import './assets/styles/input-checkbox.scss';
import TickWhiteIcon from 'app/shared/assets/svgs/tick-white.svg';
import React, { useEffect, useState } from 'react';
import BaseInput from 'shared/forms/base-input';

interface InputCheckboxProps extends BaseInput {
  className?: string;
  label?: string;
  labelClassName?: string;
  disabled?: boolean;
}

const InputCheckbox = React.forwardRef((
  { className, label, labelClassName, value, defaultValue, disabled, onChange, ...rest }: InputCheckboxProps,
  ref: React.Ref<HTMLDivElement>
) => {

  const [checked, setChecked] = useState(defaultValue || value || false)

  useEffect(() => {
    setChecked(defaultValue || value || false)
  }, [defaultValue, value])


  return (
    <div className={`input-checkbox ${className || ''}`} ref={ref}>
      <label>
        <input type="checkbox"
          defaultChecked={defaultValue || value || false}
          disabled={disabled}
          onChange={(e) => {
            const checked = e.target.checked
            setChecked(checked);
            onChange && onChange(e);
          }} />
        <div className="checkbox" 
          style={ disabled? {backgroundColor: "#d3d3d3"} : {} }>
          <img src={TickWhiteIcon} alt="" />
        </div>
        <span className={`${labelClassName || ''}`}>{label}</span>
      </label>
    </div>
  )
})

export default InputCheckbox