import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import {
  Corporate,
  StartupProfile
} from 'generated/graphql'

type Company = {
  type: string,
  id: string,
  name: string,
  logoUrl: string
}

export const companyApi = createApi({
  reducerPath: "companyApi",
  tagTypes: ['company'],
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getPersonProfileCompany: builder.query<Company | null, string>({
      query: (id) => ({
        document: gql`
          query findPersonProfileCompany($id: ID!) {
            corporates(where: {
              person_profiles: $id
            }) {
              id
              name
              logo {
                url
              }
            }
            startupProfiles(where: {
              person_profiles: $id
            }) {
              id
              name
              logo {
                url
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => {
        if (response.corporates.length === 1) {
          
          const { id, name, logo } = response.corporates[0]

          return {
            type: 'corporate',
            id,
            name,
            logoUrl: logo?.url
          }
        } else
        if (response.startupProfiles.length === 1) {
          const { id, name, logo } = response.corporates[0]

          return {
            type: 'startup',
            id,
            name,
            logoUrl: logo?.url
          }
        } else
          return null
      },
      providesTags: (result, error, id) => [{
        type: 'company',
        id
      }]
    })
  })
})

export const {
  useGetPersonProfileCompanyQuery
} = companyApi
