import React from 'react';
import FormLayout from './forms/form-layout';

const SignUpError = () => {
  return (
    <FormLayout
      subHeading="Sign up"
      heading="Oops"
    >
      <div className="row">
        <div className="col text-center">
          There was an error with your sign up, kindly contact us at <a href="mailto:contact@alkelio.com?subject=Sign up error">contact@alkelio.com</a> and we will assist as much as possible.
        </div>
      </div>
    </FormLayout>
  );
}

export default SignUpError;