import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form'
import { useResetPasswordMutation } from 'services/graphql/auth'
import { open } from 'features/modals/modalSlice'
import InputPassword from 'shared/forms/input-password';
import {
  useParams,
  useHistory
} from 'react-router-dom';
import { getLocalStorage } from 'shared/utilities'

interface Inputs {
  password: string;
  retypePassword: string;
  code: string;
}

const ResetPassword = () => {
  const { code }: any = useParams()
  const { push }: any = useHistory()
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [ ResetPassword, { isLoading, isSuccess }] = useResetPasswordMutation()
  const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({
    mode: "onChange"
  });

  const onSubmit: SubmitHandler<Inputs> = async ({password, retypePassword, code}: Inputs) => {
    try {
      await ResetPassword({
        password,
        retypePassword,
        code,
        uuid: getLocalStorage('forgot-password-uuid')
      }).unwrap();
    } catch (err) {
      setError(true)
    }
    
  }

  const handleBackToLogin = () => {
    localStorage.removeItem('forgot-password-uuid')
    push('/login')
  }

  useEffect(() => {

  }, [])

  return (
    <div className="reset-password">
      <h1 className="mb-5">Password reset</h1>
      
      {isSuccess && 
      <>
      <div className="alert alert-success" role="alert">
        Your password has been updated
      </div>
      <div className="mt-5 text-center txt-weight-500">
        <button type="button" className="btn-link" onClick={handleBackToLogin} >Login now</button>
      </div>
      </>
      }
      
      {!isSuccess && 
      <>
      <form className="form" onSubmit={handleSubmit(onSubmit)} >
        <input type="hidden" value="x" />
        {isLoading && <div>Loading</div>}
        {error && <div className="alert alert-danger" role="alert">
          The passwords are not valid
        </div>}
        
        <label>
          <div className="text">
            Code
          </div>
          <input type="text" {...register("code", { required: true })} autoComplete="new-code" />
          <div className={`invalid-feedback ${errors.code ? 'd-block':''}`}>This field is required</div>
        </label>
        
        <label id="password-txt">
          <div className="text">
            Password
          </div>
          <InputPassword {...register("password", { required: true})} autoComplete="new-password" />
          <div className={`invalid-feedback ${errors.password ? 'd-block':''}`}>This field is required</div>
        </label>
        
        <label id="password-txt">
          <div className="text">
            Confirm Password
          </div>
          <InputPassword {...register("retypePassword", { required: true})} autoComplete="new-password-confirm" />
          <div className={`invalid-feedback ${errors.retypePassword ? 'd-block':''}`}>This field is required</div>
        </label>

        <button type="submit" className="mt-4 btn btn-primary" >Reset Password</button>
      </form>

      <div className="mt-5 text-center txt-weight-500">
        Back to <button type="button" className="btn-link" onClick={handleBackToLogin} >Login</button>
      </div>
      </>
      }

    </div>
  )
}

export default ResetPassword