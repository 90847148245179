import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  NotificationPreferenceInput
} from 'generated/graphql'

export type Settings = {
  notifications: NotificationPreferenceInput
}

export type ProfileCurrency = {
  currency: string
}

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  tagTypes: ["settings", "currency"],
  endpoints: (builder) => ({
    updateSettings: builder.mutation<void, Settings>({
      query: (input) => ({
        document: gql`
          mutation setCurrentUserNotifications($input: SettingsInput!) {
            setCurrentUserNotifications(input: $input)
          }
        `,
        variables: {
          input,
        }
      }),
      invalidatesTags: ['settings']  
    }),
    getSettings: builder.query<Settings, void>({
      query: () => ({
        document: gql`
          query Settings {
            myProfile {
              person {
                user {
                  notification_preference {
                    team_notifications
                    platform_notifications
                    potential_partner
                    partner_collaboration
                    chat
                    activity
                  }
                }
              }
              region_currency
            }
          }
        `
      }),
      transformResponse: (response: any) => ({
        notifications: response.myProfile.person.user.notification_preference
      }),
      providesTags: ['settings']
    }),
    getCurrency: builder.query<ProfileCurrency, void>({
      query: () => ({
        document: gql`
          query Settings {
            myProfile {
              region_currency
            }
          }
        `
      }),
      transformResponse: (response: any) => ({
        currency: response.myProfile.region_currency
      }),
      providesTags: ['currency']
    })
  })
})

export const {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetCurrencyQuery
} = settingsApi
