import './assets/styles/news-card.scss';
import CaretRightIcon from 'assets/svgs/caret-right-azure.svg';
import { useHistory, useParams } from 'react-router-dom';

type Props = {
  id: string;
  image: string;
  title: string;
  description: string;
}

const ServiceCard = ({
  id,
  image,
  title,
  description,
}: Props) => {
  const { push } = useHistory();
  const { entityType }: any = useParams();

  return (
    <div className="news-card" onClick={() => {
      push(`/app/${entityType}/news/${id}`)
    }}>
      <div className="image">
        <img src={image} alt={title} />
      </div>
      <div className="content">
        <h5>
          {title}</h5>
        <div className="body txt-light font-size-14 line-height-140 color-text">
          {description}
        </div>
      </div>
      <div className="row-action font-size-12 bold-type letter-spacing-5 color-text color-bg-grey_opacity_20 d-flex flex-row align-center">
        READ MORE <img src={CaretRightIcon} alt="read more" style={{ width:'6px' }} className="ms-2 d-block" />
      </div>
    </div>
  )
}

export default ServiceCard;