import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { PurchasedSubscription } from 'generated/graphql'

export const purchasedSubscriptionApi = createApi({
  reducerPath: 'purchasedSubscriptionApi',
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getMyPurchasedSubscription: builder.query<PurchasedSubscription, void>({
      query: () => ({
        document: gql`
          query {
            getMyPurchasedSubscription {
              id
              balance
              spent
              expiry
              service_package {
                name
                region_code
                slug
              }
            }
          }
        `
      }),
      transformResponse: (response: any) => response.getMyPurchasedSubscription
    })
  })
})

export const {
  useGetMyPurchasedSubscriptionQuery
} = purchasedSubscriptionApi