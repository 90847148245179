import React from 'react';
import NewsCard from 'app/shared/news-card';
import Tile from 'app/shared/tile';
import { 
  useGetAllNewsQuery
} from 'services/graphql/news';

const AllNews = () => {
  const { data, isSuccess } = useGetAllNewsQuery()

  return (
    <Tile 
      title="News"
      >
      <div className="p-0 container-fluid">
        <div className="row">
          <div className="p-0 col">
            <div className="flex-wrap p-0 d-flex flex-row ms-n-1 me-n-1">
              {isSuccess && (data ?? []).map(({ 
                id,
                headline, 
                summary,
                main_image
              }, i) => (
                <NewsCard 
                  key={i}
                  id={id}
                  title={headline?? ''} 
                  description={summary?? ''}
                  image={`${main_image?.formats['thumbnail'] ? main_image?.formats['thumbnail'].url : ''}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Tile>
  )
}

export default AllNews;
