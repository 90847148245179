import { useEffect } from 'react'
import Tile from 'app/shared/tile'
import PopCrackerIcon from './assets/svgs/pop-cracker.svg'
import './assets/styles/complete.scss'
import Button from 'app/shared/button'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { currentCart, reset } from 'features/cart/cartSlice'
import LongArrowRightWhite from 'app/shared/assets/svgs/long-arrow-right-white.svg';

const Complete = () => {
  const dispatch = useDispatch()
  const { push } = useHistory();
  const { entityType }: any = useParams();
  const cart = useSelector(currentCart)

  useEffect(() => {
    dispatch(reset)
  }, [])

  return (
    <Tile>
      <div className="payment-complete d-flex flex-column align-items-center justify-content-center">
        <img className="icon" src={PopCrackerIcon} alt="" />
        <div className="title">Your order was successful!</div>
        <div className="font-size-14 font-size-md-12 line-height-140 txt-regular letter-spacing-n-2 mb-3 text-center">
          We will reach out to you to get started
        </div>
        <Button
          variant="primary" 
          className="wide"
          onClick={() => {
            push(`/app/${entityType}/collaborate`)
          }}>
          Go to collaboration
          <img src={LongArrowRightWhite} alt="next" className='ms-2' />
        </Button>
      </div>
    </Tile>
  )
}

export default Complete;