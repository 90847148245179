import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request' 
import { NewsArticle } from 'generated/graphql'

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getAllNews: builder.query<Array<NewsArticle>, void>({
      query: () => ({
        document: gql`
          query GetAllNews {
            newsArticles(sort: "updated_at:desc") {
              id,
              headline,
              summary,
              main_image {
                formats
              }
            }
          }
        `
      }),
      transformResponse: (response: any) => response.newsArticles
    }),
    getLatestNews: builder.query<Array<NewsArticle>, number>({
      query: (count) => ({
        document: gql`
          query GetNews($count: Int = 3) {
            newsArticles(limit: $count, sort: "updated_at:desc") {
              id
              headline
              summary
              main_image {
                formats
              }
            }
          }
        `,
        variables: {
          count
        },
      }),
      transformResponse: (response: any) => response.newsArticles
    }),
    getOneNewsArticle: builder.query<NewsArticle, number>({
      query: (id) => ({
        document: gql`
          query GetArticle($id: ID!) {
            newsArticle(id: $id) {
              headline
              published_at
              body
              main_image {
                formats
              }
            }
          }
        `,
        variables: {
          id
        },
      }),
      transformResponse: (response: any) => response.newsArticle
    })
  }),
})

export const { 
  useGetLatestNewsQuery, 
  useGetOneNewsArticleQuery,
  useGetAllNewsQuery
} = newsApi