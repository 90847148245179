import React, { useState } from 'react'
import './assets/styles/expand.scss'
import PlusThinGreyIcon from 'app/shared/assets/svgs/plus-thin-grey.svg';
import MinusThinGreyIcon from 'app/shared/assets/svgs/minus-thin-grey.svg';

interface ExpandProps {
  icon?: string;
  label: string;
  children?: React.ReactNode;
}

const Expand = ({
  icon,
  label,
  children  
}: ExpandProps) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className={`expand ${expanded ? 'expanded':''}`}>
      <div className="controls"
        onClick={() => {
          setExpanded(!expanded)
        }} >
        <img src={icon} alt="" />
        {label && <span>{label}</span>}
        <button className={`btn-expand-toggle`}>
          <img className="not-expanded" src={PlusThinGreyIcon} alt="" />
          <img className="expanded" src={MinusThinGreyIcon} alt="" />
        </button>
      </div>
      <div className="children">
        {children}
      </div>
    </div>
  )
}

export default Expand