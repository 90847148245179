import React from 'react'
import './assets/styles/form-layout.scss'

type FormLayoutProps = {
  subHeading: string;
  heading: string;
  description?: string;
  formClassName?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const FormLayout = ({
  subHeading,
  heading,
  description,
  formClassName,
  children
}: FormLayoutProps) => {
  return (
    <div className={`container-fluid p-0 ${formClassName} form-layout`}>
      <div className="row">
        <div className="col">
          <h6 className="tangerine">{subHeading}</h6>
          <h1 className="mt-md-4 mt-0">{heading}</h1>
          {description && <p className="mt-3 form-description">{description}</p>}
        </div>
      </div>
      <div className="row pt-4 pt-xl-4 pt-md-0">
        <div className="col mt-3 mt-xl-4 mt-md-5">
          {children}
        </div>
      </div>
    </div>
  )
}

export default FormLayout;