import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { 
  setCurrentSession, 
  setNewSession,
  selectCurrentSession,
  selectIsFromHomePage,
  setEmail,
  reset
} from 'features/signup/signupSlice';
import { 
  OnboardingSessionCorporatePayloadInput, 
  OnboardingSessionStartupPayloadInput,
  CompleteOnboardingSessionByUuidPayload 
} from 'generated/graphql';
import { 
  useCreateOboardingSessionMutation,
  useCompleteOnboardingSessionMutation,
  useLazyTryGetNewQuery
} from 'services/graphql/on-boarding-session'
import { startCache, hasCache, saveCache, updateCacheKey, getCacheKey, clearCache, clearCacheKey } from '../utils/caching'
import { useUpdateOnboardingSessionMutation } from 'services/graphql/on-boarding-session'
import { setStep } from 'features/signup/signupSlice';

const useOnboarding = () => {
  const [uuid, setUuid] = useState<string>('')
  const [getNew, onboardingUUID] = useCreateOboardingSessionMutation()
  const [tryGetNewOnboardingSession, { data, isFetching, isSuccess}] = useLazyTryGetNewQuery()
  const [completeOnboardingByUUID] = useCompleteOnboardingSessionMutation();
  const currentSession = useSelector(selectCurrentSession);
  const isFromHomePage = useSelector(selectIsFromHomePage);
  const dispatch = useDispatch()
  const [update] = useUpdateOnboardingSessionMutation()

  const startOnboarding = async () => {
    const hasStartCache = hasCache();
    
    if (!hasStartCache) {
      const result = (await getNew({}).unwrap());
      if (result && result.uuid) {
        startCache(result.uuid)
      }
    } else {
      const cachedUuid = getCacheKey()
      if (cachedUuid !== undefined &&cachedUuid !== null && cachedUuid !== '') {
        tryGetNewOnboardingSession(cachedUuid);
      }
    }
  }

  const resetOnboardingPayload = () => {
    dispatch(reset())
  }

  const newOnboarding = async () => {
    clearCache();
    clearCacheKey();
    await startOnboarding();
  }

  const newOnboardingWithRequests = async (payloadInput: OnboardingSessionCorporatePayloadInput | OnboardingSessionStartupPayloadInput) => {
    clearCache();
    clearCacheKey();
    const result = (await getNew({}).unwrap());
    if (result && result.uuid) {
      startCache(result.uuid)
    }
    const updatedSession = {
      ...payloadInput
    }
    dispatch(setCurrentSession(updatedSession));
    dispatch(setStep(payloadInput.step ?? 2))
    await update({
      uuid: getCacheKey(),
      payload: updatedSession
    })
  }

  const completeOnboarding = async (): Promise<CompleteOnboardingSessionByUuidPayload | null> => {
    return new Promise((resolve, reject) => {
      const uuid = getCacheKey();
      if (uuid) {
        resolve(completeOnboardingByUUID(uuid).unwrap())
        clearCache()
        clearCacheKey()
      } else {
        reject()
      }
    })
  }

  const setOnboardingPayload = async (payloadInput: OnboardingSessionCorporatePayloadInput | OnboardingSessionStartupPayloadInput) => {
    let updatedSession = {}
    const prevStep = currentSession?.payload?.step ?? null
    updatedSession = {
      ...currentSession?.payload ?? null,
      ...payloadInput
    }
    dispatch(setCurrentSession(updatedSession));
    // only save after step 1
    if ((payloadInput?.step ?? 1) > 1 || prevStep === 2) {
      await update({
        uuid: getCacheKey(),
        payload: updatedSession
      })
    }
    // }
  }

  useEffect(() => {
    if (data && data.payload) {
      console.log('try get onboarding data', data)
      const uuid = data.uuid as string
      setUuid(uuid)
      updateCacheKey(uuid)

      dispatch(setCurrentSession(data.payload));
      dispatch(setStep(data.payload.step))
      dispatch(setEmail(data.payload?.user?.email?? ''))
    }
  }, [isSuccess])

  return {
    onboardingPayload: currentSession?.payload?? null, 
    isFromHomePage,
    newOnboarding,
    setOnboardingPayload,
    resetOnboardingPayload,
    startOnboarding,
    completeOnboarding,
    newOnboardingWithRequests,
    isFetching,
    isExisting: hasCache()
  };
}

export default useOnboarding;