const VideoBackground = () => {
  return (
    <video 
      autoPlay={true} 
      loop={true} 
      muted={true} 
      controls={false}
      playsInline={true}
    >
      <source src="/videos/alkelio-bg.mp4" type="video/mp4" />
    </video>
  )
}

export default VideoBackground;