import Logo from '../logo';
import { NavItemProps } from './nav-item';
import MenuNavItem from './nav-item';
import './assets/styles/menu.scss';
import Help from './help';

export interface MenuProps {
  links: Array<NavItemProps>;
  active?: boolean;
}

const Menu = ({
  links,
  active
}: MenuProps) => {
  const NavList = links.map((item, i) => (
    <MenuNavItem key={i} {...item} ></MenuNavItem>
  ))

  return (
    <div className={`menu ${active ? 'active':''
    }`}>
      <Logo></Logo>
      <div className="nav-items">
        <div className="">
          {NavList}
        </div>
        <Help/>
      </div>
    </div>
  )
};

export default Menu;