import './assets/styles/input-uploads.scss'
import UploadAzureIcon from 'app/shared/assets/svgs/upload-azure.svg'
import React, { useState, useRef, useEffect } from 'react';
import DocumentBlueIcon from 'app/shared/assets/svgs/document-blue.svg';
import CircleOCrossIcon from 'app/shared/assets/svgs/circle-o-cross.svg';
import BaseInput from './base-input'
import useFileUpload from 'hooks/file-uploads';
import Config from 'config';
import { FileItem } from 'hooks/file-uploads';
import Loader from 'shared/loader';
interface InputUploadsProps extends BaseInput{
  width? : string;
  height? :string;
  maxFileCount?: number;
  value?: Array<FileItem> | null;
  defaultValue?: Array<FileItem> | null;
  layout?: string;
  onUploadsChange?: (files: Array<FileItem>) => unknown;
}
export const getUrl = (srcUrl: string | null | undefined) => {
  if (srcUrl == null || srcUrl === undefined) return '';
  
  if (srcUrl.indexOf('http') === -1 && srcUrl !== '') {
    srcUrl = `${Config.storageBaseURL}${srcUrl}`
  }
  return srcUrl;
}
const MultipleUploads = React.forwardRef((
  props: InputUploadsProps, 
  ref: React.Ref<HTMLDivElement>
) => {
  const dropRef = useRef<HTMLLabelElement>(null)

  const [prevLength,setPrevLength] = React.useState(0);
  const initial = props.defaultValue !== null && props.defaultValue !== undefined ? 
  props.defaultValue : (props.value !== null && props.value !== undefined ? 
    props.value : [])
    
  const { uploads, onChange, remove, isLoading, onDrop } = useFileUpload({
    name: props.name || '',
    max: 10,
    initial,
    onUploadsChange: (files) => {
      // console.log("input uploads", files)
      props.onChange && props.onChange(files?? [])
    }
  })
  
  const removeFile = (id: string) => {
    remove(id);
  }

  useEffect(() => {
    if(uploads.length > 0 && prevLength!==uploads.length){
      setPrevLength(uploads.length);
    }

    props.onChange && props.onChange(uploads);

    return () => {
    }
  },[uploads])

  return (
    <div className="input-uploads" 
    ref={ref}
    style={{
      width: props.width || '100%',
      height: props.height || '100%'
    }}
    >
      <label className="input-area" 
        onDrop={onDrop}
        ref={dropRef}
      >
        <div className="input-area-inner">

          <input type="file"
          multiple={true}
            // hidden
            onChange={onChange} 
             />
          <button type="button" className="action">
            <img src={UploadAzureIcon } alt="browse" />
            <span>Upload Document</span>
          </button>
        </div>

        { isLoading && <div className="loading"><Loader /></div>}
        
        <div className="mapped-uploads">
        {uploads.length>0 && uploads.map((item, i) => (
          <>
          {item.id!==undefined? <>
            <div key={i} className="file" style={{maxWidth:'250px'}}>
            <img src={DocumentBlueIcon} alt={item.name} />
            <a href={getUrl(item.url)} target="_blank" rel="noreferrer">
              <div className="name">
              {item.name}
              </div>
            </a>
            <button type="button" className="button-reset" onClick={() => {
                removeFile(item.id)
              }}>
                <img src={CircleOCrossIcon} alt="remove" />
              </button>
          </div>
          </>:<></>}
          
          </>
        ))}
      </div>
      </label>
      
    </div>
  )
});

export default MultipleUploads;