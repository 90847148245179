import { useState } from 'react';
import './assets/styles/sign-up-inline.scss';
import OptionButtons from 'shared/forms/option-buttons';
import { SignUpPurposesStartup, SignUpPurposeCorporate} from 'app-constants';
import { useDispatch } from 'react-redux';
import { open } from 'features/modals/modalSlice';
import InfoOIcon from './assets/svgs/info-o-red.svg';
import {
  setIsFromHomePage
} from "features/signup/signupSlice";
import useOnboarding from 'shared/sign-up-v2/hooks/onboarding.hook';
import { SignUpType } from 'types/sign-up'

const StartupOptions = SignUpPurposesStartup.map(({label, key}) => ({ label, value: key }))

const CorporateOptions = SignUpPurposeCorporate.map(({label, key}) => ({ label, value: key }))

const SignUpInline = () => {
  const dispatch = useDispatch();
  // const [createOboardingSession] = useCreateOboardingSessionMutation();
  const { newOnboardingWithRequests } = useOnboarding()
  const [signUpAs, setSignUpAs] = useState<SignUpType>(SignUpType.Startup);
  const [options, setOptions] = useState<string[]>([]);
  
  return (
    <div className="sign-up-inline p-md-6 pt-5 pb-5 ps-3 pe-3 box-white-rounded-shadow">
      <div className="p-md-5">
        <h6 className="mb-0 mb-md-4 tangerine text-center text-md-start">Get Started</h6>
        <h1 className="mb-2 mb-md-4 text-md-start text-center">Sign up today</h1>
        <div className="w-100">
          <p className="pb-2 pb-md-4 txt-light font-size-14 line-height-140 text-center text-md-start">Tell us who you are and what you are looking for so we can qualify your request and create your personal space. Welcome On Board!</p>
          <h6 className="mt-5 mb-3 mt-md-5 mb-md-3 color-text semi-bold-type">
            <span className="d-none d-md-block">
            I am a
            </span>
            <span className="d-block d-md-none text-center font-size-12">
            SIGN ME UP AS A
            </span>
          </h6>
          <div className="d-none d-md-block btn-group w-100" role="group">
            <button type="button" 
              className={`btn bold-type letter-spacing-0 w-50 ${signUpAs === SignUpType.Startup ? 'btn-primary':'color-text'}`} 
              onClick={() => {
                setSignUpAs(SignUpType.Startup);
                setOptions([])
              }}>Startup</button>
            <button type="button" 
              className={`btn bold-type letter-spacing-0 w-50 ${signUpAs === SignUpType.Corporate ? 'btn-primary':'color-text'}`} 
              onClick={() => {
                setSignUpAs(SignUpType.Corporate);
                setOptions([])
              }}>Corporate</button>
          </div>
          <div className="d-flex d-md-none">
            <button type="button" 
              className={`btn less-rounded bold-type me-1 letter-spacing-0 w-50 pt-3 pb-3 ps-4 pe-43 ${signUpAs === SignUpType.Startup ? 'btn-primary':'color-text bordered'}`} 
              onClick={() => {
                setSignUpAs(SignUpType.Startup);
                setOptions([])
              }}>Startup</button>
            <button type="button" 
              className={`btn less-rounded bold-type ms-1 letter-spacing-0 w-50 ${signUpAs === SignUpType.Corporate ? 'btn-primary':'color-text bordered'}`} 
              onClick={() => {
                setSignUpAs(SignUpType.Corporate);
                setOptions([])
              }}>Corporate</button>
          </div>
          <h6 className="mt-5 mb-3 color-text semi-bold-type">
            <span className="d-none d-md-block">
            I want to
            </span>
            <span className="d-block d-md-none text-uppercase text-center font-size-12">
            I want to
            </span>
          </h6>
          <div className="d-flex">
            <OptionButtons 
              options={signUpAs === SignUpType.Startup ? StartupOptions : CorporateOptions} 
              onSelect={(selected) => {
                setOptions(selected);
              }}
            />
          </div>
          <div className="mt-4 d-flex justify-content-end">
            <button className="btn-signup btn btn-primary" onClick={() => {
              // preset the sign up status and go to step 2
              // set current session based on type of sign up and want/purpose options in 
              
              const onboardingPayload = {
                type: signUpAs,
                step: 2,
                requests: {}
              }
              
              options.forEach((item: string) => {
                (onboardingPayload.requests as Record<string, object | string | boolean | number>)[item] = true;
              })

              newOnboardingWithRequests(onboardingPayload);
              dispatch(setIsFromHomePage(true));

              // TODO: integrate new sign up onboarding hook here
              /*
              const result: OnBoardingSession = await createOboardingSession({ data: onboarding }).unwrap();
              localStorage.setItem(Config.onboardingUUIDStorageKey, String(result.uuid));
              setOnboardingSessionExpiryDate();
              dispatch(setCurrentSession({ uuid: result.uuid, payload: onboarding.payload }));
              */
              dispatch(open('sign-up'));
            }}>Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpInline