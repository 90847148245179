import './assets/styles/options-buttons.scss';
import TickWhiteIcon from './assets/svgs/tick-white.svg';
import { useState, useEffect } from 'react';

type Option = {
  label: string,
  value: string;
}

type Props = {
  options: Option[],
  onSelect: (options: string[]) => unknown;
}

const OptionButtons = ({ options, onSelect }: Props) => {
  const [selected, setSelected] = useState<string[]>([]);
  
  useEffect(() => {
    setSelected([])
  }, [options])

  return (
    <div className="options-buttons">
      {options.map((option, i) => 
        <label key={`${option.value}`}>
          <input type="checkbox" value={option.value} 
            onChange={(evt) => {
              let list = [...selected];
              
              if (evt.target.checked) {
                list.push(option.value);  
              } else {
                list = [...selected].filter((item) => item !== option.value);
              }
              setSelected(list);
              onSelect(list);
            }} />
          <div className="button">
            <div className="tick-icon d-flex d-md-none">
              <img src={TickWhiteIcon}/>
            </div>
            <div className="text d-none d-md-block" dangerouslySetInnerHTML={{__html: option.label}}></div>
            <div className="text-mobile d-block d-md-none" dangerouslySetInnerHTML={{__html: option.label}}></div>
          </div>
        </label>
      )}
    </div>
  );
}

export default OptionButtons;