import './assets/styles/input-money.scss';
import React from 'react';
import BaseInput from 'shared/forms/base-input';
import NumberFormat from 'react-number-format';

interface Props extends BaseInput { 
  prefix?: string
}

const InputMoney = React.forwardRef(({
    prefix = '', onChange, defaultValue, style, className, ...rest
}: Props,
ref: React.Ref<HTMLDivElement>) => {
  return (
    <div className={className? className:"input-money"} style={style} ref={ref}>
      <NumberFormat value={defaultValue} thousandSeparator={true} prefix={prefix} {...rest}
        onValueChange={(values) => {
          const { value } = values;
          onChange && onChange(value);
        }} 
      />
    </div>
  )
})

export default InputMoney;