import React, { useEffect } from 'react';
import GenericModal from 'shared/generic-modal';
import Confirm from 'shared/confirm';
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import {
  useHistory,
  useParams
} from 'react-router-dom';
import Tile from 'app/shared/tile';
import InputInline from 'shared/forms/input-inline';
import Button from 'app/shared/button';
import InputImage from 'shared/forms/input-image';
import CompletionStatus from './completion-status';
import InputCheckBox from 'shared/forms/input-checkbox';
import SelectMultiple from 'shared/forms/select-multiple';
import SelectOne from 'shared/forms/select-one';
import ProjectBudget from './project-budget';
import { OptionItem } from 'shared/forms/select-multiple';
import { FileItem } from 'hooks/file-uploads';
import _ from 'lodash';
import { 
  BusinessUseCase, 
  EditTechnologyRequestInput, 
  Industry, 
  TechnologyType,
  ImpactArea, 
  UploadFile,
  SdgObjectives
} from 'generated/graphql';
import { ImpactLevels } from 'app/constants';
import Inputs from './inputs';
import { useDispatch } from 'react-redux';
import { open, close } from 'features/modals/modalSlice';
import { toOptions, toValues, unNull } from 'shared/utilities'
import {
  useLazyGetOneTechnologyRequestQuery,
  useUpdateTechnologyRequestMutation,
  useDeleteTechnologyRequestMutation,
  useCreateTechnologyRequestMutation,
} from 'services/graphql/tech-requests'
import { 
  useGetBusinessUsecasesQuery, 
  useGetIndustriesQuery,
  useGetTechnologyTypesQuery,
  useGetImpactAreasQuery,
  useGetGoalsQuery,
  useGetSdgObjectivesQuery
} from 'services/graphql/tags';
import NeedHelp from './need-help';
import DateQuarterSelect from 'app/shared/date-quarter-select';
import { CountryNames } from 'shared/countries'
import InputTextAreaCounter from 'shared/forms/input-textarea-counter';
import { completionRate } from './completion-status';
import './assets/styles/tech-request-form.scss';

const SdgObjectivesOptions = (props:any) => {
  const {data, isSuccess} = useGetSdgObjectivesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const TechnologyTypesOptions = (props:any) => {
  const {data, isSuccess} = useGetTechnologyTypesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const IndustriesOptions = (props:any) => {
  const {data, isSuccess} = useGetIndustriesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const BusinessUseCasesOptions = (props:any) => {
  const {data, isSuccess} = useGetBusinessUsecasesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const ImpactAreaOptions = (props:any) => {
  const {data, isSuccess} = useGetImpactAreasQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const GoalsOptions = (props:any) => {
  const {data, isSuccess} = useGetGoalsQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const TechRequestForm = () => {
  const dispatch = useDispatch()
  const { id } : any = useParams()
  const history = useHistory();
  const [ trigger, { data, isLoading, isSuccess } ] = useLazyGetOneTechnologyRequestQuery()
  const [ updateTechnologyRequest ] = useUpdateTechnologyRequestMutation()
  const [ deleteTechnologyRequest ] = useDeleteTechnologyRequestMutation()
  const [ createTechnologyRequest ] = useCreateTechnologyRequestMutation()
  const isEdit = (id !== undefined)

  const { 
    register, 
    handleSubmit, 
    watch, 
    control,
    getValues,
    setValue,
    reset,
  formState: { errors } } = useForm<Inputs>({
    mode: "onChange",
  });

  const watchAll = watch();
  const watchGoal = watch('goals');
  const completion = completionRate(watchAll);

  const getFormInput = ({
    name,
    hero_image,
    description_project,
    want_poc,
    want_lease,
    want_seller,
    want_investment,
    business_challange_description,
    has_api,
    has_ip,
    is_gdpr,
    is_spin_off,
    can_whitelabel,
    is_plugandplay,
    is_cloud,
    sdg_driven,
    is_others,
    is_others_value,
    impact_level,
    impact_area,
    goals,
    goal_other,
    technology_types,
    industries,
    business_use_cases,
    description_how_to_resolve_problem,
    description_customer,
    technology_solution_model_b2b,
    technology_solution_model_b2c,
    technology_solution_model_b2b2c,
    technology_solution_model_others,
    technology_solution_model_others_value,
    description_successful_outcome,
    description_similiar_solutions,
    description_risk_challenges,
    project_budget,
    projected_startdate,
    projected_complete_date,
    country_implementation,
    sdg_objectives
  }: Inputs): EditTechnologyRequestInput => ({
    name,
    hero_image: hero_image?.id?? null,
    description_project,
    want_poc,
    want_lease,
    want_seller,
    want_investment,
    business_challange_description,
    has_api,
    has_ip,
    is_gdpr,
    is_spin_off,
    can_whitelabel,
    is_plugandplay,
    is_cloud,
    sdg_driven,
    is_others: is_others ? is_others_value : null,
    impact_level,
    impact_area: isNaN(parseInt(impact_area)) ? null : impact_area,
    goals,
    goal_other,
    technology_types,
    industries,
    business_use_cases,
    description_how_to_resolve_problem,
    description_customer,
    technology_solution_model_b2b,
    technology_solution_model_b2c,
    technology_solution_model_b2b2c,
    technology_solution_model_others: technology_solution_model_others ? technology_solution_model_others_value : "",
    description_successful_outcome,
    description_similiar_solutions,
    description_risk_challenges,
    project_budget: project_budget.map(({budget, currency, option}) => ({
      budget: Number(budget), 
      currency,
      option
    })),
    projected_startdate,
    projected_complete_date,
    country_implementation: country_implementation.join(','),
    sdg_objectives
  })

  const handleDelete = () => {
    dispatch(open('confirmation-delete'))
  }
  
  const onConfirm = () => {
    dispatch(close())
    deleteTechnologyRequest(id)
    history.goBack();
  }

  const onCancel = () => {
    dispatch(close())
  }

  const saveDraft: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (isEdit) {
      await (await updateTechnologyRequest({
        id: id,
        data: {
          ...getFormInput(data),
          is_published: false,
          is_completed: completion === 1
        }
      }).unwrap());
    } else {
      await (await createTechnologyRequest({
        data: {
          ...getFormInput(data),
          is_published: false,
          is_completed: completion === 1
        }
      }).unwrap());
    }
    history.goBack();
  }

  const publish: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (isEdit) {
      await (await updateTechnologyRequest({
        id: id,
        data: {
          ...getFormInput(data),
          is_published: true,
          is_completed: completion === 1
        }
      }).unwrap());
    } else {
      await (await createTechnologyRequest({
        data: {
          ...getFormInput(data),
          is_published: true,
          is_completed: completion === 1
        }
      }).unwrap());
    }

    history.goBack();
  }
  
  useEffect(() => {
    if (isEdit)
      trigger(id)
  }, [])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      reset({
        name: data?.name || '',
        hero_image: data?.hero_image as Pick<UploadFile, "id" | "name" | "url">,
        description_project: data?.description_project || '',
        want_poc: data?.want_poc || false,
        want_lease: data?.want_lease || false,
        want_seller: data?.want_seller || false,
        want_investment: data?.want_investment || false,
        business_challange_description: data?.business_challange_description || '',
        // options that are important
        has_api: data?.has_api || false,
        has_ip: data?.has_ip || false,
        is_gdpr: data?.is_gdpr || false, 
        is_spin_off: data?.is_spin_off || false,
        can_whitelabel: data?.can_whitelabel || false,
        is_plugandplay: data?.is_plugandplay || false,
        is_cloud: data?.is_cloud || false,
        is_others: !_.isEmpty(data?.is_others),
        sdg_driven: data?.sdg_driven || false,
        is_others_value: data?.is_others || '',
        impact_level: data?.impact_level || '',
        impact_area: data?.impact_area?.id || '',
        goals: data?.goals?.map(item => item?.id),

        technology_types: data?.technology_types?.map(item => item?.id),
        industries: data?.industries?.map(item => item?.id),
        business_use_cases: data?.business_use_cases?.map(item => item?.id),

        description_how_to_resolve_problem: data?.description_how_to_resolve_problem || '',
        description_customer: data?.description_customer || '',

        // looking for solution
        technology_solution_model_b2b: data?.technology_solution_model_b2b || false,
        technology_solution_model_b2c: data?.technology_solution_model_b2c || false,
        technology_solution_model_b2b2c: data?.technology_solution_model_b2b2c || false,
        technology_solution_model_others: !_.isEmpty(data?.technology_solution_model_others),
        technology_solution_model_others_value: data?.technology_solution_model_others || '',

        description_successful_outcome: data?.description_successful_outcome || '',
        description_similiar_solutions: data?.description_similiar_solutions || '',
        description_risk_challenges: data?.description_risk_challenges || '',

        project_budget: data?.project_budget?.map(item => ({
          id: item?.id,
          currency: item?.currency || '',
          budget: item?.budget || 0,
          option: item?.option || '',
        })),

        projected_startdate: data?.projected_startdate || null,
        projected_complete_date: data?.projected_complete_date || null,

        country_implementation: data?.country_implementation?.split(','),

        sdg_objectives: data?.sdg_objectives?.map(item => item?.id)
      })
    }
  }, [data])

  type ActionRowProps = {
    className?:string
  }

  const ActionRow = ({
    className
  }:ActionRowProps) => (
    <div className={`mb-0 row ${className ?? ''}`}>
      <div className="col-12 col-md-6 ps-0">
        <CompletionStatus {...{ watchAll }} />
      </div>
      <div className="col-12 col-md-6 d-flex flex-column flex-md-row mt-2 mt-md-0 ps-0 pe-0 justify-content-end actions">
        {isEdit && <Button type="button" name="delete" className="mx-md-2 mt-3" onClick={handleDelete}>Delete</Button>}
        <Button type="button" name="save" value="draft" className="mx-md-2 mt-3 " onClick={handleSubmit(saveDraft)}>Save as draft</Button>
        <Button type="button" name="save" value="publish" className="mx-md-2 mt-3" variant="primary" onClick={handleSubmit(publish)}>Publish</Button>
      </div>
    </div>
  )

  return (
    <Tile>
      {isLoading && <div>Loading</div>}
      {(isSuccess || !isEdit ) && <form>
        <div className="tech-request-form-grid">
          <div className="field-title">
            <Controller
              name="name"
              control={control}
              rules={{
                required: true
              }}
              render={({field}) => <InputInline 
                placeholder="Enter your business need title"
                {...field} /> }
            />
            <div className={`invalid-feedback ${errors.name ? 'd-block':''}`}>This field is required</div>
          </div>
          <div className="action-top">
            <ActionRow className="action-row-top"/>
          </div>
          <div className="field-image">
            <Controller
              name="hero_image"
              control={control}
              defaultValue={data?.hero_image as FileItem || null}
              rules={{ 
                required: false
              }}
              render={({field}) => <InputImage
                {...field} />}
            />
            <div className={`invalid-feedback ${errors.hero_image ? 'd-block':''}`}>Please upload an image</div>
          </div>
          <div className="field-tech-background">
            <div className="form-control-group">
              <h6 className="bold-type d-block d-md-none mb-4">Background</h6>
              <div className="form-control">
                <label className="mb-3 mb-md-0">
                  <div className="field-header d-block d-md-none">Description of the project</div>
                  <div className="field-header d-none d-md-block">Description of your project. What are you looking for?</div>
                  <Controller
                    name="description_project"
                    control={control}
                    defaultValue={data?.description_project || ''}
                    render={({field}) => <InputTextAreaCounter maxLength={500} {...field} />}
                  />
                </label>
              </div>

              <div className="form-control">
                <div className="mb-3 mb-md-0 flex-column flex-md-row flex-wrap d-flex align-items-start justify-content-between">
                  <Controller 
                    name="want_poc"
                    control={control}
                    defaultValue={data?.want_poc || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Test a Tech Solution (POC)" 
                      {...field} />}
                  />
                  <Controller 
                    name="want_lease"
                    control={control}
                    defaultValue={data?.want_lease || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Lease (contract)" 
                      {...field} />}
                  />
                  <Controller 
                    name="want_seller"
                    control={control}
                    defaultValue={data?.want_seller || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Buy (M&A)" 
                      {...field} />}
                  />
                  <Controller 
                    name="want_investment"
                    control={control}
                    defaultValue={data?.want_investment || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Invest (CVC)" 
                      {...field} />}
                  />
                </div>
              </div>

              <div className="form-control">
                <label>
                  <div className="field-header d-block d-md-none">Description of your business need</div>
                  <div className="field-header d-none d-md-block">Description of your business need /challenge / opportunity. What are you trying to resolve/ tackle? </div>
                  <textarea
                    className="mb-3 mb-md-0"
                    defaultValue={data?.business_challange_description || ''}
                    {...register("business_challange_description")} ></textarea>
                </label>
              </div>

              <div className="grid-impact-horizon-goals">
                <div className="field-impact">
                  <label>
                    <div className="field-header">Impact for your organization</div>
                    <Controller 
                      name="impact_level"
                      defaultValue={data?.impact_level || ''}
                      control={control}
                      render={({field}) => <SelectOne 
                        sortOptions={false}
                        options={ImpactLevels as Array<string>} 
                        {...field} />}
                    />
                  </label>
                </div>
                <div className="field-horizon">
                  <label>
                    <div className="field-header">Horizon</div>
                    <ImpactAreaOptions
                        render={(impactAreas: Array<ImpactArea>) => (
                          <Controller 
                            name="impact_area"
                            defaultValue={data?.impact_area?.id || ''}
                            control={control}
                            render={({field}) => <SelectOne 
                              className="mt-3"
                              options={toOptions(impactAreas)} 
                              {...field} />}
                          />
                        )}
                      />
                  </label>
                </div>
                <div className="field-goals">
                  <label>
                    <div className="field-header">Goal</div>
                    <GoalsOptions
                      render={(goals: Array<OptionItem>)=> (
                        <Controller
                          name="goals"
                          control={control}
                          defaultValue={toValues(data?.goals || [])}
                          render={({field}) => <SelectMultiple 
                            options={toOptions(goals)}
                            enableOthers={true}
                            {...field} />}
                        />
                      )}
                    />
                  </label>
                  {(watchGoal ?? []).includes('others')  && <div className="row">
                    <div className="col me-0 pe-0">
                      <label>
                        <input type="text" placeholder="Enter other goals seperated by comma" {...register("goal_other")} />
                      </label>
                    </div>  
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="field-need-help">
            <NeedHelp />
          </div>
          <div className="field-options">
            <div className="form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold d-block d-md-none">Options that are important</div>
                  <div className="bold d-none d-md-block">Options that are important for you</div>
                </label>
                <Controller 
                  name="has_api"
                  control={control}
                  defaultValue={data?.has_api || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="API available" 
                    {...field} />}
                />
                <Controller 
                  name="has_ip"
                  control={control}
                  defaultValue={data?.has_ip || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="IP Registered" 
                    {...field} />}
                />
                <Controller 
                  name="is_gdpr"
                  control={control}
                  defaultValue={data?.is_gdpr || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="GDPR compliant" 
                    {...field} />}
                />
                <Controller 
                  name="is_spin_off"
                  control={control}
                  defaultValue={data?.has_api || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="Spin-off/Tested" 
                    {...field} />}
                />
                <Controller 
                  name="can_whitelabel"
                  control={control}
                  defaultValue={data?.can_whitelabel || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="White label available" 
                    {...field} />}
                />
                <Controller 
                  name="is_plugandplay"
                  control={control}
                  defaultValue={data?.is_plugandplay || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="Ready to Plug & Play" 
                    {...field} />}
                />
                <Controller 
                  name="is_cloud"
                  control={control}
                  defaultValue={data?.is_cloud || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="Cloud based" 
                    {...field} />}
                />
                <Controller 
                  name="sdg_driven"
                  control={control}
                  defaultValue={data?.sdg_driven || false}
                  render={({field}) => <InputCheckBox className="mb-3 mr-2" label="SDG Driven" 
                    {...field} />}
                />
                <Controller 
                  name="is_others"
                  control={control}
                  defaultValue={(data?.is_others !== null && data?.is_others !== '' && data?.is_others !== undefined)}
                  render={({field}) => <InputCheckBox className="mb-md-3 mr-2" label="Other" 
                    {...field} />}
                />
              </div>
            </div>
          </div>
          <div className="field-tech-types">
            <div className="h-auto form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">Type of Technology</div>
                  <TechnologyTypesOptions 
                    render={(technologyTypes: Array<TechnologyType> ) => (
                      <Controller 
                        name="technology_types"
                        control={control}
                        defaultValue={toValues(data?.technology_types || [])}  
                        render={({ field }) => <SelectMultiple
                          options={toOptions(technologyTypes)} 
                          {...field} 
                          /> }
                      />
                    )} 
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-industries">
            <div className="h-auto form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">Potential industries interested</div>
                  <IndustriesOptions
                    render={(industries: Array<Industry> ) => (
                      <Controller 
                        name="industries"
                        control={control}
                        defaultValue={toValues(data?.industries || [])} 
                        render={({field}) => <SelectMultiple
                          options={toOptions(industries)} 
                          {...field} />}
                      />
                    )}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-business-use-case">
            <div className="h-auto form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">Potential business use case / applications</div>
                  <BusinessUseCasesOptions 
                    render={(businessUseCases: Array<BusinessUseCase>) => (
                      <Controller
                        name="business_use_cases"
                        control={control}
                        defaultValue={toValues(data?.business_use_cases || [])}
                        render={({field}) => <SelectMultiple 
                          options={toOptions(businessUseCases)} 
                          {...field} />}
                      />
                    )}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-solution-info">
            <div className="form-control-group">
              <h6 className="bold-type d-block d-md-none mb-4">Solution Information</h6>
              <div className="form-control">
                <label>
                  <div className="field-header d-block d-md-none">How to resolve the problem</div>
                  <div className="field-header d-none d-md-block">How you would like to resolve the problem</div>
                  <textarea
                    className="mb-4 mb-md-0"
                    defaultValue={data?.description_how_to_resolve_problem || ''}
                    {...register("description_how_to_resolve_problem")} ></textarea>
                </label>
              </div>
              <div className="form-control">
                <label>
                  <div className="field-header d-block d-md-none">customer & who’s the solution for</div>
                  <div className="field-header d-none d-md-block">Who would be the customer & who would be using the solution?</div>
                  <textarea
                    className="mb-4 mb-md-0"
                    defaultValue={data?.description_customer || ''}
                    {...register("description_customer")} ></textarea>
                </label>
              </div>
              <div className="form-control">
                <label>
                  <div className="field-header">Looking for solution</div>
                </label>
                <div className="flex-column flex-md-row flex-wrap d-flex align-items-md-center justify-content-between">
                  <Controller 
                    name="technology_solution_model_b2b"
                    control={control}
                    defaultValue={data?.technology_solution_model_b2b || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="BtoB" 
                      {...field} />}
                  />
                  <Controller 
                    name="technology_solution_model_b2b2c"
                    control={control}
                    defaultValue={data?.technology_solution_model_b2b2c || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="BtoBtoC" 
                      {...field} />}
                  />
                  <Controller 
                    name="technology_solution_model_b2c"
                    control={control}
                    defaultValue={data?.technology_solution_model_b2c || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="BtoC" 
                      {...field} />}
                  />
                  <Controller 
                    name="technology_solution_model_others"
                    control={control}
                    defaultValue={(data?.technology_solution_model_others !== undefined && data?.technology_solution_model_others !== null && data?.technology_solution_model_others !== "")}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Other" 
                      {...field} />}
                  />
                  <input type="text" 
                    className="mb-4 mb-md-0"
                    placeholder="Other option - enter here" 
                    defaultValue={data?.technology_solution_model_others || ''}
                    {...register("technology_solution_model_others_value")} />
                </div>
              </div>
              
              <div className="form-control mt-4 mb-4 mb-md-0">
                <label>
                  <div className="bold">SDG Objectives</div>
                  <SdgObjectivesOptions
                    render={(sdgObjectives: Array<SdgObjectives> ) => (
                      <Controller 
                        name="sdg_objectives"
                        control={control}
                        defaultValue={toValues(data?.sdg_objectives || [])} 
                        render={({field}) => <SelectMultiple
                          sort={false}
                          options={toOptions(sdgObjectives)} 
                          {...field} />}
                      />
                    )}
                    />
                </label>
              </div>

              <div className="form-control mt-4 mb-4 mb-md-0">
                <label>
                  <div className="field-header d-block d-md-none">
                    What is the successful outcome
                  </div>
                  <div className="field-header d-none d-md-block">What would be the successful outcome of this process/solution ?</div>
                  <textarea
                    defaultValue={data?.description_successful_outcome || ''}
                    {...register("description_successful_outcome")} ></textarea>
                </label>
              </div>

              <div className="form-control mt-4 mb-4 mb-md-0">
                <label>
                  <div className="field-header d-block d-md-none">
                    Similar solitions in the market
                  </div>
                  <div className="field-header d-none d-md-block">Are there any solutions similar in the market to achieve that?</div>
                  <textarea
                    defaultValue={data?.description_similiar_solutions || ''}
                    {...register("description_similiar_solutions")} ></textarea>
                </label>
              </div>

              <div className="form-control mt-4 mb-4 mb-md-0">
                <label>
                  <div className="field-header d-block d-md-none">
                    Project Risks & challenges
                  </div>
                  <div className="field-header d-none d-md-block">Are there any risks or challenges to take into account for this project?  </div>
                  <textarea
                    defaultValue={data?.description_risk_challenges || ''}
                    {...register("description_risk_challenges")} ></textarea>
                </label>
              </div>

            </div>
          </div>
          <div className="field-project-budget">
            <ProjectBudget 
              {...{ control, register, errors, getValues } }
            />
          </div>
          <div className="field-project-timing">
            <div className="form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">Project Timing</div>
                </label>
                <div className="mt-3">
                  <Controller 
                    name="projected_startdate"
                    control={control}
                    defaultValue={data?.projected_startdate || null}
                    render={({field}) => <DateQuarterSelect startFromNow={!isEdit} {...field} yearLabel="Project Start" quarterLabel="Quarter" />}
                  />
                  <Controller 
                    name="projected_complete_date"
                    control={control}
                    defaultValue={data?.projected_complete_date || null}
                    render={({field}) => <DateQuarterSelect startFromNow={!isEdit} {...field}  yearLabel="Project End" quarterLabel="Quarter" /> }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field-countries">
            <div className="form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">Country/countries of implementation</div>
                  <div className="d-block d-md-none font-size-10 mb-2 text-uppercase mt-3">Countries</div>
                  <Controller 
                    name="country_implementation"
                    control={control}
                    defaultValue={data?.country_implementation !== undefined && data.country_implementation !== null && data.country_implementation !== "" ? data?.country_implementation.split(',') : []}
                    render={({field}) => <SelectMultiple 
                      options={CountryNames}
                      {...field} />}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="action-bottom">
            <ActionRow />
          </div>
        </div>        
      </form>}
      
      <GenericModal modalId="confirmation-delete" size="sm" title="Confirm Delete" centered={true}>
        <Confirm 
          body={`Deleing of business need ${data?.name} is permanent. Are you sure you want to continue?`}
          onConfirm={onConfirm} 
          onCancel={onCancel} />
      </GenericModal>
    </Tile>
  )
}

export default TechRequestForm;