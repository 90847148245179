import './assets/styles/overlay-progress.scss';
import { useSelector, useDispatch } from 'react-redux';
import { appReducerStateType } from 'store';

const OverlayProgress = () => {
  const { progress } = useSelector((state: appReducerStateType) => state.inProgress)

  return (
    <>
      {progress ? 
      <div className="overlay-progress">
        <div className="indicator">
          <div className="loader-1 center"><span></span></div>
          <h5>Saving</h5>
        </div>
      </div> : 
      <></>}
    </>
  )
}

export default OverlayProgress;