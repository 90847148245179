import './assets/styles/latest-documents.scss';
import Tile from 'app/shared/tile';
import FileGreenIcon from 'app/shared/assets/svgs/file-green.svg';
import CalendarIcon from 'app/shared/assets/svgs/calender.svg';
import React from 'react';
import { useGetRecentDocumentsQuery } from 'services/graphql/document-sharing';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import dayjs from 'dayjs';
import Button from 'app/shared/button';
import { 
  useHistory,
  useParams 
} from 'react-router-dom'

type DocumentItemProp = {
  name: string;
  updatedSince: string;
  url: string;
}

const DocumentItem = ({
  name,
  updatedSince,
  url,
}: DocumentItemProp) => {
  return (
    <a className="document" href={url} target="_blank" rel="noreferrer" >
      <img className="icon" src={FileGreenIcon} alt={name} />
      <h5>{name}</h5>
      <div className="mt-auto footer">
        <img src={CalendarIcon} alt="since"/><div>{updatedSince}</div>
      </div>
    </a>
  )
}

const LatestDocuments = () => {
  const { push } = useHistory();
  const { entityType }: any = useParams();

  const user = useSelector(selectCurrentUser);
  const { data, isSuccess } = useGetRecentDocumentsQuery(user?.id?? '');

  return (
    <Tile 
      title="My Documents"
      actionComponents={[
        <Button key={1} onClick={() => {
          push(`/app/${entityType}/documents`)
        }}>All Documents</Button>
      ]}
    >
      <div className="latest-documents">
        {isSuccess && (data?? []).map(({title, created_at, document}, i) => (
          <DocumentItem 
            key={i}
            name={title?? ''}
            url={document?.url?? ''}
            updatedSince={dayjs(created_at).from(dayjs())}
          />
        ))}
      </div>
    </Tile>
  )
}

export default LatestDocuments;