import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form'
import { useForgotPasswordMutation, ForgotPasswordResponse } from 'services/graphql/auth'
import { open, close } from 'features/modals/modalSlice'
import { useHistory } from 'react-router-dom'

interface Inputs {
  email: string;
}

const ForgotPassword = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [ forgotPassword, { isLoading }] = useForgotPasswordMutation()
  const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({
    mode: "onChange"
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData: Inputs) => {
    
    try {
      const result: ForgotPasswordResponse = await forgotPassword(formData.email).unwrap();
      localStorage.setItem('forgot-password-uuid', String(result.uuid))
      push('/reset-password');
      // dispatch(open('reset-password'));
    } catch (err) {
      setError(true)
    }
    
  }

  const handleBackToLogin = () => {
    push('/login');
    // dispatch(open('login'))
  }

  return (
    <div className="forgot-password">
      <h1 className="mb-5">Password reset</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        {isLoading && <div>Loading</div>}
        {error && <div className="alert alert-danger" role="alert">
          Email address is invalid
        </div>}
        <label id="email-address-txt" className="mb-5">
          <div className="text">
            Email Address
          </div>
          <input type="text" {...register("email", { required: true })} />
          <div className={`invalid-feedback ${errors.email ? 'd-block':''}`}>This field is required</div>
        </label>
        <button type="submit" className="btn btn-primary" >Reset Password</button>
      </form>
      <div className="mt-5 text-center txt-weight-500">
        Back to <button type="button" className="btn-link" onClick={handleBackToLogin} >Login</button>
      </div>
    </div>
  )
}

export default ForgotPassword