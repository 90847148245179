import React, { useEffect, useRef } from 'react'
import './assets/styles/conversation-card.scss'
import Card from 'app/shared/card'
import { ConversationSummary, EditConversationInput } from 'generated/graphql'
import IconLabel from 'app/shared/icon-label'
import BoxTickAzureIcon from 'app/shared/assets/svgs/box-tick-azure.svg'
import BoxCrossedGreyIcon from 'app/shared/assets/svgs/box-crossed-grey.svg'
import StatusBadge from 'app/shared/status-badge'
import Button from 'app/shared/button'
import DocumentWhiteIcon from 'app/shared/assets/svgs/document-white.svg'
import UploadAzureIcon from 'app/shared/assets/svgs/upload-azure.svg'
import { useDispatch } from 'react-redux';
import { open } from 'features/modals/modalSlice';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Image } from 'shared/image'
import useFileUpload, { FileItem } from "hooks/file-uploads";
import _ from 'lodash';
import { Icon, IconNames } from 'app/shared/icon';
import {
  useUpdateConversationMutation
} from 'services/graphql/conversation'

interface ConversationCardProps extends ConversationSummary {
  type: string;
}

// TODO - if have uploaded NDA, change upload NDA to replace NDA

const ConversationCard = React.forwardRef((
  {
    id,
    image,
    tech_request_name,
    logo,
    conversation_stages_complete,
    nda_signed_by_both,
    nda_signed,
    nda_corporate_upload,
    type
  }: ConversationCardProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const uploadsRef = useRef<FileItem[]>([])
  const { url } = useRouteMatch();
  const [ updateConversation ] = useUpdateConversationMutation()

  const { push } = useHistory();
  const dispatch = useDispatch();
  const { uploads, remove, onChange: ndaUploaded , setUploads } = useFileUpload({
    max: 1,
  });

  const handleRemoveNDA = () => {
    remove(uploads[0].id);
  }
  const imageOverlay = <div className="conversation-other-company-logo"><Image src={logo?? ''} alt="" /></div>
  const body = <>
      <h5>{tech_request_name || ''}</h5>
      <div className="status-checks">
        <IconLabel icon={conversation_stages_complete?.business_use_case ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>
          Business Use Case</IconLabel>
        <IconLabel icon={conversation_stages_complete?.scope ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>Scope</IconLabel>
        <IconLabel icon={conversation_stages_complete?.proposal ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>Proposal</IconLabel>
        <IconLabel icon={conversation_stages_complete?.agreement ? 
          BoxTickAzureIcon : BoxCrossedGreyIcon}>Agreement</IconLabel>
      </div>
    </>

  const footer = <>
    {!nda_signed_by_both && <StatusBadge options="border" color="in-progress" toolTip="Please agree on the NDA to be able to start the conversation">NDA NOT SIGNED</StatusBadge>}
    {nda_signed_by_both && <StatusBadge options="border" color="success" toolTip="Both parties have agreed to the NDA">NDA SIGNED</StatusBadge>}
  </>
  
  const actionOverlay = <div className="conversation-actions">
    {(!nda_signed && !nda_signed_by_both) ? <Button variant="primary" 
      className="mb-4 d-flex align-items-center justify-content-center" 
      onClick={() => {
        dispatch(open({
          id: "conversation-mutual-nda",
          parameters: {
            id,
            nda_corporate_upload
          }
        }))
      }} >
      <img src={DocumentWhiteIcon} className="me-2" alt=""/>
      AGREE ON MUTUAL NDA
    </Button> : null}
    {(nda_signed && !nda_signed_by_both) ? <Button variant="primary" 
      disabled={true}
      className="mb-4 d-flex align-items-center justify-content-center" >
      <img src={DocumentWhiteIcon} className="me-2" alt=""/>
      WAITING FOR OTHER PARTY
    </Button> : null}
    {type === 'corporate' ? 
      <>
      <input
        id="upload-nda"
        type="file"
        multiple={false}
        hidden
        onChange={ndaUploaded}
      />
      {_.isEmpty(nda_corporate_upload) ? 
        <label className="app-btn icon d-flex justify-content-center" htmlFor="upload-nda">
          <img src={UploadAzureIcon} className="me-2" alt=""/>
          <span>UPLOAD NDA</span>
        </label> : null
      }
      {!_.isEmpty(nda_corporate_upload) ? 
        <div className="d-flex flex-row justify-content-between">
          <label className="app-btn icon d-flex justify-content-center flex-grow-1" htmlFor="upload-nda">
            <img src={UploadAzureIcon} className="me-2" alt=""/>
            <span>REPLACE NDA</span>
          </label>
          <Button variant="danger tight" className="ms-2 flex-grow-1 justify-content-center" onClick={handleRemoveNDA}>
            <Icon name={IconNames.Trash} colour="#fff" />
          </Button>
        </div> : null
      }
      </> : null}
  </div>

  const handleCardClick = () => {
    if (nda_signed_by_both)
      push(`${url}/${id}`);
  }

  useEffect(() => {
    if (nda_corporate_upload) {
      setUploads([nda_corporate_upload])
    }
  }, [])

  useEffect(() => {
    if (type === 'corporate') {
      const update = async (convo: EditConversationInput) => {
        await updateConversation({
          id,
          conversation: convo
        })
      }
      if (uploadsRef && uploadsRef.current) {
        if (uploadsRef?.current?.length > 0 && uploads.length === 0) {
          // delete
          update({
            nda_corporate_upload: null
          }) 
        } else
        if ((uploadsRef.current.length > 0 && uploadsRef?.current?.[0].id !== uploads[0].id)
        || (uploadsRef.current.length === 0 && uploads.length > 0)) {
          // update
          update({
            nda_corporate_upload: uploads[0].id
          })
        }
      }
      uploadsRef.current = uploads;
    }
  }, [uploads])

  return (
    <Card
      onClick={handleCardClick} 
      className="conversation-card"
      cardWidth="384px"
      cardHeight="auto" 
      variant="vertical" 
      imageHeight="159px" 
      imageSrc={image?? ''} 
      imageOverlayPosition="center"
      imageOverlay={imageOverlay}
      body={body}
      footer={footer}
      actionOverlay={actionOverlay}
      actionOverlayShow={!nda_signed_by_both}
    />
  )
})

export default ConversationCard;