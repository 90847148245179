import React from 'react'
import './assets/styles/technology-request.not-published.scss'

const TechnologyRequestNotPublished = () => {
  return (
    <div className="tech-solution-not-published">
      <h3>Please publish this business use case before you can match it.</h3>
    </div>
  )
}

export default TechnologyRequestNotPublished