/*
  Cache for onboarding
  - start a cache by creating a cookie with a unique key or uuid
*/

const cacheKeyName = 'onboarding_uuid';
const expiryDays = 14;

const getCookieValue = (key:string) => {
  const allCookies = document.cookie;
  return allCookies.split('; ')
    .find(row => row.startsWith(`${key}=`))
    ?.split('=')[1];
}

export const startCache = (uuid: string) => {
  const onboardingUUID = getCookieValue(cacheKeyName);
  if (onboardingUUID === undefined) {
    const d = new Date();
    d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = cacheKeyName + '=' + uuid + ';' + expires + ';path=/';
  }
}

export const getCacheKey = ():string | undefined => {
  return getCookieValue(cacheKeyName)
}

export const clearCacheKey = () => {
  const d = new Date('1970-01-01T00:00:00');
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = cacheKeyName + '=;' + expires + ';path=/';
}

export const updateCacheKey = (uuid: string) => {
  const d = new Date();
  d.setTime(d.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = cacheKeyName + '=' + uuid + ';' + expires + ';path=/';
}

export const hasCache = () => {
  const onboardingUUID = getCookieValue(cacheKeyName);
  return onboardingUUID !== undefined
}
export const saveCache = (obj: any) => {
  const onboardingUUID = getCookieValue(cacheKeyName);
  // save to local storage
  const jsonString = JSON.stringify(obj)
  localStorage.setItem(`cache_onboarding_${onboardingUUID}`, jsonString)
}

export const clearCache = () => {
  const onboardingUUID = getCookieValue(cacheKeyName);
  // clear local storage
  localStorage.removeItem(`cache_onboarding_${onboardingUUID}`)
}

export const getCache = () => {
  const onboardingUUID = getCookieValue(cacheKeyName);
  // get from local storage
  return localStorage.getItem(`cache_onboarding_${onboardingUUID}`)
}
