/*
  Sign up form is a multi step wizard form
  each step has a url path e.g /sign-up/step-1
  The form steps as follows
  
  1A. Select Corp/Startup - I Want to
  1B. Select I want to (triggered from sign up as corp/startup entry points)

  2. My Company (startup/corporate)
  3. Me (person profile)
  4. Corp - Business Need, Startup - Technology Solution

  5. Verification Email Code
  6. Set Password

  Auto login

  Form saves on next/prev ideally on value update
  
  Before 1A - Initial Load form - push history route
    - check cookie for onboarding uuid
    - if uuid exists - call graphql get onboarding data by uuid
      - store into state
    - if does not exist - get new onboarding uuid, save cookie
  
  Components
    - Steps status
    - Form sections
    - Action bar
  
*/

import { useEffect } from 'react';

import StepsStatus from './steps-status/steps-status';
import SelectSignUpAs from './forms/select-sign-up-as';
import MyRequests from './forms/my-request';

import MyStartup from './forms/my-startup';
import StartupPersonProfile from './forms/startup-person-profile';
import MyTechSolution from './forms/my-tech-solution';

import MyCompany from './forms/my-company';
import CorpPersonProfile from './forms/corp-person-profile';
import BusinessChallenge from './forms/business-challenge';

import { SignUpTypes } from './types';
import useOnboarding from './hooks/onboarding.hook';
import useSteps from './hooks/steps.hook';
import Progress from './progress';
import './assets/styles/sign-up.scss';

const SignUpV2 = () => {
  const { 
    startOnboarding,
    onboardingPayload,
    isFromHomePage,
    resetOnboardingPayload,
    newOnboarding,
    isFetching,
    isExisting
  } = useOnboarding()
  const { currentStep } = useSteps()
  
  useEffect(() => {
    const start = async () => {
      await startOnboarding();
    }
    start();
  }, [])

  useEffect(() => {
    const elem = document.querySelector('.generic-modal')
    if (elem !== null) {
      elem.scrollTo(0,0)
      window.scrollTo({
        top: 0,
        left: 0, 
        behavior: 'auto'
      })
    }
  }, [currentStep])

  if (isFetching) {
    return <Progress />;
  }

  return (
    <div className="container-fluid ms-n-5">
      <div className="row">
        <div className="col">
        {currentStep === 1 && <div className="title-get-started d-block d-md-none">Get Started</div>}
          <StepsStatus signUpType={onboardingPayload?.type?? SignUpTypes.STARTUP} />
        </div>
      </div>
      <div className="row">
        <div className="col mt-md-5 mt-1">
          <div className="sign-up-container">
            <Progress />
            <div className="sign-up-forms">
              {(currentStep === 1 && !isFromHomePage) ? <SelectSignUpAs /> : <></>}
              {currentStep === 1 && onboardingPayload?.type !== null && isFromHomePage ? <MyRequests /> : <></>}

              {currentStep === 2 && onboardingPayload?.type === SignUpTypes.STARTUP ? <MyStartup /> : <></>}
              {currentStep === 3 && onboardingPayload?.type === SignUpTypes.STARTUP ? <StartupPersonProfile /> : <></>}
              {currentStep === 4 && onboardingPayload?.type === SignUpTypes.STARTUP ? <MyTechSolution /> : <></>}

              {currentStep === 2 && onboardingPayload?.type === SignUpTypes.CORPORATE ? <MyCompany /> : <></>}
              {currentStep === 3 && onboardingPayload?.type === SignUpTypes.CORPORATE ? <CorpPersonProfile /> : <></>}
              {currentStep === 4 && onboardingPayload?.type === SignUpTypes.CORPORATE ? <BusinessChallenge /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SignUpV2;