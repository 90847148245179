import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  TechnologySolution, 
  CorporateFindOneTechnologySolution, 
  TechnologyReadinessLevel
} from 'generated/graphql'
import { 
  EditTechnologySolutionInput, 
  CreateTechnologySolutionInput
} from 'generated/graphql'

interface EditTechnologySolution {
  id: string;
  data: EditTechnologySolutionInput
}

export type CorporateFindOneTechnologySolutionWithTechReadiness = CorporateFindOneTechnologySolution & {
  technology_readiness_name: string;
}

export interface SearchTechnologySolutionInput {
  technology_types: Array<String>;
  countries: Array<String>;
  industries: Array<String>;
  business_use_cases: Array<String>;
  sdg_objectives: Array<String>;
}

export const techSolutionsApi = createApi({
  reducerPath: "techSolutionsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  tagTypes: ["techsolutions"],
  endpoints: (builder) => ({
    myTechSolutions: builder.query<Array<TechnologySolution>, void>({
      query: () => ({
        document: gql`
          query {
            myStartupProfile {
              technology_solutions {
                id
                name
                description
                hero_image {
                  url
                }
                updated_at
                is_completed
              }
            }
          }
        `,
      }),
      transformResponse: (response: any) => response.myStartupProfile.technology_solutions,
      providesTags: [{
        type: 'techsolutions',
        id: 'LIST'
      }]
    }),
    myTechSolutionsToMatch: builder.query<Array<TechnologySolution>, void>({
      query: () => ({
        document: gql`
          query {
            myStartupProfile {
              technology_solutions {
                id
                name
                description
                hero_image {
                  url
                }
                updated_at
                created_at
                technology_types {
                  name
                }
                industries  {
                    name
                }
                business_use_cases  {
                    name
                }
                has_ip
                has_api
                is_whitelabel
                is_plugandplay
                is_gdpr
                is_cloud
                sdg_driven
                is_spin_off
                spin_off_source
                is_others
                is_match_triggered
                is_completed
                is_published
                published_at
              }
            }
          }
        `,
      }),
      transformResponse: (response: any) => response.myStartupProfile.technology_solutions,
    }),
    getOneTechSolution: builder.query<TechnologySolution, number>({
      query: (id) => ({
        document: gql`
          query {
            technologySolution(id: ${id}) {
                id
                name
                description
                benefits_description
                hero_image {
                    id
                    url
                    name
                }
                purpose_poc
                purpose_sell
                purpose_lease
                purpose_certify
                purpose_investment
                has_ip
                has_api
                is_whitelabel
                is_plugandplay
                is_gdpr
                is_cloud
                sdg_driven
                is_spin_off
                spin_off_source
                is_others
                embed_demo_url
                key_features
                is_match_triggered

                versions_release_dates
                supported_operating_systems
                total_investment
                resources_required
                resource_transfer_details
                licensing_type_details
                reason_to_sell
                assets_to_sell
                is_consumer_data_collected
                is_tech_transferrable
        
                price_poc_min
                price_poc_max
                price_lease_min
                price_lease_max
                price_sell_min
                price_sell_max
                currency
        
                demo_video {
                    id
                    url
                    name
                }
                demo_document {
                    id
                    url
                    name
                }
        
                is_published
                media {
                    name
                    url
                    ext
                }
        
                technology_types {
                    id
                    name
                }
                technology_readiness
        
                industries  {
                    id
                    name
                }
                
                sdg_objectives {
                    id
                    name
                }
        
                business_use_cases  {
                    id
                    name
                }
                use_case_count
                is_use_case_on_request
        
                corporate_departments {
                    id
                    name
                }
        
                pricing_models {
                    id
                    name
                }
                
                project_budget {
                    id
                    currency
                    option
                    budget
                }

                testimonial {
                    id
                    page_url
                    free_text
                    have_testimonials
                    uploads {
                        id
                        name
                        url
                    }
                }

                case_studies {
                    id
                    page_url
                    free_text
                    have_case_studies
                    uploads {
                        id
                        name
                        url
                    }
                }

                key_features
                supported_operating_systems
                resources_required
                is_consumer_data_collected
                is_consumer_data_collected_remarks
                reason_to_sell
                licensing_type_details
                versions_release_dates
                total_investment
                resource_transfer_details
                want_to_transfer_resources
                is_tech_transferrable
                is_tech_transferrable_remarks
                assets_to_sell
                company_performance_annual_sales

                invest_information {
                  documents_business_plans {
                    id
                    name
                    url
                  }
                  documents_investor_pitch_deck {
                    id
                    name
                    url
                  }
                  amount_to_raise
                  amount_to_raise_currency
                }

                startup_profile {
                  id 
                  country
                  maturity_level {
                    name
                  }
                  investment_received
                  investment_stage
                  worked_with_corporates
                  employee_count
                  client_count
                  operating_locations
                  client_count
                }

                rating
            }
          }
        `,
      }),
      transformResponse: (response: any) => response.technologySolution,
      providesTags: (result) => 
        result
          ? [{
              type: 'techsolutions',
              id: result.id
            },{
              type: 'techsolutions',
              id: 'LIST'
            }]
          : [{
            type: 'techsolutions',
            id: 'LIST'
          }]
    }),
    updateTechSolution: builder.mutation<TechnologySolution, EditTechnologySolution>({
      query: ({id, data}) => ({
        document: gql`
          mutation updateTechSolutions($id: ID!, $data: editTechnologySolutionInput!) {
            updateTechnologySolution(input: { where: { id: $id }, data: $data }) {
              technologySolution {
                id
                name
              }
            }
          }
        `,
        variables: {
          id,
          data
        }
      }),
      invalidatesTags: [{
        type: "techsolutions",
        id: 'LIST'
      }]
    }),
    createTechSolution: builder.mutation<void, CreateTechnologySolutionInput>({
      query: (techSolution) => ({
        document: gql`
          mutation addTechSolutionToStartupProfile($techSolution: createTechnologySolutionInput!) {
            addTechSolutionToStartupProfile(input: $techSolution) 
          }
        `,
        variables: {
          techSolution
        }
      }),
      invalidatesTags: [{
        type: "techsolutions",
        id: 'LIST'
      }]
    }),
    deleteTechSolution: builder.mutation<TechnologySolution, number>({
      query: (id) => ({
        document: gql`
          mutation deleteTechnologySolution($id: ID!) {
            deleteTechnologySolution(input: { where: { id: $id } }) {
              technologySolution {
                name
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      invalidatesTags: [{
        type: "techsolutions",
        id: 'LIST'
      }]
    }),
    searchTechnologySolutions: builder.query<Array<TechnologySolution>, SearchTechnologySolutionInput>({
      query: ({ technology_types, industries, business_use_cases, countries}) => ({
        document: gql`
          query SearchTechnologySolutions(
            $technology_types: [ID!]
            $industries: [ID!]
            $countries: [String!]
            $business_use_cases: [ID!]
          ) {
            technologySolutions(
              where: {
                technology_types: { id_in: $technology_types }
                industries: { id_in: $industries }
                startup_profile: {
                  country_in: $countries
                }
                business_use_cases: { id_in: $business_use_cases }
                is_published: true
              }
            ) {
              id
              name
              description
              benefits_description
              hero_image {
                id
                url
                name
              }
              purpose_poc
              purpose_sell
              purpose_lease
              purpose_certify
              purpose_investment
              has_ip
              has_api
              is_whitelabel
              is_plugandplay
              is_gdpr
              is_cloud
              sdg_driven
              is_spin_off
              spin_off_source
              is_others
              key_features
              created_at

              industries {
                id
                name
              }
              technology_types {
                id
                name
              }
              business_use_cases {
                id
                name
              }
              
              startup_profile {
                id 
                country
                maturity_level {
                  name
                }
                rating
              }

              rating
            }
          }
        `,
        variables: {
          technology_types,
          industries,
          business_use_cases,
          countries
        }
      }),
      transformResponse: (response:any) => response.technologySolutions
    }),
    corporateFindOneTechnologySolution: builder.query<CorporateFindOneTechnologySolutionWithTechReadiness, number>({
      query: (id) => ({
        document: gql`
          query {
            corporateFindOneTechnologySolution(id: ${id}) {
                id
                name
                description
                benefits_description
                hero_image {
                    id
                    url
                    name
                }
                purpose_poc
                purpose_sell
                purpose_lease
                purpose_certify
                purpose_investment
                tested_by_alkelio
                has_ip
                has_api
                is_whitelabel
                is_plugandplay
                is_gdpr
                is_cloud
                sdg_driven
                is_spin_off
                spin_off_source
                is_others
                key_features
        
                technology_types {
                  id
                  name
                }
                technology_readiness
        
                industries  {
                  id
                  name
                }
        
                business_use_cases  {
                  id
                  name
                }
        
                corporate_departments {
                  id
                  name
                }
                
                sdg_objectives {
                  id
                  name
                }
        
                testimonial {
                  have_testimonials
                  quantity   
                }

                case_studies {
                  have_case_studies
                  quantity
                }

                startup_profile {
                  id 
                  country
                  maturity_level {
                    name
                  }
                  investment_received
                  worked_with_corporates
                  employee_count
                  client_count
                  operating_locations
                  client_count
                  esg_rating
                }
            }
            technologyReadinessLevels {
              id
              name
            }
          }
        `,
      }),
      transformResponse: (response: any) => {
        response.corporateFindOneTechnologySolution.technology_readiness_name = (response.technologyReadinessLevels as TechnologyReadinessLevel[]).find(item => item.id === response.corporateFindOneTechnologySolution.technology_readiness as string ?? '')?.name
        return response.corporateFindOneTechnologySolution as CorporateFindOneTechnologySolutionWithTechReadiness
      },
      providesTags: (result) => 
        result
          ? [{
              type: 'techsolutions',
              id: result.id
            },{
              type: 'techsolutions',
              id: 'LIST'
            }]
          : [{
            type: 'techsolutions',
            id: 'LIST'
          }]
    }),
    corporateSearchTechnologySolutions: builder.query<Array<TechnologySolution>, SearchTechnologySolutionInput>({
      query: ({ technology_types, industries, business_use_cases, countries, sdg_objectives}) => ({
        document: gql`
          query corporateSearchTechnologySolutions(
            $technology_types: [ID!]
            $industries: [ID!]
            $countries: [String!]
            $business_use_cases: [ID!]
            $sdg_objectives: [ID!]
          ) {
            corporateSearchTechnologySolutions(
              where: {
                technology_types: { id_in: $technology_types }
                industries: { id_in: $industries }
                startup_profile: {
                  country_in: $countries
                }
                business_use_cases: { id_in: $business_use_cases }
                sdg_objectives: { id_in: $sdg_objectives }
                is_published: true
              },
              sort: "created_at:desc"
            ) {
              id
              name
              description
              benefits_description
              hero_image {
                id
                url
                name
              }
              purpose_poc
              purpose_sell
              purpose_lease
              purpose_certify
              purpose_investment
              has_ip
              has_api
              is_whitelabel
              is_plugandplay
              is_gdpr
              is_cloud
              sdg_driven
              is_spin_off
              spin_off_source
              is_others
              key_features
              created_at

              industries {
                id
                name
              }
              technology_types {
                id
                name
              }
              business_use_cases {
                id
                name
              }
              
              startup_profile {
                id 
                country
                maturity_level {
                  name
                }
              }

              rating
            }
          }
        `,
        variables: {
          technology_types,
          industries,
          business_use_cases,
          countries,
          sdg_objectives
        }
      }),
      transformResponse: (response:any) => response.corporateSearchTechnologySolutions
    }),
  }),
})

export const { 
  useMyTechSolutionsQuery, 
  useLazyMyTechSolutionsQuery,
  useGetOneTechSolutionQuery, 
  useLazyGetOneTechSolutionQuery,
  useUpdateTechSolutionMutation,
  useDeleteTechSolutionMutation,
  useMyTechSolutionsToMatchQuery,
  useSearchTechnologySolutionsQuery,
  useCreateTechSolutionMutation,
  useCorporateFindOneTechnologySolutionQuery,
  useCorporateSearchTechnologySolutionsQuery
} = techSolutionsApi