import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { Base } from '../types/base'
import { gql } from 'graphql-request'
import { UsersPermissionsLoginPayload, UsersPermissionsMe } from 'generated/graphql'

export interface Role {
  id: number;
  name: string;
  type: string;
}

export interface User extends Base {
  email: string;
  username: string;
  role: Role;
}

export interface UserResponse {
  user: User
  jwt: string
}

export interface LoginRequest {
  identifier: string
  password: string
}

export interface LoginResponse {
  login: UsersPermissionsLoginPayload;
}

export interface MeResponse {
  me: User;
}

export interface ForgotPasswordResponse {
  uuid: String;
}

interface resetPasswordInput {
  password: string;
  retypePassword: string;
  code: string;
  uuid: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    login: builder.mutation<UsersPermissionsLoginPayload, LoginRequest>({
      query: ({identifier, password}) => ({
        document: gql`
          mutation login($identifier: String!, $password: String!) {
            login(input: { identifier: $identifier, password: $password }) {
              jwt
              user {
                id
                username
                email
                role {
                  type
                }
              }
            }
          }
        `,
        variables: {
          identifier,
          password
        },
      }),
      transformResponse: (response: LoginResponse) => response.login,
      invalidatesTags: ["User"],
    }),
    me: builder.query<UsersPermissionsMe, void>({
      query: () => ({
        document: gql`
          query {
            me {
              id
              username
              email
              role {
                  type
              }
            }
          }
        `,
      }),
      transformResponse: (response: any) => response.me,
      providesTags: ["User"]
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, string>({
      query: (email) => ({
        document: gql`
          mutation forgotPassword($email: String!){
            userForgotPassword(email: $email) {
              uuid    
            }
          }
        `,
        variables: {
          email
        }
      }),
      transformResponse: (response: any) => response.userForgotPassword
    }),
    resetPassword: builder.mutation<void, resetPasswordInput>({
      query: ({password, retypePassword, code, uuid}) => ({
        document: gql`
          mutation ResetPassword(
            $password: String!,
            $retypePassword: String!,
            $code: String!,
            $uuid: String!
          ) {
            userResetPassword(input: {
              password: $password,
              retypePassword: $retypePassword,
              code: $code,
              uuid: $uuid
            })
          }
        `,
        variables: {
          password, 
          retypePassword, 
          code,
          uuid
        }
      }),
      transformResponse: (response: any) => response.resetPassword
    })
  }),
})

export const { 
  useLoginMutation, 
  useMeQuery,
  useLazyMeQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation
} = authApi