import React, { useCallback, useState, useEffect, useRef } from 'react';
import CaretDownBlueIcon from 'app/shared/assets/svgs/caret-down-blue.svg';
import './assets/styles/select-one.scss';
import BaseInput from 'shared/forms/base-input';

interface OptionItem {
  label: string;
  value?: string;
}

interface SelectOneProps extends BaseInput {
  options: Array<OptionItem> | Array<string>;
  value?: string;
  variant?: '' | 'thicker'|'zero-border';
  sortOptions?: boolean;
  searchable?: boolean;
  orderFirst?: string[];
  placeholder?: string;
  disabled?: boolean;
}

const SelectOne = React.forwardRef((
  { 
    options, 
    value, 
    variant, 
    defaultValue, 
    style, 
    onChange, 
    placeholder = "Select...",
    sortOptions = true, 
    searchable = true, 
    orderFirst = [],
    disabled = false
  }: SelectOneProps,
  ref: React.Ref<HTMLDivElement>
) => {

  const selectedOptionFromProps: string | OptionItem | undefined = (options as Array<OptionItem | string>).find((item: OptionItem | string) => {
    if (typeof (item) === 'object') {
      return item.value === (defaultValue || value)
    } else {
      return item === (defaultValue || value)
    }
  })

  const elementRef = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [matchedOptions, setMatchOptions] = useState<Array<OptionItem | string>>(options)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelected] = useState(selectedOptionFromProps)
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const toggleOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!showOptions) {
      setShowOptions(true)
    } else {
      setShowOptions(false)
    }
  }

  const selectOption = (option: OptionItem | string) => {
    setSelected(option);
    setShowOptions(false)
    if (typeof (option) == 'string') {
      onChange && onChange(option)
      if (inputRef && inputRef.current) {
        inputRef.current.value = option || '';
      }
    } else {
      onChange && onChange(option.value || option.label)
      if (inputRef && inputRef.current) {
        inputRef.current.value = option?.label || '';
      }
    }
  }

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setShowOptions(false)
    }
  }, [])

  const clickListener = useCallback(
    (e: MouseEvent) => {
      const element = (elementRef.current! as any)
      if (element !== null) {
        if (!element.contains(e.target)) {
          setShowOptions(false)
        }
      }
    },
    [elementRef.current]
  )

  useEffect(() => {
    document.addEventListener('click', clickListener)
    document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [])

  useEffect(() => {
    setSelected(selectedOptionFromProps)
  }, [value,defaultValue])

  useEffect(() => {
    setMatchOptions(options)
  }, [options])


  const sort = (a: OptionItem | string, b: OptionItem | string): 0 | 1 | -1 => {
    if (typeof (a) === 'object' && typeof (b) === 'object') {
      if (a.label > b.label) return 1;
      else
        if (a.label < b.label) return -1;
        else
          return 0;
    } else {
      if (a > b) return 1;
      else
        if (a < b) return -1;
        else
          return 0;
    }
  }

  const setOrderFirst = (list: Array<OptionItem | string>, orderFirst: Array<string>) => {
    if (orderFirst.length === 0) {
      return list;
    }
    
    const priorityList: Array<OptionItem | string> = []
    const orderFirstOptions: Array<OptionItem | string> = []
    const others: Array<OptionItem | string> = []

    list.forEach((item) => {
      const value: string = typeof(item) === 'object' ? (item.value?? '') : (item?? '');
      if (orderFirst.indexOf(value) >= 0) {
        priorityList.push(item);
      } else {
        others.push(item);
      }
    })

    orderFirst.forEach((item) => {
      const p: OptionItem | string | undefined = priorityList.find((p) => {
        const value: string = typeof(p) === 'object' ? (p.value?? '') : (p?? '');
        return (value === item)
      });
      if (p)
        orderFirstOptions.push(p);
    })
    
    return [...orderFirstOptions, ...others];
  }
  

  const optionsSorted = sortOptions ? (matchedOptions as Array<OptionItem | string>).sort(sort) : (matchedOptions as Array<OptionItem | string>);

  const optionsElements =
    setOrderFirst(optionsSorted, orderFirst).map((item, i) => (
      <div className={`option-item ${variant ?? ''}  ${focusedIndex === i ? 'focused':''}`} key={i}
        onClick={() => {
          selectOption(item);
        }}
        onMouseEnter={() => setFocusedIndex(i)}
      >
        {typeof (item) === 'object' ? item.label : item}
      </div>
    ))

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const matchedOptions = (options as Array<OptionItem | string>).filter((item: OptionItem | string) => {
      if (typeof item === 'string')
        return item.toUpperCase().indexOf(e.target.value.toUpperCase()) === 0
      else
        return item.label.toUpperCase().indexOf(e.target.value.toUpperCase()) === 0
    })
    setMatchOptions(matchedOptions)
    if (matchedOptions.length > 0) {
      setShowOptions(true)
    }
  }

  
  const handleUpDown = (evt:React.KeyboardEvent<HTMLDivElement>) => {
    const maxLength = matchedOptions.length;
    if (evt.key === 'ArrowDown') {
      if (!showOptions) {
        setShowOptions(true)
        setFocusedIndex(0)
      } else {
        setFocusedIndex(focusedIndex === null ? 0 : (focusedIndex + 1) % maxLength)
      }
    }
    if (evt.key === 'ArrowUp') {
      if (showOptions && focusedIndex !== null) {
        setFocusedIndex(focusedIndex > 0 ? focusedIndex - 1 : maxLength - 1);
      }
    }
    if (evt.key === 'Enter') {
      focusedIndex !== null && selectOption(matchedOptions[focusedIndex]);
    }
  }

  useEffect(() => {
    if (optionsRef && optionsRef.current && focusedIndex !== null) {
      const optionItemElement = optionsRef.current.querySelectorAll('div.option-item')[focusedIndex] as HTMLElement
      
      if (((optionItemElement.offsetTop + optionItemElement.offsetHeight) - optionsRef.current.scrollTop) >= optionsRef.current.offsetHeight) {
        if (focusedIndex === matchedOptions.length - 1) {
          optionsRef.current.scrollTop = optionItemElement.offsetTop;
        } else {
          optionsRef.current.scrollTop += optionItemElement.offsetHeight;
        }
      } else
      if (((optionItemElement.offsetTop) - optionsRef.current.scrollTop) <= 0) {
        optionsRef.current.scrollTop = optionItemElement.offsetTop;
      }
    }
  }, [focusedIndex])

  const selectedValue = typeof (selectedOption) === 'string' ? selectedOption : (selectedOption && selectedOption.label)

  return (
    <div 
      className={`select-one ${variant ?? ''}`} 
      style={style} 
      ref={elementRef}
      onKeyDown={handleUpDown}
    >
      <div className="input">
        <span className="selected-wrapper">
          {searchable && <input type="text" disabled={disabled} placeholder={placeholder} autoComplete="false" onChange={handleChange} ref={inputRef}/>}
          <div className="selected">{selectedValue ?? <div className="color-grey-dark">{placeholder}</div>}</div>
        </span>
        {!disabled ? <div className={`more ${showOptions ? 'expanded' : ''}`} onClick={toggleOptions}>
          <img src={CaretDownBlueIcon} alt="more" />
        </div> : null}
      </div>
      {!disabled ? 
      <div 
        className={`options ${showOptions ? 'visible' : 'hidden'}`}
        ref={optionsRef}
      >
        {optionsElements}
      </div> : null }
    </div>
  )
})

export default SelectOne;