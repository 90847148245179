import React, { useEffect } from 'react'
import BottomNav from './bottom-nav'
import InputCheckBox from 'shared/forms/input-checkbox'
import InputRadioGroup from 'shared/forms/input-radio-group'
import Ratings from 'app/shared/ratings'
import './assets/styles/feedback.form.scss'

import { 
  useForm, 
  SubmitHandler,
  useFieldArray,
  Controller
} from "react-hook-form";
import {
  useHistory,
  useRouteMatch,
  useParams
} from 'react-router-dom'

import { 
  useGetOneServiceCollaborationQuery,
  useUpdateMyServiceCollaborationMutation
} from 'services/graphql/service-collaboration'
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';

type Inputs = {
  feedback: string;
  need_rework: boolean;
  rating: string
}

type Props = {
  id: string
}

const FeedbackForm = ({
  id
}: Props) => {
  const user = useSelector(selectCurrentUser);
  const { push } = useHistory()
  const { entityType }: any = useParams();
  const { url } = useRouteMatch();
  const { data, isLoading, isSuccess } = useGetOneServiceCollaborationQuery(id)
  const [update, updateResult] = useUpdateMyServiceCollaborationMutation()

  const { 
    register, 
    reset, 
    handleSubmit, 
    watch,
    control,
    getValues,
    formState: { errors } 
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = data => {
    update({
      id,
      feedback: {
        ...data,
        rating: Number(data.rating ?? '0')
      }
    })
  }

  useEffect(() => {
    if (isSuccess && data && data.feedback) {
      reset({
        feedback: data.feedback?.feedback ?? '',
        need_rework: data.feedback?.need_rework ?? false,
        rating: String(data.feedback?.rating ?? 0)
      })
      
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (updateResult.isSuccess) {
      push(`/app/${entityType}/service-collaborations/complete`)
    }
  }, [updateResult])

  const handleBack = () => {
    let parts = url.split('/')
    parts.pop()
    push(parts.join('/') + '/deliverables')
  }

  
  useEffect(() => {
    return () => {
      const data = getValues()
      
      update({
        id,
        feedback: {
          ...data,
          rating: Number(data.rating)
        }
      })
      
    }
  }, [])

  return (
    <div className="main__feedback-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          {isSuccess && data ?
          <div className="form-control-group">
            <div className="form-control">
              <label>
                <div className="bold-type font-size-14 color-nightsky">
                  Overall feedback
                </div>
                {user?.role?.type !== 'catalyst' ? <textarea
                  rows={6}
                  className="mb-3 mb-md-0 w-100"
                  defaultValue={data.feedback?.feedback ?? ''}
                  {...register(`feedback`)} >
                </textarea> 
                : <div>{data.feedback?.feedback ?? ''}</div>}
              </label>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-control">
                  <label>
                    <div className="bold-type font-size-14 color-nightsky">
                      Need Rework
                    </div>
                    {user?.role?.type !== 'catalyst' ? 
                    <Controller 
                      name="need_rework"
                      control={control}
                      defaultValue={data?.feedback?.need_rework || false}
                      render={({field}) => <InputCheckBox 
                        {...field} />}
                    /> : <div>{data?.feedback?.need_rework ? 'Yes':'No' }</div>}
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-control">
                  <label>
                    <div className="bold-type font-size-14 color-nightsky">
                      Rating
                    </div>
                    {user?.role?.type !== 'catalyst' ? 
                    <Controller 
                      name="rating"
                      control={control}
                      defaultValue={String(data?.feedback?.rating || 0)}
                      render={({field}) => 
                        <InputRadioGroup {...field} options={[
                          { label: '1', value: '1' },
                          { label: '2', value: '2' },
                          { label: '3', value: '3' },
                          { label: '4', value: '4' },
                          { label: '5', value: '5' }
                        ]} /> 
                      }
                    />
                    : <Ratings rating={data?.feedback?.rating || 0} /> }
                  </label>
                </div>
              </div>
            </div>
          </div>
          : null}
          <div className="bottom">
            <BottomNav onBack={handleBack}/>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FeedbackForm