import React, { useState } from 'react'
import './assets/styles/left.scss'
import Grid from '@material-ui/core/Grid';
import { Box } from "@material-ui/core";
import ScheduleDateIcon from '../../shared/menu/help/assets/svgs/schedule-date.svg';

import MockPhoto from '../assets/img/alkelio-catalyst-dummy.jpg';

import Company from './company'
import Person from './person'
import Chat from './chat'

type Props = {
  id: string;
  currentUserType: string | null; // startup or corporate
  talkingToPerson?: {
    profileId: string;
    userType: string;
    role: string;
    name: string;
    photoUrl: string;
    email: string
  } | null
}

const styleRep = {
  marginTop: "8px",
  marginBottom: "8px",
  width: "80px",
  height: "80px",
  borderRadius: "50%",
};

const grayStyle={
  borderRadius:"50%",
  backgroundColor: "rgba(0,0,0, .2)",
  flex: '0 0 auto',
  marginTop: "5%",
  marginBottom: "5%",
  width: "80px",
  height: "80px",
}

const Left = ({
  id,
  currentUserType = null,
  talkingToPerson = null
}:Props) => {
  return (
    <div className="service__collaboration-left">
      <div className="left__takingto-container">
        {(currentUserType === 'catalyst' && talkingToPerson?.profileId) ? 
          <Company profileId={talkingToPerson?.profileId ?? null} /> 
        : null}
        <hr/>
        {talkingToPerson ? <Person {...talkingToPerson} /> : null}
        {currentUserType !== 'catalyst' ? 
        <Box border={1}
          borderColor="grey.300"
          borderRadius={10}>
          <div className="catalyst">
            <img style={styleRep}
              src={MockPhoto}
              alt={'profile_picture'}/>
            <h6>{}</h6>
            <p>Your ALKELLIO Catalyst</p>
            <hr/>
            <div className="links">
              <button className="link" color='primary'>
                <img src={ScheduleDateIcon}
                  alt="Link"/>
                Book a meeting
              </button>
            </div>
          </div>
        </Box> : null} 
      </div>
      <div className="left__chat-container">
        <Chat id={id} />
      </div>
    </div>
  )
}

export default Left;
