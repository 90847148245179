import React from 'react'
import './assets/styles/mobile-message.scss'

const MobileMessage = () => {
  return (
    <div className="mobile-message text-center">
      <h5>
        This space is currently not optimized for mobile. <br/>
        We are working on it! To access, please visit us on your desktop
      </h5>
    </div>
  )
}

export default MobileMessage