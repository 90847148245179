import { open } from '../../features/modals/modalSlice';
import { useDispatch } from 'react-redux';
import './assets/styles/why-choose-us.scss';

type CardProps = {
  title: string;
  content: string;
}

const Card = ({ title, content} : CardProps) => {
  return (
    <div className="mb-2 col-xl-6 col-12">
      <div className="flex-md-row flex-column d-flex align-items-md-start align-items-center">
        <img src="imgs/circle-o-tick.svg" className="icon-circle-tick mb-1 mb-md-0" alt={title} />
        <div className="mt-2 d-flex flex-column align-items-md-start align-items-center ms-3">
          <h4 className="mb-2 mb-md-3 color-white">{title}</h4>
          <h6 className="txt-light color-white line-height-140 d-md-block d-none">{content}</h6>
          <p className="txt-light color-white text-center font-size-14 line-height-140 d-block d-md-none">{content}</p>
        </div>
      </div>
    </div>
  );
}

const WhyChooseUs = () => {
  const dispatch = useDispatch();
  
  const data = [
    {
      title: "Quality Over Quantity",
      content: "We don’t want volume but the best partner for you.  All the information you see in the platform has been assessed & prequalified beforehand by our teams to ensure you peace of mind."
    },
    {
      title: "Automated and Digitized",
      content: "We know open innovation processes can be long. With our platform, we have digitized all the low value tasks & provided automated matchmaking to increase your efficiency & productivity."
    },
    {
      title: "Holistic View",
      content: "Thanks to our global, cross industry and technology agnostic approach, we see the big picture,. Spotting  the right approach & partner for you and anticipating opportunities for growth. "
    },
    {
      title: "Systems Thinking Approach",
      content: "We tap into the collective intelligence of our ecosystem to propose you an integrated approach of multiple partners & solutions to realize your objectives & ensure a cohesive impact. "
    }
  ]

  return (
    <>
      <a id="why-choose-us"></a>
      <div className="pt-5 pb-5 pt-md-5 pb-md-5 container-fluid color-bg-nightsky" id="why-choose-us-section">
      <div className="container pt-md-5 pb-md-5">
        <div className="row">
          <div className="col-12 col-md-6 pe-md-5">
            <h6 className="mb-1 mb-md-4 tangerine text-center text-md-start">WHY CHOOSE US?</h6>
            <h1 className="mb-5 mb-md-4 color-white text-center text-md-start pe-md-5 me-md-5">
              Trusted partners bringing excellence across the whole value chain of open innovation.
            </h1>
            <button className="btn btn-primary d-none d-md-block" type="button" onClick={() => {
                dispatch(open('sign-up'));
            }}>Get Started</button>
          </div>
          <div className="col-12 col-md-6">
            <div className="flex-row flex-wrap row d-flex">
              {data.map((item, i) => <Card key={i} {...item} />)}
            </div>
          </div>
          <div className="col-12 d-block d-md-none mt-3 mb-4">
            <button className="btn btn-primary w-100" type="button" onClick={() => {
                  dispatch(open('sign-up'));
              }}>Get Started</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default WhyChooseUs;