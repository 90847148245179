import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { SubmitInterestInput } from 'generated/graphql'

export const servicesInterestApi = createApi({
  reducerPath: "servicesInterestApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    submitInterest: builder.mutation<void, SubmitInterestInput>({
      query: (input) => ({
        document: gql`
          mutation submitInterest($input: SubmitInterestInput!) {
            submitInterests(input: $input)
          }
        `,
        variables: {
          input
        }
      })
    })
  })
})

export const {
  useSubmitInterestMutation
} = servicesInterestApi