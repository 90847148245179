import React, { useEffect, useContext } from 'react'
import './styles/mobile-menu.scss'
import { MenuContext } from 'app/providers/menu.provider'
import { useLocation } from 'react-router-dom'

const MobileMenu = () => {
  const { menuActive, toggleMenu, showMenu, hideMenu } = useContext(MenuContext)
  const location = useLocation()
  useEffect(() => {
    hideMenu()
  }, [location])

  return (
    <div className={`mobile-menu`}>
      <div className={`menu-icon ${menuActive ? 'active':''}` }
        onClick={() => {
          toggleMenu()
        }}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  )
}

export default MobileMenu;