import React from 'react';
import Tile from 'app/shared/tile';
import Card from 'app/shared/card';
import Button from 'app/shared/button';
import TargetIcon from 'app/shared/assets/svgs/target.svg';
import BarChartsIcon from 'app/shared/assets/svgs/bar-chart.svg';
import CalenderIcon from 'app/shared/assets/svgs/calender.svg';
import IconLabel from 'app/shared/icon-label';
import { useMyTechRequestsQuery } from 'services/graphql/tech-requests';
import dayjs from 'dayjs';
import { ViewCount } from 'app/shared';

import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import './assets/styles/dashboard.scss'
import { CompletionBadge } from 'app/shared'
import Carousel from 'react-grid-carousel'
import CaretRightIcon from 'assets/svgs/caret-right-azure.svg';
import MatchCounterTechnologyRequest from 'app/shared/match-counter/match-count.techrequest.component'
import CardPlus from '../shared/card-plus'

const TechRequests = () => {
  let { url } = useRouteMatch();
  const { entityType }: any = useParams();
  
  const { push } = useHistory()
  const { data, refetch, isFetching, isLoading } = useMyTechRequestsQuery()

  let cards: Array<React.ReactNode> = data ? data?.map((item, i) => (
    <Carousel.Item key={i}>
      <Card 
        variant="vertical"
        className="corporate-dashboard-tech-req"
        imageHeight="159px"
        title={item.name || ''} 
        imageSrc={item.hero_image?.url}
        imageOverlay={<CompletionBadge isCompleted={item.is_completed?? false} />}
        body={<><p>{item.business_challange_description}</p><Button className="w-100" variant="primary" onClick={() => {
          push(`/app/${entityType}/tech-requests/${item.id}`)
        }}>edit</Button></>}
        footer={<>
          <IconLabel icon={CalenderIcon}>{dayjs(item.updated_at).from(dayjs())}</IconLabel>
          <ViewCount articleId={`tech-req-${item.id}`} />
          <IconLabel icon={TargetIcon}><MatchCounterTechnologyRequest id={item.id} /></IconLabel>
        </>}
      />
    </Carousel.Item>
  )) : []

  for(let i = cards.length; i < 3; i++) {
    cards.push(
      <Carousel.Item key={i}>
        <CardPlus key={i} />
      </Carousel.Item>
    )
  }

  return (
    <Tile 
      title="My Business Needs"
      actionComponents={[
        <Button key={'new-tech-request'} onClick={() => {
          push(`/app/${entityType}/tech-requests/new`)
        }}>Add new</Button>
      ]}>
        <div className="carousel-wrapper">
          <Carousel cols={3} rows={1} loop 
            arrowLeft={
            <div className="carousel-left-button"><img src={CaretRightIcon} alt="previous"/></div>}
            arrowRight={<div className="carousel-right-button"><img src={CaretRightIcon} alt="next"/></div>} >
            {cards}
          </Carousel>
        </div>
        <div className="mobile-business-needs">
          {cards}
        </div>
    </Tile>
  )
}

export default TechRequests;