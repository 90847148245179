import React from 'react'
import { FileItem } from "hooks/file-uploads";

type MemberProps = {
  name: string;
  profile_image: FileItem | null;
  role: string;
  email: string;
}

const Member = ({
  name,
  profile_image,
  role,
  email
}: MemberProps) => {
  return (
    <div className="member">
      <div className="photo">
        <img src={profile_image?.url ?? ''} alt={name} />
      </div>
      <div className="font-size-14 semi-bold-type mb-2 color-nightsky">{name}</div>
      <div className="font-size-12 text-regular mb-2 color-text">{role}</div>
      <a className="font-size-12 text-regular color-azure" href={`mailto:${email}`}>{email}</a>
    </div>
  )
}

export default Member