import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { EnquiryInput} from 'generated/graphql'

export const enquiriesApi = createApi({
  reducerPath: "enquiriesApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    createEnquiry: builder.mutation<Boolean, EnquiryInput>({
      query: (data) => ({
        document: gql`
          mutation sendContact($data: EnquiryInput) {
            sendContact(input: {
              data: $data
            })
          }
        `,
        variables: {
          data,
        }
      }),
      transformResponse: (response: any) => response.sendContact
    })
  })
})

export const {
  useCreateEnquiryMutation
} = enquiriesApi