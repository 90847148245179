import Config from 'config'
import React from 'react';

export const getImage = (srcUrl: string | null | undefined) => {
  if (srcUrl == null || srcUrl === undefined) return '';
  
  if (srcUrl.indexOf('data:image/') === -1 && srcUrl.indexOf('http') === -1 && srcUrl !== '') {
    srcUrl = `${Config.storageBaseURL}${srcUrl}`
  }
  return srcUrl;
}

export const Image = ({src, ...rest}: React.ImgHTMLAttributes<HTMLImageElement>) => {

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={getImage(src?? '')} {...rest} />
  );
}
