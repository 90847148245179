import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { JobPosting } from 'generated/graphql'

export const jobsApi = createApi({
  reducerPath: "jobsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getJobs: builder.query<Array<JobPosting>, void>({
      query: (data) => ({
        document: gql`
          query getJobPostings {
            jobPostings {
              id
              job_title
              description
            }
          }
        `
      }),
      transformResponse: (response: any) => response.jobPostings
    })
  })
})

export const {
  useGetJobsQuery
} = jobsApi