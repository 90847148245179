import React from 'react';
import InputRadioGroup from "shared/forms/input-radio-group";
import Inputs from './inputs';
import { 
  Control, 
  UseFormRegister, 
  FieldError, 
  Controller,
  UseFormWatch
} from 'react-hook-form'; 

type SellingPropsErrors = {
  key_features?: FieldError | undefined;
  supported_operating_systems?: FieldError | undefined;
  resources_required?: FieldError | undefined;
  is_consumer_data_collected?: FieldError | undefined;
  is_consumer_data_collected_remarks?: FieldError | undefined;
  reason_to_sell?: FieldError | undefined;
  licensing_type_details?: FieldError | undefined;
  versions_release_dates?: FieldError | undefined;
  total_investment?: FieldError | undefined;
  resource_transfer_details?: FieldError | undefined;
  want_to_transfer_resources?: FieldError | undefined;
  is_tech_transferrable?: FieldError | undefined;
  is_tech_transferrable_remarks?: FieldError | undefined;
  assets_to_sell?: FieldError | undefined;
  company_performance_annual_sales?: FieldError | undefined;
}

interface SellingProps {
  key_features?: string | null;
  supported_operating_systems?: string | null;
  resources_required?: string | null;
  is_consumer_data_collected?: boolean | null;
  is_consumer_data_collected_remarks?: string | null;
  reason_to_sell?: string | null;
  licensing_type_details?: string | null;
  versions_release_dates?: string | null;
  total_investment?: string | null;
  resource_transfer_details?: string | null;
  want_to_transfer_resources?: string | null;
  is_tech_transferrable?: 'true' | 'false' | 'other';
  is_tech_transferrable_remarks?: string | null;
  assets_to_sell?: string | null;
  company_performance_annual_sales?: string | null;
  control: Control<Inputs>;
  register: UseFormRegister<Inputs>;
  errors: SellingPropsErrors;
  watch: UseFormWatch<Inputs>;
}

const Selling = React.forwardRef(({
  key_features,
  supported_operating_systems,
  resources_required,
  is_consumer_data_collected,
  is_consumer_data_collected_remarks,
  reason_to_sell,
  licensing_type_details,
  versions_release_dates,
  total_investment,
  resource_transfer_details,
  want_to_transfer_resources,
  is_tech_transferrable,
  is_tech_transferrable_remarks,
  assets_to_sell,
  company_performance_annual_sales,
  control,
  watch,
  register,
  errors
}: SellingProps,
ref: React.ForwardedRef<HTMLDivElement>) => {
  const watchCanBeTransferred = watch('is_tech_transferrable', 'other');

  return (
    <div className="form-control-group">
      <div className="mb-3 row">
        <div className="col p-0">
          <label>
            <div className="bold">Selling Information</div>
          </label>
        </div>
      </div>
      <div className="flex-row mb-0 row d-flex align-items-stretch">
        <div className="col p-0 pe-lg-5">
          <div className="form-control mb-4">
            <label>
              <div className="text">Key Features</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={key_features || ''}
                {...register("key_features", {
                  required: false
                })} ></textarea>
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">Supported Operating Systems</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={supported_operating_systems || ''}
                {...register("supported_operating_systems", {
                  required: false
                })}></textarea>
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">Key resources to run your tech solution</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={resources_required || ''}
                {...register("resources_required", {
                  required: false
                })}></textarea>
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">Have you collected any consumer data?</div>
              <Controller 
                name="is_consumer_data_collected"
                control={control}
                defaultValue={is_consumer_data_collected || false} 
                rules={{
                  required: false
                }}
                render={({field}) => <InputRadioGroup 
                  options={[
                    {
                      label: "Yes",
                      value: true
                    },
                    {
                      label: "No",
                      value: false
                    }
                  ]} 
                {...field} />}
              />
              <input 
                className="mt-3" 
                type="text" 
                placeholder="Other - please specify..." 
                defaultValue={is_consumer_data_collected_remarks || ''}
                {...register("is_consumer_data_collected_remarks")} />
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">Reason for selling?</div>
              <textarea 
                placeholder="Your response goes here"
                defaultValue={reason_to_sell || ''}
                {...register("reason_to_sell")}></textarea>
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">Licensing type</div>
              <textarea 
                placeholder="Your response goes here"
                defaultValue={licensing_type_details || ''}
                {...register("licensing_type_details")}></textarea>
            </label>
          </div>
        </div>
        <div className="col p-0 ps-lg-5">
          <div className="form-control mb-4">
            <label>
              <div className="text">Versions &amp; release date</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={versions_release_dates || ''}
                {...register("versions_release_dates")}></textarea>
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">What is the total amount invested to date?</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={total_investment || ''}
                {...register("total_investment")}></textarea>
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">Do you want to transfer these resources?</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={want_to_transfer_resources || ''}
                {...register("want_to_transfer_resources")}></textarea>
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">Can your technology be transferred?</div>
              <Controller
                name="is_tech_transferrable"
                control={control}
                defaultValue={is_tech_transferrable ?? 'false'}
                rules={{
                  required: false
                }}
                render={({field}) => <InputRadioGroup 
                  options={[
                    {
                      label: "Yes",
                      value: 'true'
                    },
                    {
                      label: "No",
                      value: 'false'
                    },
                    {
                      label: "Other",
                      value: 'other'
                    }
                  ]} 
                {...field} />}
              />
              {watchCanBeTransferred === 'other' &&
              <input 
                className="mt-3" 
                type="text" 
                placeholder="Other - please specify..." 
                defaultValue={is_tech_transferrable_remarks || ''} 
                {...register("is_tech_transferrable_remarks")} />
              }
            </label>
          </div>
          <div className="form-control mb-4">
            <label>
              <div className="text">What assets are you willing to sell?</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={assets_to_sell || ''}
                {...register("assets_to_sell")}></textarea>
            </label>
          </div>
          <div className="form-control">
            <label>
              <div className="text">company performance (annual sales)</div>
              <textarea 
                placeholder="Your response goes here" 
                defaultValue={company_performance_annual_sales || ''}
                {...register("company_performance_annual_sales")}></textarea>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Selling;