import Button from "app/shared/button";
import LongArrowWhiteRight from 'app/shared/assets/svgs/long-arrow-right-white.svg';
import './assets/styles/service-card.scss';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setServicePackage } from 'features/cart/cartSlice';
import './assets/styles/service-card.scss';

interface Props {
  slug: string;
  name: string;
  color: string;
  matchCount: number;
  price: number;
  currency: string,
  discount: number;
  selected: boolean;
}

export const ServiceCard = ({
  slug,
  color,
  matchCount,
  price,
  currency,
  discount,
  name,
  selected
}: Props) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  
  return (
    <div className={`p-md-5 m-2 service-card-startup box-white-rounded-shadow d-flex flex-column align-items-center justify-content-start ${selected ? `border-${color}`:`hover-border-${color}`}`}>
      <div className={`mb-3 txt-semibold font-size-12 letter-spacing-n-2 text-uppercase color-${color}`}>Start Conversation with</div>
      <div className="pb-4 bold-type font-size-24 text-uppercase color-nightsky">{matchCount} {matchCount === 1 ? 'match' : 'matches'}</div>
      <div className="mt-auto d-flex flex-column align-items-center price-action">
        {discount > 0 && <div className={`mt-2 mb-2 p-1 ps-2 pe-2 text-uppercase font-size-12 letter-spacing-n-2 txt-semibold b-r-50 color-bg-${color}-opacity-5 color-${color}`}>{discount}% off</div>}
        <div className="flex-row d-flex color-nightsky justify-content-center align-items-end">
          <div className="bold-type font-size-12 letter-spacing-n-2 line-height-100 me-1">{currency}</div>
          <div className="bold-type font-size-18 letter-spacing-n-2 me-1 line-height-100">{new Intl.NumberFormat().format(price)}</div>
        </div>
        <Button className={`mt-3 color-bg-${color} color-white border-0`} onClick={() => {
          dispatch(setServicePackage({
            slug: slug,
            price: price,
            qty: 1,
            name: name,
            currency
          }))
          push('/app/startup/alkelio-services')
        }}>
          Select
          <img src={LongArrowWhiteRight} className="ms-2" alt="select" />
        </Button> 
      </div>
    </div>
  );
}