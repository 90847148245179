import React from 'react'
import './assets/styles/team.scss'
import CircleOPlusBlueIcon from 'assets/svgs/large-circle-o-plus.svg';
import Button from 'app/shared/button'

import {
  Control,
  UseFormRegister
} from 'react-hook-form'
import { Inputs, Person, ProjectTiming, Info } from './inputs'
import Member from './member'
import MemberForm from './member.form';

type TeamProps = {
  teamType: 'client' | 'alkelio';
  editable?: boolean;
  register: UseFormRegister<Inputs>;
  control: Control<Inputs>;
  fields: Array<Person>;
  append: (person: Person) => unknown;
  remove: (index: number) => unknown;
}

const Team = React.forwardRef(({
  teamType,
  fields,
  append,
  remove,
  register,
  control,
  editable = true
}: TeamProps,
ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <div className="scope__team">
      {fields.map((item, i) => (
        <div className="team-item">
          {editable ? <MemberForm 
            teamType={teamType}
            {...item} 
            index={i} 
            register={register}
            control={control} /> 
            : <Member {...item} />}
        </div>
      ))}
      {editable ? Array(3 - fields.length).fill(0).map((item, i) => (
        <div key={i} 
          className="team-item">
          <Button 
            variant="link icon" 
            className="add-team"
            onClick={() => {
              append({
                name: '',
                profile_image: null,
                role: '',
                email :''
              })
            }}
          >
            <div className="d-flex align-items-center">
              <img src={CircleOPlusBlueIcon} alt="add" />
            </div>
          </Button>
        </div>
      )) : null}
    </div>
  )
})

export default Team