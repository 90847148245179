import ButtonIcon from './button-icon';
import { open } from '../../features/modals/modalSlice'
import { useDispatch } from 'react-redux';
import { SignUpType, SignUpTypes } from '../../shared/sign-up-v2/types';
import {
  setIsFromHomePage
} from "features/signup/signupSlice";
import './assets/styles/hero.scss';
import iconStartup from './assets/svgs/hero-cta-icon-startup.svg';
import iconCorp from './assets/svgs/hero-cta-icon-corp.svg';
import VideoBackground from './video-background';
import Logos from 'home/logos';
import useOnboarding from 'shared/sign-up-v2/hooks/onboarding.hook';

const Hero = () => {
  const { setOnboardingPayload, onboardingPayload, startOnboarding, newOnboarding } = useOnboarding()
  const dispatch = useDispatch()
  const handleSignUpClick = async (signUpType: SignUpType) => {
    // if the signUpType differs from the existing onboarding sign up type, start from new
    if (onboardingPayload.type !== signUpType) {
      // reset onboarding
      await newOnboarding()
      await setOnboardingPayload({
        step: 1,
        type: signUpType,
      });
    }
    dispatch(open('sign-up'));
    dispatch(setIsFromHomePage(true));
  }
  return (
    <div className="pt-5 hero mb-md-5">
      <div className="content">
        <h6 className="tangerine">More than a matchmaking platform</h6>
        <h1 className="mb-3">Where business <br className="d-block d-sm-none" />needs and<br className="d-none d-sm-block" /> innovative solutions meet.</h1>
        <div className="mt-4 mt-sm-5 container-fluid d-flex flex-column justify-content-between h-100">
          <div className="row justify-content-md-center signup-row">
            <div className="col d-flex justify-content-sm-end button-col">
              <ButtonIcon
                icon={iconStartup}
                altText="Startup"
                title="I'm a Startup / Scaleup"
                text="Find &amp; connect with qualified leads"
                onClick={() => {
                  handleSignUpClick(SignUpTypes.STARTUP)
                }}
              />
            </div>
            <div className="col d-flex justify-content-sm-start button-col">
              <ButtonIcon
                icon={iconCorp}
                altText="Corporate"
                title="I'm a Corporate"
                text="Find &amp; connect with qualified tech solutions"
                onClick={() => {
                  handleSignUpClick(SignUpTypes.CORPORATE)
                }}
              />
            </div>
          </div>
          <div className="mt-auto logo-container">
            <Logos />
          </div>
        </div>
      </div>
      <div className="video-background">
        <VideoBackground/>
      </div>
      <div className="d-md-none bottom-gradient"></div>
    </div>
  )
}

export default Hero;