import React from 'react'
import './assets/styles/matching-progress.scss'

type MatchingProgressProps = {
  matchProgress: {
    total: number | null;
    completed: number | null
  }
}

const MatchingProgress = ({
  matchProgress
}:MatchingProgressProps) => {
  return (
    <div className="matching-progress">
      <h3 className="color-nightsky">Matching in progress</h3>
      {matchProgress ?
      <h2 className="color-nightsky">
        {matchProgress?.completed !== null ?
        <>{matchProgress?.completed} out of {matchProgress?.total}</>
         : <>Starting...</>}
      </h2> : null}
    </div>
  )
}

export default MatchingProgress