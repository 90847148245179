import './assets/styles/input-image.scss'
import UploadWhiteIcon from 'app/shared/assets/svgs/upload-white.svg'
import UploadAzureIcon from 'app/shared/assets/svgs/upload-azure.svg'
import React, { useState, useRef, useEffect } from 'react'
import BaseInput from './base-input'
import useFileUpload from 'hooks/file-uploads';
import Config from 'config';
import { FileItem } from 'hooks/file-uploads';
import Loader from 'shared/loader';

interface InputImageProps extends BaseInput{
  width? : string;
  height? :string;
  value?: FileItem | null;
  defaultValue?: FileItem | null;
  layout?: string;
  label?: string;
}

const InputImage = React.forwardRef((
  props: InputImageProps, 
  ref: React.Ref<HTMLDivElement>
) => {
  const dropRef = useRef<HTMLLabelElement>(null)
  const initial = props.defaultValue !== null && props.defaultValue !== undefined ? 
  [props.defaultValue] : (props.value !== null && props.value !== undefined ? 
    [props.value] : [])
    
  const { uploads, onChange, onDrop, isLoading, isSuccess } = useFileUpload({
    name: props.name || '',
    max: 1,
    initial,
    onUploadsChange: (files) => {
      props.onChange && props.onChange(files[0]?? [])
    }
  })

  const dragEnterHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    dropRef.current?.classList.add('on-drag')
    e.preventDefault();
  }

  const dragOverHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  }

  const dragLeaveHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    dropRef.current?.classList.remove('on-drag')
    e.preventDefault();
  }

  const getImage = () => {
    if (uploads.length === 0 && initial.length === 1) {
      let url = initial[0].url?? '';
      if (url.indexOf('http') === -1 && url !== '') {
        url = `${Config.storageBaseURL}${url}`
      }
      return url;
    } else
    if (uploads.length === 1) {
      let url = uploads[0].url?? '';
      if (url.indexOf('http') === -1 && url !== '') {
        url = `${Config.storageBaseURL}${url}`
      }
      return url;
    } else {
      return ""
    }
  }

  const imageEmpty = () => {
    return getImage() === undefined || getImage() === ''
  }

  return (
    <div className={`input-image ${props.layout || ''}`} 
      ref={ref}
    >
      <label className="input-area"
        onDragEnter={dragEnterHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={onDrop}
        ref={dropRef}
      >
        <div className="input-area-inner">
          <input type="file" 
            onChange={onChange} 
            accept=".jpg,.jpeg,.png" />
          <button type="button" className="action">
            <img src={["elipse-left","minimal"].includes(props.layout ?? '') ? UploadAzureIcon : UploadWhiteIcon} alt="browse" />
            <span>{props.label ?? 'Upload Image'}</span>
          </button>
        </div>
      </label>
      {isLoading && <div className="loading"><Loader /></div>}
      <div className="preview">
        {!imageEmpty() && !isLoading && <img alt="preview" src={getImage()}/>}
      </div>
    </div>
  )
});

export default InputImage;