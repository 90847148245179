import React from 'react'
import DateSelect from 'app/shared/date-select';
import {
  Control,
  Controller,
  UseFormRegister
} from 'react-hook-form'
import { Inputs, ProjectTiming } from './inputs'

type Props = {
  register: UseFormRegister<Inputs>;
  control: Control<Inputs>;
  project_timing: ProjectTiming | null | undefined
  disabled?: boolean
}

const ScopeProjectTiming = ({
  register,
  control,
  project_timing,
  disabled = false
}: Props) => {
  return (
    <div>
      <h4 className="mb-4">Project Timing</h4>
      <div className="form-control">
        <div className="mt-3">
          <Controller 
            name="project_timing.start"
            control={control}
            defaultValue={(project_timing?.start ?? null)}
            render={({field}) => <DateSelect disabled={disabled} {...field} />}
          />
          <Controller 
            name="project_timing.end"
            control={control}
            defaultValue={project_timing?.end ?? null}
            render={({field}) => <DateSelect disabled={disabled} {...field} /> }
          />
        </div>
      </div>
    </div>
  )
}

export default ScopeProjectTiming