import React, { useEffect } from 'react';
import { CorporateFindOneTechnologySolution } from 'generated/graphql'
import IconLabel from 'app/shared/icon-label'
import MapPinGreenIcon from 'app/shared/assets/svgs/map-pin-green.svg';
import { Countries } from 'shared/countries';
import BoxesOddGreenIcon from 'app/shared/assets/svgs/boxes-odd-green.svg';
import PeopleGreenIcon from 'app/shared/assets/svgs/people-green.svg';
import BoxCascadeGreenIcon from 'app/shared/assets/svgs/box-cascade-green.svg';
import BoxTickGreenIcon from 'app/shared/assets/svgs/box-tick-green.svg';
import UnlockGreenIcon from 'app/shared/assets/svgs/unlock-green.svg';
import LockGreenIcon from 'app/shared/assets/svgs/lock-green.svg';
import DollarGreenIcon from 'app/shared/assets/svgs/dollar-green.svg';
import UserTickGreen from 'app/shared/assets/svgs/user-tick-green.svg';
import GlobeGreenIcon from 'app/shared/assets/svgs/globe-green.svg';
import TickNightSkyIcon from 'app/shared/assets/svgs/tick-nightsky.svg';
import RocketGreenIcon from 'app/shared/assets/svgs/rocket-green.svg';
import StarGreenOutlineIcon from 'app/shared/assets/svgs/star-green-outline.svg';
import Pill from 'app/shared/pill'
import Button from 'app/shared/button'
import { useGetTechnologyReadinessLevelsQuery } from 'services/graphql/tags'
import { CorporateFindOneTechnologySolutionWithTechReadiness } from 'services/graphql/tech-solutions'
import { ESGRatingOptions } from 'app-constants'

type KeyInfoProps = {
  data: CorporateFindOneTechnologySolutionWithTechReadiness;
  onUnlockTestimonial: () => unknown;
  onUnlockCaseStudies: () => unknown;
}

const KeyInfo = ({
  data,
  onUnlockTestimonial,
  onUnlockCaseStudies
}: KeyInfoProps) => {

  const getTechReadiness = useGetTechnologyReadinessLevelsQuery()

  const operatingCountries = data?.startup_profile?.operating_locations ? data?.startup_profile?.operating_locations?.split(",") : [];

  return (
    <div className={`key-info ${data?.tested_by_alkelio ? '': 'no-recommend'}`}>
      <h6>About them</h6>
      {data?.startup_profile ? 
        <IconLabel icon={MapPinGreenIcon}>
          {Countries.getName(data?.startup_profile.country || '', "en")}
        </IconLabel>
      : null}

      {data?.industries && data.industries.length > 0 && 
        <IconLabel icon={BoxesOddGreenIcon}>
          {data.industries.map(item => item !== null ? item.name: '').join(" / ")}
        </IconLabel>}

      {data?.startup_profile?.employee_count ? 
        <IconLabel icon={PeopleGreenIcon}>
          {data?.startup_profile?.employee_count}
        </IconLabel>
      : null}
      
      {data?.startup_profile?.esg_rating ? 
        <IconLabel icon={StarGreenOutlineIcon}>
          ESG Rate - {ESGRatingOptions.find(({value}) => value === data?.startup_profile?.esg_rating)?.label ?? ''}
        </IconLabel>
      : null}

      {data?.startup_profile?.maturity_level?.name ?
        <IconLabel icon={BoxCascadeGreenIcon}>
          {data?.startup_profile?.maturity_level?.name}
        </IconLabel> : null  
      }

      {data?.startup_profile?.investment_received ?
        <IconLabel icon={DollarGreenIcon}>
          ${data?.startup_profile?.investment_received} raised
        </IconLabel>
      : null}

      {data?.startup_profile?.client_count ? 
        <IconLabel icon={UserTickGreen}>
          {data?.startup_profile?.client_count} clients
        </IconLabel>
      : null}

      {data?.technology_readiness_name ? 
        <IconLabel icon={RocketGreenIcon}>
          {data?.technology_readiness_name ?? ''}
        </IconLabel>
      : null}
      

      {data?.startup_profile?.operating_locations ?
        <IconLabel icon={GlobeGreenIcon}>Operating in</IconLabel>
      : null }
      <div className="flex-wrap d-flex">
      {operatingCountries.map((item, i) => (
        <Pill variant="inverse" label={Countries.getName(item, "en")} icon={TickNightSkyIcon} />
      ))}
      </div>
      
      {data?.testimonial?.have_testimonials && data?.case_studies?.have_case_studies ?
      <div className="mt-4">
        {(data?.testimonial?.have_testimonials ?? false) && <div className="flex-row d-flex align-items-center">
          <div className="flex-row d-flex me-2 align-items-center">
            <img src={LockGreenIcon} alt="unlock" className="me-2" />
            <span className="bold-type font-size-14 letter-space-n-2 nowrap">Testimonials ({data?.testimonial?.quantity ?? 0})</span>
          </div>
          <Button variant="link" className="flex-row ms-1 color-java d-flex align-items-center" onClick={onUnlockTestimonial}>
            <div className="icon">
              <img src={UnlockGreenIcon} alt="unlock" className="me-1" />
            </div>
            <span className="semi-bold-type text-decoration-underline text-lowercase">unlock</span>
          </Button>
        </div>}
        {(data?.case_studies?.have_case_studies ?? false) && <div className="flex-row d-flex align-items-center">
          <div className="flex-row d-flex me-2 align-items-center">
            <img src={LockGreenIcon} alt="unlock" className="me-2" />
            <span className="bold-type font-size-14 letter-space-n-2 nowrap">Case studies ({data?.case_studies?.quantity ?? 0})</span>
          </div>
          <Button variant="link" className="flex-row ms-1 color-java d-flex align-items-center" onClick={onUnlockCaseStudies}>
            <div className="icon">
              <img src={UnlockGreenIcon} alt="unlock" className="me-1" />
            </div>
            <span className="semi-bold-type text-decoration-underline text-lowercase">unlock</span>
          </Button>
        </div>}
      </div> : null }

      {data?.startup_profile?.worked_with_corporates ?
      <div className="mt-1 mt-md-5">
        <label className="font-size-14 font-size-md-12 color-white text-regular line-height-160">
          <img src={BoxTickGreenIcon} alt="" className="me-1 icon-work-corp" /> Has worked with corporates
        </label>
      </div>
      : null }
      
    </div>
  );
}

export default KeyInfo;
