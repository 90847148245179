import React from 'react';
import './assets/styles/button-back.scss';
import CaretLeftIcon from './assets/svgs/caret-left.svg';

interface ButtonBackProps {
  onClick: () => unknown;
  className?: string;
}

const ButtonBack = React.forwardRef((
  { 
    onClick,
    className
  }: ButtonBackProps,
  ref: React.Ref<HTMLButtonElement>) => {
  return (
    <button className={`button-back ${className}`} ref={ref} onClick={onClick}>
      <div className="icon">
        <img src={CaretLeftIcon} alt="back" />
      </div>
      GO BACK
    </button>
  )
})

export default ButtonBack;