import './assets/styles/input-files.scss'
import UploadBlueIcon from 'app/shared/assets/svgs/upload-azure.svg'
import React, { useEffect, useRef } from 'react'
import useFileUpload from 'hooks/file-uploads';
import Config from 'config';
import DocumentBlueIcon from 'app/shared/assets/svgs/document-blue.svg';
import CircleOCrossIcon from 'app/shared/assets/svgs/circle-o-cross.svg';
import BaseInput from 'shared/forms/base-input';
import { FileItem } from 'hooks/file-uploads';
import Loader from 'shared/loader';
import { AppSettings } from 'app-constants';

interface InputFilesProps extends BaseInput {
  width? : string;
  height? :string;
  maxFileCount?: number;
  value?: Array<FileItem> | undefined;
  defaultValue?: Array<FileItem> | undefined;
  disabled?: boolean;
}

export const getUrl = (srcUrl: string | null | undefined) => {
  if (srcUrl == null || srcUrl === undefined) return '';
  
  if (srcUrl.indexOf('http') === -1 && srcUrl !== '') {
    srcUrl = `${Config.storageBaseURL}${srcUrl}`
  }
  return srcUrl;
}

const InputFiles = React.forwardRef((
  props: InputFilesProps,
  ref: React.Ref<HTMLDivElement>
  ) => {
  const dropRef = useRef<HTMLDivElement>(null)
  const { uploads, remove, onChange, onDrop, isLoading, errors } = useFileUpload({
    max: props.maxFileCount || 1,
    initial: props.defaultValue || props.value || []
  })

  const removeFile = (id: string) => {
    remove(id);
  }

  const dragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
    dropRef.current?.classList.add('on-drag')
    e.preventDefault();
  }

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }

  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    dropRef.current?.classList.remove('on-drag')
    e.preventDefault();
  }

  useEffect(() => {
    props.onChange && props.onChange(uploads);
    return () => {
    }
  },[uploads])

  return (
    <div className="input-files" 
      style={{
        width: props.width || '100%',
        height: props.height || '100%'
      }}
      ref={ref}>
      {props.disabled ? <div className="drop-area-disabled"></div> : null}
      {!props.disabled ? <div className="drop-area-container">
        <div className="drop-area" 
          onDragEnter={dragEnterHandler}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={onDrop}
          ref={dropRef}
          >
          {isLoading && <div className="uploading-progress">
            <Loader/>
          </div>}
          <label className="input-area">
            <div className="input-area-inner">
              <input type="file" onChange={onChange} disabled={props.maxFileCount === uploads.length || (props.disabled ?? false)}
                multiple={props.maxFileCount !== undefined ? props.maxFileCount > 1 : false} />
              <button type="button" disabled={props.disabled ?? false} className="action">
                <img src={UploadBlueIcon} alt="browse" />

                <span>
                  Drag & Drop or
                  <span className="color-azure ms-1">
                    Browse Files
                    {/* <b>
                      ({uploads.length} of {props.maxFileCount})
                    </b> */}
                  </span>
                </span>

              </button>
            </div>
          </label>
        </div>
        <div><p className="font-size-12">Max file size is {AppSettings.maxFileSizeMB} MB</p></div>
        {errors.uploading && <div className="font-size-12 color-tangerine">There was an error uploading the file</div>}
        {errors.fileCheckErrors && errors.fileCheckErrors.size && <div className="font-size-12 color-tangerine">File size cannot exceed {AppSettings.maxFileSizeMB} MB</div>}
      </div> : null }
      <div className="uploaded-files">
        {uploads.map((item, i) => (
          <div key={i} className="file">
            <img src={DocumentBlueIcon} alt={item.name} />
            <a href={getUrl(item.url)} target="_blank" rel="noreferrer">
              <div className="name">
              {item.name}
              </div>
            </a>
            {!props.disabled ? <button type="button" className="button-reset" onClick={() => {
                removeFile(item.id)
              }}>
                <img src={CircleOCrossIcon} alt="remove" />
              </button> : null}
          </div>
        ))}
      </div>
    </div>
  )
})

export default InputFiles;