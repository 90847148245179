import ContactUsForm from './contact-us-form';

const ContactUs = () => {
  return (
    <>
      <a id="contact"></a>
      <div className="pb-5 container-fluid color-bg-white pt-5 pt-md-5 contact">
        <div className="container pb-xl-5 pt-xl-5">
          <div className="row">
            <div className="col-12 col-xl-6 pe-xl-5">
              <div className="pe-xl-5">
                <h6 className="mb-0 mb-xl-4 tangerine text-center text-xl-start">CONTACT US</h6>
                <h1 className="mb-2 mb-xl-4 text-center text-xl-start">Get in touch with us</h1>
                <div className="pe-xl-5">
                  <div className="pe-xl-2">
                    <h5 className="txt-light line-height-140 color-text pe-xl-10 text-center text-xl-start pe-xl-3">
                    Questions, concerns or want to say hello? Just fill out the form and our team will get back to you
                    </h5>
                    <img src="imgs/world-map.png" alt="" className="world-map mt-3 ms-auto me-auto w-100 mb-4 mb-xl-0" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs;