import { useContext } from 'react';
import './assets/styles/header.scss';
import ButtonBack from './button-back';
import Alerts from './alerts';
import Profile from './profile';
import Bookmarks from './bookmarks';
import Greeting from './greeting';
import {
  useLocation,
  useHistory,
  useParams
} from 'react-router-dom';
import Logo from '../logo';
import MobileMenu from '../mobile-menu/mobile-menu.component'
import { MenuContext } from '../../providers/menu.provider'

interface HeaderNavItemProps {
  children?: React.ReactNode;
  className?: String;
  onClick?: () => unknown;
}

const HeaderNavItem = ({
  children,
  className,
  onClick
}:HeaderNavItemProps) => {
  return (
    <div onClick={onClick} className={`header-nav-item ${className ?? ''}`}>
      {children}
    </div>
  ) 
}

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const { menuActive } = useContext(MenuContext);
  const { entityType }: any = useParams();

  return (
    <div className="app-header">
      <div className="app-header-inner">
        <div className="left">
          {pathArray.length > 4 && <ButtonBack onClick={() => {
            history.goBack();
          }} />}
          {pathArray.length <= 4 && <Greeting />}
        </div>
        <div className="right">
          {!menuActive ? <HeaderNavItem onClick={() => {
            history.push(`/app/${entityType}`)
          }} className={`d-flex d-md-none`}>
            <div className="p-3">
              <Logo></Logo>
            </div>
          </HeaderNavItem>
          : null }
          {menuActive ? 
          <>
          <HeaderNavItem className={`d-flex d-md-none`}>
            <Profile/>
          </HeaderNavItem>
          <HeaderNavItem className={`d-flex d-md-none`}>
            <Bookmarks />
          </HeaderNavItem>
          </>
          : null }
          {entityType !=='catalyst' ? <HeaderNavItem className={'d-none d-md-flex'}>
            <Bookmarks />
          </HeaderNavItem>: null}
          {entityType !=='catalyst' ? <HeaderNavItem>
            <Alerts />
          </HeaderNavItem> : null}
          <HeaderNavItem className={'d-none d-md-flex'}>
            <Profile/>
          </HeaderNavItem>
          <HeaderNavItem className={'d-flex d-md-none'}>
            <MobileMenu />
          </HeaderNavItem>
        </div>
      </div>
    </div>
  )
};

export default Header;