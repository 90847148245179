import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SignUpType } from 'types/sign-up'
import {
  OnBoardingSession
} from 'generated/graphql'
import type { RootState } from 'store'
import { onBoardingSessionApi } from 'services/graphql/on-boarding-session'

export type SignUpState = {
  currentSession: Partial<OnBoardingSession> | null,
  step: number,
  isFromHomePage: boolean,
  email: string,
  verificationUuid: string,
  jwt: string,
  resendVerificationCodeWaitTime: number
}

const initialState = {
  currentSession: {
    payload: {
      step: 1,
      type: null
    }
  },
  step: 1,
  isFromHomePage: false,
  verificationUuid: ''
} as SignUpState

const slice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setCurrentSession: (
      state: SignUpState, 
      action: PayloadAction<any>
    ) => {
      state.currentSession = {
        payload: {
          ...state.currentSession?.payload,
          ...action.payload
        }
      }
    },
    setNewSession: (
      state: SignUpState, 
      action: PayloadAction<any>
    ) => { 
      state.currentSession = {
        payload: {
          ...action.payload
        }
      }
    },
    setIsFromHomePage: (
      state: SignUpState, 
      action: PayloadAction<boolean>
    ) => {
      state.isFromHomePage = action.payload;
    },
    setEmail: (
      state: SignUpState, 
      action: PayloadAction<string>
    ) => {
      state.email = action.payload;
    },
    setStep: (
      state: SignUpState, 
      action: PayloadAction<number>
    ) => {
      state.step = action.payload;
    },
    setVerificationUuid: (
      state: SignUpState, 
      action: PayloadAction<string>
    ) => {
      state.verificationUuid = action.payload;
    },
    setJwt: (
      state: SignUpState, 
      action: PayloadAction<string>
    ) => {
      state.jwt = action.payload;
    },
    setResendVerificationCodeWaitTime: (
      state: SignUpState, 
      action: PayloadAction<number>
    ) => {
      if (Number(action.payload)){
        state.resendVerificationCodeWaitTime = Number(action.payload);
      }
      else {
        state.resendVerificationCodeWaitTime = 0;
      }
    },
    reset: (
      state: SignUpState
    ) => {
      state = {...initialState}
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      onBoardingSessionApi.endpoints.createOboardingSession.matchPending,
      (state) => {
        state = initialState
        return state
      }
    )
  }
})

export default slice.reducer

export const {
  setCurrentSession,
  setNewSession,
  setIsFromHomePage,
  setEmail,
  setVerificationUuid,
  setJwt,
  setResendVerificationCodeWaitTime,
  reset,
  setStep
} = slice.actions

export const selectCurrentSession = (state: RootState) => state.signup.currentSession
export const selectIsFromHomePage = (state: RootState) => state.signup.isFromHomePage
export const selectVerificationUuid = (state: RootState) => state.signup.verificationUuid
export const selectEmail = (state: RootState) => state.signup.email
export const selectJwt = (state: RootState) => state.signup.jwt
export const selectStep = (state: RootState) => state.signup.step
export const selectResendVerificationCodeWaitTime = (state: RootState) => state.signup.resendVerificationCodeWaitTime