import React, { useEffect } from 'react';
import Layout from "layout";
import './assets/styles/jobs.scss';
import { useLocation } from "react-router-dom"
import { useGetJobsQuery } from 'services/graphql/jobs'

const Jobs = () => {
  const { pathname } = useLocation();
  const { data, isLoading, isSuccess } = useGetJobsQuery()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0, 
      behavior: 'auto'
    })
  }, [pathname]);

  return (
    <Layout>
      <div className="pt-xl-3 mt-xl-5 pt-xl-5 mt-xl-2 container-fluid color-bg-white pb-5 mb-xl-2" id="jobs-page">
        <a id="jobs"></a>
        <div className="container pt-xl-5 pb-5">
          <div className="row">
            <div className="col-12 d-block d-md-none pb-3">
              <h6 className="mb-xl-4 tangerine">WORKING WITH US</h6>
              <h1>We're looking for great people to make our team even greater</h1>
            </div>
            <div className="col-12 col-md-6 ps-md-4 pe-3 d-flex pb-3 pb-xl-5 pb-xl-0">
              <div className="jobs-image">
                <img src="imgs/jobs-image.png" className="d-block" alt="about" />
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-end ps-xl-5 pe-xl-5">
              <div className="ps-xl-5 pe-xl-5">
                <h6 className="mb-xl-4 tangerine d-none d-md-block">WORKING WITH US</h6>
                <h1 className="d-none d-md-block">We're looking for great people to make our team even greater</h1>
                <div className="mt-4 d-flex align-items-center justify-content-center pe-2">
                  <div className="">
                    <h5 className="pb-4 txt-light color-text line-height-160">If you are passionate about startups & scale-ups,
corporate innovation, global ecosystems, new technologies, open collaboration and want to be part of a great adventure, we would love to talk to you! </h5>

                    <h5 className="pb-4 txt-light color-text line-height-160">ALKELIO recognises the positive value of diversity. We welcome and encourage people of all backgrounds, ages, gender and expertise to apply. </h5>

                    <h5 className="pb-4 txt-light color-text line-height-160">If the current openings don't fit you but you would like to be part of the team, reach out to us at <a href="mailto:hr@alkelio.com">hr@alkelio.com</a>.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 className="title-opportunities text-center">Current Opportunities</h1>
          <div className="row">
            {isSuccess && data ? data.map(d => (
              <div className="col d-flex justify-content-center">
                <div className="job-posting-item">
                  <h1 className="sm text-center">{d.job_title ?? ''}</h1>
                  <div className="description font-size-14 light-type text-center">
                    {d.description}
                  </div>
                  <a href={`mailto:jobs@alkelio.com?subject=Job Interest: ${d.job_title}`}>Get in touch</a>
                </div>
              </div>
            )) : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Jobs;