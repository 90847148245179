import React, { useEffect } from 'react';
import Layout from "layout";
import './assets/styles/terms-conditions.scss';
import { useLocation } from "react-router-dom"

const TermsConditions = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0, 
      behavior: 'auto'
    })
  }, [pathname]);
  
  return (
    <Layout>
      <div className="pt-md-5 pt-xl-5 container-fluid color-bg-white pb-7">
        <a id="terms-conditions"></a>
        <div className="container pt-xl-5">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div className="terms-conditions">
                <h1>Terms and Conditions</h1>
                <ul className="table-of-content">
                  <li><a href="#definitions">Definitions</a></li>
                  <li><a href="#introductions">Introduction</a></li>
                  <li><a href="#agreement">Agreement</a></li>
                  <li><a href="#duration">Duration</a></li>
                  <li><a href="#rights-responsibilities">User’s Rights and Responsibilities</a></li>
                  <li><a href="#alkelio-rights-responsbilities">ALKELIO’s Rights and Responsibilities</a></li>
                  <li><a href="#payment">Payment</a></li>
                  <li><a href="#confidentiality">Confidentiality</a></li>
                  <li><a href="#intellectual-property">Intellectual Property</a></li>
                  <li><a href="#warranties">Warranties</a></li>
                  <li><a href="#liability-indemity">Liability and Indemnity</a></li>
                  <li><a href="#termination">Termination</a></li>
                  <li><a href="#governing">Governing Law</a></li>
                  <li><a href="#domicilium">Domicilium</a></li>
                </ul>

                <ol>
                  <li id="definitions">
                    DEFINITIONS AND INTERPRETATION
                    <ol>
                      <li>
                          The meanings of the following words and phrases are set out below. Where these words are used in capitalised form in the Agreement, they will have the meanings ascribed herein below.
  
                        <dl>
                          <dt>Agreement</dt>
                          <dd>means the agreement between the Parties, consisting of these Terms and Conditions and any other annexure, appendix or policy stipulated as being part hereof</dd>

                          <dt>Confidential Information</dt>
                          <dd>means all documents, software, data, records, forms and other information that the either Party provides to the other Party in order to give effect to the terms of this Agreement that: are marked as confidential, or the disclosing Party has advised as being confidential, or due to its character and nature, a reasonable person would treat as confidential, or any Solution and any and all Solution Details</dd>

                          <dt>Contract</dt>
                          <dd>means an agreement entered into between a Solution Provider and the User</dd>

                          <dt>Agreement</dt>
                          <dd>means the agreement between the Parties, consisting of these Terms and Conditions and any other annexure, appendix or policy stipulated as being part hereof</dd>

                          <dt>Solution provider</dt>
                          <dd>means an authorised solution provider in good standing, who has subscribed to the Website, and who has created, owns the rights and IP of the solution and developed or has an interest in, a concept or a Solution, which it wishes to or has submitted to the Website, for publication on the Website, subject to the terms of an agreement concluded between the Solution provider and ALKELIO</dd>
                          
                          <dt>Solution</dt>
                          <dd>means a developed inventive or innovative technology concept or product developed-, constructed or otherwise produced by an Solution provider, which may range in maturity up to a fully developed concept or Solution, which is already a mature or viable prototype, pilot project, proof of concept or product, and includes the Solution Details</dd>
                          
                          <dt>Solution Details</dt>
                          <dd>means the complete description of the technology Solution and how it works including all data, details, information, code, APIs, diagrams, videos and supporting documents</dd>

                          <dt>Intellectual Property</dt>
                          <dd>means and includes patents, inventions, registered designs, copyright, trade marks, know-how, trade dress, confidential information and other related intellectual property</dd>
                          
                          <dt>Law</dt>
                          <dd>means the law of Switzerland, which is and will be the law that will govern all aspects of the Agreement and all aspects of the relationship between the Parties</dd>
                          
                          <dt>Member</dt>
                          <dd>means and includes users and solution providers of the Website</dd>
                          
                          <dt>Membership</dt>
                          <dd>refers to the status of a Member that is in compliance with the terms applicable to Members</dd>
                          
                          <dt>Parties</dt>
                          <dd>means the parties to this Agreement and “Party” shall refer to either one as the case may be</dd>
                          
                          <dt>Personal Information</dt>
                          <dd>means personal information as defined in The General Data Protection Regulation (GDPR), Article 4 or any other law or regulation applicable to the information of a data subject and with which ALKELIO obligated to comply</dd>
                          
                          <dt>Problem Statement</dt>
                          <dd>means a statement setting out the problem, challenge or difficulty the Solution seeks to address</dd>
                          
                          <dt>Services</dt>
                          <dd>means the Services set out in this Agreement that ALKELIO agrees to provide in terms of this Agreement</dd>
                          
                          <dt>User</dt>
                          <dd>means the person or entity interested in obtaining a right or license to use or obtain ownership of a Solution and who is a Member. The User is the counterparty to this Agreement as stipulated during registration</dd>
                          
                          <dt>Website</dt>
                          <dd>means the ALKELIO website which attracts and receives Solutions from Solution providers, and generally deals with facilitating, introducing and matching Solutions and Solution providers with Users</dd>
                          
                        </dl>
                      </li>

                      <li>
                        In this Agreement, unless the context otherwise requires:
                        <ol>
                          <li>
                          if any provision in a definition is a substantive provision conferring rights or imposing obligations on a Party, notwithstanding that it is only in the interpretation clause, effect shall be given to it as if it were a substantive provision in the body of this Agreement;
                          </li>
                          <li>
                          in this Agreement, a Party includes a reference to that Party’s successors in title and assigns allowed in law;
                          </li>
                          <li>
                          any reference in this Agreement to:
                          <ol>
                            <li>"days" shall be construed as calendar days unless qualified by the word "business", in which instance a "business day" will be any day other than a Saturday, Sunday or public holiday as gazetted by Swiss government;</li>
                            <li>"law" means any law of general application and includes the common law and any statute, constitution, decree, treaty, regulation, directive, ordinance, by-law, order or any other enactment of legislative measure of government (including local and provincial government) statutory or regulatory body which has the force of law;</li>
                            <li>"person" means any person, company, close corporation, trust, partnership or other entity whether or not having separate legal personality;</li>
                          </ol>
                          </li>
                          <li>
                          the words "include" and "including" mean "include without limitation" and "including without limitation". The use of the words "includes" and "including" followed by a specific example or examples shall not be construed as limiting the meaning of the general wording preceding it; The eiusdem generis rule shall not be applied in the interpretation of such general wording or such specific example or examples, and where general words follow particular and specific words, the general words must not be confined to the particular or specific words used in the context.
                          </li>
                          <li>
                          the words "shall" and "will" and "must" used in the context of any obligation or restriction imposed on a party have the same meaning;
                          </li>
                          <li>
                          words and expressions defined in any clause shall, unless the application of any such word or expression is specifically limited to that clause, bear the meaning assigned to such word or expression throughout this Agreement;
                          </li>
                          <li>
                          words and expressions defined in this Agreement shall also apply in the interpretation of the same words and phrases in annexures or schedules to this Agreement;
                          </li>
                          <li>
                          a reference to any statutory enactment shall be construed as a reference to that enactment as at the Commencement Date and as amended or substituted from time to time;
                          </li>
                          <li>
                          unless specifically otherwise provided, any number of days prescribed shall be determined by excluding the first and including the last day or, where the last day falls on a day that is not a business day, the next succeeding business day;
                          </li>
                          <li>
                          if the due date for performance of any obligation in terms of this Agreement is a day which is not a business day then (unless otherwise stipulated) the due date for performance of the relevant obligation shall be the immediately preceding business day;
                          </li>
                          <li>
                          where figures are referred to in numerals and in words, and there is any conflict between the two, the words shall prevail, unless the context indicates a contrary intention;
                          </li>
                          <li>
                          the rule of construction that this Agreement shall be interpreted against the Party responsible for the drafting of this Agreement, shall not apply;
                          </li>
                          <li>
                          the singular shall import and include the plural and vice versa;
                          </li>
                          <li>
                          words indicating a gender shall import and include other genders
                          </li>
                          <li>
                          n this Agreement, the words "clause" or "clauses" and "annexure" or "annexures" and "schedule" or "schedules" refer to clauses and annexures or schedules to this Agreement.
                          </li>
                        </ol>
                      </li>

                    </ol>
                  </li>
                  <li id="introductions">
                    INTRODUCTION
                    <ol>
                      <li>This Agreement and any terms or conditions, policies and/or documents referred to in or incorporated into the Agreement comprise the entire Agreement between the Parties. This Agreement applies to the relationship between the Parties before and after acceptance of the User as a Member.</li>
                      <li>The primary purpose of the Website and of ALKELIO in relation to the Website is to facilitate the matching of Corporate established needs through the Website and Solutions by Solution providers, to publish Solutions on the Website, and to enable the User to view, assess and by agreement with Solution providers, use or obtain Solutions. This Agreement between ALKELIO and the User may include the facilitation of transactions between a Solution provider and the User if the Corporate User and the Solution provider enter into an agreement with ALKELIO to facilitate the transaction. In the event that ALKELIO is requested by a Solution provider or a User to facilitate a transaction as envisaged herein or provide other Adhoc services, separate terms shall be agreed to.</li>
                    </ol>
                  </li>
                  <li id="agreement">
                    AGREEMENT<br/>
                    This Agreement sets out the whole agreement between the Parties related to the subject matter hereof. No change to the Agreement will be of any effect or consequence unless in writing and signed by an authorised representative of each of the Parties.
                  </li>
                  <li id="duration">
                    DURATION<br/>
                    The duration of the User’s Membership is defined by the subscription package that is active at the time of the agreement, subject to the completion and funding subscription payment. If the User Membership expires without renewal through payment the agreement shall be terminated with a 30 day notice. The terms of this Agreement, as may be amended from time to time, as well as either Party’s right to terminate this Agreement as provided herein further below.
                  </li>
                  <li id="rights-responsibilities">
                    USER’S RIGHTS AND RESPONSIBILITIES
                    <ol>
                      <li>
                        Users have the option of subscribing to the Website for either one of two categories, namely:
                        <ol>
                          <li>Start-up User; or</li>
                          <li>Corporate User</li>
                        </ol>
                      </li>
                      <li>
                        Rights and Responsibilities of Start-up Users:
                        <ol>
                          <li>Users in this category shall have the rights on the Website established for Start-up Users</li>
                          <li>Users in this category shall be required to pay a subscription fee if they want to have access to the Corporate User or access to the Adhoc Services, ALKELIO+</li>
                          <li>Users in this category shall in particular have the right to view Corporate use cases and technology requests, engage with Corporate Users as stipulated in this agreement and have access to ALKELIO website content</li>
                          <li>Users in this category shall have set rights of access other than as stipulated in clause 5.2.3 above and shall specifically have rights to view Solution content or engage with Solution providers in line with the subscription package that is active at the time of the engagement</li>
                        </ol>
                      </li>
                      <li>
                        Rights and Responsibilities of Corporate Users:
                        <ol>
                          <li>Users in this category shall be required to pay a subscription fee in accordance with the payment terms set out herein further below.</li>
                          <li>Users in this category shall have full access to the number of Solutions stipulated in the subscription package that is active at the time of membership subject to the subscription payment being up to date.</li>
                          <li>Users in this category shall be enabled, subject to this Agreement, to engage with Solution providers to negotiate in good faith for obtaining of license to use- or ownership of Solutions.</li>
                          <li>Users in this category shall be entitled to view ALKELIO content and source additional ALKELIO services as stipulated by the active subscription package. Any services which are not included in the active subscription package will incur additional fees and will be governed by a separate agreement between ALKELIO and the User.</li>
                        </ol>
                      </li>
                      <li>
                        Rights and Responsibilities of all Users:
                        <ol>
                          <li>The User shall be bound by this Agreement in all transactions relating to the viewing of Solutions and further authorised use of the Website as stipulated in clauses 5.2 and 5.3 above.</li>
                          <li>Subject to the parameters provided in clauses 5.2 and 5.3 the User is entitled to view the number of Solutions and the same Solutions established in the subscription package that is active at the time in terms of the agreement between ALKELIO and an Solution provider</li>
                          <li>The User shall submit its full details, onto the Website.</li>
                          <li>The User may become a Solution provider subject to separate agreement between the User and ALKELIO.</li>
                          <li>In the event that a User is interested in acquiring ownership or a license for use of the Solution, they may initially register their interest with ALKELIO directly or their appointed catalyst to engage in bilateral negotiations and or negotiations through ALKELIO as stipulated by the Corporate User. The User may subsequently, at its option, choose to communicate directly with the Solution provider in order to negotiate terms for such transaction.</li>
                          <li>In dealing with ALKELIO and other Members, the User shall exercise good faith and act with integrity. The User shall be honest in its dealings with other Members and refrain from fraudulent, dishonest and any other objectionable conduct.</li>
                          <li>The User shall comply with all laws and regulations in force from time to time. The User shall not be involved in any criminal or unlawful activity.</li>
                          <li>The User shall ensure that its workplaces and employment environment comply with international standards regarding issues such as health and safety and employment equity. The User shall not make use of slavery or child labour.</li>
                          <li>The User may not engage in any discriminatory conduct, including, without limitation, discrimination on the grounds of race, gender, sex, pregnancy, marital status, ethnic or social origin, colour, sexual orientation, age, disability, religion, conscience, belief, culture, language or birth.</li>
                          <li>The User shall avoid all forms of harassment of other Members or any other persons.</li>
                          <li>The User shall at all times comply with all laws and rules regulating competition and anti-trust matters. The Solution provider shall refrain from all anti-competitive conduct and unlawful competition.</li>
                          <li>The User shall at all times adhere to the Anti-Bribery and Corruption Policy.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li id="alkelio-rights-responsbilities">
                    ALKELIO’S RIGHTS AND RESPONSIBILITIES
                    <ol>
                      <li>ALKELIO shall on receipt of the User’s details on the Website, as required, and after the satisfactory completion of the necessary risk management procedures, review the User’s details and either accept or decline the User as a Member. ALKELIO reserves the right, at any stage, to terminate the User’s Membership, in its sole discretion and without cause.</li>
                      <li>ALKELIO shall, subject to the remainder of the provisions of this Agreement, make available such content to the User as is permitted dependent on the category of the User as per clause 5 above.</li>
                      <li>ALKELIO shall reserve the right to elect Solutions to be published on the Website. ALKELIO is under no obligation to place any submission or Solution on the Website and shall not be liable in any manner whatsoever for a failure to do so. ALKELIO reserves the right to, at any stage, attend to a full review of Solutions published on the Website and, in its sole discretion, remove it from the Website. ALKELIO shall not be obliged to provide any reasons for any decision relating to the removal of a submission or Solution.</li>
                      <li>ALKELIO may remove any Solution that has become the subject of a Contract between an Solution provider and a User. ALKELIO shall not be obligated to provide the User with any reasons for such removal.</li>
                      <li>ALKELIO makes no warranty or representation, express or implied, regarding Solution providers or the Solutions they submit or which is published on the Website. The User shall be responsible for obtaining independent professional advice regarding Solution providers, their Solutions and transactions pertaining thereto, including negotiations and finalisation of Contracts.</li>
                      <li>ALKELIO shall not incur any liability of whatever nature arising, in any way from any injury, claim, loss or damage to any person, including the User, as a result of any participation on the Website or the use of any Services provided by ALKELIO.</li>
                      <li>ALKELIO does not act as the agent of the User or that of Solution providers. Furthermore, nothing herein contained shall constitute either Party to be the partner, representative, agent, joint-venturer or employee of the other Party.</li>
                      <li>ALKELIO shall process all the User’s Personal Information submitted on the Website (if any) in accordance with the provisions of The General Data Protection Regulation (GDPR), Article 4 or other data protection legislation or regulation which may be applicable to the relevant Member and with which ALKELIO is obligated to comply.</li>
                    </ol>
                  </li>
                  <li id="payment">
                    PAYMENT<br/>
                    Corporate Users and Startup Users as defined in clause 5 herein above, shall pay a package subscription fee of as per the agreement or project and VAT.
                  </li>
                  <li id="confidentiality">
                    CONFIDENTIALITY
                    <ol>
                      <li>
                        ALKELIO may receive Confidential Information about the User’s business in the course of providing the Services. The User may similarly receive Confidential Information regarding ALKELIO’s business, or that of Solution providers. Both Parties will keep confidential and will not disclose the other Party’s Confidential Information or that of the Solution providers to any third party. This does not apply to Confidential Information that:
                        <ol>
                          <li>is in the public domain; or</li>
                          <li>a Party is required by Law to disclose; or</li>
                          <li>a Party is required to disclose to its insurers or legal advisers; or</li>
                          <li>is necessary for a Party to disclose in order reasonably to perform the Services which includes disclosure to Solution providers; or</li>
                          <li>is information that a Party independently developed; or</li>
                          <li>a Party received from a third party without restriction and without a breach of a duty of confidentiality.</li>
                        </ol>
                      </li>
                      <li>
                        ALKELIO may, once a User is registered as a Member on the Website, and agreed on the NDA, share all Confidential Information with the selected Solution provider as and when the User wishes to engage in discussions with a Solution Provider. Solution providers will be bound by appropriate confidentiality measures prior to receiving disclosure of any Confidential Information.
                      </li>
                      <li>
                        The provisions of this clause 8 shall be regarded as a stipulatio alteri in favour of a Solution provider as it pertains to the Confidential Information of a Solution Provider and may be enforced by a Solution Provider at any time at its election.</li>
                      <li>
                        The User agrees to keep confidential and not to disclose to any third party any methodology or technology ALKELIO uses to perform the Services
                      </li>
                      <li>
                        Any and all information (including Confidential Information and Personal Information) received from the User, shall be stored and processed by ALKELIO in Switzerland.
                      </li>
                    </ol>
                  </li>
                  <li id="intellectual-property">
                    INTELLECTUAL PROPERTY
                    <ol>
                      <li>ALKELIO shall at all times retain full ownership and all rights in respect of all Intellectual Property it owns and which relate to the Website and the ALKELIO business, methodologies, service providers and system.</li>
                      <li>Rights granted in terms of this Agreement does not grant either Party or any third party a license to use- or the assignment of any Intellectual Property. Such further use, assignment or transfer of ownership shall be the subject of a Contract between the User and a Solution provider.</li>
                      <li>Once registered in the platform, the User grants to ALKELIO the right to use the User’s name, trademarks, logo, biographical information, pictures, images and likeness in any part of the Website (including public and secure areas) and in correspondence with Solution providers, as well as in marketing and promotional correspondence and materials and for any purpose related to the provision of the Services.</li>
                    </ol>
                  </li>
                  <li id="warranties">
                    WARRANTIES
                    <ol>
                      <li>
                        The User warrants and undertakes that:
                        <ol>
                          <li>the User possesses all rights, consents and permissions to participate as a Member on the Website;</li>
                          <li>The User shall not submit any Personal Information to the Website in respect of which it does not have the right to submit. The User warrants that it shall only submit its own Personal Information or that of its personnel and confirms that it has the right and/or all necessary permissions to submit such Personal Information. The User further warrants that it has- or has procured the right for ALKELIO to process such Personal Information. In the clause, the word “process” shall be interpreted to include any action necessary to be taken by ALKELIO in order to provide the Services.</li>
                        </ol>
                      </li>
                      <li>The User indemnifies and holds ALKELIO harmless against all liability, claims, costs, expenses and penalties suffered or incurred by ALKELIO arising from any breach by the User of the warranties set out in this clause.</li>
                      <li>ALKELIO makes no representations whatsoever and gives no guarantee whatsoever in relation to any submission or Solution submitted or placed on the ALKELIO Website. </li>
                      <li>All representations, conditions and warranties whatsoever, whether implied or otherwise, are hereby expressly excluded.</li>
                      <li>ALKELIO does not warrant that the User’s participation on the Website shall result in the conclusion of any Contracts. ALKELIO expressly disclaims and excludes all implied or other warranties relating to the submission of Solutions by Solution providers and the participation by the User on the Website.</li>
                      <li>The entire risk as to the performance, competence, viability, merchantability or any aspect of Solutions is assumed by the Solution provider and will be the subject of a Contract between the User and the relevant Solution provider.</li>
                      <li>Except as expressly set out in this Agreement, ALKELIO makes no other representations, extends no warranties, express or implied, and assumes no liabilities or responsibilities with respect to any aspect or facility provided by or relating to the Website.</li>
                    </ol>
                  </li>
                  <li id="liability-indemity">
                    LIABILITY AND INDEMNITY<br/>
                    Without derogating from the generality of any of the provisions hereof, ALKELIO shall not at any time be liable for any claims of whatsoever nature and howsoever arising for direct, indirect or consequential loss or damage which may be suffered by the User or any other third party in connection with the Service provided by ALKELIO, the Users participation on the Website or any resultant Contracts, including loss of profit or loss of business and whether the User has been informed of such possibility or not and the User hereby indemnifies ALKELIO against all such claims, loss or damage.
                  </li>
                  <li id="termination">
                    TERMINATION
                    <ol>
                      <li>ALKELIO may terminate or suspend this Agreement on notice to the User without cause and at any time in ALKELIO’s sole and absolute discretion.</li>
                      <li>The User may terminate this Agreement on 60 (sixty) days’ written notice to ALKELIO.</li>
                      <li>If either Party terminates or suspends the Agreement, both Parties will keep any rights that it may have at time of termination.</li>
                      <li>If either party materially breaches any term or condition of the Agreement, the other Party may cancel the Agreement in the manner allowed by the Law.</li>
                      <li>When the Agreement is terminated or cancelled either Party must return any property belonging to the other that is in its respective possession, if the other Party asks for it.</li>
                      <li>The termination of the Agreement will not affect any provision of the Agreement that needs to have effect after the end of the Agreement, even if this is not expressly stated in the provision.</li>
                    </ol>
                  </li>
                  <li id="governing">
                    GOVERNING LAW AND DISPUTES<br/>
                    <ol>
                      <li>This Agreement will be governed and construed in accordance with the Law.</li>
                      <li>
                        Any dispute arising between the Parties from this Agreement, including its existence, enforceability, validity, interpretation or termination, the Parties endeavour to settle such dispute in the following manner:
                        <ol>
                          <li>The Parties shall, upon a dispute arising, endeavour to settle such dispute amicably by discussion between a nominated person of each Party. The attempt to bring about an amicable settlement is considered to have failed as soon as one of the Parties hereto gives a notice thereof to the other Party in writing, provided that the Parties have attempted to resolve the dispute for a period of at least 30 (thirty) days.</li>
                          <li>If an attempt to resolve a dispute amicably by discussion between the Parties has failed, the dispute shall be referred to and finally resolved by arbitration under the rules of arbitration in Geneva Switzerland</li>
                          <li>The costs of the arbitrator shall be borne by the unsuccessful Party or as the arbitrator otherwise directs.</li>
                          <li>Nothing in this clause 13 shall prevent either Party from having recourse to a court of competent jurisdiction for the sole purpose of seeking a preliminary injunction or such other provisional relief as it considers necessary to avoid irreparable damage.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li id="domicilium">
                    DOMICILIUM
                    <ol>
                      <li>The User chooses its domicilium address details as those which have been submitted to the Website or as otherwise notified to ALKELIO in writing from time to time.</li>
                      <li>The Domicilium address of ALKELIO is as follows: Rte de Frontenex 62, 1207 Geneva, Switzerland</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsConditions;