import { useSelector, useDispatch } from 'react-redux';
import { dismissToast } from 'features/toasts/toastsSlice';
import { appReducerStateType } from '../../store';
import './assets/styles/toasts.scss';

interface ToastProps {
  active: boolean;
  title: string;
  time: Date | null;
  body: string;
  image: string | null;
}

const Toast = ({active, title, time, body, image}: ToastProps) => {
  const dispatch = useDispatch()
  return (
    <div className={`toast ${active ? 'show fade-in': 'hide fade-out'}`} role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-header">
        {image !== null && <img src={image} className="rounded me-2" alt={title} />}
        <strong className="me-auto">{title}</strong>
        {time !== null && <small>{time}</small>}
        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" 
          onClick={() => {
            dispatch(dismissToast())
          }}></button>
      </div>
      {body && <div className="toast-body">
        {body}
      </div>}
    </div>
  )
}

const ToastsNotifications = () => {
  const { active, title, body, time, image } = useSelector((state: appReducerStateType) => state.toasts)
  return (
    <div className="toasts-container">
      <Toast {...{active, title, body, time, image}} />
    </div>
  )
}

export default ToastsNotifications;