import ButtonFavourite from "../button-favourite";
import { useFindFavouriteQuery, useSetBookmarkMutation, useUnsetBookmarkMutation } from 'services/graphql/bookmarks'
import { Enum_Bookmark_Type, Enum_Bookmark_Category } from 'generated/graphql'
import { selectHeaderProfile } from 'features/header-profile/headerProfileSlice'
import { useSelector } from 'react-redux';

export interface FavouriteWidgetProps {
  itemId: string;
  type: Enum_Bookmark_Type;
}

export const FavouriteWidget = ({
  itemId, 
  type
}: FavouriteWidgetProps) => {
  const [ setBookMark ] = useSetBookmarkMutation()
  const [ unsetBookmark ] = useUnsetBookmarkMutation()

  const headerProfile = useSelector(selectHeaderProfile)
  const { data, isLoading, isSuccess } = useFindFavouriteQuery({
    personProfile: headerProfile?.id,
    itemId,
    type,
  })

  console.log('bookmark', data)
  
  const bookmarked = data !== null;

  return (
    <>
      {isSuccess && <ButtonFavourite
        mode={data?.category ?? null}
        defaultValue={bookmarked}
        onClick={() => {
          if (bookmarked) {
            data && unsetBookmark(data?.id)
          } else {
            setBookMark({
              type,
              item_id: Number(itemId),
              category: Enum_Bookmark_Category.Save,
              person_profile: headerProfile.id?? ''
            })
          }
        }} 
      />}
    </>
  );
}