import React, { useState } from 'react';
import './assets/styles/tile.scss';
import CaretRightIcon from './assets/svgs/caret-right.svg';

type TileProps = {
  children?: Array<React.ReactNode> | React.ReactNode;
  mode?: string;
  className?: string;
  title?: string;
  subHeading?: string;
  carouselHeight?: string;
  actionComponents?: Array<React.ReactNode>;
  onChange?: (index: number) => unknown;
  defaultIndex?: number;
}

const Tile = ({
  mode, 
  carouselHeight, 
  title, 
  subHeading,
  children, 
  actionComponents, 
  onChange, 
  defaultIndex,
  className
}: TileProps) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex?? 0);
  const isCarousel = (mode === 'carousel');

  const totalItems = children && Array.isArray(children) ? children.length : 0;

  const incrementPage = () => {
    if (currentIndex + 1 < totalItems) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      onChange && onChange(newIndex);
    }
  }
  
  const decrementPage = () => {
    if (currentIndex - 1 >= 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex)
      onChange && onChange(newIndex);
    }
  }

  return (
    <div className={`tile ${className ?? ''} ${isCarousel ? 'is-carousel':''}`}>
      {isCarousel && currentIndex > 0 && 
      <div className="block left" onClick={decrementPage}>
        <img className="rotate-180" src={CaretRightIcon} alt="previous" />
      </div>}
      <div className="inner">
        {title && <div className="row header">
          {title && <h4>{title}</h4>}
          {subHeading && <p>{subHeading}</p>}
          {actionComponents && <div className="actions">
            {actionComponents}
          </div>}
        </div>}
        <div className={`body ${isCarousel ? 'carousel':''}`}>
          <div className={`${isCarousel ? 'carousel-scrollable':'body-inner'}`}>
            {isCarousel && children && Array.isArray(children) && children[currentIndex]}
            {!isCarousel && children}
          </div>
        </div>
        {isCarousel && (totalItems > 1) && <div className="pagination">
          {children && Array.isArray(children) && children.map((elem, i) => (
            <button key={i} className={`point ${currentIndex === i && 'active'}`}></button>
          ))}
        </div>}
        <div className="row footer">
          {actionComponents && <div className="actions">
            {actionComponents}
          </div>}
        </div>
      </div>
      {isCarousel && totalItems > 0 && ((currentIndex + 1) < totalItems) && <div className="right" onClick={incrementPage}>
        <img src={CaretRightIcon} alt="next" />
      </div>}
    </div>
  )
}

export default Tile;