import React from 'react'
import { Inputs, Info } from './inputs'
import CircleOPlusBlueIcon from 'assets/svgs/large-circle-o-plus.svg';
import CircleOClose from 'assets/svgs/circle-o-close.svg';
import Button from 'app/shared/button'
import './assets/styles/info.scss'
import InputFiles from 'shared/forms/input-files'
import { FileItem } from 'hooks/file-uploads'
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';

import {
  Controller,
  Control,
  UseFormRegister
} from 'react-hook-form'

type Props = {
  register: UseFormRegister<Inputs>;
  control: Control<Inputs>;
  fields: Array<Info>;
  append: (item: Info) => unknown;
  remove: (index: number) => unknown;
}

const ScopeInfo = ({
  register,
  control,
  fields,
  append,
  remove
}: Props) => {
  const user = useSelector(selectCurrentUser);

  return (
    <div className="scope__info">
      <div className="d-flex flex-row mb-4">
        <h4 className="">Information needed for the project</h4>
        {user?.role?.type === 'catalyst' ? 
        <Button
          className="ms-auto button-add-info"
          variant="link icon" 
          onClick={() => {
            append({
              question_from_catalyst: '',
              response: '',
              attachments: []
            })
          }}
        >
          <div className="d-flex align-items-center">
            <img src={CircleOPlusBlueIcon} alt="add" />
            <span className="color-azure font-size-14 bold-type ms-2">Add Another</span>
          </div>
        </Button> : null}
      </div>
      {(fields.length > 0 ? fields : [{ 
        question_from_catalyst: '', 
        response:'', 
        attachments:[]
      }]).map((item, i) => (
        <div className="d-flex flex-row mt-2 mb-2 w-100">
          <div className="question-response">
            <div className="mb-4">
              {user?.role?.type === 'catalyst' ? <textarea
                rows={6}
                className="mb-3 mb-md-0 w-100"
                placeholder="Catalyst question"
                defaultValue={item.question_from_catalyst || ''}
                {...register(`info.${i}.question_from_catalyst`)} >
              </textarea> : 
              <div>{item.question_from_catalyst}</div>}
            </div>
            <div className="mb-2">
              {user?.role?.type !== 'catalyst' ? <textarea
                rows={6}
                placeholder="Response"
                className="mb-3 mb-md-0 w-100"
                defaultValue={item.response || ''}
                {...register(`info.${i}.response`)} >
              </textarea> : 
              <div>{item.response}</div>}
            </div>
            {user?.role?.type !== 'catalyst' ? 
            <div className="">
              <Controller
                name={`info.${i}.attachments`}
                control={control}
                defaultValue={(item.attachments ?? []) as Array<FileItem>}
                render={({field}) => <InputFiles maxFileCount={1} {...field} />}
              />
            </div> : null}
          </div>
          {i >  0 ? 
          <Button 
            variant="link icon" 
            className="remove"
            onClick={() => {
              remove(i);
            }} 
          >
            <img src={CircleOClose} alt="remove"/>
          </Button>
          : null}
        </div>
      ))}
    </div>
  )
}

export default ScopeInfo