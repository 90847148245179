import InputRadio from './input-radio';
import './assets/styles/input-radio-group.scss';
import BaseInput from './base-input';
import React, { useState, useEffect } from 'react';

interface RadioItem {
  label: string;
  value?: string | boolean;
}

interface InputRadioGroupProps extends BaseInput {
  options: Array<RadioItem> | [];
  className?: string;
  name: string;
}

const InputRadioGroup = React.forwardRef(({
  options, 
  className,
  name,
  value,
  defaultValue,
  onChange,
}: InputRadioGroupProps,
ref: React.Ref<HTMLDivElement>) => {
  const [ selected, setSelected ] = useState<string>(defaultValue || value || '')

  useEffect(() => {
    setSelected(defaultValue || value)
  }, [defaultValue, value])

  return (
    <div className={`input-radio-group ${className || ''}`}>
      {options.map((item, i)=>(
        <InputRadio key={i} 
          onChange={(checked) => {
            onChange && checked && onChange(item.value)
          }}
          className="mb-2 me-2"
          defaultChecked={item.value as string === selected as string}
          label={item.label} 
          name={name}
          value={item.value as string} />
      ))}
    </div>
  )
})

export default InputRadioGroup;