import { useEffect, useState } from 'react'
import OptionButtonsTick from 'shared/forms/option-buttons-tick';
import useSignUpType from '../hooks/sign-up.hook';
import { SignUpTypes } from '../types';
import './assets/styles/select-sign-up-as.scss';
import FormLayout from './form-layout'
import useOnboarding from '../hooks/onboarding.hook';
import ActionBar from '../action-bar/action-bar';
import { getSelectedOptions, StartupOptions, CorporateOptions } from '../utils/utils'

const SelectSignUpAs = () => {
  const { setOnboardingPayload, onboardingPayload } = useOnboarding()
  const { signUpType, setSignUpType } = useSignUpType()
  const [ selectedOptions, setSelectedOptions ] = useState<Record<string, boolean>>({})

  const setOptions = async (options: string[]) => {
    const myRequests: Record<string, boolean> = {}
    for(let opt of options) {
      myRequests[opt] = true
    }
    setSelectedOptions(myRequests) 
  }

  const setSignUpTypeAs = (evt: React.FormEvent<HTMLInputElement>) => {
    if (evt.currentTarget.value !== signUpType) {
      setSelectedOptions({})
    }
    if (evt.currentTarget.value === SignUpTypes.CORPORATE) {
      setSignUpType(SignUpTypes.CORPORATE)
    } else 
    if (evt.currentTarget.value === SignUpTypes.STARTUP) {
      setSignUpType(SignUpTypes.STARTUP)
    }
  }

  let myRequestOptions: {
    value: string;
    label: string;
  }[] = [];
  if (signUpType === SignUpTypes.STARTUP) {
    myRequestOptions = StartupOptions
  } else
  if (signUpType === SignUpTypes.CORPORATE) {
    myRequestOptions = CorporateOptions
  }

  const handleNext = async (nextStep: () => unknown) => {
    await setOnboardingPayload({
      type: signUpType,
      step: 2,
      requests: selectedOptions
    })

    nextStep()
  }
  
  useEffect(() => {
    // default to startup at the beginning if nothing
    if (!onboardingPayload) {
      setSignUpType(SignUpTypes.STARTUP)
    }
  }, [])

  useEffect(() => {
    if (onboardingPayload) {
      setSelectedOptions(onboardingPayload.requests)
    }
  }, [onboardingPayload])

  const subHeading = window.screen.width > 767 ? 'Get Started' : ''

  return (
    <FormLayout 
      subHeading={subHeading} 
      heading="Sign up today"
      formClassName="sign-up-as"
      description="Tell us who you are and what you are looking for so we can qualify your request and create your personal space. Welcome On Board!"
      >
      <>
        <div className="row">
          <div className="col mt-3 mt-md-5 mb-2 mb-md-1">
            <div className="color-text font-size-12 text-center text-md-start">SIGN ME UP AS A</div>
          </div>
        </div>
        <div className="row">
        <div className="col d-flex flex-row justify-content-between">
            <label className={`boxed-radio-input`}>
              <input type="radio" name="sign-up-type" 
                checked={signUpType === SignUpTypes.STARTUP} 
                value={SignUpTypes.STARTUP} 
                onChange={setSignUpTypeAs} />
              <div className="font-size-14 semi-bold-type">
              Startup
              </div>
            </label>
            <label className="boxed-radio-input">
              <input type="radio" name="sign-up-type" 
                checked={signUpType === SignUpTypes.CORPORATE} 
                value={SignUpTypes.CORPORATE} 
                onChange={setSignUpTypeAs} />
              <div className="font-size-14 semi-bold-type">
              Corporate
              </div>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col mt-5 mb-2 mb-md-1">
            <div className="color-text font-size-12 text-uppercase text-center text-md-start">i want to</div>
          </div>
        </div>
        <div className="row">
          <div className="col options">
            <OptionButtonsTick 
              addClassName='mobile-azure'
              options={myRequestOptions} 
              selectedOptions={getSelectedOptions(selectedOptions, signUpType)}
              onSelect={(selected) => {
                setOptions(selected);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ActionBar onNext={handleNext} />
          </div>
        </div>
      </>
    </FormLayout>
  )
}

export default SelectSignUpAs;