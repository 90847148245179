import { AdhocServicesStartupList } from 'app-constants';
import ServiceCard from 'shared/service-card';
import CalendarPlusIcon from './assets/svgs/calendar-plus.svg';
import PhoneIcon from './assets/svgs/phone.svg';
import EmailIcon from './assets/svgs/email.svg';
import ControlBar from './control-bar';
import CallToAction from './call-to-action';
import './assets/styles/service-card.scss';

const AdhocServiceStartup = () => {
  return (
    <div className="service-cards-container">
      <h4 className="mb-5 ms-3">ALKELIO+ Startup AdHOC Services</h4>
      <div className="flex-wrap d-flex flex-column flex-md-row justify-content-between">
        {AdhocServicesStartupList.map((item, i) => (
          <ServiceCard {...item} key={i} />
        ))}
      </div>
      <CallToAction />
      <ControlBar />
    </div>
  )
}

export default AdhocServiceStartup;