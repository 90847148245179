import React from 'react';
import InputFiles from 'shared/forms/input-files';
import InputMoney from 'shared/forms/input-money';
import SelectOne from 'shared/forms/select-one';
import { FileItem } from 'hooks/file-uploads';
import EyeCloseIcon from 'app/shared/assets/svgs/eye-close.svg';
import Inputs from './inputs';
import { 
  Control, 
  UseFormRegister, 
  FieldError, 
  Controller,
  DeepMap
} from 'react-hook-form'; 
import CurrencyCode from 'currency-codes';
import { CurrenciesPreferred } from 'app-constants';

const Currencies = CurrencyCode.codes();

type InvestInformationProps = {
  documents_business_plans?: Array<FileItem> | undefined;
  documents_investor_pitch_deck?: Array<FileItem> | undefined;
  amount_to_raise?: number | undefined;
  amount_to_raise_currency?: string | undefined;
}

interface InvestProps {
  invest_information?: InvestInformationProps | null;
  control: Control<Inputs>;
  register: UseFormRegister<Inputs>;
  errors: (DeepMap<{
    documents_business_plans?: Array<FileItem> | undefined;
    documents_investor_pitch_deck?: Array<FileItem> | undefined;
    amount_to_raise?: number | undefined;
    amount_to_raise_currency?: string | undefined;
  }, FieldError>) | undefined;
}

const Invest = React.forwardRef(({
  invest_information,
  control,
  register,
  errors
}: InvestProps,
ref: React.ForwardedRef<HTMLDivElement>) => {

  return (
    <div className="row p-0 mb-0">
      <div className="col p-0">
        <div className="form-control-group">
          <div className="mb-3 row p-0">
            <div className="col p-0">
              <label>
                <div className="bold">Invest Information</div>
              </label>
            </div>
          </div>
          <div className="flex-row row mb-0 p-0 d-flex align-items-stretch">
            <div className="col-12 col-md-6 p-0 pe-lg-5">
              <div className="mt-2 form-control">
                <label>
                  <div className="mb-2 text">business plan & financials </div>
                  <Controller
                    name="invest_information.documents_business_plans"
                    control={control}
                    defaultValue={(invest_information?.documents_business_plans || []) as Array<FileItem>}
                    render={({field}) => <InputFiles maxFileCount={5} {...field} />}
                  />
                  
                </label>
              </div>
              <div className="form-control">
                <label>
                  <div className="mb-2 text">How much are we wanting to raise</div>
                </label>
                <div className="input-group seamless">
                  <Controller
                    name="invest_information.amount_to_raise"
                    defaultValue={invest_information?.amount_to_raise || 0}
                    control={control}
                    render={({field}) => <InputMoney {...field} />}
                  />
                  <Controller 
                    name={`invest_information.amount_to_raise_currency`}
                    control={control}
                    render={({field}) => <SelectOne 
                      orderFirst={CurrenciesPreferred}
                      options={Currencies} style={{flex: "0 0 80px"}} 
                      {...field} />}
                  />
                </div>
              </div>
              <div className="flex-row d-flex mt-4 mt-md-0">
                <img className="me-2" src={EyeCloseIcon} alt="" />
                <div className="font-size-md-10">Will not be disclosed until you agree to talk to them</div>
              </div>
            </div>
            <div className="col-12 p-0 col-md-6 ps-lg-5 mt-4 mt-md-0">
              <div className="mt-2 form-control">
                <label>
                  <div className="mb-2 text">Investors' pitch deck </div>
                  <Controller
                    name="invest_information.documents_investor_pitch_deck"
                    control={control}
                    defaultValue={(invest_information?.documents_investor_pitch_deck || []) as Array<FileItem>}
                    render={({field}) => <InputFiles maxFileCount={5} {...field} /> }
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Invest;