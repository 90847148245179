import './assets/styles/news.scss'
import Tile from 'app/shared/tile';
import { Link } from 'react-router-dom';
import CaretRightIcon from 'app/shared/assets/svgs/caret-right.svg';
import Button from 'app/shared/button';
import { useGetLatestNewsQuery } from 'services/graphql/news';
import { Image } from 'shared/image'
import { 
  useHistory,
  useParams 
} from 'react-router-dom'

type ArticleShortProps = {
  title: string;
  body: string;
  url: string;
  imageSrc: string;
}

const ArticleShort = ({
  title, body, url, imageSrc
}: ArticleShortProps) => {
  const { push } = useHistory()
  return (
    <div className="article-short">
      <div className="inner" onClick={() => {
        push(url)
      }}>
        <div className="image">
          <Image src={imageSrc} alt={title} />
        </div>
        <div className="p-3 body d-flex flex-column">
          <div className="title">
            <h5>{title}</h5>
          </div>
          <div className="content">{body}</div>
        </div>
      </div>
      <div className="more">
        <Link to={url}>
          <img src={CaretRightIcon} alt="Read more" />
        </Link>
      </div>
    </div>
  )
}

const News = () => {
  const { push } = useHistory();
  const { data, isFetching, isLoading } = useGetLatestNewsQuery(3)
  const { entityType }: any = useParams();
  const articles: Array<React.ReactNode> = data ? data.map((item, i) => (
    <ArticleShort 
      key={i}
      title={item.headline ?? ''}
      body={item.summary ?? ''}
      url={`/app/${entityType}/news/${item.id}`}
      imageSrc={`${item.main_image?.formats['thumbnail'] ? item.main_image?.formats['thumbnail'].url : ''}`}
    />
  )) : []

  return (
    <Tile 
      title="Latest News"
      actionComponents={[
        <Button key={1} onClick={() => {
          push(`/app/${entityType}/news`)
        }}>All News</Button>
      ]}
    >
      <div className="news">
        {articles}
      </div>
    </Tile>
  )
}

export default News;