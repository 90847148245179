import './assets/styles/pill.scss';

interface PillProps {
  label?: string;
  icon?: string;
  variant?: string;
  iconPosition?:string;
}

const Pill = ({ 
  label, 
  icon,
  variant = "",
  iconPosition =""
}: PillProps) => {
  return (
    <div className={`pill ${variant}`}>
      {icon && iconPosition!=='left'? <span className="icon"><img src={icon} alt="" /></span>:<></>}
      {label && <span className="label">{label}</span>}
      {icon && iconPosition==='left'? <span className="icon"><img src={icon} alt="" /></span>:<></>}
    </div>
  )
}

export default Pill;