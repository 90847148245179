import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  CreateOnBoardingSessionInput,
  OnBoardingSession,
  UpdateOnboardingSessionByUuidInput,
  CompleteOnboardingSessionByUuidPayload,
  UseVerificationPayload,
  TryGetNewOnboardingSessionPayload
} from 'generated/graphql'

export type UseVerificationInput = {
  code: string;
  uuid: string;
}

export const onBoardingSessionApi = createApi({
  reducerPath: "onBoardingSessionApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    createOboardingSession: builder.mutation<OnBoardingSession, CreateOnBoardingSessionInput>({
      query: (data) => ({
        document: gql`
          mutation createOboardingSession($data: createOnBoardingSessionInput) {
            createOnBoardingSession(input: $data ) {
              onBoardingSession {
                uuid
              }
            }
          }
        `,
        variables: {
          data
        }
      }),
      transformResponse: (response:any) => response.createOnBoardingSession.onBoardingSession,
    }),
    getOnboardingSession: builder.query<OnBoardingSession, string>({
      query: (uuid) => ({
        document: gql`
          query findOnboardingSessionByUUID($uuid: String!) {
            findOnboardingSessionByUUID(uuid: $uuid) {
              payload
            }
          }
        `,
        variables: {
          uuid
        }
      }),
      transformResponse: (response:any) => response.findOnboardingSessionByUUID,
    }),
    updateOnboardingSession: builder.mutation<OnBoardingSession, UpdateOnboardingSessionByUuidInput>({
      query: ({uuid, payload}) => ({
        document: gql`
          mutation updateOnboardingSessionByUUID($uuid: String!, $payload: JSON!) {
            updateOnboardingSessionByUUID(input: {
              uuid: $uuid,
              payload: $payload
            }) {
              payload
            }
          }
        `,
        variables: {
          uuid,
          payload
        }
      })
    }),
    completeOnboardingSession: builder.mutation<CompleteOnboardingSessionByUuidPayload, string>({
      query: (uuid) => ({
        document: gql`
          mutation completeOnboardingSessionByUUID($uuid: String!) {
            completeOnboardingSessionByUUID(uuid: $uuid) {
              uuid
            }
          }
        `,
        variables: {
          uuid
        }
      }),
      transformResponse: (response:any) => response.completeOnboardingSessionByUUID,
    }),
    useVerificationCode: builder.mutation<UseVerificationPayload, UseVerificationInput>({
      query: ({code, uuid}) => ({
        document: gql`
          mutation useVerificationCode($code: String!, $uuid: String!) {
            useVerificationCode(code: $code, uuid: $uuid) {
              jwt
            }
          }
        `,
        variables: {
          code,
          uuid,
        },
      }),
      transformResponse: (response:any) => response.useVerificationCode,
    }),
    regenerateVerificationCode: builder.mutation<void, string>({
      query: (uuid) => ({
        document: gql`
          mutation regenerateVerification($uuid: String!) {
            regenerateVerificationCode(uuid: $uuid)
          }
        `,
        variables: {
          uuid,
        },
      })
    }),
    setPassword: builder.mutation<void, string>({
      query: (password) => ({
        document: gql`
          mutation setPassword($password: String!) {
            setPassword(password: $password)
          }
        `,
        variables: {
          password,
        },
      }),
    }),
    tryGetNew: builder.query<TryGetNewOnboardingSessionPayload, string>({
      query: (uuid) => ({
        document: gql`
          query tryGetNewOnboardingSession($uuid: String!) {
            tryGetNewOnboardingSession(uuid: $uuid) {
              uuid
              payload
            }
          }
        `,
        variables: {
          uuid
        }
      }),
      transformResponse: (response: any) => response.tryGetNewOnboardingSession
    })
  }),
})

export const { 
  useTryGetNewQuery,
  useLazyTryGetNewQuery,
  useCreateOboardingSessionMutation,
  useGetOnboardingSessionQuery,
  useLazyGetOnboardingSessionQuery,
  useUpdateOnboardingSessionMutation,
  useCompleteOnboardingSessionMutation,
  useUseVerificationCodeMutation,
  useRegenerateVerificationCodeMutation,
  useSetPasswordMutation
} = onBoardingSessionApi