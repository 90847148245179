import './assets/styles/help.scss';
import SendEmailIcon from './assets/svgs/send-email.svg';
import ScheduleDate from './assets/svgs/schedule-date.svg';
import CallUsIcon from './assets/svgs/call-us.svg';

interface LinkProps {
  icon: string;
  label: string;
  href?: string;
}

const Links: Array<LinkProps> = [
  {
    icon: ScheduleDate,
    label: 'Book a meeting',
    href: 'https://calendly.com/alkelio-team/meeting-with-alkelio-team'
  },
  {
    icon: SendEmailIcon,
    label: 'Email us',
    href: 'mailto:contact@alkelio.com'
  }
]

const Help = () => {

  const links = Links.map((item, i) => (
    <a className="link" key={i} href={item.href} target="_blank" rel="noreferrer">
      <img src={item.icon} alt={item.label}/>
      <span>{item.label}</span>
    </a>
  ));

  return (
    <div className="help">
      <h6>Have questions or need help?</h6>
      <div className="links">
        {links}
      </div>
    </div>
  )
}

export default Help;