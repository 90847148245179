import React, { useEffect } from 'react'
import BottomNav from './bottom-nav'
import './assets/styles/structure.form.scss'
import CircleOPlusBlueIcon from 'app/shared/assets/svgs/circle-o-plus-small-blue.svg';
import CircleOClose from 'assets/svgs/circle-o-close.svg';
import Button from 'app/shared/button';

import { 
  useForm, 
  SubmitHandler,
  useFieldArray
} from "react-hook-form";
import {
  useHistory,
  useRouteMatch
} from 'react-router-dom'

import { 
  useGetOneServiceCollaborationQuery,
  useUpdateMyServiceCollaborationMutation
} from 'services/graphql/service-collaboration'
import { selectCurrentUser } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';

type Props = {
  id: string
}

type StructureInput = {
  id?: string | null;
  content?: string | null
}

type Inputs = {
  structure: Array<StructureInput>
}

const StructureForm = ({
  id
}: Props) => {
  const user = useSelector(selectCurrentUser);
  const { push } = useHistory()
  const { url } = useRouteMatch();
  const { data, isLoading, isSuccess } = useGetOneServiceCollaborationQuery(id)
  const [update, updateResult] = useUpdateMyServiceCollaborationMutation()

  const isCatalyst = user?.role?.type === 'catalyst'
  const isFormDisabled = !isCatalyst

  const { 
    register, 
    reset, 
    handleSubmit, 
    watch,
    control,
    getValues,
    formState: { errors } 
  } = useForm<Inputs>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "structure"
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (!isFormDisabled)
      update({
        id,
        structure: data.structure
      })
  }

  const handleNext = () => {
    if (!isFormDisabled) {
      const structure = getValues('structure')
      update({
        id,
        structure
      })
    } else {
      push(`${url}/scope`)
    }
  }

  useEffect(() => {
    if (isSuccess && data && data.structure) {
      reset({
        structure: data?.structure as Array<StructureInput>
      })
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (updateResult.isSuccess) {
      push(`${url}/scope`)
    }
  }, [updateResult])

  useEffect(() => {
    if (!isFormDisabled) {
      return () => {
        const data = getValues()
        
        update({
          id,
          structure: data.structure
        })
        
      }
    }
  }, [])

  return (
    <div className="main__structure-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
            {isSuccess && data ?
            <div className="form-control-group">
              <div className="form-control">
                <label>
                  <div className="field-header">
                    Our methodology - How does it work
                  </div>
                </label>
                {(fields.length > 0 ? fields : [{content:''}]).map((item, i) => (
                  <div className="d-flex flex-row mt-2 mb-2 w-100">
                    <textarea
                      rows={6}
                      disabled={isFormDisabled}
                      className="mb-3 mb-md-0 w-100"
                      defaultValue={item.content || ''}
                      {...register(`structure.${i}.content`)} >
                    </textarea>
                    {i >  0 ? 
                    <>{!isFormDisabled ? <Button 
                      variant="link icon" 
                      className="remove"
                      onClick={() => {
                        remove(i);
                      }} 
                    >
                      <img src={CircleOClose} alt="remove"/>
                    </Button> : null}
                    </>
                    : null}
                  </div>
                ))}
                <>
                {!isFormDisabled ? <Button
                  className="ms-1"
                  variant="link icon" 
                  onClick={() => {
                    append({
                      content: ''
                    })
                  }}

                >
                  <div className="d-flex align-items-center mt-4">
                    <img src={CircleOPlusBlueIcon} alt="add" />
                    <span className="color-azure font-size-14 bold-type ms-2">Add Another</span>
                  </div>
                </Button> : null}
                </>
              </div>
            </div>
            : null}
        </div>
        <div className="bottom">
          <BottomNav onNext={handleNext}/>
        </div>
      </form>
    </div>
  )
}

export default StructureForm