import React, { useState } from 'react'
import './assets/styles/chat.scss'

import ChatWidget from 'app/chat/chat-widget';
import { Enum_Chat_Category } from 'generated/graphql';

type Props = {
  id: string;
}

const Chat = ({
  id
}:Props) => {
  return (
    <div className="left__chat">
      <ChatWidget 
        referenceId={id} 
        category={Enum_Chat_Category.ServiceCollaboration} />
    </div>
  )
}

export default Chat