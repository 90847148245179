import './assets/styles/confirm.scss'
import Button from 'app/shared/button'

interface ConfirmProps {
  onConfirm: () => unknown;
  onCancel: () => unknown;
  body: string;
}

const Confirm = ({
  onConfirm,
  onCancel,
  body,
}: ConfirmProps) => {
  return (
    <div className="confirm">
      <div className="mb-4">
        {body}
      </div>
      <div className="flex-row d-flex justify-content-between">
        <Button onClick={onConfirm} variant="danger">Confirm</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  )
}

export default Confirm;