import { useMyHeaderProfileQuery } from 'services/graphql/profile';
import UserProfileIcon from 'app/shared/assets/pngs/user-profile.png';
import './assets/styles/profile.scss';
import Dropdown from 'app/shared/dropdown';
import { DropdownItem } from 'app/shared/dropdown';
import SquareEditIcon from 'app/shared/assets/svgs/square-edit.svg';
import CogIcon from 'app/shared/assets/svgs/cog.svg';
import SquareArrowRightIcon from 'app/shared/assets/svgs/square-arrow-right.svg';
import { useParams } from 'react-router-dom';
import { Image } from 'shared/image';
import { PersonProfile } from 'generated/graphql';

const Profile = () => {
  const { entityType }: any = useParams();
  
  const { data, isSuccess } = useMyHeaderProfileQuery();

  const imageUrl = (person?: PersonProfile | null) => person ? (person.photo !== null && person.photo?.formats['thumbnail'] 
    ? person.photo?.formats['thumbnail'].url : UserProfileIcon) : null
  
  const profileDropdownItems: Array<DropdownItem> = [
    {
      label: "Edit Profile",
      icon: SquareEditIcon,
      to: `/app/${entityType}/profile`
    },
    {
      label: "Settings",
      icon: CogIcon,
      to: `/app/${entityType}/settings`
    },
    {
      label: "Logout",
      icon: SquareArrowRightIcon,
      to: "/logout"
    }
  ]

  return (
    <Dropdown className="profile-dropdown" items={profileDropdownItems}>
      <div className="profile">
        {isSuccess &&
        <>
          <div className="avatar">
            <Image src={`${imageUrl(data?.person)}`} alt="avatar" />
          </div>
          <span className="name">{data?.person?.first_name}</span>
        </>
        }
      </div>
    </Dropdown>
    
  )
}

export default Profile;