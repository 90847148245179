import './assets/styles/logos.scss';

const Logos = () => {
  return (
    <div className="logo-row">
      <div className="pt-xl-5 logos">
        <div className="part">
          <img src="/imgs/logos/nestle.png" alt="Nestle" className="" />
          <img src="/imgs/logos/ferring.png" alt="Ferring Pharmeceuticals" />
          <img src="/imgs/logos/chanel.png" alt="Chanel" />
          <img src="/imgs/logos/la-mobile.png" alt="La Mobiliere" />
        </div>
        <div className="part">
          <img src="/imgs/logos/pg.png" alt="P&amp;G" />
          <img src="/imgs/logos/mcdonalds.png" alt="Mcdonalds" />
          <img src="/imgs/logos/svgroup.png" alt="SV Group" />
          <img src="/imgs/logos/firmenich.png" alt="Firmenich" />
        </div>
      </div>
    </div>
  );
}

export default Logos;