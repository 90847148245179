import { useGetRatingQuery } from 'services/graphql/ratings'
import Ratings from '../ratings';

type Props = {
  itemId: string;
}

export const ItemRating = ({
  itemId
}: Props) => {
  const { data, isSuccess } = useGetRatingQuery(itemId); 
  
  return isSuccess 
    ? <Ratings 
        rating={data?.aggregate?.avg?.value?? 0}
        ratingCounts={data?.aggregate?.count?? 0}
      /> 
    : null
}