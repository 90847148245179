import ServiceCard from 'app/shared/service-card';
import Tile from 'app/shared/tile';
import CallToAction from 'home/adhoc-services/call-to-action';
import Button from 'app/shared/button';
import LongArrowRightWhite from 'app/shared/assets/svgs/long-arrow-right-white.svg';
import LongArrowLeftAzure from 'app/shared/assets/svgs/long-arrow-left-azure.svg';
import { useGetMyServicesQuery } from 'services/graphql/services'
import { useSelector } from 'react-redux'
import { currentCart, Enum_CartItem_Type } from 'features/cart/cartSlice'
import { useHistory } from 'react-router-dom';
import './assets/styles/services.scss';

const AdhocServiceCorporate = () => {
  const { push, goBack } = useHistory()
  const cart = useSelector(currentCart);
  const { data, isSuccess } = useGetMyServicesQuery()

  const hasSelectedPackages = cart.items.find((item) => item.itemType === Enum_CartItem_Type.SERVICE_PACKAGE) !== undefined;

  return (
    <Tile 
      title="ALKELIO+ AdHOC Services"
      className="corporate-services"
      >
      <div className="p-0 container-fluid">
        <div className="row">
          <div className="p-0 col">
            {hasSelectedPackages && <>Do you require any extra services? Just select them and click next.</>}
            {!hasSelectedPackages && <>We provide extra services to help you along the way.  Just select the ones you need :)</>}
            <div className="flex-wrap p-0 d-flex justify-content-between ms-n-1 me-n-1">
              {isSuccess && (data?.services ?? []).map(({ 
                title, 
                description, 
                slug, 
                price,
                image
              }, i) => (
                <ServiceCard 
                  key={i}
                  title={title?? ''} 
                  description={description?? ''}
                  price={price?? 0}
                  currency={data?.currency}
                  slug={slug?? ''}
                  image={image?.url ?? ''}
                  selected={cart.items.find((cartItem) => cartItem.slug === slug) !== undefined}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="p-0 col">
            <CallToAction />
          </div>
        </div>
        <div className="row mb-0 mb-md-3">
          <div className="col action-row d-flex flex-row">
            <Button variant="" className="wide" onClick={() => {
              goBack()
            }}>
              <img src={LongArrowLeftAzure} alt="next" className='me-2' />
              Back
            </Button>

            <Button variant="primary" className="wide" onClick={() => {
              push('/app/corporate/payment')
            }}>
              Next
              <img src={LongArrowRightWhite} alt="next" className='ms-2' />
            </Button>
          </div>
        </div>
      </div>
    </Tile>
  )
}

export default AdhocServiceCorporate;