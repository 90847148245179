import BackgroundImage from './assets/imgs/background.png';
import './assets/styles/explore-help-banner.scss'; 
import Button from 'app/shared/button';
import PaperPlanIcon from 'app/shared/assets/svgs/paper-plane.svg';

const ExploreHelpBanner = () => {
  return (
    <div className="explore-help-banner">
      <div className="content">
        <div className="title">Not exactly what you’re looking for?</div>
        <div className="mt-4 text">Tell us what you are looking for and we will work hard to find it for you </div>
        <Button variant="primary icon mt-4" onClick={() => {
          window.location.href = "mailto:contact@alkelio.com?subject=Explore Help Needed";
        }}>
          <>
            <img src={PaperPlanIcon} alt="contact"/>
            <span className="d-none d-md-block">
              contact ALKELIO catalyst
            </span>
            <span className="d-block d-md-none">
              ALKELIO catalyst
            </span>
          </>
        </Button>
      </div>
      <div className="image">
        <img src={BackgroundImage} />
      </div>
    </div>
  )
}

export default ExploreHelpBanner;