import './assets/styles/signup-faq.scss';
import FAQs from './faqs';
import SignUpInline from './sign-up-inline';

const SignUpFaq = () => {
  return (
    <div className="container-fluid signup-faq pb-xl-5 pt-xl-5 pt-2 mt-4 mt-xl-0">
      <div className="container pb-4 pt-2 pb-xl-5 pt-xl-5">
        <div className="row">
          <div className="col-12 col-xl-8">
            <SignUpInline />
          </div>
          <div className="col-12 col-xl-4 ps-xl-5">
            <FAQs />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpFaq;