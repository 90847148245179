import './assets/styles/input-image.scss'
import React, { useState, useRef, useEffect } from 'react'
import BaseInput from './base-input'
import useFileUpload from 'hooks/file-uploads';
import Config from 'config';
import { FileItem } from 'hooks/file-uploads';
import Loader from 'shared/loader';

interface InputImageProps extends BaseInput{
  width? : string;
  height? :string;
  value?: FileItem | null;
  defaultValue?: FileItem | null;
  layout?: string;
}

const InputImage = React.forwardRef((
  props: InputImageProps, 
  ref: React.Ref<HTMLDivElement>
) => {
  const dropRef = useRef<HTMLLabelElement>(null)
  const initial = props.defaultValue !== null && props.defaultValue !== undefined ? 
  [props.defaultValue] : (props.value !== null && props.value !== undefined ? 
    [props.value] : [])
    
  const { uploads, onChange, onDrop, isLoading, isSuccess } = useFileUpload({
    name: props.name || '',
    max: 1,
    initial,
    onUploadsChange: (files) => {
      props.onChange && props.onChange(files[0]?? [])
    }
  })


  const getImage = () => {
    if (uploads.length === 0 && initial.length === 1) {
      let url = initial[0].url?? '';
      if (url.indexOf('http') === -1 && url !== '') {
        url = `${Config.storageBaseURL}${url}`
      }
      return url;
    } else
    if (uploads.length === 1) {
      let url = uploads[0].url?? '';
      if (url.indexOf('http') === -1 && url !== '') {
        url = `${Config.storageBaseURL}${url}`
      }
      return url;
    } else {
      return ""
    }
  }

  const imageEmpty = () => {
    return getImage() === undefined || getImage() === ''
  }

  return (
    <div className={`gray-image ${props.layout || ''}`} 
      ref={ref}
    >
     
      {isLoading && <div className="loading"><Loader /></div>}
      <div className="preview" 
        style={{
          width: props.width || '100%',
          height: props.height || '100%'
        }}>
        {!imageEmpty() && !isLoading && <img alt="preview" src={getImage()}/>}
      </div>
    </div>
  )
});

export default InputImage;