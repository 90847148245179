import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';

import CollabCard from './collab-card'
import Tile from 'app/shared/tile'
import Complete from './complete'

import ServiceCollaborationDetails from './conversation';

import { useGetServiceCollaborationsQuery } from 'services/graphql/service-collaboration'

const ServiceCollaborations = () => {
  let { path } = useRouteMatch();
  const { data, isLoading, isSuccess } = useGetServiceCollaborationsQuery()

  
  return (
    <Switch>
      <Route exact path={path}>
        <Tile 
          title="Conversations" >
          <div className="conversation-cards d-none d-md-block">
          {isSuccess && data !== undefined && data.map(({
            id,
            service,
            client
          }, i) => (
            <CollabCard {...{
              id,
              title: service?.title ?? '',
              imageUrl: client?.person_profile?.photo?.url ?? ''
            }} key={i} />
          ))}
          {isSuccess && data !== undefined && data.length === 0 && 
            <h6>You don't have any service collaborations at the moment</h6>
          }
          </div>
          <div className="d-block d-md-none">
            <h6>Please open your conversations on a desktop for an ideal experience</h6>
          </div>
        </Tile>
      </Route>

      <Route path={`${path}/complete`}>
        <Complete />
      </Route>

      <Route path={`${path}/:id`}>
        <ServiceCollaborationDetails />
      </Route>

    </Switch>
  )
}

export default ServiceCollaborations