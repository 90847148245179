import { useEffect, useState, createContext } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
  Redirect,
  useLocation
} from 'react-router-dom';
import Layout from 'app/shared/layout';
import EntityConstants from './entity-type/entityConstants';
import MenuStartupConstants from './startup/menuConstants';
import MenuCorpConstants from './corporate/menuConstants';
import MenuCatalystConstants from './catalyst/menuConstants';
import Dashboard from './dashboard';
import Explore from './explore';
import Matches from './matches';
import Collaborate from './collaborate';
import ServiceCollaborations from './service-collaborations';
import TechSolutionForm from './startup/tech-solution-form';
import TechRequestForm from './corporate/tech-request-form';
import Payment from './payment';
import CorporateProfile from './corporate/profile-form'
import StartupProfile from './startup/profile-form'
import Favourites from './favourites';
import Settings from './settings';
import CatalystConversations from './catalyst/conversations';
import { useMeQuery } from 'services/graphql/auth';
import { useDispatch } from 'react-redux';
import Session from 'session'
import { logout } from 'features/auth/authSlice';
import AdhocServiceCorporate from './corporate/services';
import AdhocServiceStartup from './startup/services';
import StartConversation from './start-conversation';
import { useGetApplicationConfigQuery } from 'services/graphql/single-types';
import GenericModal from 'shared/generic-modal';
import { EarlyAccess } from 'app/shared/early-access';
import { open } from 'features/modals/modalSlice';
import NewsDetail from './news/news.detail.component';
import AllNews from './news/allnews.component';
import OverlayProgress from 'app/shared/overlay-progress/overlay-progress';
import SocketProvider from 'app/providers/socket.provder';
import MenuProvider from 'app/providers/menu.provider';
import ServiceCollaborationDetails from './service-collaborations/conversation';
import AllDocuments from './documents/all-documents.component';

const Application = () => {
  useGetApplicationConfigQuery();
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { data, isLoading, isSuccess, isError } = useMeQuery()
  const { path, url } = useRouteMatch();
  const location = useLocation()
  const { entityType }: any = useParams();

  useEffect(() => {
    if (isError) {
      Session.clear()
      dispatch(logout())
      push('/login')
    } 

    console.log('application use me', path, url, location)

    if (isSuccess) {
      if (data && data.role) {
        // push('/app/' + data?.role?.type)
        
        // push(location.pathname)
      }
    }
  }, [dispatch, push, isError, data, isLoading, isSuccess])

  useEffect(() => {
    const earlyAccessCheck = localStorage.getItem('early-access-checked');
    if (earlyAccessCheck === undefined || earlyAccessCheck === null) {
      dispatch(open('early-access'))
    }
  }, [])

  let menu = null;
  switch(entityType) {
    case EntityConstants.STARTUP:
      menu = MenuStartupConstants;
      break;
    case EntityConstants.CORP:
      menu = MenuCorpConstants;
      break;
    case EntityConstants.CATALYST:
      menu = MenuCatalystConstants;
      break;
  }

  return (
    <SocketProvider>
      <MenuProvider>
      {isSuccess && 
        <>
          <Layout menu={menu} >
            <Switch>
              <Route exact path={path}>
                <Redirect to={`/app/${data?.role?.type}/dashboard`}/>
              </Route>
              <Route path={`${path}/dashboard`}>
                <Dashboard />
              </Route>
              <Route path={`${path}/settings`}>
                <Settings/>
              </Route>
              <Route path={`${path}/favourites`}>
                <Favourites/>
              </Route>
              <Route path={`${path}/explore`}>
                <Explore/>
              </Route>
              <Route path={`${path}/matches`}>
                <Matches/>
              </Route>
              <Route path={`${path}/conversations`}>
                <CatalystConversations/>
              </Route>
              <Route path={`${path}/collaborate`}>
                <Collaborate/>
              </Route>
              <Route path={`${path}/service-collaborations`}>
                <ServiceCollaborations />
              </Route>
              <Route path={`${path}/profile`}>
                {entityType === EntityConstants.CORP && <CorporateProfile />}
                {entityType === EntityConstants.STARTUP && <StartupProfile />}
              </Route>
              <Route path={`${path}/alkelio-services`}>
                {entityType === EntityConstants.CORP && <AdhocServiceCorporate/>}
                {entityType === EntityConstants.STARTUP && <AdhocServiceStartup/>}
              </Route>
              {entityType === EntityConstants.STARTUP &&
              <Route exact path={`${path}/tech-solutions/new`}>
                <TechSolutionForm/>
              </Route>}
              {entityType === EntityConstants.STARTUP &&
              <Route path={`${path}/tech-solutions/:id`}>
                <TechSolutionForm/>
              </Route>}
              {entityType === EntityConstants.CORP &&
              <Route exact path={`${path}/tech-requests/new`}>
                <TechRequestForm/>
              </Route>}
              {entityType === EntityConstants.CORP &&
              <Route path={`${path}/tech-requests/:id`}>
                <TechRequestForm/>
              </Route>}
              <Route path={`${path}/packages`}>
                <StartConversation />
              </Route>
              <Route path={`${path}/payment`}>
                <Payment />
              </Route>
              <Route path={`${path}/news`} exact>
                <AllNews />
              </Route>
              <Route path={`${path}/news/:id`}>
                <NewsDetail />
              </Route>
              <Route path={`${path}/documents`}>
                <AllDocuments />
              </Route>
            </Switch>
          </Layout>
          <GenericModal modalId="early-access" size="lg" centered disableClose={true} onClose={() => {
            localStorage.setItem('early-access-checked', "true");
          }}>
            <EarlyAccess />
          </GenericModal>
          <OverlayProgress />
        </>
      }
      </MenuProvider>
    </SocketProvider>
  );
};

export default Application;