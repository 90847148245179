import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  TechnologyRequest,
  MyCorporateTechnologyRequest,
  EditTechnologyRequestInput,
  CreateTechnologyRequestInput 
} from 'generated/graphql'

export interface SearchTechnologyRequestInput {
  technology_types: Array<String>;
  countries: Array<String>;
  industries: Array<String>;
  business_use_cases: Array<String>;
  sdg_objectives: Array<String>;
}
interface EditTechnologyRequest {
  id: string;
  data: EditTechnologyRequestInput
}

export const techRequestsApi = createApi({
  reducerPath: "techRequestsApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  tagTypes: ["techrequests"],
  endpoints: (builder) => ({
    myTechRequests: builder.query<Array<TechnologyRequest>, void>({
      query: () => ({
        document: gql`
          query {
            myCorporateProfile {
              technology_requests {
                id
                name
                business_challange_description
                hero_image {
                    url
                }
                updated_at
                is_completed
                is_match_triggered
                is_published
                published_at

                is_gdpr
                is_cloud
                sdg_driven
                is_others
                is_spin_off
                is_plugandplay
                is_others
                can_whitelabel
                has_ip
                has_api
                technology_types {
                  name
                }
                industries {
                  name
                }
                business_use_cases {
                  name
                }
                sdg_objectives {
                  name
                }  
              }
            }
          }
        `,
      }),
      transformResponse: (response: any) => response.myCorporateProfile.technology_requests,
      providesTags: ["techrequests"]
    }),
    myTechRequestsToMatch: builder.query<Array<TechnologyRequest>, void>({
      query: () => ({
        document: gql`
          query {
            myCorporateProfile {
              technology_requests {
                id
                name
                business_challange_description
                hero_image {
                  url
                }
                updated_at
                technology_types {
                  name
                }
                industries  {
                    name
                }
                business_use_cases  {
                    name
                }
                has_ip
                has_api
                can_whitelabel
                is_plugandplay
                is_gdpr
                is_cloud
                sdg_driven
                is_spin_off
                is_others
                is_match_triggered
                is_completed
                is_published
                published_at
                sdg_objectives {
                  name
                }
              }
            }
          }
        `,
      }),
      transformResponse: (response: any) => response.myCorporateProfile.technology_requests
    }),
    searchTechnologyRequests: builder.query<Array<TechnologyRequest>, SearchTechnologyRequestInput>({
      query: ({ technology_types, industries, business_use_cases, countries, sdg_objectives}) => ({
        document:  gql`
          query SearchTechnologyRequests (
            $technology_types: [ID!], 
            $industries: [ID!], 
            $countries: [String!],
            $business_use_cases: [ID!]
            $sdg_objectives: [ID!]
          ) {
            technologyRequests (where: {
              technology_types: {
                  id_in: $technology_types
              }
              industries: {
                  id_in: $industries
              }
              business_use_cases: {
                  id_in: $business_use_cases
              }
              country_implementation_contains: $countries
              sdg_objectives: {
                id_in: $sdg_objectives
              }
              is_published: true
            },
              sort: "created_at:desc"
            ) {
                id
                name
                business_challange_description
                country_implementation
                hero_image {
                  url
                }
                is_gdpr
                is_cloud
                sdg_driven
                is_plugandplay
                has_ip
                has_api
                can_whitelabel
                want_poc
                want_seller
                want_lease
                want_investment
                
                created_at
                industries {
                    id
                    name
                }
                technology_types {
                    id
                    name
                }
                business_use_cases {
                    id
                    name
                }
                corporate_profile {
                  country_jurisdiction
                  employee_count_range
                  rating
                }
                sdg_objectives {
                  name
                }
              }
          }
        `,
        variables: {
          technology_types,
          industries,
          business_use_cases,
          countries,
          sdg_objectives
        }
      }),
      transformResponse: (response: any) => response.technologyRequests,
      providesTags: ["techrequests"]
    }),
    getOneTechnologyRequest: builder.query<TechnologyRequest, string>({
      query: (id) => ({
        document: gql`
          query getOneTechRequest($id: ID!) {
            technologyRequest(id: $id) {
              id
              name
              hero_image {
                  id
                  url
              }
              country_implementation
              business_challange_description
              projected_complete_date
              projected_startdate
              description_how_to_resolve_problem
              description_customer
              description_project
              description_risk_challenges
              description_similiar_solutions
              description_successful_outcome
              
              want_explore
              want_lease
              want_poc
              want_seller
              want_investment

              is_cloud
              sdg_driven
              is_gdpr
              is_plugandplay
              is_spin_off
              can_whitelabel
              has_api
              has_ip
              technology_types {
                  id
                  name
              }
              industries {
                  id
                  name
              }
              business_use_cases {
                  id
                  name
              }
              corporate_departments {
                  id
                  name
              }
              sdg_objectives {
                  id
                  name
              }
              technology_solution_model_b2b
              technology_solution_model_b2b2c
              technology_solution_model_b2c
              technology_solution_model_others
              other_tech_option

              goals {
                  id
                  Name
              }

              impact_level
              impact_area {
                  id
                  name
              }
              project_budget {
                  id
                  budget
                  currency
                  option
              }
              
              corporate_profile {
                country_jurisdiction
                employee_count_range
                worked_with_startups
                esg_rating
              }
              sdg_objectives {
                name
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => response.technologyRequest
    }),
    updateTechnologyRequest: builder.mutation<TechnologyRequest, EditTechnologyRequest>({
      query: ({id, data}) => ({
        document: gql`
          mutation updateTechRequest($id: ID!, $data: editTechnologyRequestInput!) {
            updateTechnologyRequest(input: { where: { id: $id }, data: $data }) {
              technologyRequest {
                id
                name
              }
            }
          }
        `,
        variables: {
          id,
          data
        }
      }),
      invalidatesTags: ["techrequests"]
    }),
    deleteTechnologyRequest: builder.mutation<TechnologyRequest, string>({
      query: (id) => ({
        document: gql`
          mutation daleteTechnologyRequest($id: ID!) {
            deleteTechnologyRequest(input: { where: { id: $id }}) {
              technologyRequest {
                id
                name
              }
            }
          }
        `,
        variables: {
          id
        }
      }),
      invalidatesTags: ["techrequests"]
    }),
    createTechnologyRequest: builder.mutation<void, CreateTechnologyRequestInput>({
      query: (techRequest) => ({
        document: gql`
          mutation addTechnologyRequestToCorporateProfile($techRequest: createTechnologyRequestInput!) {
            addTechnologyRequestToCorporateProfile(input: $techRequest) 
          }
        `,
        variables: {
          techRequest
        }
      }),
      invalidatesTags: ["techrequests"]
    })
  }),
})

export const { 
  useMyTechRequestsQuery, 
  useLazyMyTechRequestsQuery,
  useSearchTechnologyRequestsQuery,
  useGetOneTechnologyRequestQuery,
  useLazyGetOneTechnologyRequestQuery,
  useUpdateTechnologyRequestMutation,
  useDeleteTechnologyRequestMutation,
  useMyTechRequestsToMatchQuery,
  useCreateTechnologyRequestMutation
} = techRequestsApi