import React from 'react'

type MatchCounterProp = {
  counter: number;
  suffix: string
}

const MatchCounter = ({
  counter,
  suffix
}: MatchCounterProp) => {
  return (
    <>
      {counter} {suffix}
    </>
  )
}

export default MatchCounter