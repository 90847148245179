import React, { useRef } from "react";
import "../assets/styles/conversation.scss";
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Pill from "app/shared/pill";
import TickWhiteIcon from "app/shared/assets/svgs/tick-white.svg";
import CircleOClose from "../../../assets/svgs/circle-o-close.svg";
import CirclePlus from "../assets/icons/circlePlus.svg";
import Tooltip from "@material-ui/core/Tooltip";
import dayjs from 'dayjs';
//supports only 1 file upload
import InputUploads from 'shared/forms/input-uploads';
// Supports multiple files uploads
import MultipleUploads from 'shared/forms/multiple-uploads';
import DateQuarterSelect from 'app/shared/date-quarter-select';
import _ from 'lodash';
import DocumentBlueIcon from 'app/shared/assets/svgs/document-blue.svg';
import InputMoney from 'shared/forms/input-money';
import {
  useFieldArray,
  Control,
  UseFormRegister,
  UseFormSetValue ,
  Controller,
  DeepMap,
  DeepPartial,
  FieldError,
} from "react-hook-form";
import InputImage from "shared/forms/input-image";
import Inputs from "./inputs";
import SelectOne from "shared/forms/select-one";
import CurrencyCode from "currency-codes";
import XWhiteIcon from "app/shared/assets/svgs/x-white.svg";
import XNightSky from "app/shared/assets/svgs/x-nightsky.svg";
import { FileItem } from "hooks/file-uploads";
import { useUpdateConversationMutation } from "services/graphql/conversation";

type ScopeProps = {
  view: string;
  name: string;
  conversationId: any;
  startupName: string;
  corporateName: string;
  scope: any;
  stateKPIs: any;
  setStateKPIs: any;
  watch:any;
  register: UseFormRegister<Inputs>;
  setFields: UseFormSetValue<Inputs>;
  control: Control<Inputs>;
  errors: DeepMap<DeepPartial<Inputs>, FieldError>;
};

const Scope = React.forwardRef(
  (
    {
      view,
      name,
      scope,
      startupName,
      corporateName,
      stateKPIs,
      setStateKPIs,
      conversationId,
      register,
      setFields,
      watch,
      control,
      errors,
    }: ScopeProps,
    ref: React.Ref<HTMLDivElement>
  ) => {

    // const [addTeamForm, setaddTeamForm] = React.useState(false);
    // const [addCorpTeamForm, setaddCorpTeamForm] = React.useState(false);
    const myConv = {
      scope: { ...scope }
    };

    const componentMountedRef = useRef(false)
    const [componentMounted,setComponentMounted] = React.useState(false);
    const [quarterTo, setQuarterTo] = React.useState('');
    const [quarterFrom, setQuarterFrom] = React.useState('');
    //flag for upload action
    const [foundStartAttch, setFoundStartAttch] = React.useState(false);
    const [foundStartLegal, setFoundStartLegal] = React.useState(false);
    const [foundStartOther, setFoundStartOther] = React.useState(false);
    
    const [foundCorpLegal, setFoundCorpLegal] = React.useState(false);
    const [foundCorpAttch, setFoundCorpAttch] = React.useState(false);
    const [foundCorpOther, setFoundCorpOther] = React.useState(false);

    
    const conversationID = conversationId;
    const [updateConversation] = useUpdateConversationMutation();
  

    const scopeTeamCorp = scope?.team_corporate;
    const scopeTeamStartup = scope?.team_startup;
    // const [startupTeam, setStartupTeam] = React.useState(scopeTeamStartup);
    const timingFrom = scope?.timing_from;
    const timingTo = scope?.timing_to;
    const budgetFrom = scope?.budget_from;
    const budgetTo = scope?.budget_to;
    const currency = scope?.budget_currency;
    const kpis = scope?.kpis;
    const techReqStartup = scope?.technical_requirements_startup;
    const techReqCorp = scope?.technical_requirements_corporate;
    const legalReqStartup = scope?.legal_requirements_startup;
    const legalReqCorp = scope?.legal_requirements_corporate;
    const otherReqStartup = scope?.other_requirements_startup;
    const otherReqCorp = scope?.other_requirements_corporate;
    const { fields: corpTeam, append: appendCorp, remove: removeCorp } = useFieldArray({
      control,
      name: "scope.team_corporate"
    });
    const { fields: startupTeam, append: appendStartup, remove: removeStartup } = useFieldArray({
      control,
      name: "scope.team_startup"
    });

    React.useEffect(() => {
      // console.log("Scope Tab Prop ",scope);
      formatTiming();
      if (corpTeam.length === 0 || !corpTeam) {


        for (let member in scopeTeamCorp) {
          // console.log("Member Corp: ",scopeTeamCorp[member]);
          // adding members of the Team to state array
          appendCorp({
            email: scopeTeamCorp[member].email,
            name: scopeTeamCorp[member].name,
            role: scopeTeamCorp[member].role,
            profile_picture: scopeTeamCorp[member].profile_picture,
          });
        }
      }

      if (startupTeam.length === 0 || !startupTeam) {
        for (let member in scopeTeamStartup) {
          // console.log("Member: ",scopeTeamStartup[member]);
          // adding startup team members
          appendStartup({
            email: scopeTeamStartup[member].email,
            name: scopeTeamStartup[member].name,
            role: scopeTeamStartup[member].role,
            profile_picture: scopeTeamStartup[member].profile_picture,
          });
        }
      }
      //determining if an attachment or a requirement text is present for each field
      if ((techReqStartup?.attachment !== null && techReqStartup?.attachment?.length > 0)
        || techReqStartup?.requirement !== "") {
        setFoundStartAttch(true);
      }
      if ((techReqCorp?.attachment !== null && techReqCorp?.attachment?.length > 0)
        || techReqCorp?.requirement !== "") {
        setFoundCorpAttch(true);
      }
      
      if ((legalReqCorp?.attachment !== null && legalReqCorp?.attachment?.length > 0)
        || legalReqCorp?.requirement !== "") {
          setFoundCorpLegal(true);
      }

      if ((legalReqStartup?.attachment !== null && legalReqStartup?.attachment?.length > 0)
        || legalReqStartup?.requirement !== "") {
          setFoundStartLegal(true);
      }
      if ((otherReqStartup?.attachment !== null && otherReqStartup?.attachment?.length > 0)
        || otherReqStartup?.requirement !== "") {
          setFoundStartOther(true);
      }
      if ((otherReqCorp?.attachment !== null && otherReqCorp?.attachment?.length > 0)
        || otherReqCorp?.requirement !== "") {
          setFoundCorpOther(true);
      }

      setFields("scope.kpis", scope.kpis);
      componentMountedRef.current = true
      return () => {
        console.log('Component will be unmount')
      }
    }, [timingFrom, timingTo, scope]);

    // alternate function to mask input 
    const numberWithCommas = (x :number) => {
        if(x){
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
    }
    const setAttachmentsToIds = (scopeObj: any, uploadedObj: string) => {
      // console.log("The ScopeObj",scopeObj);

      //add watch list to all uploads here
      const watchTechCorp = watch('scope.technical_requirements_corporate.attachment');
      const watchTechStart = watch('scope.technical_requirements_startup.attachment');
      const watchLegalCorp = watch('scope.legal_requirements_corporate.attachment');
      const watchLegalStart = watch('scope.legal_requirements_startup.attachment');
      const watchOtherCorp = watch('scope.other_requirements_corporate.attachment');
      const watchOtherStart = watch('scope.other_requirements_startup.attachment');
      
      interface LooseObject {
        [key: string]: any;
      }
      let updatedScope: LooseObject = { ...scopeObj };
      // console.log("first updated scope",updatedScope);
      (Object.keys(scopeObj) as Array<keyof typeof scopeObj>).map((obj) => {
        //all objects are initially null
        if (scopeObj[obj] !== null) {
          // the && obj !== uploadedObj is to avoid editing the currently 
          // being uploaded file and re-writing old data 
          
          if (obj === "technical_requirements_corporate" ) {
            let oldAttach=scopeObj[obj]?.attachment;
            let techCorp = updatedScope[obj] || {requirement:"",attachment:[]} ;
            let techCorpUploads = [];

            if (oldAttach !== null && oldAttach?.length > 0) {
              techCorpUploads = (oldAttach).map((item: any) => {
                return item.id;
              })
            }
            if (watchTechCorp && watchTechCorp?.length > 0 ) {
              //if Not the uploaded Object
              if(watchTechCorp[0].id){
                techCorpUploads=watchTechCorp.map((item: any) => {
                  return item.id;
                });
              }
              //if the uploaded Object
              else{
                techCorpUploads=watchTechCorp;
              }
            }
            techCorp.attachment = techCorpUploads;
            
          }
          if (obj === "technical_requirements_startup" ) {

            let oldAttach=scopeObj[obj]?.attachment;
            let techStartUploads =[];
            let techStart = updatedScope[obj] || {requirement:"",attachment:[]} ;
            if (oldAttach !== null && oldAttach?.length > 0) {
              techStartUploads = (oldAttach).map((item: any) => {
                return item.id;
              })
            }
            if (watchTechStart && watchTechStart?.length > 0) {
              if(watchTechStart[0].id){
                techStartUploads=watchTechStart.map((item: any) => {
                  return item.id;
                });

              }
              else{
                techStartUploads=watchTechStart;
              }
            }
            techStart.attachment = techStartUploads;
          }
          if (obj === "legal_requirements_corporate" ) {
            
            let oldAttach=scopeObj[obj]?.attachment;
            let legCorp = updatedScope[obj] || {requirement:"",attachment:[]} ;
            let legalCorpUploads = [];
            // Does not have an uploaded value
            if (oldAttach !== null && oldAttach?.length > 0) {
              legalCorpUploads = (oldAttach).map((item: any) => {
                return item.id;
              })
            }
            //currently have an uploaded
            if (watchLegalCorp && watchLegalCorp?.length > 0) {
              if(watchLegalCorp[0].id){
                legalCorpUploads=watchLegalCorp.map((item: any) => {
                  return item.id;
                });
              }
              else{
                legalCorpUploads=watchLegalCorp;
              }
            }
            legCorp.attachment = legalCorpUploads;
          }
          if (obj === "legal_requirements_startup" ) {

            let oldAttach=scopeObj[obj]?.attachment;
            let legStartUploads = [];
            let legStart = updatedScope[obj] || {requirement:"",attachment:[]} ;

            if (oldAttach !== null && oldAttach?.length > 0) {
              legStartUploads = (oldAttach).map((item: any) => {
                return item.id;
              })
            }
            if (watchLegalStart && watchLegalStart?.length > 0) {
              if(watchLegalStart[0].id){
                legStartUploads=watchLegalStart.map((item: any) => {
                  return item.id;
                });
              }
              else{
                legStartUploads=watchLegalStart;
              }
            }
            legStart.attachment = legStartUploads;
          }
          if (obj === "other_requirements_corporate" ) {
            
            let oldAttach=scopeObj[obj]?.attachment;
            let othCorpUploads = [];
            let othCorp = updatedScope[obj] || {requirement:"",attachment:[]} ;
            if (oldAttach !== null && oldAttach?.length > 0) {
              othCorpUploads = (oldAttach).map((item: any) => {
                return item.id;
              })
            }
            if (watchOtherCorp && watchOtherCorp?.length > 0) {
              if(watchOtherCorp[0].id){
                othCorpUploads=watchOtherCorp.map((item: any) => {
                  return item.id;
                });
              }
              else{
                othCorpUploads=watchOtherCorp;
              }
            }
            
            othCorp.attachment = othCorpUploads;
          }
          if (obj === "other_requirements_startup" ) {

            let oldAttach=scopeObj[obj]?.attachment;
            let othStartUploads =[];
            let othStart = updatedScope[obj] || {requirement:"",attachment:[]} ;

            if (oldAttach !== null && oldAttach?.length > 0) {
              othStartUploads = (oldAttach).map((item: any) => {
                return item.id;
              })
            }
            if (watchOtherStart && watchOtherStart?.length > 0) {
              if(watchOtherStart[0].id){

                othStartUploads=watchOtherStart.map((item: any) => {
                  return item.id;
                });
              }
              else{
                othStartUploads=watchOtherStart;
              }
            }
            othStart.attachment = othStartUploads;
          }
        }
      });
      // console.log("Mapping done", updatedScope);
      return updatedScope;
    }

    const formatTiming = () => {
      let d = new Date(timingTo);
      let d2 = new Date(timingFrom);
      var dayTo = dayjs(d);
      var dayFrom = dayjs(d2);

      let toQuarter = dayTo.quarter();
      let fromQuarter = dayFrom.quarter();
      
      const fromStr = 'Q' + fromQuarter + " " + dayFrom.format('MMMM YYYY');
      const toStr = 'Q' + toQuarter + " " + dayTo.format('MMMM YYYY');
      setQuarterFrom(fromStr)
      setQuarterTo(toStr);

    }

    const handleAttachmentChange = async (files: Array<FileItem>) => {
      
    }

    interface SelectedItemProps {
      text: string;
      onRemove: (text: string) => unknown;
      variant?: string;
    }

    const SelectedItem = (props: SelectedItemProps) => {
      return (
        <div className={`selected-item ${props.variant}`}>
          <span>{props.text}</span>
          <button
            type="button"
            onClick={() => {
              props.onRemove && props.onRemove(props.text);
            }}
          >
            <img
              src={
                props.variant !== undefined &&
                  props.variant?.indexOf("inverse") >= 0
                  ? XNightSky
                  : XWhiteIcon
              }
              alt="delete"
            />
          </button>
        </div>
      );
    };

    const removeSelectedOption = (text: string) => {
      let index = stateKPIs.indexOf(text);
      let newKPIs = [...stateKPIs];
      if (newKPIs.length > 1) {
        newKPIs.splice(index, 1);
      } else {
        newKPIs = [];
      }
      setStateKPIs(newKPIs);
    };

    const keyPressKPIs = (e: any) => {
      if (e.keyCode === 13) {
        if(stateKPIs ===null){
          stateKPIs=[];
        }
        let updatedKPIs = [e.target.value.replace(/\r?\n|\r/g, "")];
        
        setStateKPIs(stateKPIs.concat(updatedKPIs));
        
        setFields("scope.kpis", stateKPIs.concat(updatedKPIs));
        //must nullify before extra addition
        e.target.value = "";
      }
    };

    const Currencies = CurrencyCode.codes();

    const styleRep = {
      marginTop: "5%",
      marginBottom: "5%",
      width: "100px",
      height: "100px",
      borderRadius: "50%",
    };

    return (
      <>
      {componentMountedRef.current? <>
        {view === "startup" ? (
          <div>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="section">
                <label>
                  <div className="bold">
                    Governance &amp; Key Team structure {corporateName}
                  </div>
                </label>
                <Grid
                  container
                  direction="row"
                >
                  {scopeTeamCorp?.length === 0 ? (
                    <div className="body">Team is not available</div>
                  ) : (
                    scopeTeamCorp?.map(
                      (teamMember: any) =>
                        teamMember?.name && (
                          <Grid item md={4}>
                            <div className="team">
                              <img
                                style={styleRep}
                                src={teamMember?.profile_picture?.url}
                                alt={"profile_picture"}
                              />
                              <Typography
                                variant="body1"
                                display="block"
                                align="left"
                                gutterBottom
                              >
                                {teamMember?.name}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                id="teamRole"
                                display="block"
                                align="left"
                                gutterBottom
                              >
                                {teamMember?.role}
                              </Typography>
                              <span className="body mt-2" >{teamMember?.email}</span>
                            </div>
                          </Grid>
                        )
                    )
                  )}
                </Grid>
              </div>
            </Box>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="section">
                <label>
                  <div className="bold">
                    Governance &amp; Key Team structure {startupName}
                  </div>
                </label>

                <br />
                <Grid
                  container
                  direction="row"
                >
                  {startupTeam?.map(
                    (teamMember, index) => (

                      <Grid item md={4}>
                        <div className="deleteButton">
                          <button
                            type="button"
                            style={{width:'36px'}}
                            className="remove pull-right"
                            onClick={() => {
                              removeStartup(index);
                            }}
                          >
                            <img src={CircleOClose} alt="remove" />
                          </button>
                        </div>

                        <div className="team" key={teamMember?.id}>


                          <Controller
                            name={`scope.team_startup.${index}.profile_picture`}
                            defaultValue={
                              (teamMember.profile_picture as FileItem) ||
                              []
                            }
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <InputImage
                                layout="minimal"
                                height="72px"
                                width="72px"
                                {...field}
                              />
                            )}
                          />

                          <label>
                            <div className="text">NAME</div>
                            <input
                              type="text"
                              defaultValue={teamMember?.name || ""}
                              {...register(
                                `scope.team_startup.${index}.name`
                              )}
                            />
                          </label>

                          <label style={{ marginTop: "1em" }}>
                            <div className="text">ROLE</div>
                            <input
                              type="text"
                              defaultValue={teamMember?.role || ""}
                              {...register(
                                `scope.team_startup.${index}.role`
                              )}
                            />
                          </label>
                          <label style={{ marginTop: "1em" }}>
                            <div className="text">EMAIL</div>
                            <input
                              type="text"
                              defaultValue={teamMember?.email || ""}
                              {...register(
                                `scope.team_startup.${index}.email`
                              )}
                            />
                          </label>
                        </div>
                      </Grid>
                    )
                  )}

                  {startupTeam && startupTeam.length < 3 ? (
                    <Grid item md={3}>
                      <div className="addTeam">
                        <Box
                          border={1}
                          borderColor="grey.300"
                          borderRadius={10}
                          className="plusSign"
                          style={{ textAlign: "center" }}
                        >
                          <Button style={{ width: '100%' }} onClick={() => {
                            appendStartup({
                              email: '',
                              name: '',
                              role: '',
                            })
                          }}>
                            <img src={CirclePlus} alt="add" />
                          </Button>
                        </Box>
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </div>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
            >
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius={10}
                  className="sideBySideBoard"
                >
                  <div className="timingSection">
                    <label>
                      <div className="bold">Project Timing</div>
                    </label>
                    <br />
                    <Typography
                      variant="body1"
                      display="block"
                      align="left"
                      gutterBottom
                    >
                      START DATE
                    </Typography>

                    <div className="date">
                      <label>
                        <b>{quarterFrom}</b>
                      </label>
                    </div>
                    <br />
                    <Typography
                      variant="body1"
                      display="block"
                      align="left"
                      gutterBottom
                    >
                      END DATE
                    </Typography>
                    <div className="date">
                      <label>
                        <b>{quarterTo}</b>
                      </label>
                    </div>
                    <br />
                  </div>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius={10}
                  className="mainBoard"
                >
                  <div className="section">
                    <label>
                      <div className="bold">Budget Range</div>
                    </label>

                    <div className="date">
                      <label>
                        <div className="Brange">
                          <span >{numberWithCommas(budgetFrom)}</span> {currency} - <span>{numberWithCommas(budgetTo)}</span> {currency} 
                        </div>
                      </label>
                    </div>
                    <br />
                    <label>
                      <div className="bold">
                        KPI'S/Success metrics of the project
                      </div>
                    </label>
                    <Grid container >
                      {kpis?.length === 0 ? (
                        <div className="body">KPIs are not available</div>
                      ) : (
                        kpis?.map((kpi: any) => (
                          <Tooltip title={kpi}>
                            <div className="flex-row flex-wrap mb-1 d-flex tags">
                              <Pill icon={TickWhiteIcon} label={kpi} />
                            </div>
                          </Tooltip>
                        ))
                      )}
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="requirementSection">
                <label>
                  <div className="bold questionTitleCenter">
                    Technical Requirements
                  </div>
                </label>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md={6} >
                    <Box
                      borderRight={0}
                      borderTop={1}
                      paddingTop={2}
                      className="leftBox"
                      // borderColor="grey.300"
                      // height="308px"
                    >
                      <div className="boxTitle">
                        <span>{name}</span>
                      </div>
                      {techReqCorp && techReqCorp?.requirement ? (
                        <div className="">
                          <TextField
                              multiline
                              rows={2}
                              disabled
                              defaultValue={
                                (techReqCorp?.requirement) || ""
                              }
                              fullWidth
                              InputProps={{ disableUnderline: true }}
                            ></TextField>
                          
                        </div>
                        
                      ) : (
                        <div className="body content">{foundCorpAttch? "":"None"}</div>
                      )}
                      {foundCorpAttch && techReqCorp?.attachment?.length>0 
                      && techReqCorp?.attachment?.map((item:FileItem, i:any) => (
                      <>
                      {item.id!==undefined?
                      <div key={i} className="mapfile">
                        <img src={DocumentBlueIcon} alt="file_name" />
                        <a href={item?.url || '/'} target="_blank" rel="noreferrer">
                          <div className="upname">
                            {item.name || 'No Files Found'}
                          </div>
                        </a>
                      </div>:<></>} 
                      </> ))}
                    </Box>
                  </Grid>
                  <Grid item md={6} className="leftLongBorder">
                    <Box
                      borderTop={1}
                      borderColor="grey.300"
                      // height="308px"
                      paddingTop={2}
                    >
                      <div className="boxTitle">
                        <span>{startupName}</span>
                      </div>

                      <TextField
                        multiline
                        rows={2}
                        defaultValue={
                          (techReqStartup && techReqStartup?.requirement) || ""
                        }
                        fullWidth
                        // style={{ padding: '20px',fontSize:'14px !important' }}
                        InputProps={{ disableUnderline: true }}
                        {...register(
                          "scope.technical_requirements_startup.requirement"
                        )}
                      ></TextField>

                      <div className="uploadDocuments">

                        <label className="Wasaction">
                        <input
                            multiple={true}
                            hidden
                            // type="file"
                            {...register(
                              "scope.technical_requirements_startup.attachment"
                            )}
                          />
                          <Controller
                            name="scope.technical_requirements_startup.attachment"
                            defaultValue={
                              (techReqStartup?.attachment as Array<FileItem>) ||
                              []
                            }
                            control={control}
                            render={({ field }) => (
                              <MultipleUploads
                                maxFileCount={10}
                                {...field} />
                            )}
                          />
                        </label>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="requirementSection">
                <label>
                  <div className="bold questionTitleCenter">
                    Legal &amp; compliance requirements
                  </div>
                </label>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md={6} >
                    <Box
                      borderRight={0}
                      borderTop={1}
                      // borderColor="grey.300"
                      className="leftBox"
                      // height="308px"
                      paddingTop={2}
                    >
                      <div className="boxTitle">
                        <span>{name}</span>
                      </div>

                      {legalReqCorp && legalReqCorp?.requirement ? (
                        <div className="">
                          {/* {legalReqCorp?.requirement} */}
                          <TextField
                              multiline
                              rows={2}
                              disabled
                              defaultValue={
                                (legalReqCorp?.requirement) || ""
                              }
                              fullWidth
                              InputProps={{ disableUnderline: true }}
                            ></TextField>
                        </div>
                      ) : (
                        <div className="body content">{foundCorpLegal? "":"None"}</div>
                      )}
                      {/* map corp legal for startup here */}
                      {foundCorpLegal && legalReqCorp?.attachment?.length>0 &&legalReqCorp?.attachment?.map((item:FileItem, i:any) => (
                      <>
                      {item.id!==undefined?
                      <div key={i} className="mapfile">
                        <img src={DocumentBlueIcon} alt="file_name" />
                        <a href={item?.url || '/'} target="_blank" rel="noreferrer">
                          <div className="upname">
                            {item.name || 'No Files Found'}
                          </div>
                        </a>
                      </div>:<></>} 
                      </> ))}
                    </Box>
                  </Grid>
                  <Grid item md={6} className="leftLongBorder">
                    <Box
                      borderTop={1}
                      borderColor="grey.300"
                      // height="308px"
                      paddingTop={2}
                    >
                      <div className="boxTitle">
                        <span>{startupName}</span>
                      </div>
                      <TextField
                        multiline
                        // style={{ padding: '20px',fontSize:'14px !important' }}
                        rows={2}
                        defaultValue={
                          (legalReqStartup && legalReqStartup?.requirement) ||
                          ""
                        }
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        {...register(
                          "scope.legal_requirements_startup.requirement"
                        )}
                      ></TextField>
                      {/* startup Legal Upload */}
                      <div className="uploadDocuments">
                        <label className="Wasaction">
                          <Controller
                            name="scope.legal_requirements_startup.attachment"
                            defaultValue={
                              (legalReqStartup?.attachment as Array<FileItem>) ||
                              []
                            }
                            control={control}
                            render={({ field }) => (
                              <MultipleUploads
                                maxFileCount={10}
                                {...field} />
                            )}
                          />

                          <input
                            multiple={true}
                            hidden
                            // type="file"
                            {...register(
                              "scope.legal_requirements_startup.attachment"
                            )}
                          />

                        </label>
                      </div>
                      {/* <span>
                        {legalReqStartup && legalReqStartup?.attachment}
                      </span> */}
                      
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="requirementSection">
                <label>
                  <div className="bold questionTitleCenter">
                    Other requirements
                  </div>
                </label>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md={6} >
                    <Box
                      borderRight={0}
                      borderTop={1}
                      // borderColor="grey.300"
                      className="leftBox"
                      // height="308px"
                      paddingTop={2}
                    >
                      <div className="boxTitle">
                        <span>{name}</span>
                      </div>
                      {otherReqCorp && otherReqCorp?.requirement ? (
                        <div className="">
                          {/* {otherReqCorp?.requirement} */}
                          <TextField
                              multiline
                              rows={2}
                              disabled
                              defaultValue={
                                (otherReqCorp?.requirement) || ""
                              }
                              fullWidth
                              InputProps={{ disableUnderline: true }}
                            ></TextField>
                        </div>
                      ) : (
                        <div className="body content">{foundCorpOther? "":"None"}</div>
                      )}
                      {foundCorpOther && otherReqCorp?.attachment?.length>0 
                      && otherReqCorp?.attachment?.map((item:FileItem, i:any) => (
                      <>
                      {item.id!==undefined?
                      <div key={i} className="mapfile">
                        <img src={DocumentBlueIcon} alt="file_name" />
                        <a href={item?.url || '/'} target="_blank" rel="noreferrer">
                          <div className="upname">
                            {item.name || 'No Files Found'}
                          </div>
                        </a>
                      </div>:<></>} 
                      </> ))}

                    </Box>
                  </Grid>
                  <Grid item md={6} className="leftLongBorder">
                    <Box
                      borderTop={1}
                      borderColor="grey.300"
                      // height="308px"
                      paddingTop={2}
                    >
                      <div className="boxTitle">
                        <span>{startupName}</span>
                      </div>
                      <TextField
                        multiline
                        // style={{ padding: '20px',fontSize:'14px !important' }}
                        rows={2}
                        defaultValue={
                          (otherReqStartup && otherReqStartup?.requirement) ||
                          ""
                        }
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        {...register(
                          "scope.other_requirements_startup.requirement"
                        )}
                      ></TextField>

                      <div className="uploadDocuments">
                        <label className="Wasaction">
                          <Controller
                            name="scope.other_requirements_startup.attachment"
                            defaultValue={
                              (otherReqStartup?.attachment as Array<FileItem>) ||
                              []
                            }
                            control={control}
                            render={({ field }) => (
                              <MultipleUploads
                                maxFileCount={10}
                                {...field} />
                            )}
                          />

                          <input
                            multiple={true}
                            hidden
                            // type="file"
                            {...register(
                              "scope.other_requirements_startup.attachment"
                            )}
                          />

                        </label>
                      </div>
                      {/* <span>
                        {otherReqStartup && otherReqStartup?.attachment}
                      </span> */}
                      
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div >
        ) : (
          <div>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="section">
                <label>
                  <div className="bold">
                    Governance &amp; Key Team structure {corporateName}
                  </div>
                </label>

                <Grid
                  container
                  direction="row"
                >


                  {corpTeam?.map(
                    (teamMember, index) => (
                      // {scopeTeamCorp?.map(
                      //   (teamMember: any, index: any) =>
                      //     teamMember?.name && (

                      <Grid item md={4}>
                        <div className="deleteButton">
                          <button
                            type="button"
                            style={{width:'36px'}}
                            className="remove pull-right"
                            onClick={() => {
                              removeCorp(index);
                            }}
                          >
                            <img src={CircleOClose} alt="remove" />
                          </button>
                        </div>
                        <div className="team" key={teamMember?.id}>
                          <Controller
                            name={`scope.team_corporate.${index}.profile_picture`}
                            defaultValue={
                              (teamMember.profile_picture as FileItem) || []
                            }
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <InputImage
                                layout="minimal"
                                height="72px"
                                width="72px"
                                {...field}
                              />
                            )}
                          />

                          <label style={{ marginTop: "1em" }}>
                            <div className="text">NAME</div>
                            <input
                              type="text"
                              defaultValue={teamMember?.name || ""}
                              {...register(
                                `scope.team_corporate.${index}.name`
                              )}
                            />
                          </label>

                          <label style={{ marginTop: "1em" }}>
                            <div className="text">ROLE</div>
                            <input
                              type="text"
                              defaultValue={teamMember?.role || ""}
                              {...register(
                                `scope.team_corporate.${index}.role`
                              )}
                            />
                          </label>

                          <label style={{ marginTop: "1em" }}>
                            <div className="text">EMAIL</div>
                            <input
                              type="text"
                              defaultValue={teamMember?.email || ""}
                              {...register(
                                `scope.team_corporate.${index}.email`
                              )}
                            />
                          </label>
                        </div>
                      </Grid>
                    )
                  )}
                  {/* {((scopeTeamCorp && scopeTeamCorp.length < 3)) ? ( */}
                  {((corpTeam && corpTeam.length < 3)) ? (
                    <Grid item md={3}>
                      <div className="addTeam">
                        <Box
                          border={1}
                          borderColor="grey.300"
                          borderRadius={10}
                          className="plusSign"
                          style={{ textAlign: "center" }}
                        >
                          <Button style={{ width: '100%' }} onClick={() => {
                            appendCorp({
                              email: '',
                              name: '',
                              role: '',
                            })
                          }} >
                            <img src={CirclePlus} alt="add" />
                          </Button>
                        </Box>
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </div>
            </Box>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="section">
                <label>
                  <div className="bold">
                    Governance &amp; Key Team structure {startupName}
                  </div>
                </label>
                <br />
                <Grid
                  container
                  direction="row"
                >
                  {scopeTeamStartup?.length === 0 ? (
                    <div className="body">Team is not available</div>
                  ) :
                    scopeTeamStartup.map(
                      (teamMember: any) =>
                        teamMember?.name && (
                          <Grid item md={4}>
                            <div className="team">
                              <img
                                style={styleRep}
                                src={teamMember?.profile_picture?.url}
                                alt={"profile_picture"}
                              />
                              <Typography
                                variant="body1"
                                display="block"
                                align="left"
                                gutterBottom
                              >
                                {teamMember?.name}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                display="block"
                                align="left"
                                id="teamRole"
                                gutterBottom
                              >
                                {teamMember?.role}
                              </Typography>
                              <span className="body mt-2">{teamMember?.email}</span>
                            </div>
                          </Grid>
                        )
                    )}
                </Grid>
              </div>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              className="timingRange"
            >
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="#e6e6e6"
                  borderRadius={10}
                  className="sideBySideBoard"
                >
                  <div className="timingSection">
                    <label>
                      <div className="bold">Project Timing</div>
                    </label>
                    <br />
                    {/* <Typography
                      variant="body1"
                      display="block"
                      align="left"
                      gutterBottom
                    >
                      START DATE
                    </Typography> */}
                    <div className="date">
                      <Controller
                        name="scope.timing_from"
                        control={control}
                        defaultValue={scope?.timing_from || null}
                        render={({ field: { ...field } }) => <DateQuarterSelect {...field} yearLabel="Start Date" quarterLabel="Quarter" />}
                      />
                      {/* <DateQuarterSelect {...register("scope.timing_from")} name={scope.timing_from}
                     yearLabel="Project Start" quarterLabel="Quarter" /> */}

                      {/* <TextField
                        placeholder={timingFrom ? timingFrom : ""}
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        {...register("scope.timing_from")}
                      ></TextField> */}
                    </div>
                    {/* <br /> */}
                    {/* <Typography
                      variant="body1"
                      display="block"
                      align="left"
                      gutterBottom
                    >
                      END DATE
                    </Typography> */}
                    <div className="date">
                      <Controller
                        name="scope.timing_to"
                        control={control}
                        defaultValue={scope?.timing_to || null}
                        render={({ field: { ...field } }) => <DateQuarterSelect {...field} yearLabel="End Date" quarterLabel="Quarter" />}
                      />
                      {/* <TextField
                        placeholder={timingTo}
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        {...register("scope.timing_to")}
                      ></TextField> */}
                    </div>
                    {/* <br /> */}
                  </div>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius={10}
                  className="mainBoard"
                >
                  <div className="timingSection">
                    <label>
                      <div className="bold">Budget Range</div>
                    </label>

                    <div >
                      <br />
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item md={4}>
                          <div className="input-group seamless">
                              <div className="inputMask">
                                <Controller
                                  name={"scope.budget_from"}
                                  control={control}
                                  defaultValue={budgetFrom}
                                  rules={{
                                    required: false
                                  }}
                                  render={({ field }) => <InputMoney
                                    style={{
                                      flex: "1 1 auto",
                                      paddingRight: '0px',
                                      minWidth: "110px",
                                      border:"0px",
                                    }}
                                    className="smallWidth"
                                    {...field}
                                  />}
                                />
                            </div>
                          </div>
                        </Grid>

                        <Grid item md={1}>
                              <b>-</b>
                        </Grid>
                        <Grid item md={7}>
                          <div className="input-group seamless">
                            {/* <div className="inputMask"> */}
                            <Controller
                                  name={"scope.budget_to"}
                                  control={control}
                                  defaultValue={budgetTo}
                                  rules={{
                                    required: false
                                  }}
                                  render={({ field }) => <InputMoney
                                    style={{
                                      width: '0px',
                                      paddingRight: '0px',
                                      paddingLeft: '8px',
                                      minWidth: "80px",
                                      border:"0px",
                                    }}
                                    {...field}
                                  />}
                                />
                            {/* </div> */}
                            <Controller
                              name={"scope.budget_currency"}
                              control={control}
                              defaultValue={currency || ""}
                              render={({ field }) => (
                                <SelectOne
                                  options={Currencies}
                                  style={{ flex: "0 0 80px" }}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <br />

                    <label>
                      <div className="bold">
                        KPI'S/Success metrics of the project
                      </div>
                    </label>

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item md={12}>
                        <br />
                        <div className="date">
                          <TextField
                            fullWidth
                            onKeyDown={keyPressKPIs}
                            InputProps={{ disableUnderline: true }}
                          ></TextField>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container >
                      {stateKPIs?.map((item: any, i: any) => (
                        <SelectedItem
                          key={i}
                          text={item}
                          onRemove={removeSelectedOption}
                        />
                      ))}
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="requirementSection">
                <label>
                  <div className="bold questionTitleCenter">
                    Technical Requirements
                  </div>
                </label>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md={6} className="longBorder">
                    <Box
                      borderTop={1}
                      borderRight={0}
                      className="leftBox"
                      // height="308px"
                      paddingTop={2}
                    >
                      <div className="boxTitle">
                        <span>{corporateName}</span>
                      </div>
                      <TextField
                        multiline
                        // style={{ padding: '20px',fontSize:'14px !important' }}
                        rows={2}
                        defaultValue={
                          (techReqCorp && techReqCorp?.requirement) || ""
                        }
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        {...register(
                          "scope.technical_requirements_corporate.requirement"
                        )}
                      ></TextField>
                      <div className="uploadDocuments">
                        <label className="Wasaction">
                          <Controller
                            name="scope.technical_requirements_corporate.attachment"
                            defaultValue={
                              (techReqCorp?.attachment as Array<FileItem>) ||
                              []
                            }
                            control={control}
                            render={({ field }) => (
                              <MultipleUploads
                                onUploadsChange={handleAttachmentChange}
                                maxFileCount={10}
                                {...field} />
                            )}
                          />

                        </label>
                      </div>
                      
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      borderTop={1}
                      borderColor="grey.300"
                      // height="308px"
                      paddingTop={2}
                    >
                      <div className="boxTitle">
                        <span>{startupName}</span>
                      </div>
                      {techReqStartup && techReqStartup?.requirement ? (
                        <div className="">
                          {/* {techReqStartup?.requirement} */}
                          <TextField
                        multiline
                        rows={2}
                        disabled
                        defaultValue={
                          (techReqStartup?.requirement) || ""
                        }
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                      ></TextField>
                        </div>
                      ) : (
                        <div className="body content">{foundStartAttch? "":"None"}</div>
                      )}
                      {/* mapping for corp */}
                     
                      {foundStartAttch && techReqStartup?.attachment?.length>0 
                      && techReqStartup?.attachment?.map((item:FileItem, i:any) => (
                      <>
                      {item.id!==undefined?
                      <div key={i} className="mapfile">
                        <img src={DocumentBlueIcon} alt="file_name" />
                        <a href={item?.url || '/'} target="_blank" rel="noreferrer">
                          <div className="upname">
                            {item.name || 'No Files Found'}
                          </div>
                        </a>
                      </div>:<></>} 
                      </> ))}

                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="requirementSection">
                <label>
                  <div className="bold questionTitleCenter">
                    Legal &amp; compliance requirements
                  </div>
                </label>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md={6} className="longBorder">
                    <Box
                      borderTop={1}
                      borderRight={0}
                      className="leftBox"
                      paddingTop={2}
                      // height="308px"
                    >
                      <div className="boxTitle">
                        <span>{corporateName}</span>
                      </div>
                      <TextField
                        multiline
                        // style={{ padding: '20px',fontSize:'14px !important' }}
                        rows={2}
                        defaultValue={
                          (legalReqCorp && legalReqCorp?.requirement) || ""
                        }
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        {...register(
                          "scope.legal_requirements_corporate.requirement"
                        )}
                      ></TextField>
                      <div className="uploadDocuments">
                        <label className="Wasaction">
                          <Controller
                            name="scope.legal_requirements_corporate.attachment"
                            defaultValue={
                              (legalReqCorp?.attachment as Array<FileItem>) ||
                             []
                            }
                           
                            control={control}
                            render={({ field }) => (
                              <MultipleUploads 
                                maxFileCount={10}
                                {...field} />
                            )}
                          />

                          <input
                            multiple={true}
                            hidden
                            // type="file"
                            {...register(
                              "scope.legal_requirements_corporate.attachment"
                            )}
                          />

                        </label>
                        
                      </div>
                      
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box borderTop={1} borderColor="grey.300" paddingTop={2}>
                      <div className="boxTitle">
                        <span>{startupName}</span>
                      </div>
                      {legalReqStartup && legalReqStartup?.requirement ? (
                        <div className="">
                          {/* {legalReqStartup?.requirement} */}
                            <TextField
                              multiline
                              rows={2}
                              disabled
                              defaultValue={
                                (legalReqStartup?.requirement) || ""
                              }
                              fullWidth
                              InputProps={{ disableUnderline: true }}
                            ></TextField>
                        </div>
                      ) : (
                        <div className="body content">{foundStartLegal? "":"None"}</div>
                      )}
                      {/* Mapping legal startup reqs */}
                      
                      {foundStartLegal && legalReqStartup?.attachment?.length>0 
                      && legalReqStartup?.attachment?.map((item:FileItem, i:any) => (
                      <>
                      {item.id!==undefined?
                      <div key={i} className="mapfile">
                        <img src={DocumentBlueIcon} alt="file_name" />
                        <a href={item?.url || '/'} target="_blank" rel="noreferrer">
                          <div className="upname">
                            {item.name || 'No Files Found'}
                          </div>
                        </a>
                      </div>:<></>} 
                      </> ))}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
            <Box
              border={1}
              borderColor="grey.300"
              borderRadius={10}
              className="mainBoard"
            >
              <div className="requirementSection">
                <label>
                  <div className="bold questionTitleCenter">
                    Other requirements
                  </div>
                </label>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md={6} className="longBorder">
                    <Box
                      borderTop={1}
                      borderRight={0}
                      className="leftBox"
                      paddingTop={2}
                      // height="308px"
                    >
                      <div className="boxTitle">
                        <span>{corporateName}</span>
                      </div>
                      <TextField
                        multiline
                        // style={{ padding: '20px',fontSize:'14px !important' }}
                        rows={2}
                        defaultValue={
                          (otherReqCorp && otherReqCorp?.requirement) || ""
                        }
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        {...register(
                          "scope.other_requirements_corporate.requirement"
                        )}
                      ></TextField>

                      <div className="uploadDocuments">
                        <label className="Wasaction">
                        <Controller
                            name="scope.other_requirements_corporate.attachment"
                            defaultValue={
                              (otherReqCorp?.attachment as Array<FileItem>) ||
                              []
                            }
                            control={control}
                            render={({ field }) => (
                              <MultipleUploads
                                maxFileCount={10}
                                {...field} />
                            )}
                          />

                          <input
                            multiple={true}
                            hidden
                            // type="file"
                            {...register(
                              "scope.other_requirements_corporate.attachment"
                            )}
                          />
                        </label>
                      </div>
                      
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Box borderTop={1} borderColor="grey.300" paddingTop={2}>
                      <div className="boxTitle">
                        <span>{startupName}</span>
                      </div>
                      {otherReqStartup && otherReqStartup?.requirement ? (
                        <div className="">
                          {/* {otherReqStartup?.requirement} */}
                            <TextField
                              multiline
                              rows={2}
                              disabled
                              defaultValue={
                                (otherReqStartup?.requirement) || ""
                              }
                              fullWidth
                              InputProps={{ disableUnderline: true }}
                            ></TextField>
                        </div>
                      ) : (
                        <div className="body content">{foundStartOther? "":"None"}</div>
                      )}
                      {/* Map Startup Other to Corp */}
                      
                      {foundStartOther && otherReqStartup?.attachment?.length>0 
                      && otherReqStartup?.attachment?.map((item:FileItem, i:any) => (
                      <>
                      {item.id!==undefined?
                      <div key={i} className="mapfile">
                        <img src={DocumentBlueIcon} alt="file_name" />
                        <a href={item?.url || '/'} target="_blank" rel="noreferrer">
                          <div className="upname">
                            {item.name || 'No Files Found'}
                          </div>
                        </a>
                      </div>:<></>} 
                      </> ))}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
        )}
      </>:<></>}
       
      </>
    );
  }
);

export default Scope;
