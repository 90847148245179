import { createApi } from '@reduxjs/toolkit/query/react'
import graphqlBaseQuery from 'services/graphql-base-query'
import graphOptions from './graph-options'
import { gql } from 'graphql-request'
import { 
  Conversations, 
  CatalystConversations,
  PersonProfile 
} from 'generated/graphql'

export const catalystCoversationsApi = createApi({
  reducerPath: "catalystConversationApi",
  baseQuery: graphqlBaseQuery(graphOptions),
  endpoints: (builder) => ({
    getCatalystCurrentConversations: builder.query<Array<Conversations>, void>({
      query: () => ({
        document: gql`
          query {
            getCurrentCatalystConversations {
              conversation {
                id
                name
                image
                logo
                tech_request_name
                conversation_stages_complete {
                  scope
                  business_use_case
                  proposal
                  agreement
                }
                nda_signed
                nda_signed_by_both
              }
            }
          } 
        `
      }),
      transformResponse: (response: any) => response.getCurrentCatalystConversations.map((x: CatalystConversations) => ({...x.conversation}))
    }),
    getCatalystForConversations: builder.query<PersonProfile, number>({
      query: (id) => ({
        document: gql`
          query($id: ID!) {
            catalystConversations(
              where: { conversation: $id },
              limit: 1
            ) {
              users_permissions_user {
                person_profile {
                  photo {
                    url
                  }
                  first_name
                  last_name
                }
              }
            }
          } 
        `,
        variables: {
          id
        }
      }),
      transformResponse: (response: any) => 
        response.catalystConversations.length > 0 ? 
          ({
            ...response.catalystConversations[0].users_permissions_user.person_profile
          }) : null
    }),
  })
})

export const {
  useGetCatalystCurrentConversationsQuery,
  useGetCatalystForConversationsQuery
} = catalystCoversationsApi