import IconCaretLeft from 'assets/svgs/caret-left.svg';
import IconCaretRight from 'assets/svgs/caret-right.svg';
import { open, close } from 'features/modals/modalSlice';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

const ControlBar = () => {
  const { push, goBack } = useHistory();
  const dispatch = useDispatch();
  const noControlBar = new URLSearchParams(window.location.search).get('control_bar');
  
  return (
    <>
    {noControlBar ?
    null :
    <div className="row">
      <div className="col">
        <div className="p-3 m-4 border d-flex flex-column flex-md-row justify-content-between">
          <button className="btn btn-primary long mb-3 mb-md-0" type="button" onClick={() => {
            goBack()
          }}>
            <img src={IconCaretLeft} alt="back" className="mr-1" /> Back</button>
          <button className="btn btn-primary long" type="button" onClick={() => {
            goBack()
            dispatch(open('sign-up'));
          }}>
            Sign up to Access <img src={IconCaretRight} alt="sign up to access" className="ml-1" /> </button>
        </div>
      </div>
    </div>
    }
    </>
  );
}

export default ControlBar;