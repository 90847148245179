import { MenuProps } from 'app/shared/menu';
import DashboardIcon from 'assets/svgs/dashboard.svg';
import ExploreIcon from 'assets/svgs/explore.svg';
import FindaMatchIcon from 'assets/svgs/find-a-match.svg';
import CollaborateIcon from 'assets/svgs/collaborate.svg';
import AlkelioPlusServicesIcon from 'assets/svgs/alkelio-plus-services.svg';

const MenuStartupConstants: MenuProps = {
  links: [
    {
      label: 'Dashboard',
      icon: DashboardIcon,
      route: '/app/startup/dashboard',
      exact: true,
    },
    {
      label: 'Explore',
      icon: ExploreIcon,
      route: '/app/startup/explore',
      exact: false,
    },
    {
      label: 'Find a match',
      icon: FindaMatchIcon,
      route: '/app/startup/matches',
      exact: false,
    },
    {
      label: 'Collaborate',
      icon: CollaborateIcon,
      route: '/app/startup/collaborate',
      exact: false,
    },
    {
      label: 'ALKELIO+ Services',
      icon: AlkelioPlusServicesIcon,
      route: '/app/startup/alkelio-services',
      exact: false,
    }
  ]
}

export default MenuStartupConstants;