import { useSelector } from 'react-redux';
import { appReducerStateType } from 'store';
import './assets/styles/progress.scss';

const Progress = () => {
  const { progress } = useSelector((state: appReducerStateType) => state.inProgress)

  return (
    <>
      {progress ? 
      <div className="sign-up-progress">
        <div className="indicator">
          <div className="loader-inverse-1 center"><span></span></div>
          <h5 className="color-nightsky">Saving</h5>
        </div>
      </div> : 
      <></>}
    </>
  );
}

export default Progress;