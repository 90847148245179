import React from 'react'
import MatchCounter from './match.counter.component'
import { 
  useCountTechnologyRequestMatchQuery
} from 'services/graphql/match-making-result'

type MatchCountTechnologyRequestProps = {
  id: string
}

const MatchCountTechnologyRequest = ({
  id
}: MatchCountTechnologyRequestProps) => {
  const { data } = useCountTechnologyRequestMatchQuery(id)
  return (
    <MatchCounter counter={data ?? 0} suffix='Matches'/>
  )
}

export default MatchCountTechnologyRequest