import React from 'react'
import InputRadioGroup from 'shared/forms/input-radio-group'
import { InfoToolTip } from 'app/shared/info-tooltip/info-tooltip.component'
import { 
  Control, 
  UseFormRegister, 
  UseFormGetValues,
  Controller,
  FieldError, 
  DeepMap,
  DeepPartial
} from 'react-hook-form';
import { Inputs } from '../inputs';
import { NotificationPreferences } from 'generated/graphql'

const FREQUENCY_VALUES = {
  DAILY: 'd',
  WEEKLY: 'w',
  BIMONTHLY: '2w',
  MONTHLY: 'm',
  NONE: ''
}

const DEFAULT_FREQUENCY_SETTINGS = {
  TEAM: 'w',
  PLATFORM: '2w',
  PARTNERS: 'd',
  COLLABORATION: 'd',
  CHAT: 'd',
  ACTIVITY: 'w'
}

const FrequncyOptions = [
  {
    label: "Daily",
    value: FREQUENCY_VALUES.DAILY
  },
  {
    label: "Weekly",
    value: FREQUENCY_VALUES.WEEKLY
  },
  {
    label: "Bi-monthly",
    value: FREQUENCY_VALUES.BIMONTHLY
  },
  {
    label: "Monthly",
    value: FREQUENCY_VALUES.MONTHLY
  },
  {
    label: "None",
    value: FREQUENCY_VALUES.NONE
  }
]

type NotificationsProps = {
  control: Control<Inputs>;
  register: UseFormRegister<Inputs>;
  errors?: DeepMap<DeepPartial<Inputs>, FieldError>;
  getValues?: UseFormGetValues<Inputs>;
  notifications?: NotificationPreferences;
}

export const Notifications = React.forwardRef(({
  control,
  register,
  errors,
  getValues,
  notifications
}: NotificationsProps,
ref: React.ForwardedRef<HTMLDivElement>) => {

  return (
    <form>
      <div className="mt-md-4 form-control-group">
        <h6 className="mb-4 bold-type">Notification preference</h6> 
        <div className="form-control mb-4">
          <label>
            <div className="text">
              <div className="d-flex flex-row align-content-center">
                  ALKELIO Team Notifications
                <InfoToolTip className="ms-1" toolTip="To keep you updated about ALKELIO’s life and any ahdoc communications from your catalyst" />
              </div>
            </div>
          </label>
          <Controller
            name={'notifications.team_notifications'}
            control={control}
            defaultValue={notifications?.team_notifications ?? DEFAULT_FREQUENCY_SETTINGS.TEAM}
            rules={{
              required: false
            }}
            render={({field}) => <InputRadioGroup 
              className="d-flex flex-column mt-1 mt-md-3"
              options={FrequncyOptions} 
              {...field}
            />}
          />
          
        </div>
        <div className="form-control mb-4">
          <label>
            <div className="text">
              <div className="line-height-140 d-flex flex-wrap">
                ALKELIO Platform Notifications 
                <InfoToolTip className="ms-1" toolTip="To keep you updated about new functionalities & relevant content" />
              </div>
            </div>
          </label>
          
          <Controller
            name={'notifications.platform_notifications'}
            control={control}
            defaultValue={notifications?.platform_notifications ?? DEFAULT_FREQUENCY_SETTINGS.PLATFORM}
            rules={{
              required: false
            }}
            render={({field}) => 
              <InputRadioGroup 
                className="d-flex flex-column mt-1 mt-md-3"
                options={FrequncyOptions} 
                {...field}
              />
            }
          />
        </div>
        <div className="form-control mb-4">
          <label>
            <div className="text">
              <div className="d-flex flex-row align-content-center">
                Potential Partner
                <InfoToolTip className="ms-1" toolTip="To let you know who wants to collaborate with you and who has looked at your profile" />
              </div>
            </div>
          </label>
          
          <Controller
            name={'notifications.potential_partner'}
            control={control}
            defaultValue={notifications?.potential_partner ?? DEFAULT_FREQUENCY_SETTINGS.PARTNERS}
            rules={{
              required: false
            }}
            render={({field}) => 
              <InputRadioGroup 
                className="d-flex flex-column mt-1 mt-md-3"
                options={FrequncyOptions} 
                {...field}
              />
            }
          />
          
        </div>
        <div className="form-control mb-4">
          <label>
            <div className="text">
              <div className="d-flex flex-row align-content-center">
                Partner In Collaboration
                <InfoToolTip className="ms-1" toolTip="To keep you updated about any activity & communications from your selected match in the in-collaboration area" />
              </div>
            </div>
          </label>
          <Controller
            name={'notifications.partner_collaboration'}
            control={control}
            defaultValue={notifications?.partner_collaboration ?? DEFAULT_FREQUENCY_SETTINGS.COLLABORATION}
            rules={{
              required: false
            }}
            render={({field}) => 
              <InputRadioGroup 
                className="d-flex flex-column mt-1 mt-md-3"
                options={FrequncyOptions} 
                {...field}
              />
            }
          />
        </div>
        <div className="form-control mb-4">
          <label>
            <div className="text">
              <div className="d-flex flex-row align-content-center">
                Chat
                <InfoToolTip className="ms-1" toolTip="To help you not to miss any important information from your conversation with your match and your ALKELIO catalyst in the chat located in the in-collaboration area" />
              </div>
            </div>
          </label>
          <Controller
            name={'notifications.chat'}
            control={control}
            defaultValue={notifications?.chat ?? DEFAULT_FREQUENCY_SETTINGS.CHAT}
            rules={{
              required: false
            }}
            render={({field}) => 
              <InputRadioGroup 
                className="d-flex flex-column mt-1 mt-md-3"
                options={FrequncyOptions} 
                {...field}
              />
            }
          />
        </div>
        <div className="form-control">
          <label>
            <div className="text">
              <div className="d-flex flex-row align-content-center">
                Activity
                <InfoToolTip className="ms-1" toolTip="To keep you updated about new registrants potentially relevant to you" />
              </div>
            </div>
          </label>
          <Controller
            name={'notifications.activity'}
            control={control}
            defaultValue={notifications?.activity ?? DEFAULT_FREQUENCY_SETTINGS.ACTIVITY}
            rules={{
              required: false
            }}
            render={({field}) => 
              <InputRadioGroup 
                className="d-flex flex-column mt-1 mt-md-3"
                options={FrequncyOptions} 
                {...field}
              />
            }
          />
        </div>
      </div>
    </form>
  )
})