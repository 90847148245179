import { RootState } from 'store'
import Session from 'session'
import Config from 'config'

interface PrepareHeadersOptions {
  getState: () => unknown;
}

export const prepareHeaders = (headers: any, { getState }: PrepareHeadersOptions) => {
  // By default, if we have a token in the store, let's use that for authenticated requests
  const token = (getState() as RootState).auth.token || Session.get()
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }
  return headers
}

const graphOptions = {
  baseUrl: `${Config.graphqlBaseURL}`,
  prepareHeaders: prepareHeaders
}

export default graphOptions;