import StatusBadge from 'app/shared/status-badge';
import { CompletionStatuses } from 'app-constants';
interface CompletionStatusProps {
  watchAll: any
}

const demoFields = [
  'embed_demo_url',
  'demo_document'
]

const standardFields = [
  'name',
  'hero_image',
  'description',
  'benefits_description',
  'technology_types',
  'technology_readiness',
  'industries',
  'corporate_departments',
  'business_use_cases',
  'use_case_count',
  'project_budget',
  'testimonial',
]

const sellingFields = [
  'key_features',
  'supported_operating_systems',
  'resources_required',
  'reason_to_sell',
  'licensing_type_details',
  'versions_release_dates',
  'total_investment',
  'want_to_transfer_resources',
  'assets_to_sell',
  'company_performance_annual_sales'
]

const componentInvestFields = [      
  'key_features',
  'supported_operating_systems',
  'resources_required',
  'reason_to_sell',
  'licensing_type_details',
  'versions_release_dates',
  'total_investment',
  'want_to_transfer_resources',
  'assets_to_sell',
  'company_performance_annual_sales',
  'documents_business_plans',
  'documents_investor_pitch_deck',
  'amount_to_raise'
]

export const completionRate = (obj: any) => {
  let completedCount = 0;
  let count = 0;
  const fieldsSkip: string[] = [];
  for(let k in obj) {
    if (!fieldsSkip.includes(k)) {
      let isCount = false;

      if (standardFields.includes(k)) {
        isCount = true;
      } else
      if (demoFields.includes(k)) {
        isCount = true;
        // add to skip list
        fieldsSkip.concat(demoFields);
      } else
      if (sellingFields.includes(k)) {
        isCount = obj.purpose_sell
      } else
      if ([
        'invest_information'
      ].includes(k)) {
        if (obj.purpose_invest) {
          for(let investKey in obj[k].invest_information) {
            if (componentInvestFields.includes(investKey)) {
                count++;
                if (obj[k].invest_information[investKey] !== undefined
                  && obj[k].invest_information[investKey] !== null
                  && obj[k].invest_information[investKey] !== '') {
                    completedCount++;
                }
              }
          }
        }
        isCount = obj.purpose_sell
      }
  
      if (isCount) {
        count++;
        if (obj[k] !== undefined 
          && obj[k] !== null 
          && String(obj[k]) !== '') {
          completedCount++;
        } else {
        }
      }
    }
  }
  
  return completedCount / count;
}

const CompletionStatus = ({ watchAll } : CompletionStatusProps ) => {
  const rate = completionRate(watchAll)

  const { color, info, label } = rate === 1 ? CompletionStatuses.complete : CompletionStatuses.incomplete;

  return (
    <StatusBadge size="large" color={color} toolTip={info}>{label}</StatusBadge>
  )
}

export default CompletionStatus;