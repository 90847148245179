import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import dayjs from "dayjs";
import Button from "app/shared/button";
import "../assets/styles/conversation.scss";
import Grid from "@material-ui/core/Grid";
// import { Box, TextField } from "@material-ui/core";
import {
  useLazyGetConversationMessagesQuery,
  useCreateConversationMessageMutation,
} from "services/graphql/conversation";
import { selectJwt } from "features/signup/signupSlice";
import UserAvatarImg from "assets/imgs/user-avatar.jpg";

type ChatProps = {
  sid: any;
};
const ChatModule = React.forwardRef(
  ({ sid }: ChatProps, ref: React.Ref<HTMLDivElement>) => {
    const { id }: any = useParams();
    const [ trigger, { data, isLoading, isSuccess } ] = useLazyGetConversationMessagesQuery({
      refetchOnFocus: true,
      refetchOnReconnect: true
    });

    const [createConversationMessage] = useCreateConversationMessageMutation();
    const [messages, setMessages] = React.useState([]);
    const [chat, setChat] = React.useState("");

    const enterMessage = (e: any) => {
      // console.log("key pressed",e.keyCode);
      if (e.keyCode === 13) {
        // console.log("Enter key is pressed");
        sendMessage();
      }
    };
    const handleChange = (e: any) => {
      setChat(e.target.value);
    };
    const sendMessage = async () => {
      // console.log(chat);
      const mid = data?.id as string;

      let oid = sid.myProfile?.person?.user?.id;
      let myMsg = {
        body: String(chat),
        conversation: mid,
        by_user: oid,
      };

      const result = await await createConversationMessage({
        conversation_messages: myMsg,
      }).unwrap();
      setChat("");
      // console.log("chat res", result);
    };

    const addMsgToArray = useCallback(
      (msg: any) => {
        console.log('addMsgToArray')
        let oid = sid.myProfile?.person?.user?.id;
        const msgsList: any = messages.slice();
        let sender = msg.by_user?.person_profile.user;
        let st = false;
        let uname = msg.by_user?.person_profile.first_name;
        let uimg = msg?.by_user?.person_profile?.photo?.url;

        if (sender !== null && sender !== undefined) {
          if (parseInt(sender) === parseInt(oid)) {
            st = true;
            uname = "You";
          }
          let myObj = {
            message: msg.body,
            mine: st,
            time: dayjs(msg.sent_datetime).fromNow(),
            name: uname,
            photo: uimg,
          };
          msgsList.push(myObj);
          setMessages(msgsList);
          scrollToLast();
        }
      },
      [messages]
    );

    const scrollToLast = () => {
      // console.log("func called");
      setTimeout(function () {
        var element = document.getElementById("chat-div");
        if (element) {
          element.scrollTop = element.scrollHeight;
        }
      }, 500);
    };

    const mapMsgToArray = (msgs: any) => {
      // console.log("All msgs", msgs);
      let oid = sid.myProfile?.person?.user?.id;
      // console.log("User's ID", oid);
      const msgsList: any = [];
      if (msgs !== null && msgs !== undefined && msgs !== []) {
        msgs.map((item: any) => {
          let sender = item.by_user?.id;
          let st = false;
          // let uname = item.by_user?.username;
          let uname = item?.by_user?.person_profile.first_name;
          let uimg = item?.by_user?.person_profile?.photo?.url;
          if (sender !== null && sender !== undefined) {
            if (sender === oid) {
              // console.log("This is My message");
              st = true;
              uname = "You";
            }
            let myObj = {
              message: item.body,
              mine: st,
              time: dayjs(item.sent_datetime).fromNow(),
              name: uname,
              photo: uimg,
            };
            msgsList.push(myObj);
          }
        });
        // console.log("afterMap", msgsList);
        setMessages(msgsList);
      }
    };

    const reverseArr = (input:any) => {
      var ret = [];
      for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    };

    React.useEffect(() => {
      // console.log("Conv ID", id);
      // console.log("Messages:", data);
      // console.log("Profile Data", sid);
      console.log('messages updated')
      trigger(id);
      
      if (data !== undefined) {
        mapMsgToArray(reverseArr(data.conversation_messages));
      }
      scrollToLast();
    }, [data, id]);

    React.useEffect(() => {
      // console.log("Init chat", id);
      const socket = io((process.env.REACT_APP_WS as string));
      const jwt = localStorage.getItem("access_token");
      socket.emit("joinConversation", { conversationId: id, jwt: jwt });

      socket.on("message", (res) => {
        // console.log("sckt msg: ",res)
        if (res === "Welcome to the conversation") return;
        addMsgToArray(res);
      });

      return () => {
        //remove socket
        socket.disconnect();
        // console.log("Unmounted");
      };
    }, [addMsgToArray]);

    return (
      <>
        <div className="chatBody" id="chat-div">
          <Grid
            container
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {isLoading ? <>Loading Messages ...</> : <></>}
            {messages &&
              messages.map((msg: any, index) => (
                <>
                  {msg.mine ? (
                    <>
                      <Grid
                        container
                        item
                        xs="auto"
                        key={index}
                        style={{ margin: "24px 0px" }}
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >
                        <div className="chatMsg ">
                          <div className="bubble mine">{msg.message}</div>
                          <img
                            className="chatImg"
                            src={
                              msg.photo || UserAvatarImg
                            }
                            alt={"profile_picture"}
                          />
                        </div>
                        <div className="timestamp mine">
                          {msg.name} - {msg.time}
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        item
                        xs="auto"
                        key={index}
                        style={{ margin: "24px 0px" }}
                        direction="column-reverse"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                      >
                        <div className="timestamp">
                          {msg.name} - {msg.time}
                        </div>

                        <div className="chatMsg">
                          <img
                            className="chatImg"
                            src={
                              msg.photo || "https://i.imgflip.com/2/d0tb7.jpg"
                            }
                            alt={"profile_picture"}
                          />
                          <div className="bubble">{msg.message}</div>
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              ))}
          </Grid>
        </div>
        <div className="chatFooter">
          <div className="input-group seamless">
            <input
              placeholder="Type your message here.."
              type="text"
              value={chat}
              onChange={handleChange}
              onKeyDown={enterMessage}
              className={"chatText"}
            />
            <Button className="sendButton" onClick={sendMessage}>
              Send
            </Button>
          </div>
        </div>
      </>
    );
  }
);

export default ChatModule;
