import { useDispatch, useSelector } from 'react-redux';
import { setStep, selectStep, setVerificationUuid } from 'features/signup/signupSlice';
import { saveCache, getCacheKey } from '../utils/caching';
import { open, close } from 'features/modals/modalSlice';
import useOnboarding from './onboarding.hook'
import {
  CompleteOnboardingSessionByUuidPayload
} from 'generated/graphql'

const useSteps = () => {
  const currentStep = useSelector(selectStep);
  const dispatch = useDispatch();
  const { completeOnboarding } = useOnboarding();
  
  const gotoStep = async (step: number) => {
    dispatch(setStep(step));
  }

  const nextStep = async () => {
    if (currentStep < 4) {
      dispatch(setStep(currentStep + 1))
    } else {
      const completeResult: CompleteOnboardingSessionByUuidPayload | null = await completeOnboarding()
      if (completeResult && completeResult.uuid) {
        dispatch(setVerificationUuid(completeResult.uuid));
        dispatch(open('sign-up-verification'));
      } else {
        // error
        dispatch(open('sign-up-error'))
      }
    }
  }

  const prevStep = () => {
    if (currentStep > 1) {
      dispatch(setStep(currentStep - 1))
    }
  }

  return {
    currentStep,
    gotoStep,
    nextStep,
    prevStep
  };
}

export default useSteps;