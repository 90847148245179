import React, { useState, useEffect } from 'react';
import GenericModal from 'shared/generic-modal';
import Confirm from 'shared/confirm';
import { useForm, Controller, SubmitHandler, UseFormWatch } from 'react-hook-form'
import {
  useHistory,
  useParams
} from 'react-router-dom';
import Tile from 'app/shared/tile';
import InputInline from 'shared/forms/input-inline';
import CompletionStatus from './completion-status';
import Button from 'app/shared/button';
import InputImage from 'shared/forms/input-image';
import InputFiles from 'shared/forms/input-files';
import InputCheckBox from 'shared/forms/input-checkbox';
import SelectMultiple from 'shared/forms/select-multiple';
import SelectOne from 'shared/forms/select-one';
import ProjectBudget from './project-budget';
import NeedHelp from './need-help';
import Certify from './certify';
import Selling from './selling';
import Invest from './invest';
import { 
  useLazyGetOneTechSolutionQuery, 
  useUpdateTechSolutionMutation,
  useDeleteTechSolutionMutation,
  useCreateTechSolutionMutation
} from 'services/graphql/tech-solutions';
import { 
  useGetBusinessUsecasesQuery, 
  useGetCorporateDepartmentsQuery,
  useGetIndustriesQuery,
  useGetTechnologyTypesQuery,
  useGetTechnologyReadinessLevelsQuery,
  useGetSdgObjectivesQuery
} from 'services/graphql/tags';
import { FileItem } from 'hooks/file-uploads';
import { 
  BusinessUseCase, 
  CorporateDepartment, 
  EditTechnologySolutionInput, 
  Industry, 
  TechnologyType, 
  TechnologyReadinessLevel,
  UploadFile,
  SdgObjectives
} from 'generated/graphql';
import Inputs from './inputs';
import { useDispatch } from 'react-redux';
import { open, close } from 'features/modals/modalSlice';
import { toOptions, toValues, unNull } from 'shared/utilities'
import BeforeEditNotification from './before-edit-notification';
import { completionRate } from './completion-status';
import './assets/styles/tech-solution-form.scss';

const TechnologyTypesOptions = (props:any) => {
  const {data, isSuccess} = useGetTechnologyTypesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const TechnologyReadinessOptions = (props:any) => {
  const {data, isSuccess} = useGetTechnologyReadinessLevelsQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const IndustriesOptions = (props:any) => {
  const {data, isSuccess} = useGetIndustriesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const CorporateDepartmentsOptions = (props:any) => {
  const {data, isSuccess} = useGetCorporateDepartmentsQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const BusinessUseCasesOptions = (props:any) => {
  const {data, isSuccess} = useGetBusinessUsecasesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const SDGObjectivesOptions = (props:any) => {
  const {data, isSuccess} = useGetSdgObjectivesQuery()
  return (
    <>
    {isSuccess && props.render(data)}
    </>);
}

const TechSolutionForm = () => {
  const dispatch = useDispatch()
  const { id } : any = useParams()
  const history = useHistory()
  const [ trigger, { data, isLoading, isSuccess } ] = useLazyGetOneTechSolutionQuery()
  const [ updateTechSolution ] = useUpdateTechSolutionMutation()
  const [ deleteTechSolution ] = useDeleteTechSolutionMutation()
  const [ createTechSolution ] = useCreateTechSolutionMutation()
  const isEdit = (id !== undefined)
  
  const { 
    register, 
    handleSubmit, 
    watch, 
    control,
    getValues,
    setValue,
    reset,
    formState: { errors } } = useForm<Inputs>({
      mode: "onChange",
    });

  const watchAll = watch();
  const watchSell = watch("purpose_sell", false)
  const watchInvest = watch("purpose_investment", false)
  const watchOptionSpinOff = watch('is_spin_off', false)

  const completion = completionRate(watchAll)
  
  const getFormInput = ({
    name,
    purpose_poc,
    purpose_sell,
    purpose_lease,
    purpose_investment,
    has_ip,
    is_whitelabel,
    description,
    has_api,
    is_plugandplay,
    hero_image,
    embed_demo_url,
    technology_types,
    sdg_objectives,
    industries,
    business_use_cases,
    corporate_departments,
    is_spin_off,
    spin_off_source,
    is_gdpr,
    is_cloud,
    sdg_driven,
    key_features,
    versions_release_dates,
    supported_operating_systems,
    total_investment,
    resources_required,
    resource_transfer_details,
    licensing_type_details,
    reason_to_sell,
    assets_to_sell,
    is_consumer_data_collected,
    is_tech_transferrable,
    demo_document,
    is_others,
    benefits_description,
    project_budget,
    testimonial,
    case_studies,
    invest_information,
    technology_readiness,
    use_case_count,
    is_use_case_on_request,
    is_consumer_data_collected_remarks,
    is_tech_transferrable_remarks,
    want_to_transfer_resources,
    company_performance_annual_sales,
    
  }: Inputs): EditTechnologySolutionInput => (
    {
      name,
      purpose_poc,
      purpose_sell,
      purpose_lease,
      has_ip,
      is_whitelabel,
      description,
      has_api,
      is_plugandplay,
      hero_image: hero_image ? hero_image.id : null,
      embed_demo_url,
      technology_types,
      sdg_objectives,
      industries,
      business_use_cases,
      corporate_departments,
      is_spin_off,
      spin_off_source,
      is_gdpr,
      is_cloud,
      sdg_driven,
      key_features,
      versions_release_dates,
      supported_operating_systems,
      total_investment,
      resources_required,
      resource_transfer_details,
      licensing_type_details,
      reason_to_sell,
      assets_to_sell,
      is_consumer_data_collected,
      is_tech_transferrable: is_tech_transferrable === 'true' ? true : (is_tech_transferrable === 'false' ? false : null),
      demo_document: (demo_document?? []).map(item => item.id),
      is_others,
      benefits_description,
      purpose_investment,
      project_budget: project_budget.map(({budget, currency, option}) => ({
        budget: Number(budget), 
        currency,
        option
      })),
      testimonial: {
        have_testimonials: testimonial ? testimonial.have_testimonials : false,
        quantity: testimonial ? Number(testimonial.quantity) : 0,
        page_url: testimonial ? testimonial.page_url : '',
        free_text: testimonial ? testimonial.free_text : '',
        uploads: (testimonial && testimonial.uploads) ? testimonial.uploads.map((item) => item.id): []
      },
      case_studies: {
        have_case_studies: case_studies ? case_studies.have_case_studies : false,
        quantity: case_studies ? Number(case_studies.quantity) : 0,
        page_url: case_studies ? case_studies.page_url : '',
        free_text: case_studies ? case_studies.free_text : '',
        uploads: (case_studies && case_studies.uploads) ? case_studies.uploads.map((item) => item.id): []
      },
      invest_information: {
        documents_business_plans: (invest_information !== undefined && invest_information.documents_business_plans !== undefined) ? 
          invest_information.documents_business_plans.map(item => item.id): [],
        documents_investor_pitch_deck: (invest_information !== undefined && invest_information.documents_investor_pitch_deck !== undefined) ? 
          invest_information.documents_investor_pitch_deck.map(item => item.id): [],
        amount_to_raise: invest_information !== undefined ? invest_information.amount_to_raise : 0,
        amount_to_raise_currency: invest_information !== undefined ? invest_information.amount_to_raise_currency : ''
      },
      technology_readiness,
      use_case_count: Number(use_case_count),
      is_use_case_on_request,
      is_consumer_data_collected_remarks,
      is_tech_transferrable_remarks,
      want_to_transfer_resources,
      company_performance_annual_sales
    }
  )

  const handleDelete = () => {
    dispatch(open('confirmation-delete'))
  }

  const onConfirm = () => {
    deleteTechSolution(id)
    history.goBack();
  }

  const onCancel = () => {
    dispatch(close())
  }

  const saveDraft: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (isEdit) {
      await (await updateTechSolution({
        id: id,
        data: {
          ...getFormInput(data),
          is_published: false,
          is_completed: completion === 1
        }
      }).unwrap());
    } else {
      await (await createTechSolution({
        data: {
          ...getFormInput(data),
          is_published: false,
          is_completed: completion === 1
        }
      }).unwrap());
    }

    history.goBack();
  }

  const publish: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (isEdit) {
      await (await updateTechSolution({
        id: id,
        data: {
          ...getFormInput(data),
          is_published: true,
          is_completed: completion === 1
        }
      }).unwrap());
    } else {
      await (await createTechSolution({
        data: {
          ...getFormInput(data),
          is_published: true,
          is_completed: completion === 1
        }
      }).unwrap());
    }

    history.goBack();
  }

  useEffect(() => {
    if (isEdit) {
      trigger(id)
    }
    dispatch(open('before-edit'))
  }, [])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      reset({
        name: data?.name || '',
        hero_image: data?.hero_image as Pick<UploadFile, "id" | "name" | "url">,
        description: data?.description || '',
        benefits_description: data?.benefits_description || '',
        purpose_poc: data?.purpose_poc || false,
        purpose_sell: data?.purpose_sell || false,
        purpose_lease: data?.purpose_lease || false,
        purpose_investment: data?.purpose_investment || false,
        technology_types: data?.technology_types?.map(item => item?.id),
        sdg_objectives: data?.sdg_objectives?.map(item => item?.id),
        technology_readiness: data?.technology_readiness || '',
        industries: data?.industries?.map(item => item?.id),
        corporate_departments: data?.corporate_departments?.map(item => item?.id),
        business_use_cases: data?.business_use_cases?.map(item => item?.id),
        use_case_count: data?.use_case_count || 0,
        is_use_case_on_request: data?.is_use_case_on_request || false,
        has_api: data?.has_api || false,
        is_whitelabel: data?.is_whitelabel || false,
        has_ip: data?.has_ip || false,
        is_plugandplay: data?.is_plugandplay || false,
        is_gdpr: data?.is_gdpr || false, 
        is_cloud: data?.is_cloud || false,
        sdg_driven: data?.sdg_driven || false, 
        is_spin_off: data?.is_spin_off || false,
        spin_off_source: data?.spin_off_source || '',
        is_others: data?.is_others || '',
        embed_demo_url: data?.embed_demo_url || '',
        demo_document: data?.demo_document as Pick<UploadFile, "id" | "name" | "url">[],
        project_budget: data?.project_budget?.map(item => (
          {
            id: item?.id,
            currency: item?.currency || '',
            budget: item?.budget || 0,
            option: item?.option || '',
          }
        )),
        testimonial: data?.testimonial ? {
          id: data?.testimonial?.id,
          have_testimonials: data?.testimonial?.have_testimonials || false,
          quantity: data?.testimonial?.quantity || 0,
          page_url: data?.testimonial?.page_url || '',
          free_text: data?.testimonial?.free_text || '',
          uploads: data?.testimonial?.uploads as Pick<FileItem, "id" | "name" | "url">[],
        }: undefined,
        case_studies: data?.case_studies ? {
          id: data?.case_studies?.id,
          have_case_studies: data?.case_studies?.have_case_studies || false,
          quantity: data?.case_studies?.quantity || 0,
          page_url: data?.case_studies?.page_url || '',
          free_text: data?.case_studies?.free_text || '',
          uploads: data?.case_studies?.uploads as Pick<FileItem, "id" | "name" | "url">[],
        }: undefined,
        key_features: data?.key_features || '',
        supported_operating_systems: data?.supported_operating_systems || '',
        resources_required: data?.resources_required || '',
        is_consumer_data_collected: data?.is_consumer_data_collected || false,
        is_consumer_data_collected_remarks: data?.is_consumer_data_collected_remarks || '',
        reason_to_sell: data?.reason_to_sell || '',
        licensing_type_details: data?.licensing_type_details || '',
        versions_release_dates: data?.versions_release_dates || '',
        total_investment: data?.total_investment || '',
        resource_transfer_details: data?.resource_transfer_details || '',
        want_to_transfer_resources: data?.want_to_transfer_resources || '',
        is_tech_transferrable: data?.is_tech_transferrable === true ? 'true' : (data?.is_tech_transferrable === false ? 'false' : 'other'),
        is_tech_transferrable_remarks: data?.is_tech_transferrable_remarks || '',
        assets_to_sell: data?.assets_to_sell || '',
        company_performance_annual_sales: data?.company_performance_annual_sales || '',
        invest_information: {
          documents_business_plans: data?.invest_information?.documents_business_plans as Pick<FileItem, "id" | "name" | "url">[],
          documents_investor_pitch_deck: data?.invest_information?.documents_investor_pitch_deck as Pick<FileItem, "id" | "name" | "url">[],
          amount_to_raise: data?.invest_information?.amount_to_raise || 0,
          amount_to_raise_currency: data?.invest_information?.amount_to_raise_currency || ''
        }
      });
    }
  }, [data])

  const ActionRow = (
    props: {
      className?: string
    }
  ) => (
    <div className={`mb-0 row p-0 ${props.className}`}>
      <div className="col-12 col-md-6 p-0 mb-3">
        <CompletionStatus {...{ watchAll }} />
      </div>
      <div className="col-12 col-md-6 p-0 d-flex flex-column flex-md-row justify-content-end actions">
        {isEdit && <Button type="button" name="delete" className="mb-3 mb-md-0 mx-md-2" onClick={handleDelete}>Delete</Button>}
        <Button type="button" name="save" value="draft" className="mb-3 mb-md-0 mx-md-2" onClick={handleSubmit(saveDraft)}>Save as draft</Button>
        <Button type="button" name="save" value="publish" className="mb-3 mb-md-0 mx-md-2" variant="primary" onClick={handleSubmit(publish)}>Publish</Button>
      </div>
    </div>
  )

  return (
    <Tile>
      {isLoading && <div>Loading</div>}
      {(isSuccess || !isEdit) && <form>
        <div className="tech-solution-form-grid">
          <div className="field-title">
            <Controller
              name="name"
              control={control}
              rules={{
                required: true
              }}
              render={({field}) => <InputInline
                placeholder="Enter the name of your technology solution"
                {...field} /> }
            />
            <div className={`invalid-feedback ${errors.name ? 'd-block':''}`}>This field is required</div>
          </div>
          <div className="action-top">
            <ActionRow className="action-row-top" />
          </div>
          <div className="field-image">
            <Controller
              name="hero_image"
              control={control}
              defaultValue={data?.hero_image as FileItem || null}
              rules={{ 
                required: false
              }}
              render={({field}) => <InputImage 
                {...field} />}
            />
            <div className={`invalid-feedback ${errors.hero_image ? 'd-block':''}`}>Please upload an image</div>
          </div>
          <div className="field-tech-background">
            <div className="form-control-group w-100">
              <h6 className="bold-type d-block d-md-none mb-4">Background</h6>
              <div className="form-control mb-3 order-1">
                <label>
                  <div className="field-header">Description of your tech solution</div>
                  <textarea
                    defaultValue={data?.description || ''}
                    {...register("description")} ></textarea>
                </label>
              </div>
              <div className="form-control mb-3 order-3">
                <label>
                  <div className="field-header">Why you? Benefits of your tech solution</div>
                  <textarea 
                    defaultValue={data?.benefits_description || ''}
                    {...register("benefits_description")}
                    ></textarea>
                </label>
              </div>
              <div className="form-control order-2 mb-0">
                <div className="flex-column flex-md-row flex-wrap d-flex align-items-start justify-content-between">
                  <Controller 
                    name="purpose_poc"
                    control={control}
                    defaultValue={data?.purpose_poc || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Do Proof of Concept (POC)" 
                      {...field} />}
                  />
                  <Controller 
                    name="purpose_lease"
                    control={control}
                    defaultValue={data?.purpose_lease || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Lease (contract)" 
                      {...field} />}
                  />
                  <Controller 
                    name="purpose_sell"
                    control={control}
                    defaultValue={data?.purpose_sell || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Sell definitely" 
                      {...field} />}
                  />
                  <Controller 
                    name="purpose_investment"
                    control={control}
                    defaultValue={data?.purpose_investment || false}
                    render={({field}) => <InputCheckBox className="mb-2 mr-2" label="Get investment (CVC)" 
                      {...field} />}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field-options">
            <div className="form-control-group">
              <div className="mb-2 row p-0">
                <div className="col p-0">
                  <label>
                    <div className="bold d-block d-md-none mb-4">
                      Options your solution offers
                    </div>
                    <div className="bold d-none d-md-block">Options that apply to your tech solution</div>
                    <div className="mt-3 mb-0 form-control">
                      <div className="wrap-2-md-column ">
                        <Controller
                          name="has_api"
                          control={control}
                          defaultValue={data?.has_api || false} 
                          render={({field}) => 
                            <InputCheckBox label="API available" 
                              className="mb-3 mb-md-2"
                            { ...field} />
                          }
                        />
                        <Controller
                          name="is_whitelabel"
                          control={control}
                          defaultValue={data?.is_whitelabel || false}
                          render={({field}) => 
                            <InputCheckBox 
                              label="White label available" 
                              className="mb-3 mb-md-2"
                              {...field} />
                          }
                        />
                        <Controller
                          name="has_ip"
                          control={control}
                          defaultValue={data?.has_ip || false}
                          render={({field}) => 
                            <InputCheckBox label="Registered IP" 
                            className="mb-3 mb-md-2"
                            {...field} />
                          }
                        />
                        <Controller
                          name="is_plugandplay"
                          control={control}
                          defaultValue={data?.is_plugandplay || false}
                          render={({field}) => 
                            <InputCheckBox label="Ready to Plug &amp; Play" 
                              className="mb-3 mb-md-2"
                              {...field} />
                          }
                        />
                        <Controller
                          name="is_gdpr"
                          control={control}
                          defaultValue={data?.is_gdpr || false}
                          render={({field}) => 
                            <InputCheckBox label="GDPR" 
                              className="mb-3 mb-md-2"
                              {...field} />
                          }
                        />
                        <Controller
                          name="is_cloud"
                          control={control}
                          defaultValue={data?.is_cloud || false}
                          render={({field}) => 
                            <InputCheckBox label="Cloud" 
                              className="mb-3 mb-md-2"
                              {...field} />
                          }
                        />
                        <Controller
                          name="sdg_driven"
                          control={control}
                          defaultValue={data?.sdg_driven || false}
                          render={({field}) => 
                            <InputCheckBox label="SDG Driven" 
                              className="mb-3 mb-md-2"
                              {...field} />
                          }
                        />
                        <Controller
                          name="is_spin_off"
                          control={control}
                          defaultValue={data?.is_spin_off || false}
                          render={({field}) => 
                            <InputCheckBox label="Spin-off / Tested" 
                              className="mb-3 mb-md-2"
                              {...field} />
                          }
                        />
                        {watchOptionSpinOff &&
                        <input type="text" 
                          placeholder="Name of spin-off" 
                          defaultValue={data?.spin_off_source || ''} 
                          {...register("spin_off_source")} />
                        }
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="row mb-0 mb-3 p-0">
                <div className="col p-0">
                  <label>
                    <div className="text">Other</div>
                    <input type="text" 
                      defaultValue={unNull(data?.is_others)} 
                      placeholder="" 
                      {...register("is_others")}/>
                  </label>
                </div>
              </div>
              <div className="mb-0 p-0 row d-none d-md-block">
                <div className="col p-0">
                  <Certify />
                </div>
              </div>
            </div>
          </div>
          <div className="field-tech-type">
            <div className="form-control-group">
              <div className="form-control mb-3">
                <label>
                  <div className="bold">Type of technology</div>
                  <TechnologyTypesOptions 
                    render={(technologyTypes: Array<TechnologyType> ) => (
                      <Controller 
                        name="technology_types"
                        control={control}
                        defaultValue={toValues(data?.technology_types || [])}  
                        render={({ field }) => <SelectMultiple
                          options={toOptions(technologyTypes)} 
                          {...field} 
                          /> }
                      />
                    )} />
                </label>
              </div>
              <div className="form-control">
                <label>
                  <div className="bold">Technology Readiness Level</div>
                  <TechnologyReadinessOptions
                    render={(technologyReadinessLevels: Array<TechnologyReadinessLevel>) => (
                      <Controller 
                        name="technology_readiness"
                        defaultValue={data?.technology_readiness || ''}
                        control={control}
                        render={({field}) => <SelectOne 
                          options={toOptions(technologyReadinessLevels)} 
                          {...field} />}
                      />
                    )}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-industries">
            <div className="form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">Potential industries interested</div>
                  <IndustriesOptions
                    render={(industries: Array<Industry> ) => (
                      <Controller 
                        name="industries"
                        control={control}
                        defaultValue={toValues(data?.industries || [])} 
                        render={({field}) => <SelectMultiple
                          options={toOptions(industries)} 
                          {...field} />}
                      />
                    )}
                    />
                </label>
              </div>
              
            </div>
          </div>

          <div className="field-sdg-objectives">
            <div className="form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">SDG Objectives</div>
                  <SDGObjectivesOptions
                    render={(sdgObjectives: Array<SdgObjectives> ) => (
                      <Controller 
                        name="sdg_objectives"
                        control={control}
                        defaultValue={toValues(data?.sdg_objectives || [])} 
                        render={({field}) => <SelectMultiple
                          sort={false}
                          options={toOptions(sdgObjectives)} 
                          {...field} />}
                      />
                    )}
                    />
                </label>
              </div>
            </div>
          </div>

          <div className="field-departments">
            <div className="form-control-group">
              <div className="form-control">
                <label>
                  <div className="bold">Potential departments interested</div>
                  <CorporateDepartmentsOptions
                    render={(corporateDepartments: Array<CorporateDepartment> ) => (
                      <Controller 
                        name="corporate_departments"
                        control={control}
                        defaultValue={toValues(data?.corporate_departments)}
                        render={({field}) => <SelectMultiple
                          options={toOptions(corporateDepartments)} 
                          {...field} />}
                      />
                    )}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-business-use-case">
            <div className="form-control-group">
              <div className="row p-o">
                <div className="col p-0">    
                  <div className="form-control">
                    <label>
                      <div className="bold">Potential business/use cases</div>
                      <BusinessUseCasesOptions 
                        render={(businessUseCases: Array<BusinessUseCase>)=> (
                          <Controller
                            name="business_use_cases"
                            control={control}
                            defaultValue={toValues(data?.business_use_cases)}
                            render={({field}) => <SelectMultiple 
                              options={toOptions(businessUseCases)} 
                              {...field} />}
                          />
                        )}
                      />
                    </label>
                  </div>
                </div>    
              </div>
              <div className="row p-0 mb-0 mb-md-3">
                <div className="col-12 col-md-5 p-0">
                  <div className="mb-0 form-control">
                    <label>
                      <div className="text">Number of use cases</div>
                      <input type="number" 
                        defaultValue={data?.use_case_count || 0} 
                        {...register("use_case_count")} />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-7 p-0">
                  <div className="mt-3 mb-0 form-control">
                    <Controller
                      name="is_use_case_on_request"
                      control={control}
                      defaultValue={data?.is_use_case_on_request || false}
                      render={({field}) => <InputCheckBox
                        label="Can provide examples on request" 
                        {...field} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field-pricing">
            <ProjectBudget
              {...{ control, register, errors, getValues }}
            />
          </div>
          <div className="field-need-help">
            <NeedHelp />
          </div>
          
          <div className="field-demo">
            <div className="form-control-group">
              <label>
                <div className="bold">Demo area</div>
              </label>
              <div className="mb-2 form-control">
                <label>
                  <div className="text">Demo Url</div>
                  <input type="text" 
                    defaultValue={unNull(data?.embed_demo_url)} 
                    {...register("embed_demo_url")} />
                </label>
              </div>
              <div className="hr split-left-right">or</div>
              <div className="mt-2 form-control">
                <label>
                  <div className="mb-2 text">Upload Document / Video</div>
                  <Controller 
                    name="demo_document"
                    control={control}
                    defaultValue={(data && data.demo_document) ? data?.demo_document as FileItem[] : []}
                    render={({field}) => <InputFiles maxFileCount={3} 
                      {...field} />}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-testimonials">
            <div className="form-control-group">
              <div className="flex-row row d-flex align-items-stretch mb-0">
                <div className="col-12 col-md-6 p-0 pe-md-5 mb-4 mb-md-0">
                  <label className="mb-md-4">
                    <div className="bold">Testimonials from the use of your tech solution </div>
                  </label>
                  <Controller 
                    name="testimonial.have_testimonials"
                    control={control}
                    defaultValue={data?.testimonial?.have_testimonials || false}
                    render={({field}) => 
                      <InputCheckBox 
                        label="We have Testimonials"
                        {...field} />
                    }
                  />
                  <div className="mt-4 form-control">
                    <label>
                      <div className="text">How many testimonials do you have?</div>
                      <input type="number" 
                        defaultValue={data?.testimonial?.quantity || 0} 
                        {...register("testimonial.quantity")} />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 p-0 ps-md-5">
                  <label className="mb-md-4">
                    <div className="bold">Testimonials</div>
                  </label>
                  <div className="form-control mb-3">
                    <label>
                      <div className="text">Page URL</div>
                      <input type="text" 
                        defaultValue={data?.testimonial?.page_url || ''} 
                        {...register("testimonial.page_url") } />
                    </label>
                  </div>
                  <div className="form-control mb-3">
                    <label>
                      <div className="text">Free Text</div>
                      <textarea
                        defaultValue={data?.testimonial?.free_text || ''} 
                        {...register("testimonial.free_text")} />
                    </label>
                  </div>
                  <div className="mt-2 form-control">
                    <label>
                      <div className="mb-2 text">Upload Document / Video</div>
                      <Controller
                        name="testimonial.uploads"
                        control={control}
                        defaultValue={data?.testimonial?.uploads as Array<FileItem> || []} 
                        render={({field}) => <InputFiles 
                          maxFileCount={10}
                          {...field} />}
                      />
                      
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field-case-studies">
            <div className="form-control-group">
              <div className="flex-row p-0 mb-0 mb-md-3 row d-flex align-items-stretch">
                <div className="col-12 col-md-6 p-0 pe-md-5">
                  <label className="mb-4">
                    <div className="bold">Case studies from the use of your tech solution </div>
                  </label>
                  <Controller 
                    name="case_studies.have_case_studies"
                    control={control}
                    defaultValue={data?.case_studies?.have_case_studies || false}
                    render={({field}) => <InputCheckBox 
                      label="We have Case Studies" 
                      {...field} />}
                  />
                  <div className="mt-4 form-control">
                    <label>
                      <div className="text">How many case studies do you have?</div>
                      <input type="number" 
                        defaultValue={data?.case_studies?.quantity || 0} 
                        {...register("case_studies.quantity")} />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 p-0 ps-md-5 mt-4 mt-md-0">
                  <label className="mb-4">
                    <div className="bold">Case Studies</div>
                  </label>
                  <div className="form-control mt-3 mt-md-0 mb-3">
                    <label>
                      <div className="text">Page URL</div>
                      <input type="text" 
                        defaultValue={data?.case_studies?.page_url || ''} 
                        {...register("case_studies.page_url") } />
                    </label>
                  </div>
                  <div className="form-control mb-3">
                    <label>
                      <div className="text">Free Text</div>
                      <textarea
                        defaultValue={data?.case_studies?.free_text || ''} 
                        {...register("case_studies.free_text")} />
                    </label>
                  </div>
                  <div className="mt-2 form-control">
                    <label>
                      <div className="mb-2 text">Upload Document / Video</div>
                      <Controller
                        name="case_studies.uploads"
                        control={control}
                        defaultValue={data?.case_studies?.uploads as Array<FileItem> || []} 
                        render={({field}) => <InputFiles 
                          maxFileCount={10}
                          {...field} />}
                      />
                      
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field-selling-info">
              {((watchSell === undefined ? data?.purpose_sell : watchSell) || (watchInvest === undefined ? data?.purpose_investment : watchInvest)) && <Selling {...{ control, register, watch, errors: {
              key_features: errors.key_features,
              supported_operating_systems: errors.supported_operating_systems,
              resources_required: errors.resources_required,
              is_consumer_data_collected: errors.is_consumer_data_collected,
              is_consumer_data_collected_remarks: errors.is_consumer_data_collected_remarks,
              reason_to_sell: errors.reason_to_sell,
              licensing_type_details: errors.licensing_type_details,
              versions_release_dates: errors.versions_release_dates,
              total_investment: errors.total_investment,
              resource_transfer_details: errors.resource_transfer_details,
              want_to_transfer_resources: errors.want_to_transfer_resources,
              is_tech_transferrable: errors.is_tech_transferrable,
              is_tech_transferrable_remarks: errors.is_tech_transferrable_remarks,
              assets_to_sell: errors.assets_to_sell,
              company_performance_annual_sales: errors.company_performance_annual_sales
            } }} />}
          </div>
          <div className="field-invest-info">
            {watchInvest === undefined ? data?.purpose_investment : watchInvest}
            {(watchInvest === undefined ? data?.purpose_investment : watchInvest) && <Invest invest_information={{
              documents_business_plans: data?.invest_information?.documents_business_plans as Array<FileItem>,
              documents_investor_pitch_deck: data?.invest_information?.documents_investor_pitch_deck as Array<FileItem>,
              amount_to_raise: data?.invest_information?.amount_to_raise || 0,
              amount_to_raise_currency: data?.invest_information?.amount_to_raise_currency || '',
            }} {...{ control, register, errors: errors.invest_information }} />}
          </div>
          <div className="action-bottom">
            <ActionRow className="action-row-bottom" />
          </div>
        </div>
      </form>}
      
      <GenericModal modalId="confirmation-delete" size="sm" title="Confirm Delete" centered={true}>
        <Confirm 
          body={`Deleting of technology solution ${data?.name} is permanent. Are you sure you want to continue?`}
          onConfirm={onConfirm} 
          onCancel={onCancel} />
      </GenericModal>
      <GenericModal modalId="before-edit" size="md" centered>
        <BeforeEditNotification />
      </GenericModal>
    </Tile>
  )
}

export default TechSolutionForm;