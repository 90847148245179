import CurrencyCode from 'currency-codes';

export const CurrenciesPreferred: string[] = [
  'USD',
  'EUR',
  'CHF',
  'SGD'
]

export const Currencies = CurrencyCode.codes().sort((a, b) => (b > a) ? 1 : ((b < a) ? -1 : 0)).sort((a, b) => {
  for (var i = 0; i < CurrenciesPreferred.length; i++) {
    if (b === CurrenciesPreferred[i]) {
      return (i + 1);
    }
  }

  return -1
});

export const EmployeeCountRange = [
  'up to 500',
  '500 - 1500',
  '1500 - 5000',
  '5,000 - 10,000',
  '10,000+'
]

export const ProjectBudgetOptions = [
  'POC',
  'Lease (Contract)',
  'Sell'
]

export const ProjectBudgetOptionsStartup = [
  'POC',
  'Lease (Contract)',
  'Sell'
]

export const ProjectBudgetOptionsCorporate = [
  'Test (POC)',
  'Adopt (Contract)',
  'Buy (M&A)',
  'Invest'
]

type Service = {
  image: string;
  title: string;
  body: string;
}

export const AdhocServicesCorporateList: Service[] = [
  {
    image: '/imgs/adhoc-services/corporate/business-challenge.png',
    title: 'Help me Identify a need, validate or redefine my business challenge',
    body: 'We audit your organization to understand where you are,  your vision and  identify, validate or redefine the challenge(s) to tackle.'
  },
  {
    image: '/imgs/adhoc-services/corporate/use-case.png',
    title: 'Identify a use case from a new tech solution',
    body: 'From tech to the problem, we build backwards to propose  innovative applications of technology for your organization.'
  },
  {
    image: '/imgs/adhoc-services/corporate/evaluate.png',
    title: 'Evaluate a startup and their tech solution for me',
    body: 'We provide due diligence on-demand with the objective of collaboration, investment or acquisition. '
  },
  {
    image: '/imgs/adhoc-services/corporate/scout-on-demand.png',
    title: 'Find a specifc tech solution for me (scouting on demand)',
    body: 'We provide scouting on demand if you need a specific tech solution or partner not registered on our platform.'
  },
  {
    image: '/imgs/adhoc-services/corporate/growth-opportunities.png',
    title: 'Identify growth opportunities',
    body: 'With our holistic systems thinking and futuristic approach, we identify strategic areas to focus on to ensure your organization future success. '
  },
  {
    image: '/imgs/adhoc-services/corporate/systems-thinking.png',
    title: 'Help me identify an ecosystem of partners ( systems thinking)',
    body: 'We use the collective intelligence of our ecosystem to build cohesive and integrated solutions to your problem & needs.'
  },
  {
    image: '/imgs/adhoc-services/corporate/prediction.png',
    title: 'Predict which technologies would disrupt my industry ',
    body: 'We predict where the future will go through emerging trends & forecasting methodologies, providing you with the tools to be prepared.'
  },
  {
    image: '/imgs/adhoc-services/corporate/pitch.png',
    title: 'Pitch the case to my organization',
    body: 'We write & help you pitch the business case of the solution to your need to your organization to ensure the final ‘buy’ & sponsorship.'
  },
  {
    image: '/imgs/adhoc-services/corporate/implement.png',
    title: 'Implement a tech solution to my organization',
    body: 'Once you have agreed on the right partner to work with, we can help you coordinate the project and implement the solution.'
  }
]

export const AdhocServicesStartupList: Service[] = [
  {
    image: '/imgs/adhoc-services/startup/valuation.png',
    title: 'Get a valuation of my technology',
    body: 'Whether you are looking for investments -or other- our experts will audit & analyze your tech solution to get a realistic valuation. '
  },
  {
    image: '/imgs/adhoc-services/startup/certify.png',
    title: 'Certify & Label my tech solution',
    body: 'After testing your solution, we can provide you with official proof of your tech solution\'s performance, security, and usability.'
  },
  {
    image: '/imgs/adhoc-services/startup/price.png',
    title: 'Price my tech solution',
    body: 'If you are not clear on how to price your solution or which revenue model to follow, we analyze the market to give you the answer.'
  },
  {
    image: '/imgs/adhoc-services/startup/new-business-applications.png',
    title: 'Identify new business applications',
    body: 'Thanks to our holistic view and access to multiple industries, we can recommend the most promising applications for your tech solution if you want to diversify or pivot.'
  },
  {
    image: '/imgs/adhoc-services/startup/lead.png',
    title: 'Find a specific qualified lead for me ',
    body: 'We provide business development on demand, identifying further leads for you, other than those you see in the platform. '
  },
  {
    image: '/imgs/adhoc-services/startup/gtm.png',
    title: 'Help me with my Go To Market (GTM) strategy',
    body: 'If you are ready to conquer the market but not sure how to do it, we advise on the right strategy to ensure a fast & smooth penetration.'
  },
  {
    image: '/imgs/adhoc-services/startup/validate.png',
    title: 'Help me validate my solution in the market (product-market fit)',
    body: 'Your tech solution is ready, but not sure there is a need for it? We help you gather the necessary insights to validate your fit or pivot.'
  },
  {
    image: '/imgs/adhoc-services/startup/new-business-opportunities.png',
    title: 'Identify new business opportunities',
    body: 'You have launched a tech solution/ product successfully and want to diversify your portfolio? We help you identify further opportunities to grow your business.'
  },
  {
    image: '/imgs/adhoc-services/startup/pitch.png',
    title: 'Help me write my Pitch sales & Investment deck ',
    body: 'Want to create a killer sales or investment pitch deck? Our team of storytellers provide kickass presentations for you to mic drop!'
  }
]

export enum EnumCorporatePersonProfile_DecisionType {
  DecisionMaker = 'decision-maker',
  Influencer = 'decision-influencer',
  Other = 'decision-other'
}

export const SignUpPurposesStartup = [
  {
    label: 'Explore Business Needs',
    key: 'purpose_explore'
  },
  {
    label: 'Do a Proof of Concept (POC)',
    key: 'purpose_poc'
  },
  {
    label:'Lease my tech solution (Contract)',
    key: 'purpose_lease'
  },
  {
    label: 'Sell definitely<br/> my tech solution (M&A)',
    key: 'purpose_sell'
  },
  {
    label: 'Get corporate investments (CVC)',
    key: 'purpose_investment'
  },
  {
    label: 'Access to ALKELIO+ services',
    key: 'purpose_access_alkelio_services'
  }
]

export const SignUpPurposeCorporate = [
  {
    label: 'Explore New Technologies',
    key: 'want_explore'
  },
  {
    label: 'Test a tech solution (POC)',
    key: 'want_poc'
  },
  {
    label: 'Adopt a tech solution (Contract)',
    key: 'want_lease'
  },
  {
    label: 'Buy a tech solution (M&A)',
    key: 'want_seller'
  },
  {
    label: 'Invest in a tech solution (CVC)',
    key: 'want_investmnet'
  },
  {
    label: 'Access to ALKELIO+ services',
    key: 'want_access_alkelio_services'
  }
]

export const AppSettings = {
  maxFileSizeMB: 50
}

type BadgeStatus = {
  info: string;
  label: string;
  color: "in-progress" | "success";
}

type CompletionStatusProps = {
  complete: BadgeStatus;
  incomplete: BadgeStatus;
}

export const CompletionStatuses: CompletionStatusProps = {
  complete: {
    info: 'We have all the information that we need to propose to you a qualified match. But remember the more you complete your profile, the more qualified your match will be',
    color: 'success',
    label: 'Completed'
  },
  incomplete: {
    info: 'Please complete your profile as much as possible. The more information we have, the more qualified your match will be!',
    color: 'in-progress',
    label: 'In Progress'
  }
}

export const ESGRatingOptions = [
  { label: 'AAA-Leader', value: 'AAA' },
  { label: 'AA-Leader', value: 'AA' },
  { label: 'A-Average', value: 'A' },
  { label: 'BBB-Average', value: 'BBB' },
  { label: 'BB-Average', value: 'BB' },
  { label: 'B-Laggard', value: 'B' },
  { label: 'CCC-Laggard', value: 'CCC' }
]