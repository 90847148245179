import CalendarPlusIcon from './assets/svgs/calendar-plus.svg';
import PhoneIcon from './assets/svgs/phone.svg';
import EmailIcon from './assets/svgs/email.svg';
import './assets/styles/call-to-action.scss';

const CallToAction = () => {
  return (
    <div className="call-to-action">
      <h5>Not what you were looking for or want to chat first? Contact us</h5>
      <div className="links">
        <a href="https://calendly.com/alkelio-team/meeting-with-alkelio-team" target="_blank" 
          className="link" rel="noreferrer">
          <img src={CalendarPlusIcon} alt="Book a meeting" className="mb-1 me-md-2" />
          <span>Book a meeting</span>
        </a>
        <a href="mailto: contact@alkelio.com" 
          className="link">
          <img src={EmailIcon} alt="Email us" className="mb-1 me-md-2" />
          <span>Email us</span>
        </a>
      </div>
    </div>
  );
}

export default CallToAction;