import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Hero from "./hero";
import Footer from "./footer";
import './assets/styles/home.scss';
import Header from "shared/header";
import GenericModal from 'shared/generic-modal';
import GenericRouteModal from 'shared/generic-route-modal';
import Login from 'shared/login';
import SignUpV2 from 'shared/sign-up-v2/sign-up';
import SignUpError from "shared/sign-up-v2/error";
import SignUpVerification from 'shared/sign-up-verification';
import SignIn from "shared/sign-in";
import ForgotPassword from 'shared/forgot-password';
import ResetPassword from 'shared/reset-password';

import About from './about';
import Services from './services';
import WhyChooseUs from './why-choose-us';
import Team from './team';
import HowitWorks from "./how-it-works";
import SignUpFaq from "./signup-faq";
import ContactUs from "./contact-us";
import AcceptCookie from "./accept-cookie";
import useCookiePermissions from 'hooks/cookie-permission';
import { open, close } from 'features/modals/modalSlice';
import { useLocation } from "react-router-dom";

import AdhocServiceCorporate from "./adhoc-services/corporate";
import AdhocServiceStartup from "./adhoc-services/startup";

const Home = () => {
  const acceptCookieModalId = 'accept-cookie';
  const dispatch = useDispatch();
  const { setPermissions, hasPermission } = useCookiePermissions();
  const [homeClass, setHomeClass] = useState('home');
  const [showNavigation, setShowNavigation] = useState(false);

  useEffect(() => {
    if (!hasPermission) {
      dispatch(open(acceptCookieModalId))
    }
  }, [hasPermission]);

  return (
    <div className={homeClass}>
      <Header />
      {
        showNavigation ? (null) : (
          <>
            <Hero />
            <About />
            <Services />
            <WhyChooseUs />
            <Team />
            <HowitWorks />
            <SignUpFaq />
            <ContactUs />
            <Footer />
          </>
        )
      }
      
      <GenericRouteModal modalId="login" size="sm" centered>
        <Login />
      </GenericRouteModal>
      <GenericModal modalId={acceptCookieModalId} size="sm" centered>
        <AcceptCookie
          onAcceptAll={(flags) => {
            setPermissions(flags);
            dispatch(close())
          }}
          onDeclineAll={() => {
            setPermissions([]);
            dispatch(close())
          }} />
      </GenericModal>
      <GenericModal modalId="sign-up" size="lg">
        <SignUpV2 />
      </GenericModal>
      <GenericModal modalId="sign-up-verification" size="sm" centered>
        <SignUpVerification />
      </GenericModal>
      <GenericModal modalId="sign-up-error" size="sm" centered>
        <SignUpError />
      </GenericModal>
      <GenericModal modalId="sign-in" size="sm">
        <SignIn />
      </GenericModal>
      <GenericRouteModal modalId="forgot-password" size="sm" centered>
        <ForgotPassword />
      </GenericRouteModal>
      <GenericRouteModal modalId="reset-password" size="sm" centered>
        <ResetPassword />
      </GenericRouteModal>
      <GenericRouteModal modalId="adhoc-service-corporate" size="xl" thinMargins>
        <AdhocServiceCorporate />
      </GenericRouteModal>
      <GenericRouteModal modalId="adhoc-service-startup" size="xl" thinMargins>
        <AdhocServiceStartup />
      </GenericRouteModal>
    </div>
  );
};

export default Home;
